<div [formGroup]="parentForm" class="card-v2">
  <div class="card-v2-title mb-4">{{ 'Contacts.V2.contactCommunicationTitle' | translate }}</div>

  <div class="row-grid mb-2">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="control-v2">
          <input-label [label]="'Contacts.V2.phone' | translate"></input-label>
          <div *ngIf="isPreview" class="control-v2-info">
            <span *ngIf="phone">{{ parentForm.get(ContactDetails.phoneCountry)?.value | phonePrefix }}</span>
            {{ phone | defaultValue }}
          </div>
          <phone-input-v2
            [hidden]="isPreview"
            [inputPlaceholder]="'Contacts.V2.phonePlaceholder' | translate"
            [form]="parentForm"
            [phoneFlagName]="ContactDetails.phoneCountry"
            [phoneName]="ContactDetails.phone"
          ></phone-input-v2>
        </div>
      </div>

      <div class="col-12 col-lg-6 mb-4 mb-lg-0 order-first order-lg-last">
        <div class="control-v2">
          <input-label [label]="'Contacts.V2.email' | translate"></input-label>
          <div *ngIf="isPreview" class="control-v2-info">
            {{ parentForm.get(ContactDetails.email)?.value | defaultValue }}
          </div>
          <input-v2
            [hidden]="isPreview"
            [formControlName]="ContactDetails.email"
            [placeholder]="'Contacts.V2.emailPlaceholder' | translate"
          ></input-v2>
        </div>
      </div>
    </div>
  </div>

  <ng-container [formArrayName]="ContactDetails.communications">
    <ng-container *ngFor="let communicationForm of communications.controls; let i = index">
      <div class="row-grid mt-4">
        <div [formGroup]="communicationForm" class="row">
          <div class="col-12 col-lg-7">
            <div class="control-v2">
              <input-label [label]="'Contacts.V2.phone' | translate"></input-label>
              <div *ngIf="isPreview" class="control-v2-info">
                <span *ngIf="communicationForm.get(ContactDetails.phone)?.value">
                  {{ communicationForm.get(ContactDetails.phoneCountry)?.value | phonePrefix }}
                </span>
                {{ communicationForm.get(ContactDetails.phone)?.value | defaultValue }}
              </div>
              <div [hidden]="isPreview" class="input-row">
                <phone-input-v2
                  [inputPlaceholder]="'Contacts.V2.phonePlaceholder' | translate"
                  [form]="communicationForm"
                  [phoneFlagName]="ContactDetails.phoneCountry"
                  [phoneName]="ContactDetails.phone"
                ></phone-input-v2>

                <app-button
                  (onClick)="deleteCommunication(i)"
                  type="button"
                  [config]="{
                    size: ButtonSize.SMALL,
                    type: ButtonTypes.NEW_PRIMARY_LIGHT_BLUE,
                    prependSrc: '/assets/img/ic-blue-trash-v2.svg'
                  }"
                >
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <app-button
    [hidden]="isPreview"
    (onClick)="addCommunication()"
    class="btn-v2"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
      text: 'Contacts.V2.addButton' | translate,
      prependSrc: '/assets/img/ic-plus.svg'
    }"
  >
  </app-button>

  <div class="mt-4">
    <div *ngIf="isPreview" class="mb-4">
      <div class="control-v2">
        <input-label [label]="'Contacts.V2.address' | translate"></input-label>
        <div class="control-v2-info">
          <ng-container *ngIf="hasAddress(); else noAddress">
            {{ parentForm.get(ContactDetails.address)?.value }}<br />
            {{ parentForm.get(ContactDetails.postalCode)?.value }}
            {{ parentForm.get(ContactDetails.town)?.value }},
            {{ countryName }}
          </ng-container>

          <ng-template #noAddress>-</ng-template>
        </div>
      </div>
    </div>

    <address-with-autocomplete-v2
      *ngIf="!isPreview"
      [form]="parentForm"
      [config]="addressConfig"
      [hideFieldLabels]="true"
    ></address-with-autocomplete-v2>
  </div>

  <hr class="spacer-v2 spacer-v2-light mt-1 mb-4" />
  <div class="mb-4">
    <input-label [label]="'Contacts.V2.webInfoTitle' | translate"></input-label>
    <ng-container *ngIf="isPreview && parentForm.get(ContactDetails.links).value as links">
      <div class="control-v2-info mb-1" *ngFor="let linkData of links">
        <div>{{ linkData.link }}</div>
      </div>
      <span class="control-v2-info" *ngIf="links.length === 0">-</span>
    </ng-container>
  </div>

  <ng-container [formArrayName]="ContactDetails.links">
    <div
      class="row-grid mb-4"
      [hidden]="isPreview"
      [formGroup]="linkForm"
      *ngFor="let linkForm of links.controls; let i = index"
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <input-v2
            [formControlName]="ContactDetails.linkName"
            [placeholder]="'Contacts.V2.linkNamePlaceholder' | translate"
          ></input-v2>
        </div>

        <div class="col-12 col-md-6 mt-3 mt-md-0">
          <input-v2
            [formControlName]="ContactDetails.linkUrl"
            [placeholder]="'Contacts.V2.linkUrlPlaceholder' | translate"
          ></input-v2>
        </div>
      </div>

      <app-button
        (onClick)="deleteWebInfo(i)"
        [type]="'button'"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.NEW_PRIMARY_LIGHT_BLUE,
          prependSrc: '/assets/img/ic-blue-trash-v2.svg'
        }"
      >
      </app-button>
    </div>

    <app-button
      [hidden]="isPreview"
      [type]="'button'"
      (onClick)="addWebInfo()"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        text: 'Contacts.V2.addButton' | translate,
        prependSrc: '/assets/img/ic-plus.svg'
      }"
    >
    </app-button>
  </ng-container>
</div>
