import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { Injector } from '@angular/core';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { EVENT_SIDENAV_FORM } from '@shared/modules/event-sidenav/enums/event-sidenav-form.enum';

export class EventController {
  injector: Injector;
  service: CalendarApiService;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.service = this.injector.get(CalendarApiService);
  }

  //TODO permissions
  updateEvent(event: CalendarEvent) {
    return this.service.editEvent(this.transformEvent(event), event.id);
  }

  transformEvent(event: CalendarEvent): any {
    return {
      [EVENT_SIDENAV_FORM.projectId]: event?.project?.id || null,
      [EVENT_SIDENAV_FORM.name]: event?.name || null,
      [EVENT_SIDENAV_FORM.isFullDay]: event?.isFullDay,
      [EVENT_SIDENAV_FORM.reminderTypes]: event?.reminderTypes || [],
      [EVENT_SIDENAV_FORM.cycleType]: event?.cycleType || null,
      [EVENT_SIDENAV_FORM.emails]: event.participants.filter((i) => !i.contact).map((i) => i.email) || [],
      [EVENT_SIDENAV_FORM.contactsIds]:
        event.participants
          .filter((i) => !!i.contact && i?.id !== event?.creator?.id)
          .map((i) => i.contact.id) || [],
      [EVENT_SIDENAV_FORM.description]: event?.description || null,
      [EVENT_SIDENAV_FORM.address]: event?.address || null,
      [EVENT_SIDENAV_FORM.termStart]: event?.termStart,
      [EVENT_SIDENAV_FORM.termEnd]: event?.termEnd,
      [EVENT_SIDENAV_FORM.calendarId]: event?.calendarId || null
    };
  }
}
