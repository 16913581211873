import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { Regex } from '@shared/configs/regex';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { PairTimepickerComponent } from '@shared/components/pair-timepicker/pair-timepicker.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { InputV2Component } from '@shared/modules/forms-v2/components/input-v2/input-v2.component';
import { filterAndMapErrorMessages } from '@shared/helpers/filter-and-map-error.helper';

@Component({
  selector: 'event-creation',
  templateUrl: './event-creation.component.html',
  styleUrls: ['./event-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCreationComponent implements AfterViewInit {
  Config = Config;

  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  @ViewChild('daterangepicker') daterangepicker: DateRangePickerComponent;
  @ViewChild('participants') participants: NgSelectComponent;
  @ViewChild('timepicker') timepicker: PairTimepickerComponent;
  @ViewChild('nameInput') nameInput: InputV2Component;

  firstLoad: boolean = false;
  canProjectAdd: boolean = false;

  modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']]
  };

  formats: string[] = ['bold', 'italic', 'link', 'strike', 'underline', 'list'];

  constructor(
    public service: EventSidenavService,
    private t: TranslateService,
    private s: SnackBarService
  ) {}

  ngAfterViewInit() {
    this.service.participants = this.participants;
    this.service.daterangepicker = this.daterangepicker;

    this.daterangepicker?.setRange(
      this.service.form?.controls?.termStart?.value,
      this.service.form?.controls?.termEnd?.value
    );
    this.checkProjectPermissions();
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return this.service.createCustomTag($event);
  }

  successLoadCalendars(resp) {
    const calendarId = this.service.form.get(this.service.EVENT_SIDENAV_FORM.calendarId);
    setTimeout(() => {
      if (!calendarId.value) {
        const index = resp.findIndex((i) => i.type === 'default');
        if (index > -1) {
          calendarId.setValue(resp[index].id);
        }
      }
    }, 100);
    return resp.map((i) => {
      i.type === 'default' ? (i.name = this.t.instant('Calendar.myCalendarTitle')) : null;
      return i;
    });
  }

  successOnLoadContacts({ records }: { records: Contact[] }) {
    this.service.setCreatorContact(records[0]);
    if (!this.firstLoad) {
      this.service.setCreatorAsParticipant(records[0]);
      this.firstLoad = true;
    }
    return [...records.map((i) => new Contact(i))];
  }

  checkProjectPermissions() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'ADD',
      objectCreatorId: []
    });
    this.canProjectAdd = ctrl.check();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.service.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength
      });
    }

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength
      });
    }

    return filterAndMapErrorMessages(messages, control.errors);
  }
}
