import { Task } from '@shared/models/task.model';
import { Component } from '@angular/core';
import { SubtasksService } from '@shared/modules/subtasks/subtasks.service';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';

@Component({
  selector: 'subtasks-list',
  templateUrl: './subtasks-list.component.html',
  styleUrls: ['./subtasks-list.component.scss']
})
export class SubtasksListComponent {
  constructor(
    public service: SubtasksService,
    public taskSidenavService: TaskSidenavService
  ) {}

  trackBy(index: number, model: Task): number {
    return model.id;
  }

  onChangeParentTask(task: Task) {
    if (task.id === this.taskSidenavService.task.id) {
      this.taskSidenavService.task = new Task(task);
    }
  }
}
