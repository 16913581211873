import { MatDialog } from '@angular/material/dialog';
import { Entry } from '@modules/protocols/shared/interfaces/entry.interface';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config';
import { Config } from '@shared/configs/config';
import { quillMediaFormats } from '@shared/helpers/quill-formats.helper';
import { AttachmentManagerListComponent } from '@shared/modules/attachment-manage/components/attachment-manager-list/attachment-manager-list.component';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { AttachmentService } from '@shared/services/attachment.service';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';
import { DocumentInternalTypeList, DocumentTypesList } from '../../data/document-types-list';
import { ProjectCreatorApiService } from '@modules/project-creator/shared/services/project-creator-api.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { HttpError } from '@shared/interfaces/error.interface';
import {
  ProjectCreatorEntryService,
  ProjectEntryActionType,
} from '@modules/project-creator/shared/services/project-creator-entry.service';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { submitHelper } from '@shared/helpers/submit-helper';
import { AttachmentType } from '@shared/components/text-attachments-input/enums/text-attachment-type.enum';
import { getFileType } from '@shared/helpers/basic-file-type.helper';
import { ProjectCreatorEntityDiscardComponent } from '../project-creator-entity-discard/project-creator-entity-discard.component';
import { StorageService } from '@core/services/storage.service';
import { editorInit } from '@shared/helpers/quill-plain-clipboard.helper';

enum EProjectEntitySidenavForm {
  ID = 'id',
  TYPE = 'type',
  NAME = 'name',
  DESCRIPTION = 'description',
  ATTACHMENT_PACKET = 'attachmentPacketId',
  PROJECT_FORM_TOKEN = 'projectFormToken',
  OBJECT_TYPE = 'objectType',
}

@Component({
  selector: 'app-project-creator-entity-sidenav',
  templateUrl: './project-creator-entity-sidenav.component.html',
  styleUrls: ['./project-creator-entity-sidenav.component.scss'],
})
export class ProjectCreatorEntitySidenavComponent implements OnInit {
  static componentName: string = 'ProjectCreatorEntitySidenavComponent';
  loading: boolean = false;
  downloadingEntry: boolean = false;
  EProjectEntitySidenavForm = EProjectEntitySidenavForm;
  editorInit = editorInit;

  readonly ButtonSize = ButtonSize;
  readonly ButtonTypes = ButtonTypes;

  items: any = [];

  @ViewChild('attachmentsList') attachmentsList: AttachmentManagerListComponent;
  @ViewChild('editor') editor;

  initFormValue: Record<EProjectEntitySidenavForm, string>;

  form: FormGroup;
  entry: Entry;
  box3Types: EntryType[] = [
    EntryType.TYPE_INTERNAL_OFFERS,
    EntryType.TYPE_INTERNAL_CONTRACTS,
    EntryType.TYPE_INTERNAL_PARTNER_OFFER,
    EntryType.TYPE_INTERNAL_ORDERS,
    EntryType.TYPE_INTERNAL_PROTOCOL,
    EntryType.TYPE_INTERNAL_DOCUMENTS,
  ];

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link', 'image', 'video'],
    ],
    imageHandler: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          return this.uploadFile(file, { resolve, reject });
        });
      },
      accepts: ['*'],
    } as any,
    imageDropAndPaste: {
      handler: this.uploadImage.bind(this),
    },
  };

  formats: string[] = quillMediaFormats;

  attachmentsConfig: TextAttachmentsConfig = {
    fileAttachments: true,
    photoAttachments: true,
    videoAttachments: true,
  };

  get details() {
    return this.sideData.data || {};
  }

  get defaultValues() {
    return {
      [EProjectEntitySidenavForm.ID]: this.details?.id || null,
      [EProjectEntitySidenavForm.TYPE]: this.details?.type || null,
      [EProjectEntitySidenavForm.NAME]: '',
      [EProjectEntitySidenavForm.DESCRIPTION]: '',
      [EProjectEntitySidenavForm.OBJECT_TYPE]: 'data-box-2',
      [EProjectEntitySidenavForm.ATTACHMENT_PACKET]:
        this.attachmentsList?.service?.attachmentPacket?.id || null,
      [EProjectEntitySidenavForm.PROJECT_FORM_TOKEN]: this.details?.projectPublicFormToken || null,
    };
  }

  get isCreate() {
    return this.details?.viewState === 'create';
  }

  constructor(
    @Inject(SIDENAV_DATA) public sideData: SIDENAV_DATA,
    private t: TranslateService,
    private s: SnackBarService,
    private attachmentService: AttachmentService,
    private fb: FormBuilder,
    private projectApi: ProjectCreatorApiService,
    private sideService: SidenavService,
    private entryService: ProjectCreatorEntryService,
    private dialog: MatDialog,
    private storage: StorageService,
  ) {
    let docs = [...DocumentTypesList];
    if (!!this.sideData.data?.isInternalDocumentation) {
      docs = [...DocumentInternalTypeList];
    }

    this.items = docs.map((i) => {
      i.name = this.t.instant('ProjectCreator.SecondStep.entryTypes.' + i.value, {
        name: this?.storage?.Company?.name,
      });
      return i;
    });
  }

  ngOnInit() {
    this.createForm();
    this.previewEntry();
    this.setValidators();
  }

  setValidators() {
    this.form
      .get(EProjectEntitySidenavForm.NAME)
      .setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(200)]);
    this.form.get(EProjectEntitySidenavForm.NAME).updateValueAndValidity();
  }

  previewEntry() {
    if (!this.isCreate) {
      this.downloadingEntry = true;
      this.projectApi
        .getEntry(this.details?.entryId, this.details?.projectPublicFormToken)
        .pipe(
          finalize(() => {
            this.downloadingEntry = false;
          }),
        )
        .subscribe((res: Entry) => {
          res.attachments = res?.attachments
            .filter((i) => !i?.deleted?.length)
            ?.map((i) => {
              i.isUploaded = true;
              return i;
            });
          this.entry = new Entry(res);

          this.form.setValue({
            [EProjectEntitySidenavForm.ID]: this.details?.id || null,
            [EProjectEntitySidenavForm.TYPE]: res?.type || null,
            [EProjectEntitySidenavForm.NAME]: res?.name || '',
            [EProjectEntitySidenavForm.DESCRIPTION]: res?.description || '',
            [EProjectEntitySidenavForm.OBJECT_TYPE]: 'data-box-2',
            [EProjectEntitySidenavForm.ATTACHMENT_PACKET]: null,
            [EProjectEntitySidenavForm.PROJECT_FORM_TOKEN]: this.details?.projectPublicFormToken || null,
          });
          this.initFormValue = Object.assign(this.form.value, {});
        });
    } else {
      this.initFormValue = Object.assign(this.form.value, {});
    }
  }

  createForm() {
    this.form = this.fb.group(this.defaultValues);
  }

  uploadImage(imageDataUrl, type, imageData) {
    const file = imageData.toFile();
    this.uploadFile(file);
  }

  cancel() {
    this.discardSaving();
  }

  submit() {
    submitHelper(this.form);
    if (this.loading || this.form.invalid) return;
    this.isCreate ? this.createEntry() : this.editEntry();
  }

  createEntry() {
    const obj = Object.assign({}, this.form.value);
    obj[EProjectEntitySidenavForm.ATTACHMENT_PACKET] =
      this.attachmentsList?.service?.attachmentPacket?.id || null;
    delete obj.id;

    if (!obj[EProjectEntitySidenavForm.PROJECT_FORM_TOKEN]) {
      delete obj[EProjectEntitySidenavForm.PROJECT_FORM_TOKEN];
      obj['id'] = this.details?.id;
    }

    obj[EProjectEntitySidenavForm.OBJECT_TYPE] = 'data-box-2';
    if (this.box3Types?.indexOf(obj[EProjectEntitySidenavForm.TYPE]) > -1) {
      obj[EProjectEntitySidenavForm.OBJECT_TYPE] = 'data-box-3';
    }

    this.loading = true;
    this.projectApi
      .addEntry(obj)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res) => this.successAddingEntry(res),
        error: (err) => this.errorAddingEntry(err),
      });
  }

  successAddingEntry(res) {
    this.entryService.actionEmitter.emit({ action: ProjectEntryActionType.ADD, payload: new Entry(res) });
    this.sideService.close();
    this.s.success(this.t.instant('ProjectCreator.Sidenav.successAdding'));
  }

  errorAddingEntry(err: HttpError) {
    this.s.error(this.t.instant('ProjectCreator.Sidenav.errorAdding'));
  }

  editEntry() {
    const obj = Object.assign({}, this.form.value);
    obj[EProjectEntitySidenavForm.ATTACHMENT_PACKET] =
      this.attachmentsList?.service?.attachmentPacket?.id || null;

    obj[EProjectEntitySidenavForm.OBJECT_TYPE] = 'data-box-2';
    if (this.box3Types?.indexOf(obj[EProjectEntitySidenavForm.TYPE]) > -1) {
      obj[EProjectEntitySidenavForm.OBJECT_TYPE] = 'data-box-3';
    }

    this.loading = true;
    this.projectApi
      .editEntry(this.entry.id, obj)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res) => this.successEditEntry(res),
        error: (err) => this.errorEditEntry(err),
      });
  }

  successEditEntry(res) {
    this.entryService.actionEmitter.emit({ action: ProjectEntryActionType.UPDATE, payload: new Entry(res) });
    this.sideService.close();
    this.s.success(this.t.instant('ProjectCreator.Sidenav.successEdit'));
  }

  errorEditEntry(err: HttpError) {
    this.s.error(this.t.instant('ProjectCreator.Sidenav.errorEdit'));
  }

  uploadFile(file: File, opt: { resolve?: any; reject?: any } = {}) {
    const { resolve, reject } = opt;

    if (getFileType(file) === AttachmentType.FILE) {
      this.s.error(this.t.instant('ProjectCreator.imagesAllowed'));
      reject('Unsupported');
      return;
    }

    const quill = this.editor.quillEditor;
    let index = (quill.getSelection() || {}).index;
    if (index === undefined || index < 0) index = quill.getLength();
    let data = {};
    if (!!this.details?.projectPublicFormToken) {
      data = { projectFormToken: this.details?.projectPublicFormToken };
    }

    this.attachmentService
      .uploadAttachment(
        {
          objectId: this.details?.id,
          objectType: 'project-description-wysiwyg',
          files: [file],
        },
        data,
      )
      .subscribe({
        next: (data) => {
          let url = `${Config.PRIVATE_ATTACHMENT}${data[0].file}`;
          if (resolve) {
            resolve(url);
          } else {
            quill.insertEmbed(index, 'image', url);
            this.form.get(EProjectEntitySidenavForm.DESCRIPTION).setValue(quill.root.innerHTML);
          }
          setTimeout(() => quill.setSelection(index + 1, 0), 0);
        },
        error: () => {
          reject ? reject('Unsupported') : null;
        },
      });
  }

  filesUploaded($event) {
    console.log($event);
  }

  errorMessages(name: string) {
    const messages = Object.assign({}, Config.validationMessages);
    const control = this.form.get(name);

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength,
      });
    }

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    return Object.keys(messages)
    .filter((key) => Object.keys(control.errors || {}).includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: messages[key] });
    }, {});
  }

  closeTaskSidenav() {
    this.sideService.close();
  }

  compareFormValues(
    stateOld: Record<EProjectEntitySidenavForm, string>,
    stateNew: Record<EProjectEntitySidenavForm, string>,
  ) {
    return JSON.stringify(stateOld) === JSON.stringify(stateNew);
  }

  discardSaving() {
    const nothingChanged = this.compareFormValues(this.form.value, this.initFormValue);
    if (nothingChanged) {
      this.closeTaskSidenav();
      return;
    }

    const ref = this.dialog.open(ProjectCreatorEntityDiscardComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        viewState: this.details?.viewState,
        confirm: this.closeTaskSidenav.bind(this),
      },
    });
    return ref;
  }
}
