import { Subscription } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import { appTours } from '@shared/modules/app-tour/shared/tours/tours';
import { CustomIStepOptionInterface } from '@shared/modules/app-tour/shared/interfaces/custom-i-step-option.interface';
import { WindowHelper } from '@shared/helpers/window.helper';
import { Location } from '@angular/common';
import { NgxmTourService } from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/ngx-md-menu-tour.service';
import {
  AppTourEvent,
  AppTourEventType
} from '@shared/modules/app-tour/shared/interfaces/app-tour-event.interface';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { UserPerson } from '@shared/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AppTourService extends BaseHttpService {
  currentAppTour;
  initedTour: boolean = false;
  emmiter: EventEmitter<AppTourEvent> = new EventEmitter<AppTourEvent>();
  callbackEndSub: Subscription = null;

  constructor(
    private tourService: NgxmTourService,
    public location: Location
  ) {
    super();
    this.listenTourEvents();
    this.onOrientationChange();
  }

  initTour(
    type: AppTourTypes,
    timeout: number = 2000,
    startTourImmediately?: boolean,
    callbackStart?: Function,
    callbackEnd?: Function
  ): void {
    for (let i = 0; i < appTours.length; i++) {
      const appTourConfig = appTours[i];
      if (appTourConfig.type === type) {
        if (this.initedTour) {
          return;
        }
        this.initedTour = true;
        this.currentAppTour = appTourConfig;
        if (!startTourImmediately) {
          this.n.go(appTourConfig.startComponentName, appTourConfig.startComponentParams, {
            queryParams: { tutorial: 1 },
            queryParamsHandling: 'merge'
          });
        }
        this.tourService.initialize(this.getTranslatedSteps(appTourConfig));
        this.tourService.disableHotkeys();
        this.startTour(timeout, callbackStart, callbackEnd);
        break;
      }
    }
  }

  startTour(timeout: number, callbackStart?: Function, callbackEnd?: Function) {
    const tourInit = () => {
      this.tourService.start();
      this.currentAppTour.blockScroll ? document.documentElement.classList.add('hidden') : null;
      callbackStart ? callbackStart() : null;
      this.callbackEndSub = this.tourService.end$.subscribe(() => {
        callbackEnd ? callbackEnd() : null;
        this.callbackEndSub.unsubscribe();
      });
    };
    timeout > 0 ? setTimeout(() => tourInit(), timeout) : tourInit();
  }

  onOrientationChange() {
    window.addEventListener(
      'orientationchange',
      () => {
        if (this.initedTour) {
          window.location.href = window.location.href;
        }
      },
      true
    );
  }

  getTranslatedSteps(appTourConfig): CustomIStepOptionInterface[] {
    appTourConfig.steps = appTourConfig.steps.filter((step: CustomIStepOptionInterface) => {
      step.content = this.t.instant(`AppTours.${appTourConfig.type}.${step.anchorId}.content`);
      step.title = this.t.instant(`AppTours.${appTourConfig.type}.${step.anchorId}.title`, {
        firstName: this.store?.UserPerson?.firstName ? this.store?.UserPerson?.firstName : ''
      });
      step.enableBackdrop = this.getEnableBackdropInStep(step);
      step.endBtnTitle = this.t.instant('AppTours.end');
      step.nextBtnTitle = this.t.instant('AppTours.next');

      if (WindowHelper.isMobileWidth && !step.hiddenOnMobile) {
        return true;
      }
      if (WindowHelper.isMoreMobileWidth && !step.hiddenOnDesktop) {
        return true;
      }
      return false;
    });
    return appTourConfig.steps;
  }

  getEnableBackdropInStep(step: CustomIStepOptionInterface): boolean {
    let enableBackdrop = true;
    if (step.enableBackdrop === false) {
      enableBackdrop = false;
    } else if (WindowHelper.isMobileWidth && isNotNullOrUndefined(step.enableBackdropOnMobile)) {
      enableBackdrop = step.enableBackdropOnMobile;
    } else {
      enableBackdrop = true;
    }
    return enableBackdrop;
  }

  listenTourEvents(): void {
    this.tourService.end$.subscribe((e) => {
      document.documentElement.classList.remove('hidden');
      this.initedTour = false;
      const current = window.location.pathname;
      if (!this.currentAppTour?.ignoreMarking) {
        this.markedTourAsShown();
      }

      if (!this.currentAppTour.endComponentName) {
        return this.emmiter.emit({ type: AppTourEventType.APP_TOUR_END });
      }

      if (current.indexOf(this.n.getPath(this.currentAppTour.endComponentName)) !== -1) {
        window.location.href = window.location.pathname;
      } else {
        this.n.go(this.currentAppTour.endComponentName);
      }
    });
  }

  markedTourAsShown() {
    const url = `${Config.API}/profile/show-tutorial`;
    return this.post(url, { type: this.currentAppTour.type }).subscribe((userPerson: UserPerson) => {
      this.store.UserPerson = userPerson;
    });
  }
}
