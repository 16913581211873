import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'contact-add-to-project-v2',
  templateUrl: './contact-add-to-project-v2.component.html',
  styleUrls: ['./contact-add-to-project-v2.component.scss']
})
export class ContactAddToProjectComponentV2 implements OnInit {
  CONFIG = Config;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  form: FormGroup;
  roles: { id: string; text: string }[] = [];
  excludedIds: string;

  loading = false;

  asContactItems = [
    {
      value: true,
      label: this.t.instant('Contacts.Preview.Projects.addAsContact'),
      description: this.t.instant('Contacts.Preview.Projects.addAsContactDescription')
    },
    {
      value: false,
      label: this.t.instant('Contacts.Preview.Projects.addAsContactAndCreateAccount'),
      description: this.t.instant('Contacts.Preview.Projects.addAsContactAndCreateAccountDescription')
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<ContactAddToProjectComponentV2>,
    private fb: FormBuilder,
    private t: TranslateService,
    private s: SnackBarService,
    private projectApiService: ProjectAPIService,
    @Inject(MAT_DIALOG_DATA) public data: { contact: Contact; currentProjectIds: number[] }
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setRoles();
    this.setExcludedIds();
  }

  get isAsContact(): boolean {
    return this.form.get('asContact').value;
  }

  get project(): number {
    return this.form.get('project').value;
  }

  get projectControl(): FormControl {
    return this.form.get('project') as FormControl;
  }

  get roleControl(): FormControl {
    return this.form.get('role') as FormControl;
  }

  setRoles(): void {
    Object.keys(UserRoles).forEach((key) => {
      this.roles.push({
        id: UserRoles[key],
        text: this.t.instant('ROLES.' + key)
      });
    });
  }

  setExcludedIds(): void {
    const params = new URLSearchParams();
    this.data.currentProjectIds.forEach((id) => params.append('excludedIds[]', id.toString()));
    this.excludedIds = params.toString();
  }

  initForm(): void {
    this.form = this.fb.group({
      asContact: this.fb.control(true),
      project: this.fb.control(null, [Validators.required]),
      role: this.fb.control(null),
      email: this.fb.control(this.data?.contact?.email || null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(255)
      ])
    });
  }

  onAddToProjectTypeChange({ value }) {
    if (!value) {
      this.form.get('role').setValidators([Validators.required]);
    } else {
      this.form.get('role').setValue(null);
      this.form.get('role').setValidators([]);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submitProject(): void {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (
      (!this.isAsContact && this.form.invalid) ||
      (this.isAsContact && !this.form.get('project').value) ||
      this.loading
    )
      return;

    this.loading = true;

    if (this.isAsContact) {
      this.addAsContactToProject();
    } else {
      this.addAsContactAndInviteToProject();
    }
  }

  addAsContactAndInviteToProject() {
    if (this.data.contact.employee) {
      this.inviteExistContactToProject();
    } else {
      this.inviteNewContactToProject();
    }
  }

  inviteExistContactToProject(): void {
    this.projectApiService
      .inviteExistContactToProject(
        this.data.contact.id,
        this.data.contact.employee.id,
        true,
        this.project,
        false
      )
      .subscribe(
        () => {
          this.s.success(this.t.instant('Contacts.V2.Projects.successContactAddedToProject'));
          this.form.reset();
          this.dialogRef.close();
        },
        () => this.s.error(this.t.instant('Contacts.V2.Projects.errorContactAddedToProject'))
      )
      .add(() => (this.loading = false));
  }

  inviteNewContactToProject(): void {
    this.projectApiService
      .inviteNewContactToProject(
        this.data.contact.id,
        this.form.get('email').value,
        this.form.get('role').value,
        true,
        this.project
      )
      .subscribe(
        () => {
          this.s.success(this.t.instant('Contacts.Preview.Projects.successContactAddedToProject'));
          this.form.reset();
          this.dialogRef.close();
        },
        () => this.s.error(this.t.instant('Contacts.Preview.Projects.errorContactAddedToProject'))
      )
      .add(() => (this.loading = false));
  }

  addAsContactToProject(): void {
    this.projectApiService
      .addContactToProject(this.data.contact.id, this.project)
      .subscribe(
        (p: Project) => {
          this.s.success(this.t.instant('Contacts.V2.Projects.successContactAddedToProject'));
          this.form.reset();
          this.dialogRef.close(p);
        },
        () => this.s.error(this.t.instant('Contacts.V2.Projects.errorContactAddedToProject'))
      )
      .add(() => (this.loading = false));
  }
}
