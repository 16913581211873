<modal-title [title]="'Contacts.RemoveAccountConfirm.title' | translate"></modal-title>
<!-- #region Content -->
<div mat-dialog-content>
  <h4 class="grey-1 mt-3" [innerHTML]="'Contacts.RemoveAccountConfirm.subtitle' | translate"></h4>
  <p [innerHTML]="'Contacts.RemoveAccountConfirm.descr' | translate: { contactName: contact?.fullName }"></p>
</div>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    [disabled]="loading"
    (onClick)="dialogRef.close(false)"
    [config]="{
      type: ButtonTypes.DISCARD_BUTTON,
      text: 'Contacts.RemoveAccountConfirm.cancel' | translate,
      size: ButtonSize.LARGE
    }"
  >
  </app-button>
  <app-button
    [class]="'ml-3'"
    [loading]="loading"
    [disabled]="loading"
    (onClick)="eventEmitter.emit(true); dialogRef.close(true)"
    [config]="{
      type: ButtonTypes.DANGER_BUTTON,
      text: 'Contacts.RemoveAccountConfirm.yesDelete' | translate,
      size: ButtonSize.LARGE,
      prependSrc: './assets/img/ic-trash-outline.svg'
    }"
  >
  </app-button>
</mat-dialog-actions>
<!-- #endregion -->
