import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { debounce } from '@shared/decorators/debounce.decorator';
import { WindowHelper } from '@shared/helpers/window.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'project-right-section',
  templateUrl: './project-right-section.component.html',
  styleUrls: ['./project-right-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectRightSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  private _isVisibleOnMobile: boolean = false;

  @Input() set isVisibleOnMobile(val: boolean) {
    this._isVisibleOnMobile = val;
    if (val) {
      this.basePanelService.blockScroll();
    } else {
      this.basePanelService.unBlockScroll();
    }
  }

  get isVisibleOnMobile() {
    return this._isVisibleOnMobile;
  }

  @Output('onCloseRightSection') onCloseRightSection: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('projectRightSection') projectRightSection: ElementRef;

  constructor(
    private basePanelService: BasePanelService,
    private changes: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.projectRightSection.nativeElement.style.marginRight = -this.basePanelService.scrollbarWidth + 'px';
      this.changes.detectChanges();
    });
    this.router.events.subscribe((routeData) => {
      this.basePanelService.unBlockScroll();
      this.changes.detectChanges();
    });
  }

  @HostListener('window:resize', ['$event'])
  @debounce(30)
  windowResize() {
    if (WindowHelper.isMobileWidth && this.isVisibleOnMobile) {
      this.basePanelService.blockScroll();
    } else {
      this.basePanelService.unBlockScroll();
    }
  }

  ngOnDestroy() {
    this.basePanelService.unBlockScroll();
    this.changes.detectChanges();
  }
}
