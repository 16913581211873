import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgSelectTranslateOptionComponent,
  NgSelectTranslateValueComponent
} from '@shared/directives/ng-select-extension.directive';

const NgSelectExtendedComponents = [NgSelectTranslateOptionComponent, NgSelectTranslateValueComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [NgSelectExtendedComponents],
  exports: [],
  entryComponents: [NgSelectExtendedComponents]
})
export class NgSelectExtendedModule {}
