import { tap, catchError } from 'rxjs/operators';
import { Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { timer, throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  get availableLanguages() {
    const langs = [];
    Object.keys(Languages).forEach((key) => {
      langs.push(Languages[key]);
    });
    return langs;
  }

  get defaultLanguage() {
    return Languages.EN;
  }

  constructor(
    private storage: StorageService,
    private translate: TranslateService
  ) {}

  saveLanguage(lang: Languages) {
    const userPerson = this.storage.UserPerson;
    userPerson.language = lang;
    this.storage.UserPerson = userPerson;
    timer(300).subscribe(() => (window.location.href = window.location.pathname));
  }

  useLanguage(lang: string): Observable<any> {
    if (this.availableLanguages.indexOf(lang) === -1) {
      lang = this.defaultLanguage;
    }

    return this.translate.use(lang).pipe(
      tap(() => {
        this.registerMomentLocale(lang);
        this.setLangToHtmlTag(lang);
        isDevMode() ? console.log(`'${lang.toUpperCase()}' language initialized`) : null;
      }),
      catchError((err) => {
        isDevMode() ? console.error(`Problem with '${lang}' language initialization.'`) : null;
        return throwError(err);
      })
    );
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  registerMomentLocale(lang: string) {
    moment.locale(lang);
    switch (lang) {
      case Languages.PL:
        registerLocaleData(localePL);
        break;
      case Languages.DE:
        registerLocaleData(localeDE);
        break;
      default:
        registerLocaleData(localeEN);
        break;
    }
  }

  getInitLanguage(): Languages {
    if (this.storage.isLogged) {
      return this.storage.UserPerson.language as Languages;
    }

    switch (this.translate.getBrowserLang()) {
      case Languages.PL:
      case Languages.DE:
      case Languages.EN:
        return this.translate.getBrowserLang() as Languages;
      default:
        return this.defaultLanguage;
    }
  }

  setLangToHtmlTag(lang: string) {
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  }
}

/**
 * @export
 * @enum {number}
 */
export enum Languages {
  PL = 'pl',
  DE = 'de',
  EN = 'en'
}
