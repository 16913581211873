import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UserSettingsService {
  private photoUpdateSubject = new Subject<string>();

  photoUpdate$ = this.photoUpdateSubject.asObservable();

  updatePhoto(photoUrl: string) {
    this.photoUpdateSubject.next(photoUrl);
  }
}
