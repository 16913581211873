import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Project } from '@modules/projects/shared/models/project.model';
import { NavigateService } from '@shared/services/navigate.service';
import { ConfirmationPopoverDirective } from '@shared/libs/angular-confirmation-directive/confirmation-popover.directive';

@Component({
  selector: 'contact-name',
  templateUrl: './contact-name.component.html',
  styleUrls: ['./contact-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactNameComponent implements OnInit {
  ContactType = ContactType;

  private _contact: Contact;
  previewPermission: boolean = false;

  @Input() placement?: string = 'top';
  @Input() isProjectProfile: boolean = true;
  @Input() project: Project;
  @ViewChild('cp') cp: ConfirmationPopoverDirective;

  @Input()
  set contact(contact: Contact) {
    this._contact = new Contact(contact);
    this.contactPreviewPermission();
    this.changes.detectChanges();
  }

  get contact() {
    return this._contact;
  }

  constructor(
    private n: NavigateService,
    private changes: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.contactPreviewPermission();
  }

  preview() {
    const ctrl = new ContactController();
    ctrl.preview({ type: this.contact.type, id: this.contact.id });
  }

  contactInProject() {
    if (!this.project) {
      return;
    }
    this.n.go('project-preview-team', { projectId: this.project.id });
  }

  contactPreviewPermission() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.CONTACTS,
      action: 'PREVIEW'
    });

    this.previewPermission = ctrl.check();
  }
}
