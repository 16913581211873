import {
  AfterViewInit,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { positionElements } from 'positioning';
import { fromEvent, Subscription } from 'rxjs';
import { ConfirmationPopoverOptions } from './confirmation-popover-options.provider';
import { ConfirmationPopoverDirective } from './confirmation-popover.directive';
import { throttleTime, delay } from 'rxjs/operators';

declare var $: any;

@Directive({
  selector: '[confirmationPopover]',
  exportAs: 'confirmationPopover'
})
export class CustomConfirmationPopoverDirective
  extends ConfirmationPopoverDirective
  implements AfterViewInit, OnDestroy
{
  @Input() customDescriptionTemplate: TemplateRef<any>;
  @Input() closeOnScroll: boolean;

  sub: Subscription;
  listenerGroup: Subscription = new Subscription();

  constructor(
    viewContainerRef: ViewContainerRef,
    public _elm: ElementRef,
    public _defaultOptions: ConfirmationPopoverOptions,
    cfr: ComponentFactoryResolver,
    renderer: Renderer2
  ) {
    super(viewContainerRef, _elm, _defaultOptions, cfr, renderer);
    this['positionPopover'] = this.customPositionPopover;
  }

  ngAfterViewInit() {
    if (this.closeOnScroll) {
      this.setScrollListener();
    }
    this.clickListener();
  }

  private clickListener() {
    const listener = fromEvent(this._elm.nativeElement, 'click')
      .pipe(delay(10))
      .subscribe(() => {
        window.dispatchEvent(new Event('resize'));
      });

    this.listenerGroup.add(listener);
  }

  private setScrollListener() {
    const listener = fromEvent(window, 'scroll', { capture: true })
      .pipe(throttleTime(900))
      .subscribe(() => {
        this.hidePopover();
      });

    this.listenerGroup.add(listener);
  }

  private customPositionPopover() {
    if (this.popover) {
      const popoverElement = this.popover.location.nativeElement.children[0];
      const triggerElement = this._elm.nativeElement;

      const triggerRect = triggerElement.getBoundingClientRect();
      const popoverHeight = popoverElement.offsetHeight;
      const spaceAbove = triggerRect.top;
      const spaceBelow = window.innerHeight - triggerRect.bottom;

      let placement = this.placement || this._defaultOptions.placement;
      if (spaceAbove < popoverHeight && spaceBelow > popoverHeight) {
        placement = 'bottom';
      } else if (spaceBelow < popoverHeight && spaceAbove > popoverHeight) {
        placement = 'top';
      }

      this.placement = placement;

      positionElements(
        triggerElement,
        popoverElement,
        placement,
        this.appendToBody || this._defaultOptions.appendToBody
      );

      this.setCorrectPosition(popoverElement);
    }
  }

  setCorrectPosition(popoverElement) {
    const $popover = $(popoverElement);
    const width = $popover.outerWidth();
    const height = $popover.outerHeight();

    const position = $popover.position();
    const bounds = $popover.get(0).getBoundingClientRect();

    let left = position.left;
    let top = position.top;

    if (bounds.left < 0) {
      left = 5;
    } else if (bounds.right > document.body.offsetWidth) {
      left = document.body.offsetWidth - width - 5;
    }

    if (bounds.top < 0) {
      top = 5;
    } else if (bounds.bottom > window.innerHeight) {
      top = window.innerHeight - height - 5;
    }

    $popover.css({
      '-webkit-transform': `translate(${left}px, ${top}px)`,
      '-moz-transform': `translate(${left}px, ${top}px)`,
      '-ms-transform': `translate(${left}px, ${top}px)`,
      '-o-transform': `translate(${left}px, ${top}px)`,
      transform: `translate(${left}px, ${top}px)`
    });

    if (this.placement) $popover.addClass(`bs-popover-${this.placement}`);
  }

  ngOnDestroy() {
    this.listenerGroup ? this.listenerGroup.unsubscribe() : null;
    super.ngOnDestroy();
  }
}
