import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ProjectTasksFiltersComponent } from '@project-modules/project-tasks/pages/project-tasks/components/project-tasks-filters/project-tasks-filters.component';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { cloneDeep } from 'lodash';
import { DefaultListConfig } from '@shared/modules/list/configs/default-list.config';
import { ProjectTasksListConfig } from '@project-modules/project-tasks/shared/configs/project-tasks-list.config';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { ListTaskService } from '@shared/modules/list/services/list-task.service';
import { ListService } from '@shared/modules/list/services/list.service';
import { StorageService } from '@core/services/storage.service';
import { SearchHistory } from '@shared/models/search-history.model';
import { SearchHistoryType } from '@shared/enums/search-history-type.enum';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { ListTasksComponent } from '@shared/modules/list/components/list-tasks/list-tasks.component';
import { ProjectTaskAPIService } from '@modules/projects/shared/services/project-task-api.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Subscription } from 'rxjs';
import { IProjectPreviewEvent } from '@project-modules/project-preview/interfaces/project-preview-event.interface';
import { ProjectPreviewEventType } from '@project-modules/project-preview/enums/projcet-preview-event-type.enum';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  styleUrls: ['./project-tasks.component.scss'],
  templateUrl: './project-tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingsService]
})
export class ProjectTasksComponent implements OnInit, OnDestroy, AfterViewInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  ProjectTasksFiltersComponent = ProjectTasksFiltersComponent;
  PermissionsGroups = PermissionsGroups;
  WindowHelper = WindowHelper;
  taskSidenavCtrl: TaskSidenavController;
  config: ListConfig;
  sub: Subscription = new Subscription();

  @ViewChild('listTasksComponent') listTasksComponent: ListTasksComponent;

  constructor(
    public projectPreviewService: ProjectPreviewService,
    public projectTaskApiService: ProjectTaskAPIService,
    public listTaskService: ListTaskService,
    public listService: ListService,
    private storageService: StorageService,
    private storage: StorageService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.taskSidenavCtrl = new TaskSidenavController();
    this.initListConfig();
  }

  ngAfterViewInit() {
    this.listTasksComponent.listTasksController.projectApiTaskService = this.projectTaskApiService;
    this.setCompleted();
    this.listenProjectPreviewEvents();
  }

  private resolveShowCompletedCheckbox(): number {
    const userPerson = this.storage.UserPerson;
    return userPerson && userPerson.showDoneTasks ? 1 : 0;
  }

  listenProjectPreviewEvents() {
    this.sub = this.projectPreviewService.projectServiceEventEmitter.subscribe(
      (event: IProjectPreviewEvent) => {
        switch (event.type) {
          case ProjectPreviewEventType.CHANGE_STAGE:
            this.listService.getRows();
            break;
        }
      }
    );
  }

  createNewTask() {
    this.taskSidenavCtrl.addTask(this.projectPreviewService.project.id);
  }

  createNewTaskGroup() {
    this.taskSidenavCtrl.addTaskGroup(this.projectPreviewService.project.id);
  }

  initListConfig() {
    this.config = cloneDeep(DefaultListConfig);
    ProjectTasksListConfig.url = ProjectTasksListConfig.url.replace(
      '[{projectId}]',
      this.projectPreviewService.project.id.toString()
    );
    this.config = Object.assign(this.config, ProjectTasksListConfig);
    this.listService.data['projectId'] = this.projectPreviewService.project.id;
  }

  setCompleted() {
    const showDoneTasks = this.resolveShowCompletedCheckbox();
    this.listService.setFilter('[show_completed]', showDoneTasks ? 1 : 0);
    if (this.listService.config) {
      this.listService.setPage(1);
      this.listService.getRows();
    }
  }

  showCompletedChange(event: { target }, value?: number): void {
    const checked = event?.target?.checked;
    const val = value || value === 0 ? value : checked === true ? 1 : 0;
    this.updateShowDoneTasks(val);
    this.handleShowCompletedValueChange(val);
  }

  private updateShowDoneTasks(newValue: number): void {
    this.settingsService.showDoneTasks(newValue).subscribe();
    const userPerson = this.storage.UserPerson;
    userPerson.showDoneTasks = !!newValue;
    this.storage.UserPerson = userPerson;
  }

  private handleShowCompletedValueChange(val: number): void {
    this.listService.setFilter('[show_completed]', val);
    this.setShowCompletedToStorageSearchHistory(val);
    this.onChangeFilter();
  }

  setShowCompletedToStorageSearchHistory(val: number) {
    let employee = this.storageService.Employee;

    employee.searchHistory = employee.searchHistory.map((s: SearchHistory) => {
      if (s.type === SearchHistoryType.PROJECT_TASKS) {
        !s.data.filters ? (s.data['filters'] = {}) : '';
        s.data['filters']['show_completed'] = val;
      }
      return s;
    });

    if (!employee.searchHistory.length) {
      employee.searchHistory.push({
        created: moment().format(Config.DATE_SERVER),
        data: {
          show_completed: val
        },
        deleted: null,
        id: 0,
        modified: moment().format(Config.DATE_SERVER),
        type: SearchHistoryType.PROJECT_TASKS
      });
    }
    this.storageService.Employee = employee;
  }

  onChangeFilter() {
    Object.keys(this.listService.filtersForm.value).forEach((key: string) => {
      let value = this.listService.filtersForm.value[key];
      Array.isArray(value) && !value.length ? (value = null) : null;
      this.listService.setFilter(key, value);
    });
    this.listService.getRows();
  }

  ngOnDestroy() {
    ProjectTasksListConfig.url = `${Config.API}/project/[{projectId}]/task`;
    this.sub.unsubscribe();
  }
}
