import { AddressType } from './../enums/address-type.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Task } from '@shared/models/task.model';
import { Commentary } from '@shared/modules/commentary/models/commentary.model';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';

export class BasicDataBox {
  deleted?: any;
  created?: string;
  modified?: string;
  area?: string;
  budgetType?: string;
  budgetAmount?: string;
  propertyType?: string;
  propertyTypeName?: any;
  propertyAge?: string;
  heatingType?: string;
  clientComment?: string;
  electricityType?: string;
  ownershipType?: string;
  floor?: number;
  floorsCount?: number;
  nameOnDoorbell?: string;
  hasParking?: boolean;
  parkingDescription?: string;
  hasBasement?: boolean;
  basementDescription?: string;
  hasElevator?: boolean;
  budget?: string;
  elevatorDescription?: string;
  comments?: Commentary[];
  contacts?: ContactAccessProject[] = [];
  mainContact?: ContactAccessProject;
  termStart?: string;
  termEnd?: string;
  responsibleEmployee?: EmployeeSearchItem;
  address?: string = '';
  addressNote?: string = '';
  addressType?: AddressType;
  fullTerm: string = '';
  tasks: Task[] = [];
  projectTasks: Task[] = [];
  responsibleContact?: Contact;
  energySource?: any;
  lastRenovationYear?: any;
  lastRenovationNote?: any;
  projectGoal?: any;
  materials?: string;
  parkingSituation?: string;

  constructor(basic?: any) {
    basic ? this.deserialize(basic) : null;
  }

  deserialize(basic: BasicDataBox) {
    Object.assign(this, basic);

    this.updateLists(basic.tasks);
    this.setFullTerm();
    this.setMainContact();
    this.setResponsibleEmployee();
    this.setResponsibleContact();
    this.deserializeContacts();
    this.deserializeComments();
    this.deserializeTasks();
  }

  setMainContact() {
    if (this.mainContact) {
      this.mainContact.mainContact = new Contact(this.mainContact.mainContact);
    }
  }

  setResponsibleEmployee() {
    this.responsibleEmployee
      ? (this.responsibleEmployee = new EmployeeSearchItem(this.responsibleEmployee))
      : null;
  }

  deserializeComments() {
    this?.comments?.length ? (this.comments = this.comments.map((c) => new Commentary(c))) : null;
  }

  deserializeTasks() {
    this.tasks?.length ? (this.tasks = this.tasks.map((i) => new Task(i))) : null;
  }

  deserializeContacts() {
    this.contacts = this.contacts.map((i) => new ContactAccessProject(i));
  }

  updateLists(tasks: Task[]) {
    this.projectTasks = tasks?.filter((i) => !i?.isDeleted) || [];
  }

  addNewTask(task: Task, listName: string) {
    this[listName] =
      this[listName]?.map((i) => {
        i.sortOrder = i.sortOrder + 1;
        return i;
      }) || [];
    this[listName].push(task);
  }

  setFullTerm() {
    if (!!this.termStart && !!this.termEnd) {
      const start = moment(this.termStart, Config.DATE_SERVER).format(Config.DATE_FORMAT_DOTS);
      const end = moment(this.termEnd, Config.DATE_SERVER).format(Config.DATE_FORMAT_DOTS);

      if (this.termStart === this.termEnd) {
        this.fullTerm = start;
        return;
      }
      this.fullTerm = `${start} - ${end}`;
    }
  }

  setResponsibleContact() {
    this.responsibleContact = new Contact(this.contacts.filter((i) => i.isResponsible)[0]?.contact) || null;
  }
}
