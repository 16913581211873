<div class="connection row" *ngIf="contact">
  <div class="col-12 col-md-4 col-lg-5 connection__info">
    <span>{{ contact | contactName }}</span>
    <div class="connection__tags">
      <div *ngIf="isCompany" class="connection__tag connection__tag--company">
        {{ 'Contacts.Type.' + contact.type | translate }}
      </div>
      <div
        class="connection__tag connection__tag--person text-capitalize"
        *ngFor="let function of contact.function"
      >
        {{ function.internal ? ('Contacts.Function.' + function.name | translate) : function.name }}
      </div>
    </div>
  </div>
  <div *ngIf="contact.email || contact.phone" class="col-12 col-md-4 col-lg-5 connection__contact">
    <span class="contact__email">{{ contact.email | defaultValue }}</span>
    <div class="contact__phone">
      <country-picker-v2
        class="contact__phone--prefix"
        [phoneFlagName]="contact.phoneCountry"
        [disabled]="true"
        [displayOnly]="true"
      ></country-picker-v2>
      <div class="contact__number">{{ contact.phone | defaultValue }}</div>
    </div>
  </div>
  <div class="col-12 col-md-4 col-lg-2 connection__actions">
    <div class="action" (click)="onRemove.emit()">
      <img draggable="false" src="/assets/img/ic-remove-v2.svg" alt="Remove" />
    </div>
    <div class="action" (click)="onEdit.emit()">
      <img draggable="false" src="/assets/img/pencil-icon.svg" alt="Edit" />
    </div>
  </div>
</div>
