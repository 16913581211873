import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Config } from '@shared/configs/config';
import { countries } from '@shared/datas/countries';
import {
  GoogleAddressComponent,
  GoogleAdressComponentType
} from '@shared/interfaces/google/google-address-component.interface';
import { BaseComponent } from '../base.component';
import { InputV2Component } from '../../modules/forms-v2/components/input-v2/input-v2.component';
import { filterAndMapErrorMessages } from '@shared/helpers/filter-and-map-error.helper';

export enum AddressFormFields {
  Address = 'address',
  PostalCode = 'postalCode',
  Town = 'town',
  Country = 'country'
}

@Component({
  selector: 'address-with-autocomplete-v2',
  templateUrl: './address-with-autocomplete-v2.component.html',
  styleUrls: ['./address-with-autocomplete-v2.component.scss']
})
export class AddressWithAutocompleteV2Component
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() form: FormGroup;
  @Input() config: AddressAutocompleteConfigV2;
  @Input() hideFieldLabels: boolean = false;

  @ViewChild('autocomplete') autocomplete: InputV2Component;
  autocompleteInstance: google.maps.places.Autocomplete;
  autocompleteListener: any;
  formFields = AddressFormFields;
  countrySelectOptions = countries;

  private readonly defaultValues = {
    address: { label: 'Address.address', placeholder: 'Address.address' },
    postalCode: { label: 'Address.postalCode', placeholder: 'Address.postalCode' },
    town: { label: 'Address.town', placeholder: 'Address.town' },
    country: { label: 'Address.country', placeholder: 'Address.country' }
  };

  constructor(public changes: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
    this.addCustomClassToPacContainer();
  }

  getLabelOrPlaceholder(type: 'placeholder' | 'label', field: AddressFormFields): string {
    const defaultConfig = this.defaultValues[field];

    let value;
    if (type === 'label') {
      value = this.config?.[`${field}Label` as keyof AddressAutocompleteConfigV2] ?? defaultConfig.label;
    } else if (type === 'placeholder') {
      value =
        this.config?.[`${field}Placeholder` as keyof AddressAutocompleteConfigV2] ??
        defaultConfig.placeholder;
    } else {
      value = type === 'label' ? defaultConfig.label : defaultConfig.placeholder;
    }

    return this.t.instant(value);
  }

  private getPlaceAutocomplete() {
    this.autocompleteInstance = new google.maps.places.Autocomplete(this.autocomplete.inputv2.nativeElement, {
      types: ['geocode']
    });
    this.autocompleteInstance.setFields(['address_component', 'formatted_address']);
    this.autocompleteListener = google.maps.event.addListener(
      this.autocompleteInstance,
      'place_changed',
      () => {
        this.setAddressComponents(this.autocompleteInstance.getPlace());
      }
    );
    this.changes.detectChanges();
  }

  private addCustomClassToPacContainer() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach((node) => {
            if ((node as HTMLElement).classList?.contains('pac-container')) {
              (node as HTMLElement).classList.add('pac-container-v2');
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  compareCountries(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2 : c1 === c2;
  }

  setAddressComponents(place: any) {
    let street = '';
    let streetNumber = '';
    place.address_components.map((component: GoogleAddressComponent) => {
      switch (component.types[0]) {
        case GoogleAdressComponentType.POSTAL_CODE:
          this.form.get(this.config.postalCodeControlName).setValue(component.long_name);
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.TOWN:
          this.form.get(this.config.townControlName).setValue(component.long_name);
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.COUNTRY:
          const country = countries.filter((c) => c.id === component.short_name)[0];
          this.form.get(this.config.countryControlName).setValue(country.id);
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.ROUTE:
          street = component.long_name;
          this.changes.detectChanges();
          break;
        case GoogleAdressComponentType.STREET_NUMBER:
          streetNumber = component.long_name;
          this.changes.detectChanges();
          break;
      }
    });

    this.form.get(this.config.addressControlName).setValue(!!street ? `${street} ${streetNumber}` : null);
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength
      });
    }

    return filterAndMapErrorMessages(messages, control.errors);
  }

  ngOnDestroy() {
    google.maps.event.removeListener(this.autocompleteListener);
    google.maps.event.clearInstanceListeners(this.autocompleteInstance);
    $('.pac-container').remove();
  }
}

export interface AddressAutocompleteConfigV2 {
  addressControlName?: string;
  postalCodeControlName?: string;
  townControlName?: string;
  countryControlName?: string;

  addressLabel?: string;
  addressPlaceholder?: string;

  postalCodeLabel?: string;
  postalCodePlaceholder?: string;

  townLabel?: string;
  townPlaceholder?: string;

  countryLabel?: string;
  countryPlaceholder?: string;
}
