import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { DeviceHelper } from '@shared/helpers/device.helper';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  RecievedAcceptance,
  SentAcceptance
} from '@modules/client-panel/shared/interfaces/recieved-acceptance.interface';
import { ClientAcceptanceService } from '@modules/client-panel/shared/services/client-acceptance.service';
import { ProtocolsStatuses } from '@modules/protocols/shared/enums/protocols-statuses.enum';
import { FormControl } from '@angular/forms';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';

@Component({
  templateUrl: './acceptance.component.html',
  styleUrls: ['./acceptance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcceptanceComponent implements OnInit {
  isError: boolean = false;
  loadingAcceptance: boolean = false;
  protocolStatuses = ProtocolsStatuses;
  loading: boolean = false;
  loadingPDF: boolean = false;

  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  recievedAcceptance: RecievedAcceptance = null;
  lastAcceptance: SentAcceptance = null;
  historyAcceptance: Array<SentAcceptance> = [];
  content;
  commentControl: FormControl = null;

  get protocolTypeTitle() {
    return this.recievedAcceptance?.type ? `Protocols.Types.${this.recievedAcceptance?.type}` : '';
  }

  constructor(
    public service: ClientAcceptanceService,
    private active: ActivatedRoute,
    private changes: ChangeDetectorRef,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
    private pService: ProtocolApiService
  ) {}

  ngOnInit() {
    this.commentControl = new FormControl(null);
    this.getProtocolAcceptance();
  }

  getAcceptancePreview(id) {
    this.loadingPDF = true;
    this.pService
      .getAcceptanceProtocolPDF(id, false)
      .subscribe({
        next: (res) => {
          this.content = URL.createObjectURL(res);
          this.changes.detectChanges();
        },
        error: () => {
          this.s.defaultError();
        }
      })
      .add(() => {
        this.loadingPDF = false;
        this.changes.detectChanges();
      });
  }

  getAcceptanceStatus(status: string, isLast: boolean = false) {
    return status ? `Protocols.Statuses.${status}` : '';
  }

  getProtocolAcceptance() {
    this.loadingAcceptance = true;
    this.changes.detectChanges();
    this.service
      .getProtocolAcceptance(this.active.snapshot.params.id)
      .subscribe({
        next: this.successAcceptanceProtocol.bind(this),
        error: this.errorAcceptanceProtocol.bind(this)
      })
      .add(() => {
        this.loadingAcceptance = false;
        this.changes.detectChanges();
      });
  }

  successAcceptanceProtocol(recieved: RecievedAcceptance) {
    this.recievedAcceptance = recieved;
    const last =
      this.recievedAcceptance?.sentAcceptances[this.recievedAcceptance?.sentAcceptances.length - 1];
    this.lastAcceptance = last ? new SentAcceptance(last) : null;
    this.lastAcceptance ? this.getAcceptancePreview(this.lastAcceptance.token) : null;
    const history = [...this.recievedAcceptance?.sentAcceptances];
    history.splice(-1, 1);
    this.historyAcceptance = history.length ? history.map((i) => new SentAcceptance(i)) : [];
    this.changes.detectChanges();
  }

  errorAcceptanceProtocol() {
    this.isError = true;
    this.changes.detectChanges();
  }

  openPdfPreview(acceptance: SentAcceptance) {
    const id = acceptance.file?.split('.')[0];
    if (WindowHelper.isMobileWidth) {
      this.n.go('protocol-acceptance-preview', { id });
    } else {
      if (DeviceHelper.isPWA) {
        this.n.go('protocol-acceptance-preview', { id });
      } else {
        const url = this.n.getPath('protocol-acceptance-preview', {}, { id });
        window.open(url, '_blank');
      }
    }
  }

  submitAcceptance(accepted: boolean) {
    const token = this.getToken(this.lastAcceptance?.file);
    this.loading = true;
    this.changes.detectChanges();
    this.service
      .sentAcceptance(token, { accepted, note: this.commentControl.value })
      .subscribe({
        next: this.successSubmitAcceptance.bind(this),
        error: (e) => this.s.error(this.t.instant('Protocols.errorAccepting'))
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successSubmitAcceptance(res) {
    this.lastAcceptance.status = res.status;
    this.changes.detectChanges();
  }

  getToken(fileName: string) {
    return fileName.split('.')[0];
  }
}
