import { UserRolePipe } from '@shared/pipes/user-role.pipe';
import { TrustUrlPipe } from '@shared/pipes/trust.pipe';
import { DateMonthPipe } from '@shared/pipes/date-month.pipe';
import { DateFromNowPipe } from '@shared/pipes/date-from-now.pipe';
import { StripHtmlPipe } from '@shared/pipes/strip-html.pipe';
import { DefaultValuePipe } from '@shared/pipes/default-value.pipe';
import { PhonePrefixPipe } from '@shared/pipes/phone-prefix.pipe';
import { ContactNamePipe } from '@shared/pipes/contact-name.pipe';
import { SafeHtml2Pipe } from '@shared/pipes/safe-html-v2.pipe';

export const SHARED_PIPES = [
  UserRolePipe,
  TrustUrlPipe,
  DateMonthPipe,
  DateFromNowPipe,
  StripHtmlPipe,
  DefaultValuePipe,
  PhonePrefixPipe,
  ContactNamePipe,
  SafeHtml2Pipe
];
