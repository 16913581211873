import { TaskShareModalComponent } from './../modules/tasks/components/task-share-modal/task-share-modal.component';
import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { Task } from '@shared/models/task.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { TaskModalComponent } from '@shared/modules/tasks/components/task-modal/task-modal.component';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { Config } from '@shared/configs/config';

export class TaskController {
  injector: Injector;
  dialog: MatDialog;
  taskService: TaskService;

  constructor(private task: Task) {
    this.injector = AppInjector.getInjector();
    this.dialog = this.injector.get(MatDialog);
    this.taskService = this.injector.get(TaskService);
  }

  // DEPRECATED!!!
  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'EDIT',
    objectCreatorIdMethod: (self: TaskController) => {
      const assignedIds = self?.task?.assignedTo?.map((u: EmployeeSearchItem) => u.id) || [];
      return [self?.task?.creator?.id].concat(assignedIds);
    }
  })
  edit(params?: any) {
    let data = { task: this.task, preview: false, snackBottom: !!params?.snackBottom, isTypeSelection: true };
    params ? (data = Object.assign(data, params)) : null;
    const ref = this.dialog.open(TaskModalComponent, {
      width: '722px',
      autoFocus: false,
      data
    });
    return ref;
  }

  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'EDIT',
    objectCreatorIdMethod: (self: TaskController) => {
      const assignedIds = self?.task?.assignedTo?.map((u: EmployeeSearchItem) => u.id) || [];
      return [self?.task?.creator?.id].concat(assignedIds);
    }
  })
  updateTask(task: Task) {
    (task as any).dataChanges = 1;
    return this.taskService.editTask(task, task.id);
  }

  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'EDIT',
    objectCreatorIdMethod: (self: TaskController) => {
      const assignedIds = self?.task?.assignedTo?.map((u: EmployeeSearchItem) => u.id) || [];
      return [self?.task?.creator?.id].concat(assignedIds);
    }
  })
  changeTaskStatus(task: Task) {
    return this.taskService.editStatus(
      { status: task.status, hiddenForClient: task.hiddenForClient },
      task.id
    );
  }

  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'EDIT',
    objectCreatorIdMethod: (self: TaskController) => {
      const assignedIds = self?.task?.assignedTo?.map((u: EmployeeSearchItem) => u.id) || [];
      return [self?.task?.creator?.id].concat(assignedIds);
    }
  })
  completeSubtasks(task: Task) {
    return this.taskService.finishTask(task.id, 1);
  }

  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'EDIT',
    objectCreatorIdMethod: (self: TaskController) => {
      const assignedIds = self?.task?.assignedTo?.map((u: EmployeeSearchItem) => u.id) || [];
      return [self?.task?.creator?.id].concat(assignedIds);
    }
  })
  assignToTask(task: Task, assignToIds: number[], assignedEmail?: string[]) {
    return this.taskService.groupAssign([task.id], assignToIds, assignedEmail);
  }

  @Permission({
    group: PermissionsGroups.TASKS,
    action: 'REMOVE',
    objectCreatorIdMethod: (self: TaskController) => {
      const assignedIds = self?.task?.assignedTo?.map((u: EmployeeSearchItem) => u.id) || [];
      return [self?.task?.creator?.id].concat(assignedIds);
    }
  })
  removeTask(task: Task) {
    return this.taskService.deleteTask(task.id);
  }

  askForShare(task: Task) {
    return this.dialog
      .open(TaskShareModalComponent, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false,
        data: { task }
      })
      .afterClosed()
      .toPromise()
      .then((res) => {
        return new Promise((resolve, reject) => {
          if (!!res?.accepted) {
            resolve(res);
          }
          reject();
        });
      });
  }
}
