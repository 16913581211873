import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { Config } from '@shared/configs/config';
import { Employee } from '@shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { StorageService } from '@core/services/storage.service';
import { ProjectTeamService } from '@modules/projects/shared/services/project-team.service';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { ListService } from '@shared/modules/list/services/list.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Component({
  selector: 'project-team-edit-memeber-modal',
  templateUrl: './project-team-edit-memeber-modal.component.html',
  styleUrls: ['./project-team-edit-memeber-modal.component.scss']
})
export class ProjectTeamEditMemberModalComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;
  currencySymbol: string = '';
  form: FormGroup = new FormGroup({
    position: new FormControl('', [Validators.maxLength(30)]),
    hourlyRate: new FormControl('', [Validators.min(0), Validators.max(99999)])
  });

  constructor(
    public dialogRef: MatDialogRef<ProjectTeamEditMemberModalComponent>,
    private t: TranslateService,
    private store: StorageService,
    private s: SnackBarService,
    private projectTeamService: ProjectTeamService,
    private projectPreviewService: ProjectPreviewService,
    private changes: ChangeDetectorRef,
    private listService: ListService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      employee: Employee;
      hourlyRate: string;
      position: string;
    }
  ) {}

  ngOnInit(): void {
    this.form.get('position').setValue(this.data.position);
    this.form.get('hourlyRate').setValue(this.data.hourlyRate ? Number(this.data.hourlyRate) / 100 : '');
    this.setCurrencySymbol();
  }

  setCurrencySymbol() {
    this.currencySymbol = this.store.Employee?.company?.currency?.symbol;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.loading || !this.form.valid) {
      return;
    }
    this.loading = true;
    const data = Object.assign(this.form.value, { employeeIds: [this.data.employee.id] });

    this.projectTeamService
      .addTeamMember(data, this.projectPreviewService.project.id)
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('ProjectTeam.Manage.successEditingTeamMember'));
          this.listService.getRows();
          this.dialogRef.close();
        },
        error: () => {
          this.s.error(this.t.instant('ProjectTeam.Manage.errorEditingTeamMember'));
        }
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength
      });
    }

    if (control?.errors?.min?.min || control?.errors?.min?.min === 0) {
      messages[GbxsoftErrorTypes.min] = this.t.instant('FormErrors.min', {
        number: control.errors?.min?.min.toString()
      });
    }

    if (control?.errors?.max?.max) {
      messages[GbxsoftErrorTypes.max] = this.t.instant('FormErrors.max', {
        number: control.errors?.max.max
      });
    }

    return messages;
  }
}
