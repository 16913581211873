import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { MyWorkComponent } from '@modules/my-work/pages/my-work/my-work.component';

export const MyWorkRoutes: CustomRoutes = [
  {
    path: '',
    component: MyWorkComponent,
    children: [],
    name: 'my-work',
    data: {
      metaTitle: 'MyWork.myWork',
      notRestorationPosition: true
    }
  }
];
