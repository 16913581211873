<form [formGroup]="form">
  <textarea
    class="textarea-v2"
    trim
    autocomplete="off"
    formControlName="control"
    [placeholder]="placeholder"
    [attr.readOnly]="readonly ? 'readonly' : null"
  ></textarea>

  <!-- <span *ngIf="errorCtrl.isError" class="form-control-error-msg" [innerHTML]="errorCtrl.errorMessage"></span> -->
</form>
