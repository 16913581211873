<div class="project" *ngIf="projectData" (click)="redirectToDetails(projectData.id)">
  <div class="project__title">
    {{ projectData.name | defaultValue }}
    <svg-icon
      *ngIf="!isFinished"
      class="pointer"
      src="/assets/img/close-icon.svg"
      (click)="onRemove.emit(project); $event.stopPropagation()"
    ></svg-icon>
  </div>
  <div class="project__stage text-uppercase">
    <div class="stage-icon"></div>
    {{ 'Projects.Stage.' + projectData.stage | translate }}
  </div>
  <div class="project__info">
    <span class="info__name">{{ projectData.mainContactFullName }}</span>
    <span class="info__address">
      {{ projectData.fullAddress | defaultValue }}
    </span>
  </div>
  <div class="project__person">
    <avatar [imageUrl]="responsiblePersonAvatar" [width]="24"></avatar>
    {{ responsiblePersonFullName | defaultValue }}
  </div>
</div>
