import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Task } from '@shared/models/task.model';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { NgxPrettyCheckboxComponent } from 'ngx-pretty-checkbox';
import { Subscription, timer } from 'rxjs';
import { ListTaskService } from '@shared/modules/list/services/list-task.service';
import { TaskController } from '@shared/controllers/task.controller';
import { ConfirmationPopoverDirective } from '@shared/libs/angular-confirmation-directive/confirmation-popover.directive';

@Component({
  selector: 'task-checkbox',
  templateUrl: './task-checkbox.component.html',
  styleUrls: ['./task-checkbox.component.scss']
})
export class TaskCheckboxComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  TaskStatus = TaskStatus;

  static readonly DELAY_AFTER_TASK_COMPLETED = 0;

  subCompletedTask: Subscription = new Subscription();

  @Input() task: Task;
  @Input() disabled: boolean = false;
  @Input() placement: string = 'right';
  @Output('onCheckSubtasks') onCheckSubtasks: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onUpdateTask') onUpdateTask: EventEmitter<Task> = new EventEmitter<Task>();
  @ViewChild('prettyCheckboxComponent') prettyCheckboxComponent: NgxPrettyCheckboxComponent;
  @ViewChild('cp') cp: ConfirmationPopoverDirective;

  constructor(private listTaskService: ListTaskService) {}

  ngOnInit(): void {}

  setCompletedForSubtasks() {
    this.prettyCheckboxComponent.checked = true;
    this.onStatusChange({ value: true, checked: true }, true);
  }

  onStatusChange(e: { value; checked: boolean }, subtasksCompleted?: boolean) {
    this.subCompletedTask.unsubscribe();
    if (e.checked) {
      this.subCompletedTask = timer(TaskCheckboxComponent.DELAY_AFTER_TASK_COMPLETED).subscribe(() => {
        if (subtasksCompleted) {
          this.completeSubtasks();
          this.onCheckSubtasks.emit(true);
        }
        this.task.status = TaskStatus.COMPLETED;
        this.onUpdateTask.emit(this.task);
        this.subCompletedTask.unsubscribe();
      });
    } else {
      this.task.status = TaskStatus.CREATED;
      this.listTaskService.updateTask(this.task);
      this.onUpdateTask.emit(this.task);
    }
  }

  onCheckboxWithSubtasksClick(e) {
    if (!this.prettyCheckboxComponent.checked) {
      e.preventDefault();
    } else {
      this.cp.ngOnDestroy();
      this.subCompletedTask.unsubscribe();
    }
  }

  completeSubtasks() {
    const complete = (task) => {
      task.children.map((t: Task) => {
        t.status = TaskStatus.COMPLETED;
        this.listTaskService.updateTask(t);
        complete(t);
        return t;
      });
    };
    complete(this.task);
    const ctrl = new TaskController(this.task);
    ctrl.completeSubtasks(this.task).toPromise();
  }
}
