import { Component } from '@angular/core';

@Component({
  selector: 'header-back-icon',
  templateUrl: './header-back-icon.component.html',
  styleUrls: ['./header-back-icon.component.scss']
})
export class HeaderBackIconComponent {
  history = history;

  constructor() {}
}
