import { Task } from "@shared/models/task.model";
import * as moment from "moment";

export function sortTasks(a: Task, b: Task) {
  // Step 1: Move non-completed high tasks to the top
  if (a.status !== 'completed' && b.status === 'completed') {
    return -1;
  }
  if (a.status === 'completed' && b.status !== 'completed') {
    return 1;
  }

  // Step 2: Sort within the non-completed tasks
  if (a.status !== 'completed' && b.status !== 'completed') {
    if (a.isHighPriority !== b.isHighPriority) {
      return a.isHighPriority ? -1 : 1;
    }
    return moment(a.created).unix() - moment(b.created).unix();
  }

  // Step 3: Sort within the completed tasks
  if (a.status === 'completed' && b.status === 'completed') {
    if (a.isHighPriority !== b.isHighPriority) {
      return a.isHighPriority ? -1 : 1;
    }
    return moment(a.created).unix() - moment(b.created).unix();
  }

  return 0;
}
