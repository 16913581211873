import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PropertyType } from '@modules/projects/shared/enums/property-type.enum';

@Component({
  selector: 'project-property-avatar-type',
  templateUrl: './project-property-avatar-type.component.html',
  styleUrls: ['./project-property-avatar-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPropertyAvatarTypeComponent implements OnInit {
  PropertyType = PropertyType;

  @Input() propertyType: PropertyType = PropertyType.OTHER;

  constructor() {}

  ngOnInit(): void {}
}
