import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';

@Component({
  selector: 'app-contact-connection',
  templateUrl: './contact-connection.component.html',
  styleUrls: ['./contact-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactConnectionComponent {
  @Input() contact: Contact;

  @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();

  get isCompany(): boolean {
    return this.contact.type === ContactType.COMPANY;
  }
}
