<div *ngIf="!isEditMode" class="comment">
  <div class="header">
    <avatar [width]="24" [imageUrl]="getPhoto(commentary.creator)"></avatar>
    <div class="creator-name">{{ commentary.creator?.userPerson?.previewName }}</div>
  </div>
  <div class="content">{{ commentary.content }}</div>
  <div class="attachments">
    <div class="files mb-2" *ngIf="commentary?.attachments?.length">
      <attachment-file
        *ngFor="let file of commentary?.attachments; trackBy: trackAttachment"
        [file]="file"
        [edit]="true"
      ></attachment-file>
    </div>
  </div>

  <hr class="spacer-v2 spacer-v2-light my-2" />

  <div class="bottom">
    <div class="date">
      {{ commentary.created | dateTimeFormat: Config.DATETIME_FORMAT_SEPARATOR }}
    </div>
    <div class="actions">
      <app-action-button-link-like
        [permission]="{
          group: PermissionsGroups.COMMENTARIES,
          action: 'EDIT',
          objectCreatorId: [commentary?.creator?.id]
        }"
        [text]="'Commentaries.edit' | translate"
        icon="./assets/img/ic-edit-v2.svg"
        (onClick)="edit()"
      ></app-action-button-link-like>
      <app-action-button-link-like
        [permission]="{
          group: PermissionsGroups.COMMENTARIES,
          action: 'REMOVE',
          objectCreatorId: [commentary?.creator?.id]
        }"
        [text]="'Commentaries.remove' | translate"
        icon="./assets/img/ic-trash-blue-v2.svg"
        (onClick)="remove()"
      ></app-action-button-link-like>
    </div>
  </div>
</div>

<div *ngIf="isEditMode" class="edit-container">
  <div class="edit-title">{{ 'Commentaries.editTitle' | translate }}</div>
  <div>
    <text-attachments-input
      #textAttach
      [attachments]="attachments?.length ? attachments : []"
      [fileName]="'files'"
      [textName]="'note'"
      [form]="form"
      [config]="config"
      [attachmentsConfig]="attachmentsConfig"
      (removeFileEvent)="removeFileEvent($event)"
    >
      <div #content></div>
    </text-attachments-input>
  </div>
  <div class="edit-buttons">
    <app-action-button-link-like
      [text]="'cancel' | translate"
      (onClick)="cancelEdit()"
    ></app-action-button-link-like>
    <app-button
      (onClick)="saveEditedComment()"
      [config]="{
        type: ButtonTypes.NEW_SECONDARY_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL,
        text: 'Commentaries.saveChange' | translate
      }"
    ></app-button>
  </div>
</div>
