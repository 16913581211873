import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { SEOService } from '@shared/services/seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

export class RouterNavigationController {
  routerNavigationEventEmitter: EventEmitter<RouterNavigationEvent> =
    new EventEmitter<RouterNavigationEvent>();
  private restore: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SEOService,
    private translate: TranslateService
  ) {
    this.listenNavigationEnd();
    this.listenRouterNavigationEvents();
  }

  listenRouterNavigationEvents() {
    this.routerNavigationEventEmitter.subscribe((event: RouterNavigationEvent) => {
      switch (event.type) {
        case RouterNavigationEventType.RESTORE:
          this.restore = true;
          break;
        case RouterNavigationEventType.NO_RESTORE:
          this.restore = false;
          break;
      }
    });
  }

  listenNavigationEnd() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData) => {
        this.restorePosition(routeData);
        this.setMetatitle(routeData);
      });
  }

  restorePosition(routeData) {
    if (!routeData.notRestorationPosition && this.restore) {
      document.getElementsByClassName('base-panel')[0].scrollTo(0, 0);
    }
  }

  setMetatitle(routeData) {
    const baseTitle = `${Config.SYSTEM_NAME} ${Config.INSTANCE_NAME}`;
    if (routeData.metaTitle) {
      const translate = this.translate.instant(`Metatitles.${routeData.metaTitle}`);
      this.seoService.updateTitle(`${baseTitle} | ${translate}`);
    } else {
      this.seoService.updateTitle(`${baseTitle}`);
    }
  }
}

export class RouterNavigationEvent {
  type: RouterNavigationEventType;
}

export enum RouterNavigationEventType {
  NO_RESTORE,
  RESTORE
}
