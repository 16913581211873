import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ListCustomCell } from '@shared/modules/list/interfaces/list-custom-cell.interface';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { ListService } from '@shared/modules/list/services/list.service';
import { ListEventType } from '@shared/modules/list/model/list-event.model';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { UserPerson } from '@shared/interfaces/user.interface';
import { StorageService } from '@core/services/storage.service';
import { Employee } from '@shared/models/employee.model';
import { NavigateService } from '@shared/services/navigate.service';
import {
  BasePanelChangeEventType,
  BasePanelService
} from '@shared/modules/base-panel/services/base-panel.service';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import {
  ProjectChangeStageEvent,
  ProjectChangeStageEventType
} from '@modules/projects/shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'project-toggle-menu-cell',
  templateUrl: './project-toggle-menu-cell.component.html'
})
export class ProjectToggleMenuCellComponent implements ListCustomCell, OnDestroy {
  Object = Object;
  ProjectStage = ProjectStage;
  PermissionsGroups = PermissionsGroups;

  row: Project;
  @ViewChild('template') template;
  ctrl: ProjectController;
  subs = new Subscription();

  constructor(
    private listService: ListService,
    private settingsService: SettingsService,
    private store: StorageService,
    private basePanelService: BasePanelService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
    public projectPreviewService: ProjectPreviewService
  ) {}

  preview() {
    this.ctrl = new ProjectController(this.row);
    this.ctrl.preview(this.row);
  }

  edit() {
    this.ctrl = new ProjectController(this.row);
    this.ctrl.edit(this.row);
  }

  editSecondBox() {
    this.ctrl = new ProjectController(this.row);
    this.ctrl.editSecondBox(this.row);
  }

  changeResponsible() {
    this.ctrl = new ProjectController(this.row);
    this.ctrl.setResponsibleCompany(new Project(this.row)).subscribe(() => {
      if (this.listService.rows.length === 1) {
        this.getUserProfileForHasResponsibleCheck();
      }
      this.listService.getRows();
    });
  }

  onStageChange(stage: ProjectStage) {
    const ctrl = new ProjectController(this.row);
    ctrl
      .setStage(this.row, stage, this.row?.basicDataBox?.termStart, this.row?.basicDataBox?.termEnd)
      .subscribe((e: ProjectChangeStageEvent) => {
        switch (e.type) {
          case ProjectChangeStageEventType.CHANGED:
            this.s.success(this.t.instant('Projects.PreviewHeader.stageUpdated'));
            this.listService.eventEmitter.emit({
              type: ListEventType.ROW_REMOVED,
              data: this.row.stage
            });
            this.listService.eventEmitter.emit({
              type: ListEventType.ROW_ADD,
              data: stage
            });
            this.listService.setFilter('[a-stage][eq]', stage);
            this.listService.config.listTitle = 'Projects.Stage.' + stage;
            this.listService.getRows();
            break;
        }
      });
  }

  private getUserProfileForHasResponsibleCheck() {
    this.settingsService.getUserData().subscribe((user: UserPerson) => {
      this.store.UserPerson = user;
      user.employees.map((e: Employee) => {
        if (e.id === this.store.Employee.id) {
          this.store.Employee = e;
          if (
            !e.hasResponsibleProjects &&
            e.role !== UserRoles.ROLE_EMPLOYEE_ADMIN &&
            e.role !== UserRoles.ROLE_EMPLOYEE_MANAGER
          ) {
            this.basePanelService.panelChangeEmitter.emit({
              type: BasePanelChangeEventType.RECONFIGURE_NAVIGATION
            });
            this.n.go('user-settings');
          }
        }
      });
    });
  }

  remove() {
    this.ctrl = new ProjectController(this.row);
    this.ctrl.remove(this.row).subscribe(() => {
      this.listService.getRows();
      this.listService.eventEmitter.emit({ type: ListEventType.ROW_REMOVED, data: this.row.stage });
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
