<div class="content-v2 connections">
  <sidenav-loading *ngIf="!mainContactId" class="content-loading" [cover]="true"></sidenav-loading>

  <div *ngIf="mainContactId" class="content-wrapper-v2">
    <div *ngIf="isCompany" class="card-v2 card-v2-highlighted">
      <div class="card-v2-title mb-4">{{ 'Contacts.V2.Connections.managingDirectorTitle' | translate }}</div>

      <div class="connections__list">
        <app-contact-connection
          [contact]="director"
          (onRemove)="onRemove(director.id, 'director')"
          (onEdit)="onEdit(director, 'director')"
        ></app-contact-connection>
      </div>

      <ng-container *ngIf="!director">
        <p class="no-items">{{ 'Contacts.V2.Connections.noManagingDirector' | translate }}</p>

        <hr class="spacer-v2 spacer-v2-light" />

        <ng-select
          ngSelectExtension
          class="new-select"
          bindLabel="fullName"
          bindValue="value"
          [items]="contacts"
          [addTagText]="'Contacts.V2.Connections.addContact' | translate"
          [placeholder]="'Contacts.V2.Connections.contactPlaceholder' | translate"
          [notFoundText]="'Contacts.V2.Connections.contactNotFound' | translate"
          [loadingText]="'Contacts.V2.loading' | translate"
          [addTag]="true"
          [loading]="selectedTypeSearch === 'director' && loading"
          [addNewItemFunction]="addDirectorFn.bind(this)"
          [searchFn]="searchFn.bind(this)"
          [formControl]="directorControl"
          (search)="onSearch($event)"
          (scrollToEnd)="fetchMore()"
          (open)="onSelectOpen('director')"
        ></ng-select>
      </ng-container>
    </div>

    <div class="card-v2 card-v2-highlighted">
      <div class="card-v2-title mb-4">{{ 'Contacts.V2.Connections.subContactsTitle' | translate }}</div>

      <div class="connections__list">
        <ng-container *ngFor="let connection of subContacts">
          <app-contact-connection
            [contact]="connection"
            (onRemove)="onRemove(connection.id, 'subContact')"
            (onEdit)="onEdit(connection, 'subContact')"
          >
          </app-contact-connection>
        </ng-container>
      </div>

      <div *ngIf="subContacts.length === 0" class="connections__placeholder">
        <svg-icon src="/assets/img/ic-no-contact-v2.svg"></svg-icon>
        <p>{{ 'Contacts.V2.Connections.noSubContacts' | translate }}</p>
      </div>

      <hr class="spacer-v2 spacer-v2-light" />

      <ng-select
        ngSelectExtension
        class="new-select"
        bindLabel="fullName"
        bindValue="value"
        [items]="contacts"
        [loading]="selectedTypeSearch === 'subContact' && loading"
        [addTagText]="'Contacts.V2.Connections.addContact' | translate"
        [placeholder]="'Contacts.V2.Connections.contactPlaceholder' | translate"
        [notFoundText]="'Contacts.V2.Connections.contactNotFound' | translate"
        [loadingText]="'Contacts.V2.loading' | translate"
        [addTag]="true"
        [addNewItemFunction]="addSubContactFn.bind(this)"
        [searchFn]="searchFn.bind(this)"
        [formControl]="connectionControl"
        (search)="onSearch($event)"
        (scrollToEnd)="fetchMore()"
        (open)="onSelectOpen('subContact')"
      ></ng-select>
    </div>

    <div class="card-v2 card-v2-highlighted">
      <div class="card-v2-title mb-4">{{ 'Contacts.V2.Connections.recommendationTitle' | translate }}</div>

      <div class="connections__list">
        <app-contact-connection
          [contact]="recommender"
          (onRemove)="onRemove(recommender.id, 'recommender')"
          (onEdit)="onEdit(recommender, 'recommender')"
        ></app-contact-connection>
      </div>

      <ng-container *ngIf="!recommender">
        <p class="no-items">{{ 'Contacts.V2.Connections.noRecommendations' | translate }}</p>

        <hr class="spacer-v2 spacer-v2-light" />

        <ng-select
          ngSelectExtension
          class="new-select"
          bindLabel="fullName"
          bindValue="value"
          [items]="contacts"
          [addTagText]="'Contacts.V2.Connections.addContact' | translate"
          [placeholder]="'Contacts.V2.Connections.contactPlaceholder' | translate"
          [notFoundText]="'Contacts.V2.Connections.contactNotFound' | translate"
          [loadingText]="'Contacts.V2.loading' | translate"
          [addTag]="true"
          [loading]="selectedTypeSearch === 'recommender' && loading"
          [addNewItemFunction]="addRecommenderFn.bind(this)"
          [searchFn]="searchFn.bind(this)"
          [formControl]="recommenderControl"
          (search)="onSearch($event)"
          (scrollToEnd)="fetchMore()"
          (open)="onSelectOpen('recommender')"
        ></ng-select>
      </ng-container>
    </div>
  </div>
</div>
