import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ContactsListComponent } from '@modules/contacts/pages/contacts-list/contacts-list.component';
import { ContactsPanelComponent } from '@modules/contacts/pages/contacts-panel/contacts-panel.component';
import { ContactsListGuard } from '@core/guards/contacts/contacts-list.guard';

export const ContactsRoutes: CustomRoutes = [
  {
    path: '',
    component: ContactsPanelComponent,
    data: {
      hideProgressBar: true
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/panel/contacts/list'
      },
      {
        path: 'list',
        name: 'contacts-list',
        canActivate: [ContactsListGuard],
        data: {
          metaTitle: 'Contacts.list'
        },
        component: ContactsListComponent
      }
    ]
  }
];
