<modal-title class="modal-title-v2" [title]="'Settings.Team.sendInvitation' | translate"></modal-title>
<!-- #region Content -->

<form [formGroup]="form">
  <mat-dialog-content class="pr-1">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="mb-3">
          <div class="new-select-label">{{ 'Settings.Team.employeeEmail' | translate }}</div>
          <input-v2
            class="text-control required"
            formControlName="email"
            [errMessages]="errorMessages('email')"
          >
          </input-v2>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="mb-3">
          <div class="new-select-label">{{ 'Settings.Team.employeePosition' | translate }}</div>
          <ng-select
            formControlName="role"
            [items]="positionSelectOptions"
            bindLabel="name"
            bindValue="value"
            [clearable]="false"
            [placeholder]="'Settings.Team.notSelected' | translate"
            class="new-select"
          ></ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div>
          <div class="new-select-label">{{ 'Settings.Team.hourlyRate' | translate }}</div>
          <input-v2
            class="text-control required"
            [type]="'number'"
            formControlName="hourlyRate"
            [errMessages]="errorMessages('hourlyRate')"
          >
          </input-v2>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
<mat-dialog-actions align="end">
  <div class="d-flex mb-4">
    <app-button
      class="mr-2"
      [disabled]="loading"
      (onClick)="closeModal()"
      [config]="{
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        text: 'Settings.Team.cancel' | translate,
        size: ButtonSize.LARGE
      }"
    ></app-button>
    <app-button
      (onClick)="submit()"
      [loading]="loading"
      [disabled]="loading"
      [config]="{
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        text: 'Settings.Team.add' | translate,
        size: ButtonSize.LARGE
      }"
    ></app-button>
  </div>
</mat-dialog-actions>
<!-- #endregion -->
