<div class="content-v2 projects">
  <sidenav-loading *ngIf="!contact" class="content-loading" [cover]="true"></sidenav-loading>

  <div *ngIf="contact" class="content-wrapper-v2">
    <div class="card-v2 card-v2-highlighted">
      <div class="card-v2-title mb-4">{{ 'Contacts.V2.Projects.activeProjectsTitle' | translate }}</div>

      <div class="projects__list">
        <app-contact-project
          *ngFor="let project of activeProjects"
          [project]="project"
          (onRemove)="onRemove($event)"
        ></app-contact-project>

        <div class="no-items" *ngIf="activeProjects.length === 0">
          {{ 'Contacts.V2.Projects.noActiveProjects' | translate }}
        </div>
      </div>

      <hr class="spacer-v2 spacer-v2-light mt-4" />

      <div class="projects__actions">
        <app-button
          (onClick)="addExistingProject()"
          [config]="{
            text: 'Contacts.V2.Projects.addProjectButton' | translate,
            type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
            size: ButtonSize.LARGE,
            smSize: ButtonSize.SMALL,
            prependSrc: './assets/img/ic-project-v2.svg'
          }"
        ></app-button>

        <app-button
          (onClick)="addNewProject()"
          [loading]="newProjectButtonLoading"
          [config]="{
            text: 'Contacts.V2.Projects.addNewProjectButton' | translate,
            type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
            size: ButtonSize.LARGE,
            smSize: ButtonSize.SMALL,
            prependSrc: './assets/img/ic-project-v2.svg'
          }"
        ></app-button>
      </div>
    </div>

    <div class="card-v2 card-v2-highlighted">
      <div class="card-v2-title mb-4">{{ 'Contacts.V2.Projects.inactiveProjectsTitle' | translate }}</div>

      <div class="projects__list">
        <app-contact-project
          *ngFor="let project of inactiveProjects"
          [project]="project"
        ></app-contact-project>

        <div class="no-items" *ngIf="inactiveProjects.length === 0">
          {{ 'Contacts.V2.Projects.noInactiveProjects' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
