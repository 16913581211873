import { EntryManageType } from '@shared/modules/entry/components/entry-list/entry-list.component';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { IEntryTemplateConfig } from '../interfaces/entry-template-config.interface';
import { ProjectBoxEntryConfig } from './project-box-entry-config';

export const ProjectThirdBoxEntries: IEntryTemplateConfig[] = [
  {
    title: 'Projects.basicBox3Titles.agreements',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_TYPE_AGREEMENTS,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox3Titles.agreements',
      editTitle: 'Projects.basicBox3Titles.agreementsEdit'
    }
  },

  {
    title: 'Projects.basicBox3Titles.estimations',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_ESTIMATIONS,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox3Titles.estimations',
      editTitle: 'Projects.basicBox3Titles.estimationsEdit'
    }
  },

  {
    title: 'Projects.basicBox3Titles.orders',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_ORDERS,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox3Titles.orders',
      editTitle: 'Projects.basicBox3Titles.ordersEdit'
    }
  },

  {
    title: 'Projects.basicBox3Titles.acceptances',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_ACCEPTANCES,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox3Titles.acceptances',
      editTitle: 'Projects.basicBox3Titles.acceptancesEdit'
    }
  },

  {
    title: 'Projects.basicBox3Titles.other',
    type: EntryManageType.BUTTON,
    entryType: EntryType.TYPE_OTHER,
    attachmentsConfig: ProjectBoxEntryConfig,
    entryModalConfig: {
      addTitle: 'Projects.basicBox3Titles.other',
      editTitle: 'Projects.basicBox3Titles.otherEdit'
    }
  }
];
