<div class="new-modal">
  <div class="title__wrapper mb-4">
    <span class="title">{{ 'Contacts.V2.Projects.addProjectButton' | translate }}</span>
    <svg-icon class="clear-icon" src="/assets/img/close-icon.svg" (click)="cancel()"></svg-icon>
  </div>

  <form [formGroup]="form" class="rows__wrapper">
    <ng-select
      class="new-select"
      formControlName="asContact"
      bindLabel="label"
      bindValue="value"
      [items]="asContactItems"
      [clearable]="false"
      [searchable]="false"
      [tabIndex]="-1"
      (change)="onAddToProjectTypeChange($event)"
    >
      <ng-template ng-option-tmp let-item="item" class="contact-option">
        <div class="contact-option__title">{{ item.label }}</div>
        <div class="contact-option__subtitle">{{ item.description }}</div>
      </ng-template>
    </ng-select>

    <ng-container *ngIf="!isAsContact">
      <div class="control-v2">
        <input-label [label]="'Contacts.V2.email' | translate" [required]="true"></input-label>
        <input-v2
          formControlName="email"
          [placeholder]="'Contacts.V2.emailPlaceholder' | translate"
        ></input-v2>
      </div>

      <div class="control-v2">
        <input-label
          [label]="'Contacts.V2.Projects.roleInSystem' | translate"
          [required]="true"
        ></input-label>
        <ng-select
          ngSelectExtension
          class="new-select"
          bindLabel="text"
          bindValue="id"
          [items]="roles"
          [placeholder]="'Contacts.V2.Projects.roleInSystemPlaceholder' | translate"
          [tabIndex]="-1"
          formControlName="role"
        >
        </ng-select>
        <span *ngIf="roleControl?.touched && roleControl?.invalid" class="d-flex form-control-error-msg mt-2">
          {{ 'Contacts.V2.Projects.notChosenRole' | translate }}
        </span>
      </div>
    </ng-container>

    <div class="control-v2">
      <input-label [label]="'Contacts.V2.Projects.projectLabel' | translate" [required]="true"></input-label>

      <ng-select
        ngSelectExtension
        class="new-select new-select-searchable"
        formControlName="project"
        bindLabel="name"
        bindValue="id"
        [url]="CONFIG.API + '/project/search?' + excludedIds"
        searchGetParam="query"
        appendTo="body"
        [loadingText]="'Contacts.V2.Projects.projectsLoading' | translate"
        [placeholder]="'Contacts.V2.Projects.projectSelectPlaceholder' | translate"
        [notFoundText]="'Contacts.V2.Projects.notFoundProject' | translate"
        [tabIndex]="-1"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" class="contact-option">
          <div class="contact-option__title">
            {{ item.name | defaultValue: ('Contacts.V2.Projects.noProjectName' | translate) }}
          </div>
          <div class="contact-option__subtitle">{{ item.address | defaultValue }}</div>
        </ng-template>
      </ng-select>
      <span
        *ngIf="projectControl?.touched && projectControl?.invalid"
        class="d-flex form-control-error-msg mt-2"
      >
        {{ 'Contacts.V2.Projects.notChosenProject' | translate }}
      </span>
    </div>
  </form>

  <div class="d-flex justify-content-end mt-4">
    <app-button
      (onClick)="cancel()"
      [config]="{
        text: 'Contacts.Preview.cancel' | translate,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>

    <app-button
      [class]="'ml-2'"
      [disabled]="loading"
      [loading]="loading"
      (onClick)="submitProject()"
      [config]="{
        text: 'Contacts.Preview.saveContactPerson' | translate,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>
  </div>
</div>
