import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { Employee } from '@shared/models/employee.model';
import { CheckPermission } from '../check-permission';
import { PermissionDecorator } from '../interfaces/permission-decorator.interface';
import { Moment } from 'moment';
import * as moment from 'moment';

/**
 * @description Directive to control permissions on DOM element. Important! You can't use it with non DOM elements, e.x. ng-container or ng-template
 * @author Robert Juszczyk
 * @export
 * @class PermissionDirective
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements AfterViewInit, OnChanges, OnDestroy {
  employee: Employee;
  observer: MutationObserver;
  lastChangeTime: Moment;
  changeCounter: number = 0;

  // tslint:disable-next-line: no-input-rename
  @Input('permission') permission: PermissionDecorator;

  constructor(private store: StorageService, private el: ElementRef, private changes: ChangeDetectorRef) {
    this.employee = this.store.Employee;
  }

  ngAfterViewInit() {
    if (!this.checkHostElement()) {
      return;
    }
    this.checkPermissions();
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        this.checkPermissions();
      });
    });
    this.observer.observe(this.el.nativeElement, { attributes: true, childList: true, characterData: true });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.lastChangeTime && moment().diff(this.lastChangeTime, 'ms') < 10 && this.changeCounter > 10) {
      this.changes.detach();
      console.warn(
        `WARNING Warning: Probably the host element for permission have not set change detection strategy 'OnPush'. Check element below:`,
      );
      console.warn(this.el.nativeElement);
    }

    this.lastChangeTime = moment();
    this.changeCounter++;

    this.checkPermissions();
  }

  checkPermissions() {
    if (!this.checkHostElement()) {
      return;
    }
    if (!this.permission || (this.permission && !Object.keys(this.permission).length)) return;

    const ctrl = new CheckPermission(this.permission);
    if (!ctrl.check()) {
      this.el.nativeElement.style.setProperty('display', 'none', 'important');
    } else {
      this.el.nativeElement.style.removeProperty('display');
    }
  }

  checkHostElement() {
    if (!this.el.nativeElement || this.el.nativeElement.style === undefined) {
      console.error(
        'ERROR Error: There is a problem with host element for permission directive. This is probably problem that you used ng-container or ng-template as host element.',
      );
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    if (this.checkHostElement() && this.observer) {
      this.observer.disconnect();
    }
  }
}
