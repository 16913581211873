import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { CHIPS_VIEW, ListService } from '@shared/modules/list/services/list.service';
import { Employee } from '@shared/models/employee.model';
import { Config } from '@shared/configs/config';
import { FormControl, FormGroup } from '@angular/forms';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { PropertyType } from '@modules/projects/shared/enums/property-type.enum';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'projects-filters',
  templateUrl: './projects-filters.component.html'
})
export class ProjectsFiltersComponent extends BaseListFiltersComponentComponent implements OnInit, OnDestroy {
  Config = Config;
  UserRoles = UserRoles;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  searchRoles: string;
  propertyTypes: any[] = [];

  constructor(
    @Optional() @Inject(CHIPS_VIEW) public chipsView: boolean,
    public service: ListService,
    @Optional() private dialogRef: MatDialogRef<ProjectsFiltersComponent>
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.createForm();
    this.setPropertyTypes();
    this.setSearchRoles();

    if (!this.chipsView) {
      this.service.readUrlParams(false);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setSearchRoles() {
    const params = new URLSearchParams();
    const roles = [
      UserRoles.ROLE_EMPLOYEE_ADMIN.toString(),
      UserRoles.ROLE_EMPLOYEE_MANAGER.toString(),
      UserRoles.ROLE_EMPLOYEE_WORKER.toString()
    ];
    roles.forEach((role) => params.append('filters[a.role][in][]', role));
    this.searchRoles = params.toString();
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[responsibleEmployee-id][eq]': new FormControl(null),
      '[basicDataBox-propertyType][eq]': new FormControl(null),
      '[mainContact-id][eq]': new FormControl(null),
      '[a-stage][eq]': new FormControl(null),
      '[favourite][is not null]': new FormControl(null),
      '[deleted]': new FormControl(null)
    });
  }

  setPropertyTypes() {
    this.propertyTypes = Object.values(PropertyType).map((type) => {
      return {
        id: type,
        name: this.t.instant('Projects.PropertyType.' + type)
      };
    });
  }

  clearFilters() {
    Object.keys(this.service?.filtersForm.value).forEach((key) => {
      if (key !== '[a-stage][eq]' && key !== '[favourite][is not null]') {
        this.service.filtersForm.get(key).setValue(null);
      }
    });
  }

  shouldShowClearFilters() {
    let show = false;
    Object.keys(this.service.filtersForm.value).forEach((key) => {
      if (
        this.service?.filtersForm?.value[key] &&
        key !== '[a-stage][eq]' &&
        key !== '[favourite][is not null]'
      ) {
        show = true;
      }
    });
    return show;
  }

  creatorName(employee: Employee) {
    const e = new Employee(employee);
    return e.userPerson.previewName;
  }

  contactName(contact: Contact) {
    const c = new Contact(contact);
    return c.fullName;
  }

  responseTransform(res) {
    return res.records;
  }

  cancel() {
    this.dialogRef.close();
    this.refreshChips();
  }

  submit() {
    this.service.getRows();
    this.dialogRef.close();
    this.refreshChips();
  }
}
