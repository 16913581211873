import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { ContactDetails } from '@modules/contacts/shared/consts/contact-form.const';
import { ContactTag } from '@modules/contacts/shared/interfaces/contact-tag.interface';
import { isPersonal } from '@modules/contacts/shared/utils/contact-type.util';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Config } from '@shared/configs/config';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { ContactServiceV2 } from '@shared/modules/contact-sidenav/services/contact-v2.service';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { StickyFooterEventType, StickyFooterService } from '@shared/services/sticky-footer.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'contact-details-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit {
  @Input() isPreview: boolean = false;
  @Input() parentForm: FormGroup;
  @Input() isEdit: boolean = false;
  @ViewChild('select') select: NgSelectExtensionDirective | NgSelectComponent;

  Config = Config;
  ContactDetails = ContactDetails;
  tagsTotalCount: number;
  tagsPage = 1;
  tags: ContactTag[] = [];
  tagsLoading = false;
  searchTerm = '';

  addTag$: EventEmitter<any> = new EventEmitter();

  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  isLoading$ = this.footerService.emitter.pipe(map(({ type }) => type === StickyFooterEventType.SUBMITTED));

  constructor(
    private contactService: ContactServiceV2,
    private footerService: StickyFooterService,
    private dialog: MatDialog,
    private t: TranslateService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.getInitTags();
    this.searchSubjectInit();
  }

  get selectedTags() {
    const selectedItems = this.select?.selectedItems || [];
    return selectedItems.sort((a, b) => a.value['name']?.localeCompare(b.value['name']));
  }

  get isPersonal() {
    return isPersonal(this.parentForm.get(ContactDetails.type));
  }

  get selectedFunctions() {
    return this.parentForm.get(ContactDetails.functions).value;
  }

  getInitTags({ search, openSelect }: { search?: string; openSelect?: boolean } = {}): void {
    const selectedIds = this.parentForm.get(ContactDetails.tagIds).value;
    if (selectedIds.some((element) => element === undefined)) return;

    this.tagsLoading = true;
    this.contactService
      .getTags({
        page: this.tagsPage,
        selectedIds,
        search
      })
      .subscribe(({ records, total }) => {
        this.tags = records;
        this.tagsTotalCount = total;
        this.tagsLoading = false;
        if (openSelect) (this.select as NgSelectComponent).open();
      });
  }

  removeSelectedTag(tagId: string | number) {
    const control = this.parentForm.get(ContactDetails.tagIds);
    control.setValue(control.value?.filter((id) => Number(id) !== Number(tagId)));
  }

  fetchMoreTags(): void {
    const nextPage = this.tagsPage + 1;

    if (this.tagsTotalCount <= this.tags.length) return;

    this.contactService
      .getTags({ page: nextPage, selectedIds: this.parentForm.get(ContactDetails.tagIds).value })
      .subscribe(({ records }) => {
        this.tags = [...this.tags, ...records];
        this.tagsPage = nextPage;
      });
  }

  searchSubjectInit(): void {
    this.searchSubject
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((search) => {
          this.searchTerm = search;
          this.getInitTags({ search });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onSearch({ term }) {
    if (this.searchTerm === term) return;
    this.tagsPage = 1;
    this.searchSubject.next(term);
  }

  addTagFn = ({ name, value }: { name: string; value: number }): void => {
    if (value || !name.trim()) return;

    this.tagsLoading = true;

    (this.select as NgSelectComponent).close();

    this.contactService.addTag(name).subscribe(
      (addedTag: ContactTag) => this.onSuccessAddTag(addedTag),
      () => this.onErrorAddTag()
    );
  };

  onSuccessAddTag(addedTag: ContactTag): void {
    const control = this.parentForm.get(ContactDetails.tagIds);
    this.addTag$.emit([{ name: addedTag.name, value: addedTag.id }]);
    control.setValue([...control.value, addedTag.id].filter((id) => id));
    this.getInitTags({ openSelect: true });
    this.tagsLoading = false;
    this.snackBarService.success(this.t.instant('Contacts.Fields.successTagAdded'));
  }

  onErrorAddTag(): void {
    const control = this.parentForm.get(ContactDetails.tagIds);
    this.tagsLoading = false;
    control.setValue(control.value.filter((id) => id));
    this.getInitTags({ openSelect: true });
    this.snackBarService.error(this.t.instant('Contacts.Fields.errorTagAdded'));
  }

  removeTagFromApi(tagId: number, event: Event): void {
    event.stopPropagation();

    this.dialog
      .open(ConfirmModalComponent, {
        width: Config.CONFIRM_MODAL_WIDTH,
        autoFocus: false,
        data: {
          title: 'RemoveData.title',
          body: 'RemoveData.description',
          submitButtonText: 'RemoveData.confirm',
          submitButtonType: ButtonTypes.NEW_RED,
          submitButtonIcon: './assets/img/ic-warning-v2.svg'
        }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.tagsLoading = true;
          this.contactService.removeTag(tagId).subscribe(
            () => {
              this.removeSelectedTag(tagId);
              this.tags = this.tags.filter((tag) => +tag.value !== tagId);
              this.tagsLoading = false;
              this.snackBarService.success(this.t.instant('Contacts.Fields.successTagRemoved'));
            },
            () => {
              this.tagsLoading = false;
              this.snackBarService.error(this.t.instant('Contacts.Fields.errorTagRemoved'));
            }
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
