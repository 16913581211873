import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'event-protocols-list',
  templateUrl: './event-protocols-list.component.html',
  styleUrls: ['./event-protocols-list.component.scss']
})
export class EventProtocolsListComponent implements OnInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  PermissionsGroups = PermissionsGroups;

  constructor(
    public service: EventSidenavService,
    private s: SnackBarService,
    private t: TranslateService
  ) {}

  ngOnInit(): void {}

  createProtocol() {
    const successCallback = (protocol: Protocol) => {
      this.service.eventProtocolCtrl.add(protocol);
      this.service.updateEvent(true).subscribe({
        next: () => this.s.success(this.t.instant('CalendarEvents.protocolAssinged')),
        error: () => this.service.eventProtocolCtrl.delete(protocol)
      });
    };
    const ctrl = new ProtocolController(null, null);
    ctrl.add(null, { successCallback, ignoreTutorial: true });
  }

  trackPortocol(index: number, protocol: Protocol) {
    return protocol.id;
  }
}
