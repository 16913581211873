export const getCalendarIcon = (i: { type: string }) => {
  const uri = './assets/img/';
  switch (i.type) {
    case 'url':
      return `${uri}ic-calendar-small.svg`;
    case 'google':
      return `${uri}ic-calendar-google.svg`;
    case 'default':
    case 'intilio':
    default:
      return `${uri}ic-calendar-default.svg`;
  }
};
