import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DeviceHelper } from '@shared/helpers/device.helper';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class FormErrorTypes {
  static readonly red = 'red';
  static readonly required = 'required';
  static readonly min = 'min';
  static readonly max = 'max';
  static readonly requiredTrue = 'requiredTrue';
  static readonly email = 'email';
  static readonly minLength = 'minlength';
  static readonly maxLength = 'maxlength';
  static readonly pattern = 'pattern';
}

export const getErrorMessages = (translate: TranslateService) => ({
  [FormErrorTypes.red]: '&nbsp;',
  [FormErrorTypes.required]: translate.instant('FormErrors.required'),
  [FormErrorTypes.min]: ({ min }: ValidationErrors) => translate.instant('FormErrors.min', { number: min }),
  [FormErrorTypes.max]: ({ max }: ValidationErrors) => translate.instant('FormErrors.max', { number: max }),
  [FormErrorTypes.email]: translate.instant('FormErrors.email'),
  [FormErrorTypes.minLength]: ({ requiredLength }: ValidationErrors) =>
    translate.instant('FormErrors.minLength', { number: requiredLength }),
  [FormErrorTypes.maxLength]: ({ requiredLength }: ValidationErrors) =>
    translate.instant('FormErrors.maxLength', { number: requiredLength })
});

@Component({
  selector: 'input-v2',
  templateUrl: './input-v2.component.html',
  styleUrls: ['./input-v2.component.scss']
})
export class InputV2Component implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() type: string = 'text';
  @Input() errMessages: { [key: string]: string } = {};
  @Input() prependSrc = '';
  @ViewChild('inputv2') inputv2: ElementRef<HTMLInputElement>;

  @Input() set readonly(value: boolean) {
    this._readonly = value;
  }

  get readonly(): boolean {
    return this._readonly;
  }

  get isTypeNumber(): boolean {
    return this.type === 'number';
  }

  _readonly = false;

  value: string = '';
  isDisabled: boolean = false;
  isTouched: boolean = false;
  isInvalid: boolean = false;
  isScrolled: boolean = false;
  errors: { [key: string]: any } = {};

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  control: AbstractControl | null = null;

  destroy$ = new Subject();

  get pattern() {
    if (this.type === 'number') {
      return '\\d*';
    }
    return '';
  }

  get errorMessage(): string {
    if (!this.isInvalid || !this.isTouched) return '';

    for (const errorKey in this.errors) {
      return this.errMessages[errorKey]
        ? this.getValidatorMessage(errorKey)
        : this.getDefaultMessages(errorKey);
    }

    return '';
  }

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private t: TranslateService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.control = this.ngControl.control;

      combineLatest([this.control.valueChanges, this.control.statusChanges])
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.validate());
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(): void {
    this.onChange(this.value);
    this.validate();
    this.handleScrollOnMobile();
  }

  onBlur(): void {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
    this.validate();
  }

  onWheel(event: WheelEvent): void {
    if (this.type === 'number') event.preventDefault();
  }

  private validate(): void {
    if (this.control) {
      this.errors = this.control.errors || {};
      this.isInvalid = this.control.invalid;
      this.isTouched = this.control.touched;
    }
  }

  getValidatorMessage(name: string) {
    if (name === FormErrorTypes.pattern) {
      const pattern = this.control?.errors?.pattern?.requiredPattern;
      return this.errMessages[pattern] || 'No pattern validator';
    }
    return this.errMessages[name] || this.getDefaultMessages(name);
  }

  getDefaultMessages(errorType: string) {
    const err = this.control.errors[errorType];
    const errorMessages = getErrorMessages(this.t);
    const errorMessage = errorMessages[errorType];
    return typeof errorMessage === 'function' ? errorMessage(err) : errorMessage || 'No validator message';
  }

  onFocus(): void {
    this.isScrolled = false;
    this.handleInputVisibilityOnMobile();
  }

  private handleScrollOnMobile(): void {
    // Solution for problem on mobile devices, the sticky footer covers the input field when it is located at the bottom of the screen.
    if (DeviceHelper.isMobileDevice && !this.isScrolled) {
      setTimeout(() => {
        const footerElement = document.querySelector('.sticky-footer');
        const scrollContainer = this.findScrollContainer(this.inputv2.nativeElement);

        // Calculate only if sticky footer exists and container is scrollable.
        if (footerElement && scrollContainer) {
          const inputRect = this.inputv2.nativeElement.getBoundingClientRect();
          const inputPosition = window.innerHeight - inputRect.bottom;
          const isInFooterArea = inputPosition < footerElement.getBoundingClientRect().height;

          // Scroll only if input is located at the bottom of the screen (in sticky footer area).
          if (isInFooterArea && inputPosition > 0) {
            scrollContainer.scrollBy({ top: footerElement.getBoundingClientRect().height });
          }
        }
      }, 100);
      this.isScrolled = true;
    }
  }

  // When the input is cut off at the bottom of the screen and we focus on it, we scroll the screen to ensure the entire input is visible.
  handleInputVisibilityOnMobile(): void {
    if (DeviceHelper.isMobileDevice) {
      const inputPosition = window.innerHeight - this.inputv2.nativeElement.getBoundingClientRect().bottom;
      if (inputPosition < 0) {
        const scrollContainer = this.findScrollContainer(this.inputv2.nativeElement);
        scrollContainer.scrollBy({ top: Math.abs(inputPosition) });
      }
    }
  }

  // Finds the closest ancestor element that has overflow-y set to 'auto' or 'scroll'.
  private findScrollContainer(element: HTMLElement): HTMLElement | null {
    let parent = element.parentElement;

    while (parent) {
      if (
        window.getComputedStyle(parent).overflowY === 'auto' ||
        window.getComputedStyle(parent).overflowY === 'scroll'
      ) {
        return parent;
      }
      parent = parent.parentElement;
    }

    return null;
  }
}
