import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { TranslateService } from '@ngx-translate/core';
import { getTranslatedFunctions } from '@shared/helpers/contact-translated-functions.helper';
import { ContactListSubcontactsModalComponent } from '@modules/contacts/shared/components/contact-list-subcontacts-modal/contact-list-subcontacts-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { Config } from '@shared/configs/config';
import { ContactProjectPemissionDialogData } from '../../../../../shared/components/contact-project-permission-modal/contact-project-permission-modal.component';
import { ISingleContactProjectConfig } from '../../interfaces/single-contact-project-config.interface';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

export enum EProjectContactRole {
  CONTACT_MAIN,
  CONTACT_RESPONSIBLE
}
@Component({
  selector: 'contact-info-single',
  templateUrl: './contact-info-single.component.html',
  styleUrls: ['./contact-info-single.component.scss']
})
export class ContactInfoSingleComponent implements OnInit, OnDestroy {
  PermissionsGroups = PermissionsGroups;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  @Input() showMobileTags: boolean = true;
  @Input() contact: Contact;
  @Input() projectId: number = null;
  @Input() mainContact: Contact;
  @Input() changeContactPersonInTemplate: boolean = true;
  @Input() isRemoveAction: boolean = true;
  @Input() isQuickActions: boolean = false;
  @Input() withoutActions: boolean = false;
  @Input() withoutBorders: boolean = false;
  @Input() showContactPerson: boolean = true;
  @Input() isResponsibleClient: boolean = false;
  @Input() isPreviewVisible: boolean = true;
  @Input() isMainClient: boolean = false;
  @Input() showAvailability: boolean = true;
  @Input() hideResponsible: boolean = false;
  @Input() contactProjectDialogData: ContactProjectPemissionDialogData;
  @Input() extraConfig: ISingleContactProjectConfig;
  @Input() projectContactId: number = null;

  $objectCreatorId: number[] = [];
  @Input()
  set objectCreatorId($objectCreatorId: number[]) {
    this.$objectCreatorId = $objectCreatorId;
    this.permissionEdit.objectCreatorId = this.$objectCreatorId?.length ? [...this.$objectCreatorId] : [];
  }

  get objectCreatorId() {
    return this.$objectCreatorId;
  }

  @Output('onUpdate') onUpdate: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output('onRemove') onRemove: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output('onRemoveContactPerson') onRemoveContactPerson: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output('onChangeContactPerson') onChangeContactPerson: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() contactRespChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setContactRole: EventEmitter<{
    role: any;
    contact: Contact;
    type: EProjectContactRole;
  }> = new EventEmitter();
  sub: Subscription;

  permissionEdit: PermissionDecorator = {
    group: PermissionsGroups.CONTACTS,
    action: 'EDIT',
    objectCreatorId: []
  };

  constructor(
    public t: TranslateService,
    private dialogRef: MatDialog,
    private s: SnackBarService,
    private changes: ChangeDetectorRef,
    private service: ContactService
  ) {}

  ngOnInit(): void {}

  setContactMainContact() {
    this.setContactRole.emit({ role: '', contact: this.contact, type: EProjectContactRole.CONTACT_MAIN });
  }

  setResponsibleContact() {
    this.setContactRole.emit({
      role: '',
      contact: this.contact,
      type: EProjectContactRole.CONTACT_RESPONSIBLE
    });
  }

  edit() {
    this.contact.isResponsible = !!this.isResponsibleClient;
    const ctrl = new ContactController(this.contact, this.objectCreatorId);
    !this.isQuickActions ? ctrl.edit() : this.modalEdit(ctrl);
  }

  fullEdit() {
    const ctrl = new ContactController();
    ctrl.editContact(this.contact);
  }

  applySubContact() {
    const ctrl = new ContactController(this.contact, this.objectCreatorId);
    ctrl.addQuickSubContact(this.contact, true);
  }

  modalProjectPermission() {
    const ctrl = new ContactController(this.contact, this.objectCreatorId);
    const dialog = ctrl.editMainContactOrResponsible(this.contactProjectDialogData);
    dialog.afterClosed().subscribe(() => {
      this.contactRespChanged.emit();
    });
  }

  modalEdit(ctrl: ContactController) {
    const dialog = ctrl.editQuickContact(this.hideResponsible, this.projectId);
    dialog.beforeClosed().subscribe(() => {
      this.contact = new Contact(dialog.componentInstance?.contact);
      this.onUpdate.emit(this.contact);
    });
  }

  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        const _ctrl = new ContactController(this.contact, this.objectCreatorId);
        const stream = this.projectContactId
          ? _ctrl.removeContactFromProject(this.projectContactId)
          : _ctrl.remove(this.contact);

        stream.subscribe({
          next: () => this.onRemove.emit(this.contact)
        });
      })
      .subscribe(() => {});
  }

  preview() {
    if (!this.isPreviewVisible) return;
    const ctrl = new ContactController(this.contact, this.objectCreatorId);
    ctrl.preview(this.contact.id);
  }

  getTranslatedFunctions(functions: { id: number; name: string }[]) {
    return getTranslatedFunctions(functions, this.t);
  }

  editContactPerson() {
    const dialog = this.dialogRef.open(ContactListSubcontactsModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false
    });

    dialog.componentInstance.mainContact = this.mainContact;
    dialog.componentInstance.form.get('subcontact').setValue(this.contact);

    this.sub = dialog.componentInstance.contactPersonEmitter.subscribe((contact: Contact) => {
      this.changeContactPersonInTemplate ? (this.contact = new Contact(contact)) : null;
      this.mainContact.childContacts.map((childContact: Contact) => {
        if (childContact.id === contact.id) {
          childContact.isContactPerson = true;
          this.mainContact.contactPerson = new Contact(childContact);
          this.onChangeContactPerson.emit(childContact);
          this.changes.detectChanges();
        }
      });
    });
    dialog.afterClosed().subscribe(() => {
      this.sub.unsubscribe();
    });
  }

  turnOffContactPerson() {
    this.service.removeContactPerson(this.contact.id, this.contact.parentContacts[0].id).subscribe({
      next: () => {
        this.s.success(this.t.instant('Contacts.Preview.successRemovingContactPerson'));
        this.contact.isContactPerson = false;
        this.changes.detectChanges();
        this.onRemoveContactPerson.emit(this.contact);
      },
      error: () => {
        this.s.error(this.t.instant('Contacts.Preview.errorRemovingContactPerson'));
      }
    });
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }
}
