import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'list-single-clear-filters',
  templateUrl: './list-single-clear-filters.component.html'
})
export class ListSingleClearFiltersComponent {
  @Input() form: FormGroup;
  @Input() shouldShowClearFilters?: Function;
  @Input() clearFilters?: Function;
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() {}

  get showClearFilters() {
    if (this.shouldShowClearFilters) {
      return this.shouldShowClearFilters();
    }

    let show = false;
    Object.keys(this.form.value).forEach((key) => {
      if (this.form.value[key]) {
        show = true;
      }
    });
    return show;
  }

  clear() {
    if (this.clearFilters) {
      this.clearFilters();
    } else {
      Object.keys(this.form.value).forEach((key) => {
        this.form.get(key).setValue(null);
      });
    }
    this.change.emit();
  }
}
