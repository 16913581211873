import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Config } from '@shared/configs/config';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { AppInjector } from '@shared/services/app-injector.service';
import { tap } from 'rxjs/operators';

export class RemoveModalController {
  injector: Injector;
  dialog: MatDialog;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.dialog = this.injector.get(MatDialog);
  }

  remove(confirm: () => void = () => {}, cancel: () => void = () => {}, data: any = {}) {
    const ref = this.dialog.open(ConfirmModalComponent, {
      width: Config.CONFIRM_MODAL_WIDTH,
      autoFocus: false,
      data: {
        title: 'RemoveData.title',
        body: 'RemoveData.description',
        submitButtonText: 'RemoveData.confirm',
        submitButtonType: ButtonTypes.NEW_RED,
        submitButtonIcon: './assets/img/ic-warning-v2.svg',
        ...data
      }
    });
    return ref.afterClosed().pipe(
      tap((state: boolean | undefined) => {
        if (!!state) {
          confirm ? confirm() : null;
        } else {
          cancel ? cancel() : null;
        }
      })
    );
  }
}
