import { Pipe, PipeTransform } from '@angular/core';
import { phoneCountries } from '@shared/components/phone-input-v2/components/country-picker-v2/phone-countries';

@Pipe({ name: 'phonePrefix' })
export class PhonePrefixPipe implements PipeTransform {
  phoneCountries = phoneCountries;

  transform(countryCode: string): string {
    const country = this.phoneCountries.find((country) => country.flagClass === countryCode);
    return country ? `+${country.dialCode}` : '';
  }
}
