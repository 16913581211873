import { Component, OnInit } from '@angular/core';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { EStatusColor } from '@shared/modules/ui/components/status-cell/status-cell.component';

@Component({
  selector: 'event-participants-list',
  templateUrl: './event-participants-list.component.html',
  styleUrls: ['./event-participants-list.component.scss'],
})
export class EventParticipantsListComponent implements OnInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  EStatusColor = EStatusColor;

  constructor(public service: EventSidenavService) {}

  ngOnInit() {}

  sendMailings() {
    const emails = [];

    this.service.calendarEvent?.participants.forEach((i) =>
      emails.push(i.contact ? i.contact.email : i.email),
    );

    window.location.href = `mailto:${emails.join(';')}`;
  }
}
