import { SharedModule } from '@shared/shared.module';
import { GbxsoftFormModule } from '@form/src/public-api';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAILING_SIDENAV_COMPONENTS } from './components';
import { AttachmentsManageModule } from '@shared/modules/attachment-manage/attachments-manage.module';
import { FormsV2Module } from '@shared/modules/forms-v2/forms-v2.module';

@NgModule({
  imports: [CommonModule, GbxsoftFormModule, FormsV2Module, SharedModule, AttachmentsManageModule],
  declarations: [MAILING_SIDENAV_COMPONENTS]
})
export class MailingSidenavModule {}
