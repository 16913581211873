import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Config } from '@shared/configs/config';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService
} from '@shared/services/sticky-footer.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, map, tap } from 'rxjs/operators';
import { ContactManagePanelService } from '../../services/contact-manage-panel.service';
import { ContactServiceV2 } from '../../services/contact-v2.service';
import { ContactDetailsComponent } from '../contact-details/contact-details.component';
import { ContactConnectionsComponent } from '../contact-connections/contact-connections.component';
import { ContactProjectsComponent } from '../contact-projects/contact-projects.component';
import { ContactCommentariesComponent } from '../contact-commentaries/contact-commentaries.component';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';

@Component({
  selector: 'contact-manage-panel',
  templateUrl: './contact-manage-panel.component.html',
  styleUrls: ['./contact-manage-panel.component.scss']
})
export class ContactManagePanelComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  StickyFooterEventType = StickyFooterEventType;
  ContactType = ContactType;
  tabLinks: TabLink[] = [];
  loading: boolean = false;
  showFooter$: Observable<boolean>;
  selectedComponent: any;

  @Input('contact') contactData: Contact;

  private unsubscribe$ = new Subject<void>();

  isPreview$ = this.managePanelService.isPreview$;
  isFormDirty$ = this.managePanelService.isFormDirty$;
  contactData$ = this.managePanelService.contactData$.pipe(
    tap((contact: Contact) => {
      this.contactData = contact;
      this.initTabLinks();
    })
  );

  footerButtons = [
    {
      action: () => this.cancel(),
      loading: false,
      type: ButtonTypes.NEW_SECONDARY_BLUE,
      text: 'Contacts.V2.cancel'
    },
    {
      action: () => this.saveContact(),
      loading: true,
      type: ButtonTypes.NEW_PRIMARY_DARK_BLUE,
      text: 'Contacts.V2.save'
    }
  ];

  constructor(
    private t: TranslateService,
    private footerService: StickyFooterService,
    private managePanelService: ContactManagePanelService,
    private contactService: ContactServiceV2,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private basePanelService: BasePanelService,
    private sideNavService: SidenavService
  ) {}

  ngOnInit(): void {
    this.handleSubscriptions();
    this.basePanelService.blockScroll();
    this.selectedComponent = ContactDetailsComponent;
    this.initTabLinks();
  }

  initTabLinks(): void {
    const id = this.contactData?.id;
    const isCreation = !id;

    this.tabLinks = [
      {
        name: this.t.instant('Contacts.V2.Menu.details'),
        navigateFn: () => (this.selectedComponent = ContactDetailsComponent),
        active: this.selectedComponent === ContactDetailsComponent
      },
      {
        name: this.t.instant('Contacts.V2.Menu.subcontacts'),
        disabled: isCreation,
        navigateFn: () => (this.selectedComponent = ContactConnectionsComponent)
      },
      {
        name: this.t.instant('Contacts.V2.Menu.projects'),
        disabled: isCreation,
        navigateFn: () => (this.selectedComponent = ContactProjectsComponent)
      },
      {
        name: this.t.instant('Contacts.V2.Menu.comments'),
        disabled: isCreation,
        navigateFn: () => (this.selectedComponent = ContactCommentariesComponent)
      }
    ];
  }

  handleSubscriptions(): void {
    this.footerService.emitter.pipe(takeUntil(this.unsubscribe$)).subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.START_SAVING:
          this.loading = true;
          break;
        case StickyFooterEventType.END_SAVING:
          this.loading = false;
          break;
      }
    });

    this.showFooter$ = combineLatest([this.isPreview$, this.isFormDirty$]).pipe(
      map(([isPreview, isFormDirty]) => !isPreview && isFormDirty)
    );
  }

  saveContact(): void {
    this.footerService.emitter.emit({ type: StickyFooterEventType.SUBMITTED });
  }

  cancel(): void {
    if (this.contactData?.id) {
      this.managePanelService.setIsPreview(true);
      this.managePanelService.defaultRestored.next();
    } else {
      this.sideNavService.close();
    }
  }

  removeContact(): void {
    this.dialog
      .open(ConfirmModalComponent, {
        width: Config.CONFIRM_MODAL_WIDTH,
        autoFocus: false,
        data: {
          title: 'RemoveData.title',
          body: 'RemoveData.description',
          submitButtonText: 'RemoveData.confirm',
          submitButtonType: ButtonTypes.NEW_RED,
          submitButtonIcon: './assets/img/ic-warning-v2.svg'
        }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.contactService.removeContact(this.contactData.id).subscribe(
            () => {
              this.snackBarService.success(this.t.instant('Contacts.Fields.successContactRemoved'));
              this.managePanelService.notifyDataChanged();
              this.sideNavService.close();
            },
            () => this.snackBarService.error(this.t.instant('Contacts.Fields.errorContactRemoved'))
          );
        }
      });
  }

  editContact(): void {
    this.managePanelService.setIsPreview(false);
  }

  closeSideNav(): void {
    this.sideNavService.close(this.contactData);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.basePanelService.unBlockScroll();
  }
}
