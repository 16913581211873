import { Component, OnInit } from '@angular/core';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';

@Component({
  selector: 'protocol-acceptance-preview',
  templateUrl: './protocol-acceptance-preview.component.html',
  styleUrls: ['./protocol-acceptance-preview.component.scss']
})
export class ProtocolAcceptancePreviewComponent implements OnInit {
  content;

  constructor(
    public pService: ProtocolApiService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private store: StorageService,
    private t: TranslateService,
    private s: SnackBarService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.getAcceptancePreview(id);
  }

  getAcceptancePreview(id) {
    this.pService.getAcceptanceProtocolPDF(id, !this.store.isLogged).subscribe({
      next: (res) => {
        this.content = URL.createObjectURL(res);
      },
      error: (e) => {
        this.s.error(this.t.instant('Protocols.errorDownloadPDF'));
      }
    });
  }
}
