import { UserPerson } from '@shared/interfaces/user.interface';
import { Contact } from '@modules/contacts/shared/models/contact.model';

export class AssignedContactItem {
  id?: number;
  email: string;
  name: string;
  isRecentlyCreated: boolean = false;
  object?: any;

  constructor(data: AssignedContactItem, _object?: any) {
    Object.assign(this, data);
    _object ? (this.object = _object) : null;
  }
}

export class AssignedContactList {
  list: AssignedContactItem[] = [];

  constructor() {}

  updateList(original: Contact[] = []) {
    this.list = [];
    original.forEach((i: Contact) => this.createTagToList(i));
  }

  createTagToList(i: Contact) {
    const model = this.createContactTag(i);
    this.list.push(new AssignedContactItem(model, i));
  }

  createContactTag(i: Contact) {
    const c = new Contact(i);
    const isEmployee = !!(i?.employeeId && i?.employee);
    const id = isEmployee ? c.employeeId : c.email;
    const email = c.email || c?.employee?.userPerson?.email;
    const name = c?.fullName || c?.employee?.userPerson?.previewName || email;
    return this.createTag(id, email, name, !isEmployee);
  }

  createTagFromUserPerson(i: UserPerson) {
    const c = new UserPerson(i);
    return this.createTag(c.helperID, c?.email, c?.previewName, false);
  }

  createCustomTag(email: string) {
    return this.createTag(email, email, email, true);
  }

  createTag(id: any, email: string, name: string, isRecentlyCreated: boolean) {
    return {
      id,
      email,
      name,
      isRecentlyCreated
    };
  }
}
