import { HostListener, ElementRef, Renderer2, Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[type=file]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileValueAccessorDirective,
      multi: true,
    },
  ],
})
export class FileValueAccessorDirective implements ControlValueAccessor {
  onChange;

  @HostListener('change', ['$event.target.files']) _handleInput(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file);
  }

  constructor(private element: ElementRef, private render: Renderer2) {}

  writeValue(value: any) {
    // const normalizedValue = value == null ? '' : value;
    // this.render.setProperty(this.element.nativeElement, 'value', normalizedValue);
    if (value === null) {
      this.render.setProperty(this.element.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {}

  nOnDestroy() {}
}
