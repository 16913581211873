import { AddressType } from './../../../../enums/address-type.enum';
import { BaseDirectoryComponent } from './../base-directory.component';
import { NgOption } from '@ng-select/ng-select';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { FormGroup } from '@angular/forms';
import { AddressAutcompleteConfig } from '@shared/components/address-with-autocomplete/address-with-autocomplete.component';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PROJECT_DATA_FORM_BOX } from '../../consts/project-data-form-box';
import { PropertyType } from '@modules/projects/shared/enums/property-type.enum';
import { AddressTypeList } from '@modules/projects/shared/consts/address-type-list';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'directory-first-step-one',
  templateUrl: './directory-first-step-one.component.html',
  styleUrls: ['./directory-first-step-one.component.scss']
})
export class DirectoryFirstStepOne extends BaseDirectoryComponent implements OnInit {
  CONFIG = Config;
  PROJECT_DATA_FORM_BOX = PROJECT_DATA_FORM_BOX;
  PropertyType = PropertyType;
  GbxsoftInputTypes = GbxsoftInputTypes;

  initialArea: NgOption;
  initialOwnershipType: NgOption;
  initialElectricityType: NgOption;
  initialHeatingType: NgOption;
  initialPropertyAgeType: NgOption;

  AreaList = [];

  AddressTypeList = AddressTypeList.map((i) => {
    i.label = this.t.instant(i.label);
    return i;
  });

  @Input() form: FormGroup;

  addressConfig: AddressAutcompleteConfig = {
    adrressControlName: 'address',
    postalCodeControlName: 'postalCode',
    townControlName: 'town',
    countryControlName: 'country'
  };

  get propertyType() {
    return this.form.get(PROJECT_DATA_FORM_BOX.propertyType).value;
  }

  set propertyType(type) {
    this.form.get(PROJECT_DATA_FORM_BOX.propertyType).setValue(type);
  }

  get addressType() {
    return this.form.get(PROJECT_DATA_FORM_BOX.addressType).value;
  }

  get isNotBuildYet() {
    return this.addressType === AddressTypeList[1].id;
  }

  get isWholeBuildingUsage() {
    const value = this.form.get(PROJECT_DATA_FORM_BOX.propertyType).value;
    return value === PropertyType.SINGLE_FAMILY || value === PropertyType.TOWNHOUSE;
  }

  constructor(
    private t: TranslateService,
    public projectStore: ProjectStoreService
  ) {
    super(projectStore);
  }

  ngOnInit() {
    this.setControlsListFromStore();
    this.projectStore.setCountryData();
    this.projectStore.setAddressAsMainContact();
  }

  setPropertyType(type: PropertyType) {
    this.propertyType = type;
  }

  onAddressTypeChange($event) {
    this.setAddressAsMainContact($event.id);
  }

  setAddressAsMainContact(type: AddressType) {
    if (type === AddressType.FROM_CLIENT) {
      this.projectStore.setAddressAsMainContact();
    } else {
      this.projectStore.clearAddressField();
    }
  }

  setControlsListFromStore() {
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.area, 'initialArea');
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.ownershipType, 'initialOwnershipType');
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.electricityType, 'initialElectricityType');
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.heatingType, 'initialHeatingType');
    this.setItemValueFromStore(PROJECT_DATA_FORM_BOX.propertyAge, 'initialPropertyAgeType');
  }

  /**
   *Method sets new value, when that tag not found in list
   *
   * @param {string} formKey
   * @param {string} listName
   * @memberof DirectoryFirstStepOne
   */
  setControlValueFromStore(formKey: string, listName: string) {
    const control = this.projectStore.mainDirectoryFirstForm.get(formKey);
    if (control && control.value) {
      const index = this[listName].findIndex((i) => i.id === control.value);
      if (index === -1) {
        this[listName] = [...this[listName], { id: control.value, label: control.value }];
      }
    }
  }

  changeValue($event: any, key: string, itemsKey: string) {
    this.projectStore.mainDirectoryFirstForm.get(key).setValue($event?.id || $event?.label || $event);
    if (!$event?.id) {
      const label = $event?.label || $event;
      const event = { id: label, label };
      this[itemsKey] = [...this[itemsKey], event];
    }
  }
}
