<button
  [disabled]="disabled"
  (click)="onClick.emit($event)"
  [ngClass]="type"
  class="action-button d-flex align-items-center"
>
  <svg-icon [src]="icon" *ngIf="icon"></svg-icon>

  <span *ngIf="text" class="text">{{ text }}</span>
</button>
