import { Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserPerson } from '@shared/interfaces/user.interface';
import { HttpError } from '@shared/interfaces/error.interface';
import { BaseComponent } from '@shared/components/base.component';
import { StorageEventType, StorageService } from '@core/services/storage.service';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { LanguageService } from '@core/services/language.service';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { Config } from '@shared/configs/config';
import { map, tap } from 'rxjs/operators';
import { ChangeSystemResponse } from '@shared/interfaces/change-system-response.interface';
import { Company } from '@shared/models/company.model';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
})
export class SystemSettingsComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  subSaving: Subscription;

  constructor(
    private service: SettingsService,
    private changes: ChangeDetectorRef,
    private langService: LanguageService,
    private footerService: StickyFooterService,
    public storage: StorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.initUserData();
    this.setLanguage();
    this.subscribeSaving();
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submit();
          break;
        case StickyFooterEventType.DISCARD:
          this.resetForm();
          break;
        default:
          break;
      }
    });
  }

  resetForm() {
    this.form.patchValue(this.store.UserPerson);
    this.form.patchValue(this.store.Company);
    this.service.changeFormDirty(false);
  }

  createForm() {
    this.form = new FormGroup({
      language: new FormControl(''),
      numberFormat: new FormControl(Config.DEFAULT_NUMBER_FORMAT),
      contactFirstName: new FormControl('', [Validators.maxLength(100)]),
      contactLastName: new FormControl('', [Validators.maxLength(100)]),
      contactEmail: new FormControl('', [Validators.email]),
      contactPhone: new FormControl('', [Validators.maxLength(100)]),
      contactPhoneCountry: new FormControl(''),
    });
  }

  setLanguage() {
    const lang = this.form.get('language').value;
    this.form.get('language').setValue(lang);
  }

  initUserData() {
    this.service.getUserData().subscribe({
      next: this.onSuccessUserData.bind(this),
      error: this.onErrorUserData.bind(this),
    });
    this.form.patchValue(this.store.UserPerson);

    this.service.getCompanyData().subscribe({
      next: this.onSuccessCompanyData.bind(this),
      error: this.onErrorCompanyData.bind(this),
    });
    this.form.patchValue(this.store.Company);

    this.form.valueChanges
      .pipe(
        map(() => this.form.dirty),
        tap((value: boolean) => {
          this.service.changeFormDirty(value);
        }),
      )
      .subscribe();
  }

  onSuccessUserData(user: UserPerson) {
    this.store.UserPerson = user;
    this.userPerson = user;
  }

  onErrorUserData(e: HttpError) {
    switch (e.messageCode) {
      default:
        this.s.error(this.t.instant('Settings.User.Errors.userDataError'));
        break;
    }
  }

  onSuccessCompanyData(company: Company) {
    this.store.Company = company;
  }

  onErrorCompanyData(e: HttpError) {
    this.s.error(this.t.instant('Settings.Company.Errors.companyDataError'));
  }

  submit() {
    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid || this.loading) {
      this.scrollToError();
      return;
    }

    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.loading = true;
    const data = { ...this.form.value, companyId: this.store.Company.id };

    this.service
      .putUserSystem(data)
      .subscribe({
        next: this.onSuccessPutUser.bind(this),
        error: this.onErrorPutUser.bind(this),
      })
      .add(() => {
        this.loading = false;
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.changes.detectChanges();
      });
  }

  onSuccessPutUser(system: ChangeSystemResponse) {
    const reloadForLanguage = this.checkIfReloadForLanguage();

    this.s.success(this.t.instant('Settings.User.successPutUserData'));

    this.store.UserPerson = system.userPerson;
    this.userPerson = system.userPerson;
    this.store.Company = system.company;

    this.store.emmiter.emit({ type: StorageEventType.UPDATE_USER_PERSON, userPerson: system.userPerson });
    this.store.emmiter.emit({ type: StorageEventType.UPDATE_COMPANY, company: system.company });

    reloadForLanguage ? this.langService.saveLanguage(this.form.value['language']) : null;
  }

  checkIfReloadForLanguage() {
    if (this.form.value['language'] !== this.userPerson.language) {
      return true;
    }
    return false;
  }

  onErrorPutUser(e: HttpError) {
    switch (e.messageCode) {
      case IntilioCodes.EMAIL_IS_TAKEN:
        this.s.error(this.t.instant('Settings.User.Errors.emailIsTaken'));
        break;
      case IntilioCodes.FILE_IS_BIG:
        this.s.error(this.t.instant('Settings.User.Errors.errorFileTooBig').replace('{{size}}', 10));
        break;
      case IntilioCodes.WRONG_EXTENSION:
        this.s.error(this.t.instant('Settings.User.Errors.wrongFileExtension'));
        break;
      default:
        this.s.error(this.t.instant('Settings.User.Errors.userPutDataError'));
        break;
    }
  }

  ngOnDestroy() {
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
