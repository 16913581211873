<div
  class="title-header"
  [ngClass]="{
    'title-header--sticky': sticky,
    'title-header--sticky--side-navigation': stickyWithSideNavigation,
    'title-header--double-height-mobile': doubleHeightOnMobile
  }"
  #header
>
  <div class="title-header__left-side">
    <h1 class="title-header-title" [innerHTML]="title"></h1>
  </div>
</div>
