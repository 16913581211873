import { Company } from '@shared/models/company.model';
import { BasicDataBox } from './project-basic-data-box.model';
import { AcceptedChange } from '@shared/models/accepted-change.model';
import { Invoice } from '@shared/interfaces/invoice.interface';
import { Task } from '@shared/models/task.model';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { Employee } from '@shared/models/employee.model';
import { ProjectAlert } from '@modules/projects/shared/models/project-alert.model';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ProjectDataBox } from './project-data-box.model';
import { ProjectLostStageReason } from '@shared/enums/project-lost-stage-reason.enum';
import { countries } from '@shared/datas/countries';
import { PropertyType } from '../enums/property-type.enum';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export class Project {
  hasChangesAccess: boolean = false;
  deleted: string;
  created: string;
  accountCreated?: boolean;
  modified: string;
  id: number;
  name?: string;
  address?: string;
  town?: string;
  postalCode?: string;
  country?: string;
  isFavourite: boolean = false;
  projectId?: string;
  propertyType?: PropertyType;
  alerts: ProjectAlert[];
  propertyTypeName?: string;
  specification?: Attachment;
  acceptedChanges?: AcceptedChange[] = [];
  acceptedCorrections?: Task[];
  invoices?: Invoice[] = [];
  stage: ProjectStage;
  failureReason?: ProjectLostStageReason;
  failureNote?: string;
  basicDataBox?: BasicDataBox;
  creator: Employee;
  dataBox2: ProjectDataBox;
  dataBox3: ProjectDataBox;
  prevStage?: ProjectStage;
  pendingStageNotification: boolean = false;
  fullName: string;
  projectEditIDs: number[] = [];
  display?: string;
  title?: string;
  subtitle?: string;
  fullAddress?: string;
  orderAmount?: number;
  fullNameAndAddress?: string;
  nameAndProjectID?: string;
  company?: Company;
  accessToClient?: boolean;
  accessToPreview?: boolean;
  accessToEdit?: boolean;
  accessToRemove?: boolean;
  accessToEditSecondFromList?: boolean;
  projectPublicFormToken?: string;
  status?: string;
  mainContactFullName?: string;

  get MainContact(): Contact {
    if (this?.basicDataBox?.mainContact?.mainContact) {
      return this.basicDataBox?.mainContact?.mainContact;
    }

    return null;
  }

  get projectNameWithID() {
    return [this?.name, this?.projectId].filter(Boolean).join(', ');
  }

  get clientNameWithAddress() {
    return [this?.MainContact?.fullName, this?.fullAddress].filter(Boolean).join(', ');
  }

  get clientName() {
    return this?.MainContact?.fullName ?? '';
  }

  constructor(project?: any) {
    project ? this.deserialize(project) : null;
  }

  deserialize(project?: Project) {
    Object.assign(this, project);

    this.orderAmount = this.orderAmount !== undefined ? Number(this.orderAmount) : null;

    if (project.basicDataBox) {
      this.basicDataBox = new BasicDataBox(project.basicDataBox);
    }

    if (project.dataBox2) {
      this.dataBox2 = new ProjectDataBox(project.dataBox2);
    }

    if (project.dataBox3) {
      this.dataBox3 = new ProjectDataBox(project.dataBox3);
    }

    if (this.creator) {
      this.creator = new Employee(this.creator);
    }

    this.projectEditIDs = this.basicDataBox?.responsibleEmployee?.id
      ? [this.basicDataBox?.responsibleEmployee?.id]
      : [];

    this.setCompany();
    this.setFullAddress();
    this.setFullName();
  }

  setCompany() {
    this.company = this.company ? new Company(this.company) : null;
  }

  setFullNameAndAddress() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'CLIENT_VISIBILITY',
      objectCreatorId: [this.basicDataBox?.responsibleEmployee?.id]
    });
    !ctrl.check() && this.MainContact ? (this.MainContact.fullName = '') : null;
    this.fullNameAndAddress = [this?.MainContact?.fullName, this?.fullAddress].filter(Boolean).join(', ');
  }

  setNameAndProjectID() {
    this.nameAndProjectID = [this?.name, this?.projectId].filter(Boolean).join(', ').trim();
  }

  setFullName() {
    this.setFullNameAndAddress();
    this.setNameAndProjectID();
    this.fullName = [this.fullNameAndAddress, this.nameAndProjectID].filter(Boolean).join(', ').trim();
  }

  setFullAddress() {
    if (!!this.address || !!this.country || !!this.postalCode || !!this.town) {
      const country = countries.filter((i) => i.id.toString() === this.country)[0]?.text;
      const adressAndCode = [this.address, this.postalCode].filter(Boolean).join(', ');
      const townAndCountry = [this.town, country].filter(Boolean).join(', ');
      this.fullAddress = `${adressAndCode} ${townAndCountry}`?.trim();
    }
  }
}

export const projectNotificationTitle = (project: Project) => {
  const { name, projectId, address } = project;
  const arr = [address, name, projectId].filter(Boolean);
  const projectTitle = arr.length ? '| ' + arr.filter(Boolean).join(' | ') : '';
  return projectTitle;
};

export const projectSelectOption = (project: Project) => {
  const { name, MainContact, fullAddress, projectId } = project;
  const arr = [MainContact?.fullName, fullAddress, name, projectId].filter(Boolean);
  const projectTitle = arr.length ? arr.filter(Boolean).join(' | ') : '';
  return projectTitle;
};
