<div class="content-with-tabs-v2 content-with-tabs-menu-v2">
  <div class="content-with-tabs-menu-tabs-v2 vertical-tabs">
    <nav mat-tab-nav-bar>
      <a
        *ngFor="let tabLink of tabLinks"
        mat-tab-link
        draggable="false"
        [routerLink]="getTabLinkAttrs(tabLink).routerLink"
        [routerLinkActive]="getTabLinkAttrs(tabLink).routerLinkActive"
        (click)="getTabLinkAttrs(tabLink).clickHandler()"
        [queryParamsHandling]="getTabLinkAttrs(tabLink).queryParamsHandling"
        [queryParams]="tabLink.queryParams"
        [disabled]="tabLink?.disabled"
        [ngClass]="{
          'active-link': (customActivePaths && isActivePath(tabLink.activePathsPatterns)) || tabLink?.active
        }"
        [permission]="
          tabLink?.permissionGroup && tabLink?.permissionAction
            ? {
                group: tabLink?.permissionGroup,
                action: tabLink?.permissionAction,
                objectCreatorId: tabLink?.objectCreatorId || null
              }
            : {}
        "
      >
        <span>{{ tabLink.name }}</span>
      </a>
    </nav>
  </div>
</div>
