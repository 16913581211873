import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GbxsoftInputComponent } from '@form/src/lib/gbxsoft-input/gbxsoft-input.component';
import { Task } from '@shared/models/task.model';
import { ListTaskEventType, ListTaskService } from '@shared/modules/list/services/list-task.service';
import { TaskController } from '@shared/controllers/task.controller';
import { Employee } from '@shared/models/employee.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectTaskAPIService } from '@modules/projects/shared/services/project-task-api.service';
import { AssignSearchDropdown } from '@modules/projects/shared/models/assign-search-dropdown.model';
import { publicFile } from '@shared/helpers/public-file.helper';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EmployeeService } from '@shared/services/employee.service';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { combineLatest } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ListResponse } from '@shared/modules/list/model/list-response.model';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';

@Component({
  selector: 'assign-search-dropdown',
  templateUrl: './assign-search-dropdown.component.html',
  styleUrls: ['./assign-search-dropdown.component.scss'],
})
export class AssignSearchDropdownComponent implements OnInit, AfterViewInit, OnDestroy {


  formGroup = new FormGroup({
    search: new FormControl(''),
  });
  searchTimeout;
  assignSearchDropdown: AssignSearchDropdown;
  loading: boolean = false;

  @Input() task: Task;
  @Output('onTaskUpdate') onTaskUpdate: EventEmitter<Task> = new EventEmitter<Task>();
  @ViewChild('searchInput') searchInput: GbxsoftInputComponent;

  constructor(
    private listTaskService: ListTaskService,
    private s: SnackBarService,
    private projectTaskApiService: ProjectTaskAPIService,
    private changes: ChangeDetectorRef,
    private employeeService: EmployeeService,
    private contactService: ContactService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.searchInput.inputElement.focus();
  }

  assignEmployeeToTask(employee: Employee) {
    const ctrl = new TaskController(this.task);
    this.assignSearchDropdown.employees = this.assignSearchDropdown.employees.filter(
      (e) => e.id !== employee.id,
    );
    this.changes.detectChanges();
    ctrl.assignToTask(this.task, [employee.id, ...this.task.assignedTo.map(e => e.id)], this.task.assignedEmails).subscribe({
      next: (tasks: Task[]) => {
        this.task = new Task(tasks[0]);
        this.onTaskUpdate.emit(this.task);
        this.listTaskService.emitter.emit({type: ListTaskEventType.ASSIGNEE_CHANGE});
        this.listTaskService.emitter.emit({type: ListTaskEventType.UPDATE_TASK, data: this.task});
      },
      error: () => {
        this.s.error('Projects.assignedError');
      },
    });
  }

  assignContactToTask(contact: Contact) {
    const ctrl = new TaskController(this.task);
    this.assignSearchDropdown.contacts = this.assignSearchDropdown.contacts.filter(
      (c) => c.id !== contact.id,
    );
    const arrEmployee = contact.employee ? [contact.employee.id, ...this.task.assignedTo.map(e => e.id)] : [...this.task.assignedTo.map(e => e.id)];
    const arrEmails = !contact.employee ? [contact.email, ...this.task.assignedEmails] : [...this.task.assignedEmails];
    this.changes.detectChanges();
    ctrl.assignToTask(this.task, arrEmployee, arrEmails).subscribe({
      next: (tasks: Task[]) => {
        this.task = new Task(tasks[0]);
        this.onTaskUpdate.emit(this.task);
        this.listTaskService.emitter.emit({type: ListTaskEventType.UPDATE_TASK, data: this.task});
      },
      error: () => {
        this.s.error('Projects.assignedError');
      },
    });
  }

  imageUrl(url: string) {
    return publicFile(url, 100);
  }

  searchEmployee() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.loading = true;
      this.changes.detectChanges();
      if (this.task.project) {
        this.searchInProject();
      } else {
        this.searchInCompany();
      }
    }, 200);
  }

  getQuery() {
    return this.formGroup.get('search').value.trim();
  }

  searchInProject() {
    this.projectTaskApiService
      .getEmployeeToAssigneeToTask(this.getQuery(), this.task, this.task.project)
      .subscribe({
        next: (res: AssignSearchDropdown) => {
          this.assignSearchDropdown = res;
          this.changes.detectChanges();
        },
        error: () => {
          this.s.defaultError();
        },
      }).add(() => {
      this.loading = false;
      this.changes.detectChanges();
    });
  }

  searchInCompany() {
    const employeeSubscribe = this.employeeService.getEmployees(this.getQuery());
    const contactsSubscribe = this.contactService.search(this.getQuery());
    combineLatest([employeeSubscribe, contactsSubscribe]).pipe(
      map((res: [Employee[], ListResponse<Contact>]) => {
          this.assignSearchDropdown = this.removeDuplicates(res[0], res[1].records);
          this.changes.detectChanges();
        },
      ),
      catchError(error => {
        this.s.defaultError();
        throw error;
      }),
    ).subscribe(() => {
      this.loading = false;
      this.changes.detectChanges();
    });
  }

  removeDuplicates(employees: Employee[], contacts: Contact[]) {
    this.task.assignedTo.map((e: EmployeeSearchItem) => {
      employees = employees.filter(employee => employee.id !== e.id);
      contacts = contacts.filter(contact => (!contact.employee || contact.employee.id !== e.id));
    });
    this.task.assignedEmails.map((email: string) => {
      contacts = contacts.filter(contact => contact.email && contact.email !== email);
    });
    return new AssignSearchDropdown({
      employees,
      contacts
    });
  }

  ngOnDestroy() {
    this.assignSearchDropdown = null;
  }
}
