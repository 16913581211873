<modal-title [title]="data?.title || 'RemoveData.headerTitle' | translate"></modal-title>
<!-- #region Content -->
<div mat-dialog-content>
  <h4 class="grey-1" [innerHTML]="data?.subtitle || 'RemoveData.title' | translate"></h4>
  <p>{{ data?.description || 'RemoveData.description' | translate }}</p>
</div>
<!-- #endregion -->
<!-- #region Actions -->
<mat-dialog-actions align="end">
  <app-button
    (onClick)="dialogRef.close()"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
      text: data?.cancelBtn || 'RemoveData.cancel' | translate
    }"
  ></app-button>
  <app-button
    (onClick)="dialogRef.close(true)"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.NEW_RED,
      text: data?.confirmBtn || 'RemoveData.confirm' | translate,
      prependSrc: './assets/img/ic-trash-outline-white.svg'
    }"
  ></app-button>
</mat-dialog-actions>
<!-- #endregion -->
