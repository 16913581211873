import {PipeTransform, Pipe} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safe2Html',
  pure: true,
})
export class SafeHtml2Pipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string): any {
    if(!value) return '';
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

}
