<div class="tile p-4">
  <div class="tile-body threeDots">
    <div class="role-container mb-2">
      <div class="role-status" [ngClass]="{ 'status-active': active }"></div>
      <div class="role-name">{{ role }}</div>
    </div>
    <h6 class="tile-subtitle mb-2 threeDots" [matTooltip]="previewName" matTooltipPosition="above">
      {{ previewName }}
    </h6>
    <p class="tile-text mb-2">{{ 'Settings.Team.salary' | translate }}: {{ salary }}</p>
    <p class="tile-text m-0 threeDots" [matTooltip]="address" matTooltipPosition="above">
      {{ address }}
    </p>
  </div>
  <div class="tile-action">
    <svg-icon
      class="pointer ml-auto"
      mat-icon-button
      src="/assets/img/ic_more_vert.svg"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
    ></svg-icon>

    <mat-menu #menu="matMenu" xPosition="before" class="menu-v2">
      <button mat-menu-item (click)="onEmitAction(ActionType.EDIT)" *ngIf="employee?.isEmployeeOwner">
        <span>{{ 'Settings.Team.edit' | translate }}</span>
      </button>
      <button mat-menu-item (click)="onEmitAction(ActionType.BLOCK)" *ngIf="active">
        <span>{{ 'Settings.Team.block' | translate }}</span>
      </button>
      <button mat-menu-item (click)="onEmitAction(ActionType.UNBLOCK)" *ngIf="!active">
        <span>{{ 'Settings.Team.unblock' | translate }}</span>
      </button>
      <button mat-menu-item (click)="onEmitAction(ActionType.SEND_PASSWORD)">
        <span>{{ 'Settings.Team.passwordLink' | translate }}</span>
      </button>
      <button mat-menu-item (click)="onEmitAction(ActionType.SEND_INVITE)">
        <span>{{ 'Settings.Team.sendAgain' | translate }}</span>
      </button>
      <button
        mat-menu-item
        class="hover_red red-500"
        (click)="$event.stopPropagation()"
        confirmationPopover
        (confirm)="onEmitAction(ActionType.REMOVE)"
        [cancelText]="'Settings.Team.cancel' | translate"
        [confirmText]="'Settings.Team.yesRemove' | translate"
        placement="top"
        [popoverTitle]="'Settings.Team.confirmRemoveTitle' | translate"
        [popoverMessage]="'Settings.Team.confirmRemoveDescr' | translate"
      >
        <span>{{ 'Settings.Team.remove' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
