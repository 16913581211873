import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'input-label',
  template: ` <div class="control-v2-title mb-2">{{ label }}{{ required ? '*' : '' }}</div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputLabelComponent {
  @Input() label: string;
  @Input() required: boolean = false;
}
