import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { CONTACT_FORM } from '@modules/contacts/shared/consts/contact-form.const';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { Config } from '@shared/configs/config';
import { HttpError } from '@shared/interfaces/error.interface';

export type ContactProjectPemissionDialogData = {
  projectId: number;
  contactList: ContactAccessProject[];
  mainContactId: number;
  responsibleContactId: number;
  getContactsList?: boolean;
  project?: Project;
};

@Component({
  selector: 'contact-project-permission-modal',
  templateUrl: './contact-project-permission-modal.component.html',
  styleUrls: ['./contact-project-permission-modal.component.scss']
})
export class ContactProjectPermissionModalComponent implements OnInit, OnDestroy, AfterViewInit {
  loading: boolean = false;
  form: FormGroup;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  CONTACT_FORM = CONTACT_FORM;
  mainContactList: Contact[] = [];
  responsibleContactList: Contact[] = [];

  subMain: Subscription;
  subResponsible: Subscription;

  onProjectChange: EventEmitter<Project> = new EventEmitter<Project>();

  get formData() {
    return { [CONTACT_FORM.isResponsible]: null, [CONTACT_FORM.isMainContact]: null };
  }

  get contactList(): ContactAccessProject[] {
    return this.data?.contactList?.length ? this.data.contactList : [];
  }

  @ViewChild('mainContactSelect') mainContactSelect: NgSelectExtensionDirective;
  @ViewChild('responsibleSelect') responsibleSelect: NgSelectExtensionDirective;

  constructor(
    private s: SnackBarService,
    private t: TranslateService,
    private service: ProjectAPIService,
    private fb: FormBuilder,
    private dialog: MatDialogRef<ContactProjectPermissionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ContactProjectPemissionDialogData
  ) {}

  ngOnInit(): void {
    this._createForm();
    this._fillInForm();
  }

  ngAfterViewInit() {
    this.setContactsLists();
  }

  private setContactsLists() {
    if (this.data?.getContactsList) {
      this.mainContactSelect.url =
        Config.API +
        '/contact/search' +
        (this.data.mainContactId ? '?requiredIds[]=' + this.data.mainContactId : '');
      this.mainContactSelect.getItems();
      this.responsibleSelect.url =
        Config.API +
        '/contact/search' +
        (this.data.responsibleContactId ? '?requiredIds[]=' + this.data.responsibleContactId : '');
      this.responsibleSelect.getItems();
    }
  }

  private _createForm() {
    this.form = this.fb.group(this.formData);
  }

  private _fillInForm() {
    const responsible = this.form.get(CONTACT_FORM.isResponsible);
    const mainContact = this.form.get(CONTACT_FORM.isMainContact);
    responsible.setValue(this.data?.responsibleContactId || null);
    mainContact.setValue(this.data?.mainContactId || null);
    this.filterContactLists();
    this._setSubscribtion(responsible, mainContact);
  }

  private _setSubscribtion(responsible: AbstractControl, mainContact: AbstractControl) {
    this.subResponsible = responsible.valueChanges.subscribe(this.filterContactLists.bind(this));
    this.subMain = mainContact.valueChanges.subscribe(this.filterContactLists.bind(this));
  }

  filterMainContactList() {
    this.mainContactList = [
      ...this.contactList
        .map((i) => i.contact || i.mainContact)
        .filter((x) => x?.id !== this.form.get(CONTACT_FORM.isResponsible).value)
    ];
  }

  filterResponsibleContactList() {
    this.responsibleContactList = [
      ...this.contactList
        .map((i) => i.contact || i.mainContact)
        .filter((x) => x?.id !== this.form.get(CONTACT_FORM.isMainContact).value)
    ];
  }

  transformContacts({ records }: { records: Contact[] }) {
    return records.map((c) => new Contact(c));
  }

  filterContactLists() {
    this.filterResponsibleContactList();
    this.filterMainContactList();
  }

  closeDialog() {
    this.dialog.close();
  }

  submit() {
    if (this.loading) {
      return;
    }
    const data = {
      mainContactId: this.form.get(CONTACT_FORM.isMainContact).value,
      responsibleContactId: this.form.get(CONTACT_FORM.isResponsible).value
    };
    this.loading = true;

    this.service
      .assignMainAndResponsibleContact(this.data.projectId, data)
      .subscribe({
        next: (project: Project) => {
          this.s.success(this.t.instant('Projects.MainContact.successSaveData'));
          this.onProjectChange.emit(project);
          this.closeDialog();
        },
        error: (e: HttpError) => {
          if (e.messageCode === 'intilio_54') {
            this.s.error(this.t.instant('Projects.MainContact.cantBeTheSame'));
          } else {
            this.s.error(this.t.instant('Projects.MainContact.errorSaveData'));
          }
        }
      })
      .add(() => {
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.subMain?.unsubscribe();
    this.subResponsible?.unsubscribe();
  }
}
