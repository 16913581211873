import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ListSingleFilter } from '@shared/modules/list/interfaces/list-single-filter.interface';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'list-single-filter-range-date-v2',
  templateUrl: './list-single-filter-range-date-v2.component.html',
  styleUrls: ['./list-single-filter-range-date-v2.component.scss']
})
export class ListSingleFilterRangeDateV2Component implements ListSingleFilter, AfterViewInit {
  selected: { startDate: moment.Moment | string; endDate: moment.Moment | string } = null;

  dateRangeLocale: any = {
    format: Config.DATE_SERVER,
    displayFormat: Config.DATE_FORMAT_DOTS,
    applyLabel: this.t.instant('DATERANGEPICKER.acceptBtn'),
    cancelLabel: this.t.instant('DATERANGEPICKER.cancelBtn'),
    firstDay: 1,
    daysOfWeek: this.t.instant('DATERANGEPICKER.daysOfWeek'),
    monthNames: this.t.instant('DATERANGEPICKER.monthNames')
  };

  ranges: any = {
    [this.t.instant('DATERANGEPICKER.today')]: [moment(), moment()],
    [this.t.instant('DATERANGEPICKER.yesterday')]: [
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days')
    ],
    [this.t.instant('DATERANGEPICKER.last7Days')]: [moment().subtract(6, 'days'), moment()],
    [this.t.instant('DATERANGEPICKER.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
    [this.t.instant('DATERANGEPICKER.lastMonth')]: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ],
    [this.t.instant('DATERANGEPICKER.thisYear')]: [moment().startOf('year'), moment().endOf('year')]
  };

  @Input() name: string;
  @Input() iconUrl: string;
  @Input() form: FormGroup;
  @Input() fromControlName: string;
  @Input() toControlName: string;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @ViewChild('input') input: ElementRef;

  get isValue() {
    return this.selected;
  }

  constructor(private t: TranslateService) {}

  ngOnInit(): void {
    const fromSub = this.form.get(this.fromControlName).valueChanges;
    const toSub = this.form.get(this.toControlName).valueChanges;

    const sub = combineLatest([fromSub, toSub]).subscribe(([fromVal, toVal]) => {
      if (fromVal && toVal) {
        this.selected = {
          startDate: this.form.get(this.fromControlName).value,
          endDate: this.form.get(this.toControlName).value
        };
        sub.unsubscribe();
      }
    });
  }

  ngAfterViewInit() {}

  onArrowClick() {
    this.input.nativeElement.click();
  }

  datesUpdated(e: { startDate: moment.Moment; endDate: moment.Moment }) {
    this.form.get(this.fromControlName).setValue(e.startDate.format(Config.DATE_SERVER));
    this.form.get(this.toControlName).setValue(e.endDate.format(Config.DATE_SERVER));
    this.change.emit();
  }

  clearDateRangePicker() {
    this.form.get(this.fromControlName).setValue(null);
    this.form.get(this.toControlName).setValue(null);
    this.selected = null;
  }

  clear() {
    this.clearDateRangePicker();
    this.change.emit();
  }
}
