import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MailingPanelLinks } from '../../shared/data/mailing-panel-links.data';
import { TranslateService } from '@ngx-translate/core';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { MailingDataService } from '@modules/mailing-templates/shared/services/mailing-data.service';

@Component({
  selector: 'app-mailing-panel',
  templateUrl: './mailing-panel.component.html',
  styleUrls: ['./mailing-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailingPanelComponent implements OnInit, OnDestroy {
  tabLinks: TabLink[] = [];
  subscribe: Subscription = new Subscription();

  constructor(
    private t: TranslateService,
    public active: ActivatedRoute,
    public mailingService: MailingDataService,
  ) {}

  ngOnInit() {
    const { project } = this.active.snapshot.data.records;
    this.mailingService.project =
      project || this.active.snapshot?.parent?.parent?.parent?.parent?.data?.project || null;
    this.initTabLinks();
  }

  private initTabLinks() {
    const params = this.active.snapshot.queryParams || {};
    this.tabLinks = MailingPanelLinks(
      this.t,
      params,
      !!this.active.snapshot?.data?.innerEmails && !!this.mailingService.project?.id
        ? `/panel/projects/preview/${this.mailingService.project?.id}/mailings/`
        : '',
    );
  }

  ngOnDestroy(): void {
    this.mailingService.project = null;
    this.subscribe?.unsubscribe();
  }
}
