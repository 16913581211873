<div class="task__sidenav__blue">
  <div
    [formGroup]="service.form"
    *ngIf="(service.isPreview && !!service.task) || service.isCreation || (service.isEdit && !!service.task)"
  >
    <task-sidenav-blue-preview *ngIf="service.isPreview"></task-sidenav-blue-preview>
    <task-sidenav-blue-create *ngIf="!service.isPreview"></task-sidenav-blue-create>

    <div class="toggles my-2">
      <div *ngIf="service.isTaskSelection">
        <new-toggle-checkbox-v2
          [label]="'Projects.showOnCalendar' | translate"
          [formControlName]="service.TASK_SIDENAV_FORM.showInCalendar"
          [disabled]="
            service.task?.isDeleted ||
            (!service.permissionCtrl.edit && (service.isEdit || service.isPreview)) ||
            service?.task?.showInCalendar
          "
        ></new-toggle-checkbox-v2>
      </div>

      <new-toggle-checkbox-v2
        [label]="'Projects.highPriority' | translate"
        [formControlName]="service.TASK_SIDENAV_FORM.isHighPriority"
        [disabled]="!service.permissionCtrl.edit && (service.isEdit || service.isPreview)"
      ></new-toggle-checkbox-v2>

      <new-toggle-checkbox-v2
        [label]="'ProjectTasks.Task.hiddenFromClient' | translate"
        [formControlName]="service.TASK_SIDENAV_FORM.hiddenForClient"
        [disabled]="!service.permissionCtrl.edit && (service.isEdit || service.isPreview)"
        *ngIf="!service?.storage?.Employee?.isClient"
      ></new-toggle-checkbox-v2>
    </div>
  </div>
</div>
