import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NavigateService } from '@shared/services/navigate.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { Config } from '@shared/configs/config';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectPreviewResolver implements Resolve<Project> {
  constructor(
    private service: ProjectAPIService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
    private projectStore: ProjectStoreService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Project> {
    const id = route.params['projectId'];
    return this.service.getProject(Number(id)).pipe(
      map((project) => {
        project = new Project(project);
        this.projectStore.projectCtrl.setProject(project);
        //Anyone has access to project!!!
        return project;
      }),
      catchError((error) => {
        Config.DEBUG ? console.log(error) : null;
        if (error.messageCode === 'intilio_42') {
          this.s.error(this.t.instant('Projects.projectRemoved'));
        } else {
          this.s.error(this.t.instant('Projects.errorGettingProject'));
        }
        this.n.go('projects-list');
        return of(error);
      }),
    );
  }
}
