import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { StorageService } from '@core/services/storage.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private storage: StorageService) {}
  /**
   *
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpConfigInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    const token = this.storage.jwt;
    if (!!token) {
      request = request.clone({
        headers: request.headers
          .set('Accept-Language', this.storage?.UserPerson?.language)
          .set('Authorization', 'Bearer ' + token)
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        this.handleAuthError(error);
        return of(error);
      }) as any
    );
  }

  /**
   *
   *
   * @private
   * @param {HttpErrorResponse} err
   * @returns {Observable<any>}
   * @memberof HttpConfigInterceptor
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    throw err;
  }
}
