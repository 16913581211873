import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

// Override library
import { polyfill } from './libs/mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from './libs/mobile-drag-drop/scroll-behaviour';

// Native library
// import { polyfill } from 'mobile-drag-drop';
// import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';

polyfill({
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});

try {
  window.addEventListener('touchmove', function () {}, { passive: false });
} catch (e) {}

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true });

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch((err) => console.log(err));
}
