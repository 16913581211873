<div [formGroup]="form" class="content-v2">
  <div class="content-wrapper-v2">
    <!-- #region type selection -->
    <div [ngClass]="{ disabled: isPreview$ | async }">
      <div class="form-control-checkbox-v2 mr-4">
        <label class="m-0 d-flex align-items-center">
          <input type="radio" [formControlName]="ContactDetails.type" [value]="ContactType.PERSONAL" />
          <span></span>
          <span [innerHTML]="'Contacts.V2.personal' | translate"></span>
        </label>
      </div>

      <div class="form-control-checkbox-v2">
        <label class="m-0 d-flex align-items-center">
          <input type="radio" [formControlName]="ContactDetails.type" [value]="ContactType.COMPANY" />
          <span></span>
          <span [innerHTML]="'Contacts.V2.company' | translate"></span>
        </label>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region general-information -->
    <contact-details-general-info
      [isPreview]="isPreview$ | async"
      [isEdit]="!!editId"
      [parentForm]="form"
    ></contact-details-general-info>
    <!-- #endregion -->

    <!-- #region contact & communication -->
    <contact-details-communication
      [isPreview]="isPreview$ | async"
      [parentForm]="form"
    ></contact-details-communication>
    <!-- #endregion -->

    <!-- #region additional information -->
    <contact-details-additional-info
      [isPreview]="isPreview$ | async"
      [parentForm]="form"
    ></contact-details-additional-info>
    <!-- #endregion -->
  </div>
</div>
