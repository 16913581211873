import { switchMap } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { ProjectCreatorApiService } from '../services/project-creator-api.service';
import { BaseProjectCreator } from './base-project-creator.controller';
import { EProjectCreatorSecondStepForm } from '../enums/project-creator-second-step.enum';

export class ProjectCreatorSecondStepController extends BaseProjectCreator {
  get defaultFormValues() {
    return {
      [EProjectCreatorSecondStepForm.clientComment]: this.project?.basicDataBox?.clientComment || ''
    };
  }

  constructor(
    private fb: FormBuilder,
    private api: ProjectCreatorApiService
  ) {
    super();
    this.createForm(this.fb);
  }

  submit(token: string, step?: number): any {
    return this.api
      .updateSecondStep(token, this.form.value)
      .pipe(switchMap(() => this.api.getFullProjectData(token)));
  }

  submitInternal(id: number, step?: number) {
    return this.api
      .updateSecondStepInternal(id, this.form.value)
      .pipe(switchMap(() => this.api.getProjectById(id)));
  }
}
