import { FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ProjectCreatorFirstStepController } from '../controllers/project-creator-first-step.controller';
import { ProjectCreatorStepperController } from '../controllers/project-creator-stepper.controller';
import { ProjectCreatorSecondStepController } from '../controllers/project-creator-second-step.controller';
import { ProjectCreatorThirdStepController } from '../controllers/project-creator-third-step.controller';
import { ProjectCreatorFourthStepController } from '../controllers/project-creator-fourth-step.controller';
import { ProjectCreatorApiService } from './project-creator-api.service';
import { ProjectCreatorFifthStepController } from '../controllers/project-creator-fifth-step.controller';

@Injectable()
export class ProjectCreatorDataService {
  stepper: ProjectCreatorStepperController = new ProjectCreatorStepperController();

  steps: any[] = [];

  constructor(
    private fb: FormBuilder,
    public api: ProjectCreatorApiService
  ) {}

  initializeSteps(isInternalSteps: boolean = false) {
    this.steps = [
      new ProjectCreatorFirstStepController(this.fb, this.api, isInternalSteps),
      new ProjectCreatorSecondStepController(this.fb, this.api),
      new ProjectCreatorThirdStepController(this.fb, this.api),
      new ProjectCreatorFourthStepController(this.fb, this.api, isInternalSteps),
      new ProjectCreatorFifthStepController(this.api)
    ];
  }

  getCurrentStep() {
    return this.steps[this.stepper.currentStep - 1];
  }
}
