import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  ViewContainerRef
} from '@angular/core';
import type { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import withinviewport from 'withinviewport';
import { TourAnchorOpenerComponent } from './tour-anchor-opener.component';
import { TourStepTemplateService } from './tour-step-template.service';
import { first } from 'rxjs/operators';
import { TourBackdropService } from './tour-backdrop.service';
import { INgxmStepOption as IStepOption } from './step-option.interface';
import { NgxmTourService } from './ngx-md-menu-tour.service';
import { TourAnchorDirective, TourState } from 'ngx-tour-core';
import { WindowHelper } from '../../../../../helpers/window.helper';

@Directive({
  selector: '[tourAnchor]'
})
export class TourAnchorMatMenuDirective implements OnInit, OnDestroy, TourAnchorDirective {
  @Input() public tourAnchor: string;
  public opener: TourAnchorOpenerComponent;
  public menuCloseSubscription: Subscription;

  @HostBinding('class.touranchor--is-active') public isActive: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainer: ViewContainerRef,
    private element: ElementRef,
    private tourService: NgxmTourService,
    private tourStepTemplate: TourStepTemplateService,
    private tourBackdrop: TourBackdropService
  ) {
    this.opener = this.viewContainer.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(TourAnchorOpenerComponent)
    ).instance;
  }

  public ngOnInit(): void {
    if (!this.tourService.anchors[this.tourAnchor]) {
      this.tourService.register(this.tourAnchor, this);
    }
  }

  public ngOnDestroy(): void {
    this.tourService.unregister(this.tourAnchor);
    this.tourBackdrop.close();
  }

  public showTourStep(step: IStepOption): void {
    this.isActive = true;
    this.tourService.blockChangeStep = true;

    // Ignore step.placement

    if (!step.preventScrolling) {
      if (!withinviewport(this.element.nativeElement, { sides: 'bottom' })) {
        this.tourBackdrop.showFullBackdrop();
        (<HTMLElement>this.element.nativeElement).scrollIntoView(false);
        this.scrollIntoViewEnd(step, { sides: 'bottom' });
      } else if (!withinviewport(this.element.nativeElement, { sides: 'left top right' })) {
        this.tourBackdrop.showFullBackdrop();
        (<HTMLElement>this.element.nativeElement).scrollIntoView(true);
        this.scrollIntoViewEnd(step, { sides: 'left top right' });
      } else {
        this.showStepMenu(step);
      }
    } else {
      this.showStepMenu(step);
    }
  }

  private elementInViewport(el) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    let width = el.offsetWidth;
    let height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    );
  }

  private scrollIntoViewEnd(step: IStepOption, withinviewportOptions) {
    let interval;
    let scrollTimeout;

    const onScrollEnd = () => {
      if (WindowHelper.isMobileWidth) {
        if (withinviewport(this.element.nativeElement, { sides: 'bottom' })) {
          const y = window.scrollY;
          window.scrollTo(window.scrollX, y + 100);
          setTimeout(() => {
            this.showStepMenu(step);
          }, 200);
        } else {
          this.showStepMenu(step);
        }
      } else {
        this.showStepMenu(step);
      }
    };

    const scrollFunction = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        document.removeEventListener('scroll', scrollFunction);
        clearInterval(interval);
        onScrollEnd();
      }, 100);
    };
    document.addEventListener('scroll', scrollFunction);

    interval = setInterval(() => {
      if (
        withinviewport(this.element.nativeElement, withinviewportOptions) ||
        this.elementInViewport(this.element.nativeElement)
      ) {
        document.removeEventListener('scroll', scrollFunction);
        clearTimeout(scrollTimeout);
        clearInterval(interval);
        onScrollEnd();
      }
    }, 150);
  }

  private showStepMenu(step: IStepOption) {
    this.tourStepTemplate.templateComponent.step = step;
    (<any>this.opener.trigger)._element = this.element;
    this.opener.trigger.menu = this.tourStepTemplate.templateComponent.tourStep;
    this.opener.trigger.ngAfterContentInit();
    this.opener.trigger.openMenu();

    if (step.enableBackdrop) {
      this.tourBackdrop.show(this.element);
    } else {
      this.tourBackdrop.close();
    }

    step.prevBtnTitle = step.prevBtnTitle || 'Prev';
    step.nextBtnTitle = step.nextBtnTitle || 'Next';
    step.endBtnTitle = step.endBtnTitle || 'End';

    this.tourService.blockChangeStep = false;
    if (this.menuCloseSubscription) {
      this.menuCloseSubscription.unsubscribe();
    }
    this.menuCloseSubscription = this.opener.trigger.menuClosed.pipe(first()).subscribe(() => {
      if (this.tourService.getStatus() !== TourState.OFF) {
        this.tourService.end();
      }
      this.tourBackdrop.close();
    });
  }

  public hideTourStep(): void {
    this.isActive = false;
    if (this.menuCloseSubscription) {
      this.menuCloseSubscription.unsubscribe();
    }
    this.opener.trigger.closeMenu();
    if (this.tourService.getStatus() === TourState.OFF) {
      this.tourBackdrop.close();
    }
  }
}
