import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BaseAvatarComponent } from '@shared/modules/ui/components/base-avatar.component';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent extends BaseAvatarComponent {
  @Input() color: string;
  @Input() rounded: boolean = false;

  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }
}
