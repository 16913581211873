import { switchMap } from 'rxjs/operators';
import { Config } from '@shared/configs/config';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';

@Injectable({ providedIn: 'root' })
export class ProjectCreatorApiService extends BaseHttpService {
  constructor() {
    super();
  }

  createProject(token: string, skipAuth: boolean = true) {
    const url = `${Config.API}/project-public-form/${token}`;
    return this.post(url, {}, skipAuth) as Observable<any>;
  }

  getFullProjectData(token: string, skipAuth: boolean = true) {
    return this.getProject(token, skipAuth);
  }

  getProject(token: string, skipAuth: boolean = true) {
    const url = `${Config.API}/project-public-form/${token}`;
    return this.get(url, skipAuth) as Observable<any>;
  }

  getProjectCompany(token: string, skipAuth: boolean = true) {
    const url = `${Config.API}/project-public-form/${token}`;
    return this.get(url, skipAuth) as Observable<any>;
  }

  getProjectById(id: number) {
    const url = `${Config.API}/project/${id}`;
    return this.get(url) as Observable<any>;
  }

  getProjectEntries(token: string, skipAuth: boolean = true) {
    const url = `${Config.API}/project-public-form/${token}/simple-data-box/data-box-2`;
    return this.get(url, skipAuth) as Observable<any>;
  }

  updateFirstStep(token: string, data: any) {
    const url = `${Config.API}/project-public-form/${token}/first-step`;
    return this.post(url, data) as Observable<any>;
  }

  updateFirstStepInternal(id: number, data: any) {
    const url = `${Config.API}/project/${id}/creator/first-step`;
    return this.post(url, data) as Observable<any>;
  }

  updateSecondStep(token: string, data: any) {
    const url = `${Config.API}/project-public-form/${token}/second-step`;
    return this.post(url, data) as Observable<any>;
  }

  updateSecondStepInternal(id: number, data: any) {
    const url = `${Config.API}/project/${id}/creator/second-step`;
    return this.post(url, data) as Observable<any>;
  }

  updateThirdStep(token: string, data: any) {
    const url = `${Config.API}/project-public-form/${token}/third-step`;
    return this.post(url, data) as Observable<any>;
  }

  updateThirdStepInternal(id: number, data: any) {
    const url = `${Config.API}/project/${id}/creator/third-step`;
    return this.post(url, data) as Observable<any>;
  }

  updateFourthStep(token: string, data: any) {
    const url = `${Config.API}/contact/project-form/${token}`;
    return this.post(url, data, true) as Observable<any>;
  }

  assignNewContactToProject(id: number, data: any) {
    const url = `${Config.API}/contact/project/${id}`;
    return this.post(url, data, false) as Observable<any>;
  }

  assignExistingContactToProject(id: number, data: { projectId: number }) {
    const url = `${Config.API}/contact/${id}/main-contact`;
    return this.post(url, data, false) as Observable<any>;
  }

  assignExistingContactToProjectWithClientFunction(id: number, data: { projectId: number }) {
    return this.assignExistingContactToProject(id, data).pipe(
      switchMap((data: any) => this.assignClientFunction(id))
    );
  }

  assignClientFunction(id: number, data: any = { function: ['client'] }) {
    const url = `${Config.API}/contact/${id}/function`;
    return this.post(url, data, false) as Observable<any>;
  }

  createExtendedContact(data: any) {
    const url = `${Config.API}/contact/extended`;
    return this.post(url, data, false) as Observable<any>;
  }

  assignSubcontactToParent(id: number, data: any) {
    const url = `${Config.API}/contact/${id}/parent`;
    return this.post(url, data, false) as Observable<any>;
  }

  createAssignToParent(parentId: number, token: string, data: any) {
    const url = `${Config.API}/contact/project-form/${parentId}/child?projectToken=${token}`;
    return this.post(url, data, false) as Observable<any>;
  }

  publishProject(token: string, data: any) {
    const url = `${Config.API}/project-public-form/${token}/publish`;
    return this.post(url, data) as Observable<any>;
  }

  activateProject(id: number, data: any) {
    const url = `${Config.API}/project/${id}/active`;
    return this.post(url, data, false) as Observable<any>;
  }

  // #region Entry
  addEntry(data: any) {
    const url = `${Config.API}/entry`;
    return this.post(url, data) as Observable<any>;
  }

  getEntry(id: number, token: string) {
    const url = `${Config.API}/entry/${id}${!!token ? '?projectFormToken=' + token : ''}`;
    return this.get(url) as Observable<any>;
  }

  editEntry(id: number, data: any) {
    const url = `${Config.API}/entry/${id}`;
    return this.post(url, data) as Observable<any>;
  }

  deleteEntry(id: number, token: string) {
    const url = `${Config.API}/entry/${id}${!!token ? '?projectFormToken=' + token : ''}`;
    return this.delete(url) as Observable<any>;
  }

  deleteEntryInternal(id: number) {
    const url = `${Config.API}/entry/${id}`;
    return this.delete(url) as Observable<any>;
  }
}
