import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactDetails } from '@modules/contacts/shared/consts/contact-form.const';
import { AddressAutocompleteConfigV2 } from '@shared/components/address-with-autocomplete-v2/address-with-autocomplete-v2.component';
import { countries } from '@shared/datas/countries';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'contact-details-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent {
  @Input() isPreview: boolean = false;
  @Input() parentForm: FormGroup;

  ContactDetails = ContactDetails;
  countries = countries;

  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  addressConfig: AddressAutocompleteConfigV2 = {
    addressControlName: ContactDetails.address,
    postalCodeControlName: ContactDetails.postalCode,
    townControlName: ContactDetails.town,
    countryControlName: ContactDetails.country,
    addressLabel: 'Contacts.V2.address',
    addressPlaceholder: 'Contacts.V2.addressPlaceholder',
    townLabel: 'Contacts.V2.town',
    townPlaceholder: 'Contacts.V2.townPlaceholder',
    postalCodeLabel: 'Contacts.V2.postalCode',
    postalCodePlaceholder: 'Contacts.V2.postalCodePlaceholder',
    countryLabel: 'Contacts.V2.country',
    countryPlaceholder: 'Contacts.V2.countryPlaceholder'
  };

  get communications() {
    return this.parentForm.controls[ContactDetails.communications] as FormArray;
  }

  get links() {
    return this.parentForm.controls[ContactDetails.links] as FormArray;
  }

  get phone(): string {
    return this.parentForm.get(ContactDetails.phone).value;
  }

  get countryName(): string {
    const countryCode = this.parentForm.get(ContactDetails.country)?.value;
    return countries.find((c) => c.id === countryCode)?.text || '';
  }

  constructor(
    private fb: FormBuilder,
    private changes: ChangeDetectorRef
  ) {}

  addCommunication() {
    const communicationForm = this.fb.group({
      [ContactDetails.phone]: [null],
      [ContactDetails.phoneCountry]: ['pl'] // todo get language from user preference
    });

    this.communications.push(communicationForm);
    setTimeout(() => this.changes.detectChanges(), 0);
  }

  deleteCommunication(index: number) {
    this.communications.removeAt(index);
  }

  addWebInfo() {
    const linkForm = this.fb.group({
      [ContactDetails.linkName]: [null, [Validators.maxLength(255)]],
      [ContactDetails.linkUrl]: [null, [Validators.maxLength(255)]]
    });

    this.links.push(linkForm);
    setTimeout(() => this.changes.detectChanges(), 0);
  }

  deleteWebInfo(index: number) {
    this.links.removeAt(index);
  }

  hasAddress(): boolean {
    return (
      this.parentForm.get(ContactDetails.address)?.value ||
      this.parentForm.get(ContactDetails.postalCode)?.value ||
      this.parentForm.get(ContactDetails.town)?.value ||
      this.parentForm.get(ContactDetails.country)?.value
    );
  }
}
