import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from '@shared/components/base.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ListEvent, ListEventType } from '../../model/list-event.model';
import { ListService } from '../../services/list.service';
import { WindowHelper } from '@shared/helpers/window.helper';
import { Languages, LanguageService } from '@core/services/language.service';
import { debounce } from '@shared/decorators/debounce.decorator';

@Component({
  selector: 'list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent extends BaseComponent implements OnInit {
  WindowHelper = WindowHelper;
  Languages = Languages;

  private subject: Subject<string> = new Subject();

  form: FormGroup;

  @Input() component;
  @Input() searchName: string;
  @Input() isSearch: boolean;
  @Input() filtersTourAnchor: boolean;

  @HostListener('window:resize')
  @debounce(50)
  resize() {
    this.changes.detectChanges();
  }

  constructor(
    private service: ListService,
    public languageService: LanguageService,
    private changes: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.debounceSearch();
    this.listenQueryParams();
  }

  debounceSearch() {
    this.subject.pipe(debounceTime(500)).subscribe((e) => {
      this.service.setSearch(this.form.get('search').value);
    });
  }

  listenQueryParams() {
    this.sub = this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      if (e.type === ListEventType.QUERY_PARAMS && e.data.search) {
        this.form.get('search').setValue(e.data.search);
      }
    });
  }

  createForm() {
    this.form = new FormGroup({
      search: new FormControl('')
    });
    this.service?._search.length ? this.form.get('search').setValue(this.service._search) : null;
  }

  onChange() {
    this.subject.next();
  }
}
