<app-button
  class="columns-action"
  [matMenuTriggerFor]="showColumnsMenu"
  [matMenuTriggerData]="columnMenuData"
  [config]="{
    type: ButtonTypes.NEW_SECONDARY_BLUE,
    text: WindowHelper.isLessXXL ? '' : ('List.showColumns' | translate),
    size: ButtonSize.LARGE,
    smSize: ButtonSize.SMALL,
    prependSrc: './assets/img/eye-icon.svg'
  }"
>
</app-button>

<mat-menu #showColumnsMenu="matMenu" xPosition="before" class="menu-v2">
  <ng-template matMenuContent let-menuWidth="menuWidth">
    <div [style.minWidth.px]="menuWidth">
      <ng-container
        *ngFor="
          let column of service.config?.columns.concat(service.config.hideColumns).sort(columnsSort);
          trackBy: identify
        "
      >
        <div
          class="form-control-checkbox d-block mb-1 p-1"
          *ngIf="!column.toggleMenu && !column.hideNotShow"
          (click)="$event.stopPropagation()"
          [ngClass]="{ 'form-control-checkbox--disabled': column.canNotShow === false }"
        >
          <label class="m-0 d-flex align-items-center">
            <input
              type="checkbox"
              [checked]="!column.notShow"
              (change)="onColumnShowChange(column)"
              [disabled]="column.canNotShow === false"
            />
            <span></span>
            <span [innerHTML]="column.name"></span>
          </label>
        </div>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>
