import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Entry } from '@modules/protocols/shared/interfaces/entry.interface';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { EntryManageType } from '@shared/modules/entry/components/entry-list/entry-list.component';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { NavigateService } from '@shared/services/navigate.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ProjectThirdBoxEntries } from '../../consts/project-third-box-entries';
import { IEntryTemplateConfig } from '../../interfaces/entry-template-config.interface';
import { Project } from '../../models/project.model';
import { ProjectAPIService } from '../../services/project-api.service';
import { ProjectStoreService } from '../../services/project-store.service';
import { ProjectQuestionDirectoryBase } from '../project-question-directory-base/project-question-directory-base.component';

@Component({
  templateUrl: './project-question-directory-third-sidenav.component.html',
  styleUrls: ['./project-question-directory-third-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectQuestionDirectoryThirdSidenavComponent
  extends ProjectQuestionDirectoryBase
  implements OnInit {
  PermissionsGroups = PermissionsGroups;
  ProjectThirdBoxEntries = ProjectThirdBoxEntries;

  entryListsConfig: IEntryTemplateConfig[] = this.filterList(
    ProjectThirdBoxEntries,
    'dataBox3',
    EntryManageType.PREVIEW_ENTRY,
  );

  static componentName: string = 'ProjectQuestionDirectoryThirdSidenavComponent';

  attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachments: 20,
    maxAttachmentFileSize: 50,
  };

  constructor(
    public changes: ChangeDetectorRef,
    public projectAPI: ProjectAPIService,
    public projectStore: ProjectStoreService,
    public n: NavigateService,
    public globalSideNav: SidenavService,
  ) {
    super(projectStore, changes);
  }

  ngOnInit() {}

  submitDirectoryModel() {}

  goToEdit() {
    this.n
      .go('question-directory-edit-third', { projectid: this.projectPreviewService?.project.id })
      .then(() => this.globalSideNav.close());
  }

  updateListModel(list: IEntryTemplateConfig[], $event, type: EntryType, boxName: string) {
    this.updateListEntry(list, $event, type, boxName);
    this.projectPreviewService.project = new Project(this.projectStore.projectCtrl.project);
    this.changes.detectChanges();
  }

  trackEntries(index: number, item: Entry) {
    return item.id;
  }
}
