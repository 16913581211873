<div [ngClass]="{ 'has-error': isInvalid && isTouched && errorMessage }">
  <input
    #inputv2
    class="input-v2"
    [ngClass]="{ 'error-border': isInvalid && isTouched && errorMessage }"
    [ngStyle]="
      prependSrc
        ? {
            background: 'url(' + prependSrc + ') no-repeat scroll 16px 11px',
            paddingLeft: '42px'
          }
        : {}
    "
    [(ngModel)]="value"
    (ngModelChange)="onInputChange()"
    (blur)="onBlur()"
    (wheel)="onWheel($event)"
    [pattern]="pattern"
    [disabled]="isDisabled"
    [type]="type"
    [placeholder]="placeholder"
    [attr.readOnly]="readonly ? 'readonly' : null"
    [attr.aria-invalid]="isInvalid ? 'true' : 'false'"
    [attr.aria-describedby]="isInvalid ? 'error-msg' : null"
    [attr.inputmode]="isTypeNumber ? 'decimal' : null"
    (focus)="onFocus()"
  />

  <span
    *ngIf="isInvalid && isTouched"
    class="form-control-error-msg d-flex mt-2"
    [innerHTML]="errorMessage"
  ></span>
</div>
