import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { NavigateService } from '@shared/services/navigate.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@modules/notification/shared/services/notifications.service';
import { RedirectHelper } from '@shared/helpers/redirect-helper';

@Injectable({ providedIn: 'root' })
export class CompanySelectionGuard implements CanActivate {
  constructor(
    private store: StorageService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService,
    private notificationService: NotificationsService
  ) {}

  canActivate(route?, state?) {
    if (!!this.store?.jwt && !!this.store?.UserPerson) {
      this.notificationService.startNotificationsInterval();
      //TODO FIX CHANGE
      // if (!!this.store?.Employee?.id) {
      //   this.n.navigate(Config.MAIN_VIEW);
      //   return false;
      // }
      return true;
    }
    this.s.error(this.t.instant('Auth.Errors.permissionsDenied'));
    this.n.navigate('login', { redirectTo: RedirectHelper.getWindowPath() });
    return false;
  }
}
