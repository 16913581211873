import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService
} from '@shared/services/sticky-footer.service';
import { of, Subject } from 'rxjs';
import { tap, takeUntil, catchError, map, take } from 'rxjs/operators';
import { ContactManagePanelService } from '../../services/contact-manage-panel.service';
import { ContactServiceV2 } from '../../services/contact-v2.service';
import { CONTACT_FORM, ContactDetails } from '@modules/contacts/shared/consts/contact-form.const';
import { isPersonal } from '@modules/contacts/shared/utils/contact-type.util';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactTag } from '@modules/contacts/shared/interfaces/contact-tag.interface';

@Component({
  selector: 'contact-details',
  templateUrl: './contact-details.component.html'
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  ContactType = ContactType;
  ContactDetails = ContactDetails;
  form: FormGroup;
  editId: number;

  unsubscribe$ = new Subject<void>();
  isPreview$ = this.managePanelService.isPreview$;

  constructor(
    private t: TranslateService,
    private fb: FormBuilder,
    private footerService: StickyFooterService,
    private contactService: ContactServiceV2,
    private route: ActivatedRoute,
    private managePanelService: ContactManagePanelService,
    private snackBarService: SnackBarService
  ) {
    this.createForm();
    this.switchFormValidators();

    this.form
      .get(ContactDetails.type)
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.switchFormValidators())
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.handleEditState();
    this.handleSubscriptions();
  }

  get contactType(): ContactType {
    return this.form.get(ContactDetails.type).value;
  }

  private handleSubscriptions() {
    this.footerService.emitter.pipe(takeUntil(this.unsubscribe$)).subscribe((event: StickyFooterEvent) => {
      if (event.type === StickyFooterEventType.SUBMITTED) this.submit();
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map(() => this.form.dirty),
        tap((value) => this.managePanelService.setFormDirty(value))
      )
      .subscribe();

    this.managePanelService.defaultRestored.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      (this.form.get(CONTACT_FORM.links) as FormArray).clear();
      (this.form.get(CONTACT_FORM.communications) as FormArray).clear();
      this.handleEditState();
    });
  }

  private handleEditState() {
    this.managePanelService.contactData$.pipe(take(1)).subscribe((contact) => {
      if (!contact) return;
      this.editId = contact.id;
      Object.keys(contact).forEach((key) => {
        const formControl = this.form.get(CONTACT_FORM[key]);
        if (formControl && !(formControl instanceof FormArray)) formControl.setValue(contact[key]);
      });

      contact.links?.forEach(({ link, name }) => {
        (this.form.get(CONTACT_FORM.links) as FormArray).push(
          this.fb.group({
            [ContactDetails.linkName]: [name],
            [ContactDetails.linkUrl]: [link]
          })
        );
      });

      contact.communications?.forEach(({ phone, phoneCountry }) => {
        (this.form.get(CONTACT_FORM.communications) as FormArray).push(
          this.fb.group({
            [ContactDetails.phone]: [phone],
            [ContactDetails.phoneCountry]: [phoneCountry]
          })
        );
      });

      this.form.get(CONTACT_FORM.tagIds).setValue(contact.tags.map((t: ContactTag) => t.id));

      this.form.get(CONTACT_FORM.functions).setValue(contact.function || []);

      this.form.get(CONTACT_FORM.isNetPrices).setValue(contact.isNetPrices ?? false);
    });
  }

  private createForm() {
    this.form = this.fb.group({
      [ContactDetails.type]: [this.route?.snapshot?.params['type'] || ContactType.PERSONAL],
      [ContactDetails.companyName]: [''],
      [ContactDetails.firstName]: [''],
      [ContactDetails.lastName]: [''],
      [ContactDetails.birth]: [null],
      [ContactDetails.functions]: [[]],
      [ContactDetails.tagIds]: [[]],
      [ContactDetails.email]: [null, [Validators.email, Validators.maxLength(255)]],
      [ContactDetails.phone]: [null, [Validators.maxLength(255)]],
      [ContactDetails.phoneCountry]: ['pl'], // TODO: Change from personal info
      [ContactDetails.communications]: this.fb.array([]),
      [ContactDetails.address]: [null, [Validators.maxLength(255)]],
      [ContactDetails.postalCode]: [null, [Validators.maxLength(255)]],
      [ContactDetails.town]: [null, [Validators.maxLength(255)]],
      [ContactDetails.country]: [null, [Validators.maxLength(255)]],
      [ContactDetails.links]: this.fb.array([]),
      [ContactDetails.taxNumber]: [null, [Validators.maxLength(100)]],
      [ContactDetails.taxNumberEU]: [null, [Validators.maxLength(100)]],
      [ContactDetails.discount]: [null, [Validators.max(100)]],
      [ContactDetails.isNetPrices]: [false],
      [ContactDetails.availability]: [null, [Validators.maxLength(255)]]
    });
  }

  private switchFormValidators() {
    const typeControl = this.form.get(ContactDetails.type) as FormControl;
    const _isPersonal = isPersonal(typeControl);
    this.setPersonalValidators(!_isPersonal);
    this.setCompanyValidators(_isPersonal);
  }

  private setPersonalValidators(reset: boolean = false) {
    this.updateValidators(
      ContactDetails.firstName,
      reset ? [] : [Validators.required, Validators.maxLength(255)]
    );
    this.updateValidators(
      ContactDetails.lastName,
      reset ? [] : [Validators.required, Validators.maxLength(255)]
    );
  }

  private setCompanyValidators(reset: boolean = false) {
    this.updateValidators(
      ContactDetails.companyName,
      reset ? [] : [Validators.required, Validators.maxLength(255)]
    );
  }

  private updateValidators(name: string, validators: ValidatorFn[] | null) {
    this.form.get(name).setValidators(validators);
    this.form.get(name).updateValueAndValidity();
  }

  submit(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid) return;

    const payload = {
      ...this.form.value,
      functions: this.form.get(CONTACT_FORM.functions).value.map((f) => f.name),
      communications: this.form.get(CONTACT_FORM.communications).value.filter((c) => !!c.phone),
      links: this.form.get(CONTACT_FORM.links).value.filter((l) => !!l.link)
    };

    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });

    this.editId ? this.updateContact(payload) : this.createContact(payload);
  }

  createContact(payload): void {
    this.contactService
      .saveContact(payload)
      .pipe(
        tap((data: Contact) => this.onSuccessSave(data)),
        catchError((error) => {
          this.onErrorSave('Contacts.V2.Manage.errorCreatingContact');
          return of(error);
        })
      )
      .subscribe();
  }

  updateContact(payload): void {
    this.contactService
      .updateContact(this.editId, payload)
      .pipe(
        tap((data: Contact) => this.onSuccessSave(data)),
        catchError((error) => {
          this.onErrorSave('Contacts.V2.Manage.errorEditingContact');
          return of(error);
        })
      )
      .subscribe();
  }

  onErrorSave(message: string): void {
    this.snackBarService.error(this.t.instant(message));
    this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
  }

  onSuccessSave(contact: Contact): void {
    this.snackBarService.success(
      this.t.instant(`Contacts.V2.Manage.${this.editId ? 'successEditingContact' : 'successCreatingContact'}`)
    );
    this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
    this.managePanelService.notifyDataChanged();
    this.managePanelService.setContactData(contact);
    this.managePanelService.setFormDirty(false);
    this.managePanelService.setIsPreview(true);
  }

  ngOnDestroy(): void {
    this.managePanelService.setFormDirty(false);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
