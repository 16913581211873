import { switchMap } from 'rxjs/operators';
import { ProjectCreatorApiService } from '@modules/project-creator/shared/services/project-creator-api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { EProjectCreatorFirstStepForm } from '../enums/project-creator-first-step.enum';
import { EMaterialsType } from '../enums/materials-type.enum';
import { BaseProjectCreator } from './base-project-creator.controller';
import { EParkingType } from '../enums/parking-type.enum';

export class ProjectCreatorFirstStepController extends BaseProjectCreator {
  get defaultFormValues() {
    return {
      [EProjectCreatorFirstStepForm.name]: this.project?.name || null,
      [EProjectCreatorFirstStepForm.responsibleEmployeeId]:
        this.project?.basicDataBox?.responsibleEmployee?.id || null,
      [EProjectCreatorFirstStepForm.propertyType]: this.project?.basicDataBox?.propertyType || null,
      [EProjectCreatorFirstStepForm.propertyTypeName]: this.project?.basicDataBox?.propertyTypeName || null,
      [EProjectCreatorFirstStepForm.address]: this.project?.address || null,
      [EProjectCreatorFirstStepForm.town]: this.project?.town || null,
      [EProjectCreatorFirstStepForm.postalCode]: this.project?.postalCode || null,
      [EProjectCreatorFirstStepForm.area]: this.project?.basicDataBox?.area || null,
      [EProjectCreatorFirstStepForm.floorsCount]: this.project?.basicDataBox?.floorsCount || null,
      [EProjectCreatorFirstStepForm.floor]: this.project?.basicDataBox?.floor || null,
      [EProjectCreatorFirstStepForm.heatingType]: this.project?.basicDataBox?.heatingType || null,
      [EProjectCreatorFirstStepForm.energySource]: this.project?.basicDataBox?.energySource || null,
      [EProjectCreatorFirstStepForm.propertyAge]: this.project?.basicDataBox?.propertyAge || null,
      [EProjectCreatorFirstStepForm.lastRenovationYear]:
        this.project?.basicDataBox?.lastRenovationYear || null,
      [EProjectCreatorFirstStepForm.lastRenovationNote]:
        this.project?.basicDataBox?.lastRenovationNote || null,
      [EProjectCreatorFirstStepForm.projectGoal]: this.project?.basicDataBox?.projectGoal || null,
      [EProjectCreatorFirstStepForm.materials]: this.project?.basicDataBox?.materials || EMaterialsType.basic,
      [EProjectCreatorFirstStepForm.hasElevator]:
        this.project?.basicDataBox?.hasElevator !== undefined
          ? this.project?.basicDataBox?.hasElevator
          : null,
      [EProjectCreatorFirstStepForm.elevatorDescription]:
        this.project?.basicDataBox?.elevatorDescription || null,
      [EProjectCreatorFirstStepForm.parkingSituation]:
        this.project?.basicDataBox?.parkingSituation || EParkingType.bad,
      [EProjectCreatorFirstStepForm.parkingDescription]:
        this.project?.basicDataBox?.parkingDescription || null,
      [EProjectCreatorFirstStepForm.nameOnDoorbell]: this.project?.basicDataBox?.nameOnDoorbell || null,
      [EProjectCreatorFirstStepForm.adressUnknown]:
        !!this.project?.address?.length || !!this.project?.town?.length || !!this.project?.postalCode?.length
          ? false
          : true,
    };
  }

  get isProjectNameFilled() {
    return (
      !!this.form.get(EProjectCreatorFirstStepForm.name)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.responsibleEmployeeId)?.value
    );
  }

  get isFirstSectionFilled() {
    return !!this.form.get(EProjectCreatorFirstStepForm.propertyType)?.value;
  }

  get isSecondSectionFilled() {
    return (
      !!this.form.get(EProjectCreatorFirstStepForm.address)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.town)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.postalCode)?.value
    );
  }

  get isThirdSectionFilled() {
    return (
      !!this.form.get(EProjectCreatorFirstStepForm.area)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.floorsCount)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.floor)?.value
    );
  }

  get isFourthSectionFilled() {
    return (
      !!this.form.get(EProjectCreatorFirstStepForm.heatingType)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.energySource)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.propertyAge)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.lastRenovationYear)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.lastRenovationNote)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.projectGoal)?.value
    );
  }

  get isFifthSectionFilled() {
    return !!this.form.get(EProjectCreatorFirstStepForm.materials)?.value;
  }

  get isSixthSectionFilled() {
    return (
      !!(this.form.get(EProjectCreatorFirstStepForm.hasElevator)?.value !== null) ||
      !!this.form.get(EProjectCreatorFirstStepForm.elevatorDescription)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.parkingSituation)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.parkingDescription)?.value ||
      !!this.form.get(EProjectCreatorFirstStepForm.nameOnDoorbell)?.value
    );
  }

  constructor(private fb: FormBuilder, private api: ProjectCreatorApiService, private internal: boolean) {
    super();
    this.createForm(this.fb);
    this.setValidators();
  }

  setValidators() {
    if (!!this.internal) {
      // Validators.required
      this.form.get(EProjectCreatorFirstStepForm.name).setValidators([Validators.maxLength(100)]);
      this.form.get(EProjectCreatorFirstStepForm.name).updateValueAndValidity();

      //Validators.required
      this.form.get(EProjectCreatorFirstStepForm.responsibleEmployeeId).setValidators([]);
      this.form.get(EProjectCreatorFirstStepForm.responsibleEmployeeId).updateValueAndValidity();
    }

    this.form.get(EProjectCreatorFirstStepForm.address).setValidators([Validators.maxLength(100)]);
    this.form.get(EProjectCreatorFirstStepForm.address).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.town).setValidators([Validators.maxLength(100)]);
    this.form.get(EProjectCreatorFirstStepForm.town).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.postalCode).setValidators([Validators.maxLength(100)]);
    this.form.get(EProjectCreatorFirstStepForm.postalCode).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.propertyAge).setValidators([Validators.maxLength(4)]);
    this.form.get(EProjectCreatorFirstStepForm.propertyAge).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.lastRenovationYear).setValidators([Validators.maxLength(4)]);
    this.form.get(EProjectCreatorFirstStepForm.lastRenovationYear).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.area).setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.area).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.floorsCount).setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.floorsCount).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.floor).setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.floor).updateValueAndValidity();

    this.form
      .get(EProjectCreatorFirstStepForm.elevatorDescription)
      .setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.elevatorDescription).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.parkingDescription).setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.parkingDescription).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.nameOnDoorbell).setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.nameOnDoorbell).updateValueAndValidity();

    this.form.get(EProjectCreatorFirstStepForm.propertyTypeName).setValidators([Validators.maxLength(200)]);
    this.form.get(EProjectCreatorFirstStepForm.propertyTypeName).updateValueAndValidity();
  }

  setPropertyType(type: string) {
    this.form.get(EProjectCreatorFirstStepForm.propertyType).setValue(type);
  }

  setMaterialsQuality(quality: string) {
    this.form.get(EProjectCreatorFirstStepForm.materials).setValue(quality);
  }

  setParkingSituation(parkingSituation: string) {
    this.form.get(EProjectCreatorFirstStepForm.parkingSituation).setValue(parkingSituation);
  }

  setElevator(exists: boolean) {
    this.form.get(EProjectCreatorFirstStepForm.hasElevator).setValue(exists);
  }

  submit(token: string, step?: number): any {
    const obj = Object.assign({}, this.form.value);
    return this.api.updateFirstStep(token, obj).pipe(switchMap(() => this.api.getFullProjectData(token)));
  }

  submitInternal(id: number, step?: number) {
    const obj = Object.assign({}, this.form.value);
    return this.api.updateFirstStepInternal(id, obj).pipe(switchMap(() => this.api.getProjectById(id)));
  }
}
