<div class="content-v2 commentaries">
  <sidenav-loading *ngIf="!contact" class="content-loading" [cover]="true"></sidenav-loading>

  <div *ngIf="contact" class="content-wrapper-v2">
    <div class="card-v2 card-v2-highlighted">
      <commentary-manage-v2
        [id]="contact?.id"
        [hideSort]="true"
        [showTitle]="false"
        [type]="CommentaryType.CONTACT"
        [commentaries]="contact?.comments"
        class="w-100"
        (commentaryEvent)="updateCommentaries($event)"
      ></commentary-manage-v2>
    </div>
  </div>
</div>
