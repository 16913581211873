import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContactDetails } from '@modules/contacts/shared/consts/contact-form.const';
import { isPersonal } from '@modules/contacts/shared/utils/contact-type.util';
import { TranslateService } from '@ngx-translate/core';

const PRICE_TYPES = [
  { value: false, name: 'Contacts.V2.bruttoPrices' },
  { value: true, name: 'Contacts.V2.nettoPrices' }
];

@Component({
  selector: 'contact-details-additional-info',
  templateUrl: './additional-info.component.html'
})
export class AdditionalInfoComponent implements OnInit {
  @Input() isPreview: boolean = false;
  @Input() parentForm: FormGroup;

  ContactDetails = ContactDetails;

  translatedPriceTypes: { value: boolean; name: string }[];

  get isPersonal() {
    return isPersonal(this.parentForm.get(ContactDetails.type));
  }

  constructor(private t: TranslateService) {}

  ngOnInit(): void {
    this.translatedPriceTypes = PRICE_TYPES.map((priceType) => ({
      ...priceType,
      name: this.t.instant(priceType.name)
    }));
  }
}
