<div class="file-preview" [ngClass]="{ 'file-preview--deleted': !!file.deleted }">
  <span class="file-preview-name" [innerText]="file.name"></span>

  <app-button
    [class]="'download-button'"
    type="button"
    [hidden]="!!file.deleted"
    [loading]="downloading"
    [disabled]="downloading"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
      text: 'Commentaries.download' | translate,
      prependSrc: './assets/img/download-blue-icon.svg'
    }"
    (onClick)="downloadAttachment()"
  ></app-button>

  <div class="spinner-holder">
    <span
      *ngIf="restoring"
      style="width: 1rem; height: 1rem"
      class="spinner-border text-primary ml-2"
      role="status"
      aria-hidden="true"
    ></span>

    <img
      matTooltip="{{ 'Attachments.restore' | translate }}"
      matTooltipPosition="above"
      [matTooltipDisabled]="!file.deleted || restoring"
      #tooltip="matTooltip"
      class="cursor-pointer"
      draggable="false"
      *ngIf="!!file.deleted && !restoring && edit"
      src="./assets/img/ic-restore.svg"
      alt="restore"
      (click)="restoreAttachment()"
    />
  </div>
</div>
