import { LoginComponent } from '@modules/authorization/pages/login/login.component';
import { AuthPanelComponent } from '@modules/authorization/pages/auth-panel/auth-panel.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { RegisterComponent } from '@modules/authorization/pages/register/register.component';
import { ForgotPasswordComponent } from '@modules/authorization/pages/forgot-password/forgot-password.component';
import { PasswordSetComponent } from '@modules/authorization/pages/passowrd-set/password-set.component';
import { RegisterConfirmationComponent } from '@modules/authorization/pages/register-confirmation/register-confirmation.component';
import { ContactComponent } from '@modules/authorization/pages/contact/contact.component';
import { CompanySelectionComponent } from '@modules/authorization/pages/company-selection/company-selection.component.';
import { CompanySelectionGuard } from '@core/guards/company-selection.guard';
import { AuthorizationGuard } from '@core/guards/authorization/authorization.guard';

export const AuthorizationRoutes: CustomRoutes = [
  {
    path: '',
    component: AuthPanelComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/sign-in'
      },
      {
        path: 'sign-in',
        component: LoginComponent,
        name: 'login',
        canActivate: [AuthorizationGuard],
        data: {
          metaTitle: 'Auth.login',
          hideHeader: true,
          showFooter: true
        }
      },
      {
        path: 'sign-up',
        component: RegisterComponent,
        name: 'register',
        canActivate: [AuthorizationGuard],
        data: {
          register: true,
          metaTitle: 'Auth.register',
          hideHeader: true,
          showFooter: true
        }
      },
      {
        path: 'reset',
        component: ForgotPasswordComponent,
        name: 'reset',
        data: {
          metaTitle: 'Auth.forgotPassword',
          hideHeader: true,
          showFooter: true
        }
      },
      {
        path: 'company-selection',
        component: CompanySelectionComponent,
        canActivate: [CompanySelectionGuard],
        name: 'company-selection',
        data: {
          metaTitle: 'Auth.companySelection',
          hideHeader: true,
          showFooter: true
        }
      },
      {
        path: 'recovery/:token',
        component: PasswordSetComponent,
        name: 'recovery',
        data: {
          metaTitle: 'Auth.setPassword',
          hideHeader: true,
          showFooter: true
        }
      },
      {
        path: 'email-confirm/:confirmation-token',
        component: RegisterConfirmationComponent,
        name: 'email-confirm',
        data: {
          metaTitle: 'Auth.confirm',
          hideHeader: true,
          showFooter: true
        }
      },
      {
        path: 'contact',
        component: ContactComponent,
        name: 'contact',
        data: {
          metaTitle: 'Auth.contact',
          hideHeader: true,
          showFooter: true
        }
      }
    ]
  }
];
