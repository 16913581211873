import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TeamAddEmployeeComponent } from './components/team-add-employee/team-add-employee.component';
import {
  SettingsService,
  SettingsEvent,
  SettingEventType,
} from '@modules/settings/shared/services/settings.service';
import { BaseComponent } from '@shared/components/base.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { publicFile } from '@shared/helpers/public-file.helper';
import { TeamEditEmployeeComponent } from './components/team-edit-employee/team-edit-employee.component';
import { Employee } from '@shared/models/employee.model';
import { Config } from '@shared/configs/config';
import { ActionType } from './components/team-card-component/team-card.component';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
})
export class TeamSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  ActionType = ActionType;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;

  searchTerm: string = '';

  testList: any[] = new Array(7);
  employees: Employee[] = [];
  listLoaded: boolean = false;
  companyCurrency: string;

  private searchSubscription: Subscription;

  constructor(public dialog: MatDialog, private service: SettingsService) {
    super();
  }

  ngOnInit(): void {
    this.getEmployees();
    this.initListener();
    this.setupSearchListener();
    this.companyCurrency = this.store.Employee?.company?.currency?.symbol;
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  setupSearchListener() {
    this.searchSubscription = this.service.searchTerm$
      .pipe(
        tap((searchTerm) => {
          this.searchTerm = searchTerm;
          this.getEmployees(searchTerm);
        }),
      )
      .subscribe();
  }

  openDialog(): void {
    this.dialog.open(TeamAddEmployeeComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      disableClose: false,
      autoFocus: false,
    });
  }

  initListener() {
    this.sub = this.service.emitter.subscribe((e: SettingsEvent) => {
      if (e.type === SettingEventType.RELOAD_EMPLOYEES_LIST) {
        this.getEmployees(this.searchTerm);
      }
    });
  }

  getEmployees(searchTerm?: string) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.service
      .getListEmployees(searchTerm)
      .subscribe({
        next: this.onSuccessGetEmployees.bind(this),
        error: this.onErrorGetEmployees.bind(this),
      })
      .add(() => {
        this.loading = false;
      });
  }

  handleAction(type: ActionType, employee: Employee) {
    switch (type) {
      case ActionType.BLOCK:
        this.blockEmployee(employee);
        break;
      case ActionType.UNBLOCK:
        this.unblockEmployee(employee);
        break;
      case ActionType.SEND_PASSWORD:
        this.sendPasswordLink(employee);
        break;
      case ActionType.SEND_INVITE:
        this.sendInviteAgain(employee);
        break;
      case ActionType.REMOVE:
        this.removeEmployee(employee);
        break;
      case ActionType.EDIT:
        this.editEmployee(employee);
        break;
      default:
        break;
    }
  }

  onSuccessGetEmployees(employees: Employee[]) {
    this.employees = employees.map((e: Employee) => new Employee(e));
    this.listLoaded = true;
  }

  onErrorGetEmployees(e: HttpError) {
    switch (e.messageCode) {
      default:
        this.s.error(this.t.instant('Settings.Team.Errors.errorGetEmployees'));
        break;
    }
  }

  getPhoto(employee: Employee) {
    return employee.userPerson.photo ? publicFile(employee.userPerson.photo) : null;
  }

  blockEmployee(employee: Employee) {
    this.service.blockEmployee(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.employeeBlocked'));
        this.service.emitter.emit({ type: SettingEventType.RELOAD_EMPLOYEES_LIST });
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorBlockedEmployee'));
      },
    });
  }

  unblockEmployee(employee: Employee) {
    this.service.unblockEmployee(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.employeeUnblocked'));
        this.service.emitter.emit({ type: SettingEventType.RELOAD_EMPLOYEES_LIST });
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorBlockedEmployee'));
      },
    });
  }

  sendPasswordLink(employee: Employee) {
    this.service.sendPasswordLink(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.passwordLinkSent'));
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorSendPasswordLink'));
      },
    });
  }

  sendInviteAgain(employee: Employee) {
    this.service.sendInviteAgain(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.invitationSent'));
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorSendInvitationAgain'));
      },
    });
  }

  removeEmployee(employee: Employee) {
    this.service.removeEmployee(employee).subscribe({
      next: () => {
        this.s.success(this.t.instant('Settings.Team.employeeRemoved'));
        this.service.emitter.emit({ type: SettingEventType.RELOAD_EMPLOYEES_LIST });
      },
      error: () => {
        this.s.error(this.t.instant('Settings.Team.Errors.errorRemovedEmployee'));
      },
    });
  }

  editEmployee(employee) {
    this.dialog.open(TeamEditEmployeeComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        employee,
      },
    });
  }
}
