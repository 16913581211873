import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ProjectLostStageReason } from '@shared/enums/project-lost-stage-reason.enum';
import { Config } from '@shared/configs/config';
import { Observable } from 'rxjs';
import { IProjectStage } from '@modules/projects/shared/interfaces/project-stage.interface';
import { Project } from '@modules/projects/shared/models/project.model';

@Injectable({providedIn: 'root'})
export class ProjectStageAPIService extends BaseHttpService {
  constructor() {
    super();
  }

  setStage(
    projectId: number,
    stage: ProjectStage,
    reason?: { failureNote: string; reason: ProjectLostStageReason },
    termStart?: string,
    termEnd?: string,
  ): Observable<Project> {
    const url = `${Config.API}/project/${projectId}/stage`;
    let body = {
      stage,
      termStart,
      termEnd
    };
    reason ? (body = Object.assign(body, reason)) : null;
    return this.post(url, body, false, false) as Observable<Project>;
  }

  clearStageNotification(projectId: number) {
    const url = `${Config.API}/project/${projectId}/clear-stage-notification`;
    return this.post(url, {});
  }

  sendStageNotification(projectId: number, content: string) {
    const url = `${Config.API}/project/${projectId}/send-stage-notification`;
    return this.post(url, {content});
  }

  getStages(onlyFavourite: boolean = false): Observable<IProjectStage[]> {
    const url = `${Config.API}/project/stage`;
    return this.get(url, false, {onlyFavourite: onlyFavourite ? 1 : 0});
  }
}
