<div class="settings settings-content">
  <div *ngIf="employee?.isEmployeeOwner">
    <content-title-v2 [title]="'Settings.Company.company' | translate"> </content-title-v2>

    <div class="settings-content-section-forms">
      <company-base-data [form]="form"></company-base-data>
      <hr class="spacer-v2" />
      <company-extend-data [form]="form"></company-extend-data>
      <hr class="spacer-v2 mt-0" />
      <company-payments [form]="form"></company-payments>
      <hr class="spacer-v2" />
    </div>
  </div>
  <company-no-account *ngIf="userPerson.canCreateCompany"></company-no-account>
</div>
