export enum EntryType {
  TYPE_ACCEPTANCE_STAGE = 'acceptance_stage',
  TYPE_ACCEPTANCE_EVIDENCE = 'acceptance_evidence',
  TYPE_PHOTO_DOCUMENTATION = 'photo_documentation',
  TYPE_WORKER_ENTRY = 'worker_entry',
  TYPE_ESTATE_PLAN = 'estate-plan',
  TYPE_EXECUTIVE_PLAN = 'executive-plan',
  TYPE_PERMISSION = 'permission',
  TYPE_OTHER_DOCUMENTS = 'other-documents',
  TYPE_ESTATE_PHOTOS = 'estate-photos',
  TYPE_ESTATE_VIDEOS = 'estate-videos',
  TYPE_INSPIRATIONS = 'inspirations',
  TYPE_MATERIALS = 'materials',
  TYPE_ADDITIONAL_PHOTOS = 'additional-photos',
  TYPE_MEETING_VIDEO = 'meeting-video',
  TYPE_UNUSUAL_THINGS = 'unusual-things',
  TYPE_TO_STAY = 'to-stay',
  TYPE_INITIAL_ESTIMATE = 'initial-estimate',

  TYPE_MATERIAL_LIST = 'material-list',
  TYPE_PHOTOS_VIDEOS = 'photos-videos',

  // Third box types
  TYPE_TYPE_AGREEMENTS = 'agreements',
  TYPE_ACCEPTANCES = 'acceptances',
  TYPE_ESTIMATIONS = 'estimations',
  TYPE_ORDERS = 'orders',
  TYPE_OTHER = 'other',

  // private documents
  TYPE_INTERNAL_OFFERS = 'internal-offers',
  TYPE_INTERNAL_CONTRACTS = 'internal-contracts',
  TYPE_INTERNAL_PARTNER_OFFER = 'internal-partner-offers',
  TYPE_INTERNAL_ORDERS = 'internal-orders',
  TYPE_INTERNAL_PROTOCOL = 'acceptances',
  TYPE_INTERNAL_DOCUMENTS = 'other',
}
