import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';

export enum ActionType {
  EDIT = 'edit',
  BLOCK = 'block',
  UNBLOCK = 'unblock',
  SEND_PASSWORD = 'sendPassword',
  SEND_INVITE = 'sendInvite',
  REMOVE = 'remove'
}

@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent extends BaseComponent {
  ActionType = ActionType;
  @Input() role: string = '';
  @Input() previewName: string = '';
  @Input() salary: string = '';
  @Input() address: string = '';
  @Input() active: boolean = false;
  @Output() emitAction: EventEmitter<ActionType> = new EventEmitter();

  constructor() {
    super();
  }

  onEmitAction(type: ActionType) {
    this.emitAction.emit(type);
  }
}
