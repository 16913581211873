import { CheckPermission } from '@core/permissions/check-permission';
import { NavigateService } from '@shared/services/navigate.service';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Injectable({ providedIn: 'root' })
export class ProjectCreateGuard implements CanActivate {
  constructor(
    private n: NavigateService,
    private projectAPI: ProjectAPIService,
    private projectStore: ProjectStoreService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const projectDraft = this.handleRequest(this.projectAPI.findProjectByID(route.params.projectid));
    return projectDraft.pipe(
      map((i) => {
        this.projectStore.projectCtrl.setProject(i);
        const ctrl = new CheckPermission({
          group: PermissionsGroups.PROJECTS,
          action: 'ADD',
          objectCreatorId: [this.projectStore.projectCtrl.project?.basicDataBox?.responsibleEmployee?.id]
        });
        if (ctrl.check()) {
          this.projectStore.setDefault();
          this.projectStore.setInitialFormValues();
          return true;
        }
        this.n.go('projects-list');
        return false;
      })
    );
  }

  handleRequest(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      catchError((err) => {
        return of(null);
      })
    );
  }
}
