<modal-title [title]="modalTitle | translate"></modal-title>
<mat-dialog-content>
  <div class="mb-2" *ngIf="data?.attachmentsConfig?.maxAttachments">
    <div class="alert alert-primary p-1" role="alert">
      <img draggable="false" src="./assets/img/ic-info.svg" class="d-inline-block mr-2" alt="info" />
      {{ 'Entries.maxAttachments' | translate : { count: data.attachmentsConfig.maxAttachments } }}
    </div>
  </div>
  <form [formGroup]="form" class="assign-employee relative">
    <div class="componentContainer">
      <div class="w-100" #componentContainer></div>
    </div>
    <div>
      <text-attachments-input
        #textAttach
        [inputTour]="data?.attachmentTour?.input"
        [buttonTour]="data?.attachmentTour?.button"
        class="w-100 smaller-font"
        [attachments]="attachments?.length ? attachments : []"
        [fileName]="'files'"
        [textName]="'note'"
        [form]="form"
        [config]="config"
        [onlyTextInteraction]="data.onlyTextInteraction"
        [attachmentsConfig]="attachmentsConfig"
        (removeFileEvent)="removeFileEvent($event)"
      >
        <div #content></div>
      </text-attachments-input>
      <span
        *ngIf="isNoteInvalid && isFilesInvalid && (submited || NoteField.touched)"
        class="d-flex form-control-error-msg"
      >
        {{ 'FormErrors.textOrAttachmentRequired' | translate }}
      </span>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="d-flex justify-content-end">
    <app-button
      (onClick)="closeAssignee()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        text: 'Protocols.discard' | translate
      }"
    ></app-button>
    <app-button
      [class]="'ml-2'"
      [loading]="loading"
      [disabled]="loading"
      (onClick)="submitEntryAdd()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        text: 'Protocols.editSave' | translate,
        prependSrc: './assets/img/ic-save.svg'
      }"
    >
    </app-button>
  </div>
</mat-dialog-actions>
