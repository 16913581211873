import { Subscription } from 'rxjs';
import { debounce } from '@shared/decorators/debounce.decorator';
import { Component, HostListener, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@shared/configs/error.messages';
import { Config } from '@shared/configs/config';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterNavigationController } from '@shared/controllers/router-navigation.controller';
import { SEOService } from '@shared/services/seo.service';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { SwUpdate } from '@angular/service-worker';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { DialogHelperService } from '@shared/services/dialog-helper.service';
import { AppController } from './app.controller';
import { DeviceHelper } from '@shared/helpers/device.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit, OnDestroy {
  appCtrl: AppController;
  routerNavigationController: RouterNavigationController;
  langChangeSubscriber: Subscription;

  constructor(
    private dialogHelper: DialogHelperService,
    private t: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public seoService: SEOService,
    private gaService: GoogleAnalyticsService,
    private swupdate: SwUpdate,
    public basePanelService: BasePanelService,
    private changes: ChangeDetectorRef
  ) {
    this.initApplication();
  }

  initApplication() {
    this.langChangeSubscriber = this.t.onLangChange.subscribe(() => {
      this.setValidationMessages();
    });
    this.appCtrl = new AppController();
    this.setValidationMessages();
    this.gaService.initGA();
    this.setNavigationCtrl();
    this.subscribePWAUpdate();
    this.dialogHelper.subscribeModals();
  }

  setNavigationCtrl() {
    this.routerNavigationController = new RouterNavigationController(
      this.router,
      this.route,
      this.seoService,
      this.t
    );
    this.basePanelService.routerNavigationController = this.routerNavigationController;
  }

  subscribePWAUpdate() {
    this.swupdate.available.subscribe((event: any) => {
      if (confirm(this.t.instant('Global.appUpdate'))) {
        this.swupdate.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  private setValidationMessages() {
    Object.keys(ValidationErrors).forEach((key: string) => {
      if (!!key) {
        Config.validationMessages[key] = this.t.instant(ValidationErrors[key]);
      }
    });
  }

  ngAfterViewInit() {
    this.appCtrl.defineWindowHeightRwd();
  }

  @HostListener('window:orientationchange', ['$event'])
  @debounce(500)
  onOrientationChange(event) {
    //Android browser instantly gets  previous state of screen height
    this.appCtrl.defineWindowHeightRwd();
    this.changes.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  onResize() {
    if (!DeviceHelper.isMobileDevice) {
      this.appCtrl.defineWindowHeightRwd();
      this.changes.detectChanges();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onBackClicked() {
    this.appCtrl.closeFancybox();
  }

  ngOnDestroy() {
    this.langChangeSubscriber?.unsubscribe();
  }
}
