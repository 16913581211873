<div [formGroup]="parentForm" class="card-v2">
  <div class="card-v2-title mb-4">{{ 'Contacts.V2.additionalTitle' | translate }}</div>
  <div class="row">
    <div *ngIf="!isPersonal" class="col-12 col-md-6 mb-4">
      <div class="control-v2">
        <input-label [label]="'Contacts.V2.nip' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.taxNumber)?.value | defaultValue }}
        </div>
        <input-v2
          [hidden]="isPreview"
          [formControlName]="ContactDetails.taxNumber"
          [placeholder]="'Contacts.V2.nipPlaceholder' | translate"
        ></input-v2>
      </div>
    </div>

    <div *ngIf="!isPersonal" class="col-12 col-md-6 mb-4">
      <div class="control-v2">
        <input-label [label]="'Contacts.V2.eunip' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.taxNumberEU)?.value | defaultValue }}
        </div>
        <input-v2
          [hidden]="isPreview"
          [formControlName]="ContactDetails.taxNumberEU"
          [placeholder]="'Contacts.V2.eunipPlaceholder' | translate"
        ></input-v2>
      </div>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <div class="control-v2">
        <input-label [label]="'Contacts.V2.invoiceType' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{
            (parentForm.get(ContactDetails.isNetPrices)?.value
              ? 'Contacts.V2.nettoPrices'
              : 'Contacts.V2.bruttoPrices'
            )
              | translate
              | defaultValue
          }}
        </div>
        <ng-select
          [hidden]="isPreview"
          [items]="translatedPriceTypes"
          [formControlName]="ContactDetails.isNetPrices"
          bindLabel="name"
          bindValue="value"
          [clearable]="false"
          [placeholder]="'Contacts.V2.invoiceTypePlaceholder' | translate"
          class="new-select"
        ></ng-select>
      </div>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <div class="control-v2">
        <input-label [label]="'Contacts.V2.discount' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.discount)?.value | defaultValue }}
        </div>
        <input-v2
          type="number"
          [hidden]="isPreview"
          [formControlName]="ContactDetails.discount"
          [placeholder]="'Contacts.V2.discountPlaceholder' | translate"
        ></input-v2>
      </div>
    </div>

    <div class="col-12">
      <div class="control-v2 mb-4 mb-md-0">
        <input-label [label]="'Contacts.V2.availability' | translate"></input-label>
        <div *ngIf="isPreview" class="control-v2-info">
          {{ parentForm.get(ContactDetails.availability)?.value | defaultValue }}
        </div>
        <input-v2
          [hidden]="isPreview"
          [formControlName]="ContactDetails.availability"
          [placeholder]="'Contacts.V2.availabilityPlaceholder' | translate"
        ></input-v2>
      </div>
    </div>
  </div>
</div>
