<label
  *ngIf="!interactive && !remoteSelect"
  class="d-flex align-items-center m-0 fake-select cursor-pointer"
  [matMenuTriggerFor]="picker"
  [ngClass]="{ disabled: disabled }"
>
  <img draggable="false" width="20px" class="mr-1" src="assets/img/calendar-v2-icon.svg" alt="Calendar" />
  <span class="fake-select-title" [innerHTML]="(title ? title : 'Protocols.realizationTerm') | translate">
  </span>
  <span class="ml-1">
    <img draggable="false" src="/assets/img/arrow-down-grey.svg" alt="arrow" />
  </span>
</label>

<div
  [ngClass]="{ 'daterangepicker--disabled': disabled, 'daterangepicker--has-error': hasError }"
  class="relative control-preview"
  [matMenuTriggerFor]="picker"
>
  <input-v2
    [formControl]="dateControl"
    class="daterangepicker special-control"
    *ngIf="interactive && !remoteSelect"
    [readonly]="true"
    [prependSrc]="'assets/img/calendar-v2-icon.svg'"
    [placeholder]="(title ? title : 'Protocols.realizationTerm') | translate"
  >
  </input-v2>
  <span
    *ngIf="dateControl?.value && interactive && !disabled && clearable"
    (click)="resetValue(); $event.stopPropagation()"
    aria-hidden="true"
    class="ng-clear"
  >
    ×
  </span>
</div>

<div *ngIf="remoteSelect" [matMenuTriggerFor]="picker"></div>
<div class="mat-menu-datepicker">
  <mat-menu [class]="'range-date-picker'" #picker="matMenu" xPosition="after" class="p-0">
    <form [formGroup]="form" (click)="$event.stopPropagation()">
      <div *ngIf="!config?.singleDatePicker" class="d-flex grey-4 p-2 mb-2 filters">
        <div class="col-4" [ngClass]="{ 'has-error': isError }">
          <b><span [innerHTML]="'Protocols.startDate' | translate"></span></b>
          <!-- TODO remove -->
          <gbxsoft-input
            [formControlName]="controlStart"
            [config]="{ placeholder: '', name: ' ' }"
            class="standard"
          ></gbxsoft-input>
          <!-- TODO remove -->
        </div>
        <div class="col-4" [ngClass]="{ 'has-error': isError }">
          <b><span [innerHTML]="'Protocols.endDate' | translate"></span></b>
          <!-- TODO remove -->
          <gbxsoft-input
            [formControlName]="controlEnd"
            [config]="{ placeholder: '', name: ' ' }"
            class="standard"
          ></gbxsoft-input>
          <!-- TODO remove -->
        </div>
        <div class="col-4">
          <b><span [innerHTML]="'Protocols.duration' | translate"></span></b>
          <!-- TODO remove -->
          <gbxsoft-input
            [readonly]="true"
            [formControl]="totalControl"
            [config]="{ placeholder: '', name: ' ' }"
            class="standard"
          ></gbxsoft-input>
          <!-- TODO remove -->
        </div>
      </div>
      <div>
        <ngx-daterangepicker-material
          #daterangepicker
          [singleDatePicker]="!!config?.singleDatePicker"
          [showDropdowns]="!!config?.singleDatePicker"
          [autoApply]="!!config?.singleDatePicker"
          [showCancel]="true"
          [locale]="locale"
          [timePickerIncrement]="config?.timePickerIncrement"
          [timePicker]="config?.timePicker"
          [timePicker]="config?.timePicker"
          [timePicker24Hour]="config?.timePicker24Hour"
          (startDateChanged)="startDateChanged($event)"
          (endDateChanged)="endDateChanged($event)"
          (chosenDate)="chosenDate($event)"
        ></ngx-daterangepicker-material>
      </div>
    </form>
  </mat-menu>
</div>
