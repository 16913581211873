<div
  class="attachment-manager-list"
  [ngClass]="{ 'attachment-manager-list--disabled': disabled }"
  drag-drop
  (dragenter)="dragEnter($event)"
  (dragleave)="dragLeave()"
  (filesDropped)="dragDropped(); !disabled ? onFilesChange($event) : null"
  [attachments]="attachments"
  [attachmentsConfig]="attachmentsConfig"
  #textAttachmentControl
>
  <!-- #region Upload Area -->
  <div class="dragging-file" [ngClass]="{ 'dragging-file--visible': entered }">
    <span>{{ 'Attachments.uploadFiles' | translate }}</span>
  </div>
  <!-- #endregion -->

  <!-- #region Header -->
  <div [formGroup]="form" class="d-flex justify-content-between attachment-manager-list__header mb-2">
    <div class="d-flex align-items-center attachment-manager-list__header__title">
      <span class="attachment-manager-list__header__title-files">
        {{ 'Protocols.attachments' | translate }}</span
      >
    </div>
    <div class="d-flex align-items-center">
      <app-action-button-link-like
        type="red"
        class="remove"
        *ngIf="selectedFilesCount > 0 && !disabled"
        (onClick)="deleteSelectedFiles()"
        [disabled]="fileDeleting || disabled"
        [text]="'Attachments.remove' | translate"
        [icon]="'./assets/img/trash-icon-red.svg'"
      ></app-action-button-link-like>

      <app-button
        [type]="'button'"
        *ngIf="!selectedFilesCount && !disabled"
        (onClick)="addFile()"
        class="add-file-btn"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
          text: 'Attachments.addAttachments' | translate,
          prependSrc: './assets/img/paper-clip-icon.svg'
        }"
      ></app-button>
      <input type="file" #fileInput (change)="onFilesChange()" multiple [accept]="acceptFiles()" hidden />
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Files List -->
  <ng-container *ngFor="let attachment of fileAttachments; let i = index; trackBy: trackAttachments">
    <attachment-inline-v2
      [index]="date"
      *ngIf="(!!showLimit && i + 1 <= limit) || !showLimit || !!showAllFiles"
      [disabled]="disabled"
      [form]="form"
      [attachment]="attachment"
    ></attachment-inline-v2>
  </ng-container>
  <!-- #endregion -->

  <div class="empty-state text-center" *ngIf="!isPreview && !fileAttachments?.length">
    <img draggable="false" src="./assets/img/attachment-icon.svg" alt="empty" />
    <span class="text-center w-100 d-block">{{ 'Attachments.noAttachments' | translate }}</span>
  </div>

  <app-button
    class="mt-3"
    [type]="'button'"
    *ngIf="!!showLimit && fileAttachments.length > limit"
    (onClick)="showAllFiles = !showAllFiles"
    [config]="{
      size: ButtonSize.LARGE,
      type: ButtonTypes.DISCARD_BUTTON,
      text: (!showAllFiles ? 'Attachments.showAllFiles' : 'Attachments.hideFiles') | translate
    }"
  ></app-button>
</div>
