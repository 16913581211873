import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';
import { Observable } from 'rxjs';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import {
  ContactFormData,
  ContactSearchRequest
} from '@modules/contacts/shared/interfaces/contact-v2.interface';
import {
  ContactTagsListRequest,
  ContactTagsListResponse
} from '@modules/contacts/shared/interfaces/contact-tags-list.interface';
import { ContactTag } from '@modules/contacts/shared/interfaces/contact-tag.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactServiceV2 extends BaseHttpService {
  constructor() {
    super();
  }

  saveContact(contactData: ContactFormData): Observable<Contact> {
    const url = `${Config.API}/v2/contact`;
    return this.post(url, contactData);
  }

  getContact(contactId: number): Observable<Contact> {
    const url = `${Config.API}/contact/${contactId}`;
    return this.get(url);
  }

  updateContact(id: number, contactData: ContactFormData): Observable<Contact> {
    const url = `${Config.API}/v2/contact/${id}`;
    return this.post(url, contactData);
  }

  removeContact(id: number): Observable<unknown> {
    const url = `${Config.API}/contact/${id}`;
    return this.delete(url);
  }

  searchContacts({
    page = 1,
    query = '',
    excludedIds = []
  }: ContactSearchRequest): Observable<{ records: Contact[]; total: number }> {
    const url = `${Config.API}/contact/search`;
    return this.get(url, false, { page, perPage: 20, query, 'excludedIds[]': excludedIds });
  }

  addSubContact(mainContactId: number, subContactId: number): Observable<Contact> {
    const url = `${Config.API}/contact/${mainContactId}/parent`;
    return this.post(url, { isContactPerson: false, id: subContactId });
  }

  removeSubContact(mainContactId: number, subContactId: number): Observable<unknown> {
    const url = `${Config.API}/contact/${mainContactId}/parent`;
    return this.delete(url, false, { params: { id: subContactId } });
  }

  addRecommender(mainContactId: number, recommenderId: number): Observable<Contact> {
    const url = `${Config.API}/v2/contact/${mainContactId}/recommender`;
    return this.patch(url, { id: recommenderId });
  }

  removeRecommender(mainContactId: number): Observable<unknown> {
    const url = `${Config.API}/v2/contact/${mainContactId}/recommender`;
    return this.patch(url, { id: null });
  }

  addDirector(mainContactId: number, directorId: number): Observable<Contact> {
    const url = `${Config.API}/v2/contact/${mainContactId}/owner`;
    return this.patch(url, { id: directorId });
  }

  removeDirector(mainContactId: number): Observable<unknown> {
    const url = `${Config.API}/v2/contact/${mainContactId}/owner`;
    return this.patch(url, { id: null });
  }

  removeContactFromProject(id: number) {
    const url = `${Config.API}/contact-project/${id}`;
    return this.delete(url);
  }

  getTags({
    page,
    selectedIds = [],
    search = ''
  }: ContactTagsListRequest): Observable<ContactTagsListResponse> {
    const url = `${Config.API}/v2/contact/tag`;
    return this.get(url, false, { page, records: 20, 'selectedValues[]': selectedIds, search });
  }

  addTag(name: string): Observable<ContactTag> {
    const url = `${Config.API}/v2/contact/tag`;
    return this.post(url, { name });
  }

  removeTag(id: number): Observable<unknown> {
    const url = `${Config.API}/v2/contact/tag/${id}`;
    return this.delete(url);
  }
}
