import { SharedModule } from '@shared/shared.module';
import { CONTACT_SIDENAV_COMPONENTS } from './index';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { FormsV2Module } from '../forms-v2/forms-v2.module';
import { CommentaryModule } from '../commentary/commentary.module';

@NgModule({
  declarations: [CONTACT_SIDENAV_COMPONENTS],
  imports: [CoreModule, SharedModule, FormsV2Module, CommentaryModule]
})
export class ContactSidenavModule {}
