import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { publicFile } from '@shared/helpers/public-file.helper';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { NavigateService } from '@shared/services/navigate.service';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';

@Component({
  selector: 'app-contact-project',
  templateUrl: './contact-project.component.html',
  styleUrls: ['./contact-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactProjectComponent {
  responsiblePersonAvatar: string;
  responsiblePersonFullName: string;
  projectData: Project;

  @Input() project: ContactAccessProject;

  @Output() onRemove: EventEmitter<ContactAccessProject> = new EventEmitter<ContactAccessProject>();

  constructor(
    private n: NavigateService,
    private sideNav: SidenavService
  ) {}

  get isFinished(): boolean {
    return (
      this.projectData?.stage === ProjectStage.STAGE_FINISHED ||
      this.projectData?.stage === ProjectStage.STAGE_LOST
    );
  }

  ngOnChanges(): void {
    if (this.project) {
      this.projectData = new Project(this.project.project ?? this.project.mainProject);
      const { firstName, lastName, photo } =
        this.projectData.basicDataBox?.responsibleEmployee?.userPerson ||
        this.projectData.creator?.userPerson ||
        {};
      this.responsiblePersonFullName = `${firstName || ''} ${lastName || ''}`.trim();
      this.responsiblePersonAvatar = publicFile(photo, 100);
    }
  }

  redirectToDetails(id: number): void {
    this.n.goWithPromise('project-preview', { projectId: id }).then(() => this.sideNav.close());
  }
}
