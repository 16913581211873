import { Config } from '@shared/configs/config';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsedEmail } from '@shared/interfaces/used-email.interface';
import { Regex } from '@shared/configs/regex';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { StorageService } from '@core/services/storage.service';
import { ProtocolAction } from '../../enums/protocol-action.enum';
import { ProtocolApiService } from '../../services/protocol-api.service';
import { Protocol } from '../../models/protocol';
import { Contact } from '@modules/contacts/shared/models/contact.model';

@Component({
  selector: 'protocol-send',
  templateUrl: './protocol-send.component.html',
  styleUrls: ['./protocol-send.component.scss']
})
export class ProtocolSendComponent implements OnInit {
  Config = Config;
  loadingUsedEmails: boolean = false;
  sending: boolean = false;
  clientsTimeout = null;
  usedEmails: Array<UsedEmail> = [];

  form: FormGroup;

  get emailErrorMessages() {
    const control = this.form.get('email');
    if (control.errors?.required) {
      return 'FormErrors.required';
    }

    const pattern = control.errors?.pattern?.requiredPattern;
    if (pattern) {
      if (pattern === Regex.email.toString()) {
        return 'FormErrors.email';
      }
    }
    return '';
  }

  get isOwnerNotFilledFullData() {
    return this.store.Employee.isOwnerNotFilledFullData(this.t);
  }

  constructor(
    public pService: ProtocolApiService,
    private changes: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ProtocolSendComponent>,
    private t: TranslateService,
    private s: SnackBarService,
    private store: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: { id: number; snackBottom: boolean }
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadUsedEmails();
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.pattern(Regex.email)]),
      client: new FormControl(true),
      contact: new FormControl(null)
    });
  }

  searchUsedEmails(event): void {
    this.loadingUsedEmails = true;
    clearTimeout(this.clientsTimeout);
    this.clientsTimeout = setTimeout(() => this.loadUsedEmails(event.term), 300);
    this.changes.detectChanges();
  }

  transformContactResponse(res) {
    return res?.contacts ? res.contacts : [];
  }

  onSendTypeChange(e) {
    this.form.get('client').setValue(e);
  }

  onChangeContact() {
    const val: { email: string } | Contact = this.form.get('contact').value;
    if ((val as Contact).id) {
      this.form.get('email').setValue((val as Contact).email);
    } else {
      this.form.get('email').setValue(val.email);
    }
  }

  loadUsedEmails(query?: string): void {
    this.loadingUsedEmails = true;
    this.pService
      .getUsedEmails(query)
      .subscribe({
        next: (clientList: Array<UsedEmail>) => this.successOnLoadClients(clientList),
        error: () => {}
      })
      .add(() => {
        this.loadingUsedEmails = false;
        this.changes.detectChanges();
      });
  }

  successOnLoadClients(clientList: Array<UsedEmail>): void {
    this.usedEmails = clientList;
    const concated = this.usedEmails.concat(clientList);
    this.usedEmails = Array.from(new Set(concated.map((i) => i.email))).map((email) =>
      concated.find((a) => a.email === email)
    );
  }

  sendProtocol() {
    this.form.get('email').markAsTouched();
    this.changes.detectChanges();
    if (this.sending || this.form.invalid) {
      return;
    }
    this.sending = true;

    if (this.form.value['client']) {
      return this.pService
        .sendProtocolAcceptance(this.form.value['email'], this.data.id)
        .subscribe({
          next: this.successSendingAcceptance.bind(this),
          error: () => this.errorSendingAcceptance.bind(this)
        })
        .add(() => {
          this.sending = false;
        });
    } else {
      return this.pService
        .sendProtocolPlain(this.form.value['email'], this.data.id)
        .subscribe({
          next: this.successSendingAcceptance.bind(this),
          error: () => this.errorSendingAcceptance.bind(this)
        })
        .add(() => {
          this.sending = false;
          this.changes.detectChanges();
        });
    }
  }

  successSendingAcceptance(protocol: Protocol) {
    this.pService.manager.setProtocol(protocol);
    this.closeProtocolSend();
    this.pService.manager.protocolAction.emit({ type: ProtocolAction.RELOAD_LIST });
    this.s.success(
      this.t.instant('Protocols.sendSuccess'),
      this.data?.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG
    );
    this.changes.detectChanges();
  }

  errorSendingAcceptance() {
    this.s.error(
      this.t.instant('Protocols.sendError'),
      this.data?.snackBottom ? Config.BOTTOM_TOASTER_CONFIG : Config.CENTER_TOASTER_CONFIG
    );
  }

  // TODO close also sidenav with quick preview from protocols list
  closeProtocolSend() {
    this.form.reset();
    this.dialogRef.close();
  }
}
