import { catchError, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Config } from '@shared/configs/config';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectCreatorApiService } from '../services/project-creator-api.service';
import { NavigateService } from '@shared/services/navigate.service';
import { Project } from '@modules/projects/shared/models/project.model';

@Injectable()
export class ProjectCreatorMiddlewareResolver implements Resolve<any> {
  constructor(
    private api: ProjectCreatorApiService,
    private n: NavigateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.api
      .createProject(route.params.token)
      .pipe(
        tap((project: Project) => {
          this.n.go(
            'project-creator-manage',
            { token: project?.projectPublicFormToken },
            { replaceUrl: true }
          );
        })
      )
      .pipe(
        catchError((error) => {
          Config.DEBUG ? console.log(error) : null;
          this.n.go('404');
          return of(error);
        })
      );
  }
}
