import { SnackBarService } from './../../../../../../core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Regex } from '@shared/configs/regex';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Config } from '@shared/configs/config';
import { UserPerson } from '@shared/interfaces/user.interface';

@Component({
  selector: 'assignment',
  styleUrls: ['./assignment.component.scss'],
  templateUrl: './assignment.component.html',
})
export class AssignmentComponent implements OnInit {
  loading: boolean = false;
  timeOut = null;
  control: FormControl = new FormControl(null);

  @Input() disabled: boolean = false;
  @ViewChild('assingSelect') assingSelect: NgSelectComponent;

  @Output() userAssign: EventEmitter<{
    user?: UserPerson;
    assignedEmails?: any[];
    assignedIds?: any[];
  }> = new EventEmitter();

  get URL() {
    return `${Config.API}/employee/search`;
  }

  get assignedEmails() {
    const items =
      this.assingSelect?.selectedItems
        .filter((i) => !!(i.value as any)?.newItem)
        .map((i) => (i.value as any)?.previewName) || [];
    return items;
  }

  get assignedIds() {
    const items =
      this.assingSelect?.selectedItems
        .filter((i) => !(i.value as any)?.newItem)
        .map((i) => (i.value as any)?.helperID) || [];
    return items;
  }

  constructor(private t: TranslateService, private s: SnackBarService) {}

  ngOnInit(): void {}

  changeValue(userPerson: UserPerson) {
    this.userAssign.emit({
      user: userPerson,
      assignedEmails: this.assignedEmails,
      assignedIds: this.assignedIds,
    });
    this.control.setValue(null);
    setTimeout(() => this.assingSelect.blur(), 0);
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return this.createCustomTag($event);
  }

  createCustomTag(name: string): any {
    return { email: name, previewName: name, helperID: name, newItem: true, label: name };
  }

  transformEmployees(list: EmployeeSearchItem[]) {
    return list.map((i) => new EmployeeSearchItem(i).userPerson);
  }
}
