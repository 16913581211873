import { NgModule } from '@angular/core';
import { AttachmentFileComponent } from './components/attachment-file/attachment-file.component';
import { AttachmentImageComponent } from './components/attachment-img/attachment-img.component';
import { AttachmentImageMoreComponent } from './components/attachment-img-more/attachment-img-more.component';
import { FileSizePipe } from '@shared/pipes/fileSize.pipe';
import { CoreModule } from '@core/core.module';
import { AttachmentFilePreviewComponent } from './components/attachment-file-preview/attachment-file-preview.component';
import { AttachmentsManageService } from '../attachment-manage/services/attachments-manage.service';

@NgModule({
  declarations: [
    FileSizePipe,
    AttachmentFileComponent,
    AttachmentImageComponent,
    AttachmentImageMoreComponent,
    AttachmentFilePreviewComponent
  ],
  imports: [CoreModule],
  exports: [
    AttachmentFileComponent,
    AttachmentImageComponent,
    AttachmentImageMoreComponent,
    AttachmentFilePreviewComponent
  ],
  providers: [AttachmentsManageService]
})
export class AttachmentsModule {}
