import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

export interface ConfirmModalData {
  title?: string;
  body?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  submitButtonIcon?: string;
  submitButtonType?: ButtonTypes;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styles: [
    `
      .new-modal {
        min-width: initial;
      }
    `
  ]
})
export class ConfirmModalComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  defaultData: ConfirmModalData = {
    title: 'ConfirmModal.defaultTitle',
    body: 'ConfirmModal.defaultBody',
    cancelButtonText: 'ConfirmModal.defaultCancelButtonText',
    submitButtonText: 'ConfirmModal.defaultSubmitButtonText',
    submitButtonType: ButtonTypes.NEW_PRIMARY_BLUE
  };

  modalData: ConfirmModalData;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData
  ) {
    this.modalData = { ...this.defaultData, ...data };
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
