import { Attachment } from '@shared/interfaces/attachment.interface';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { AttachmentController } from '../../shared/controllers/attachment.controller';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { AttachmentsManageService } from '@shared/modules/attachment-manage/services/attachments-manage.service';
import { privateFile } from '@shared/helpers/private-file.helper';
import { downloadFile } from '@shared/helpers/download-file.helper';

@Component({
  selector: 'attachment-file',
  templateUrl: './attachment-file.component.html',
  styleUrls: ['./attachment-file.component.scss']
})
export class AttachmentFileComponent {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  downloading: boolean = false;
  restoring: boolean = false;

  @Input() file: Attachment;
  @Input() edit: boolean = false;
  @Input() downloadable: boolean = true;

  constructor(
    private changes: ChangeDetectorRef,
    private service: AttachmentsManageService
  ) {}

  restoreAttachment() {
    if (this.restoring || !this.edit) return;
    this.restoring = true;
    const ctrl = new AttachmentController();
    ctrl.restoreAttachment(this.file).subscribe({
      next: () => this.successRestore(),
      error: () => this.errorRestore()
    });
  }

  successRestore() {
    this.restoring = false;
    this.file.deleted = null;
    this.file = new Attachment(this.file);
    this.changes.detectChanges();
  }

  errorRestore() {
    this.restoring = false;
    this.changes.detectChanges();
  }

  downloadAttachment(): void {
    if (this.downloading) return;
    this.downloading = true;
    this.changes.detectChanges();
    this.service
      .getFileBlob(privateFile(this.file.file), false)
      .subscribe({
        next: (res) => downloadFile(res, this.file.name)
      })
      .add(() => {
        this.downloading = false;
        this.changes.detectChanges();
      });
  }
}
