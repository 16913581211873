<div
  [ngClass]="{ 'datepicker--disabled': disabled, 'datepicker--has-error': hasError }"
  class="relative control-preview"
  [matMenuTriggerFor]="picker"
>
  <input-v2
    [formControl]="dateControl"
    class="datepicker special-control"
    [readonly]="true"
    [prependSrc]="'assets/img/calendar-v2-icon.svg'"
    [placeholder]="(title ? title : 'Protocols.realizationTerm') | translate"
  >
  </input-v2>
  <span
    *ngIf="dateControl?.value && !disabled && clearable"
    (click)="resetValue(); $event.stopPropagation()"
    aria-hidden="true"
    class="ng-clear"
  >
    ×
  </span>
</div>

<div class="mat-menu-datepicker">
  <mat-menu [class]="'range-date-picker'" #picker="matMenu" xPosition="after" class="p-0">
    <form [formGroup]="form" (click)="$event.stopPropagation()">
      <div>
        <ngx-daterangepicker-material
          #datepicker
          [singleDatePicker]="true"
          [showDropdowns]="true"
          [autoApply]="true"
          [locale]="locale"
          [timePickerIncrement]="config?.timePickerIncrement"
          (datesUpdated)="applyDates($event)"
        ></ngx-daterangepicker-material>
      </div>
    </form>
  </mat-menu>
</div>
