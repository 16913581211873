<div
  class="list-tasks-single-task {{ 'list-tasks-single-task--' + task?.status }}"
  #host
  [ngClass]="{
    'list-tasks-single-task--subtask': parentTask,
    'list-tasks-single-task--opened': task?.openedSubtasks,
    'list-tasks-single-task--disabled': disabled,
    'list-tasks-single-task--my-work': listTaskService?.isMyWorkTaskLists,
    'list-tasks-single-task--active': isActive
  }"
>
  <div class="list-tasks-single-task__container">
    <div class="list-tasks-single-task__content {{ 'list-tasks-single-task__content--' + task?.status }}">
      <div class="list-tasks-single-task__content__expander">
        <expander
          [opened]="task?.openedSubtasks"
          (onClick)="toggleSubtasks()"
          *ngIf="task?.childrenCount"
        ></expander>
      </div>

      <task-checkbox
        *ngIf="taskPermission?.edit"
        [task]="task"
        (onCheckSubtasks)="checkedSubtasks($event)"
        (onUpdateTask)="updateTask($event)"
        #taskCheckboxComponent
      ></task-checkbox>

      <div class="list-tasks-single-task__content__assignee mr-2">
        <avatar-multiple
          [assignSearchDropdownTask]="task"
          [items]="[].concat(task?.assignedEmails || [], task?.assignedTo || [])"
          [width]="24"
          [disabledSearch]="!taskPermission?.edit"
        ></avatar-multiple>
      </div>

      <div
        draggable="false"
        class="list-tasks-single-task__content__title d-flex {{
          'list-tasks-single-task__content__title--' + task?.status
        }}"
        (click)="!disabled ? previewTask() : null"
      >
        <svg-icon
          *ngIf="task?.isHighPriority"
          class="ml-1 mr-1 list-tasks-single-task__content__high-priority"
          [src]="'/assets/img/ic-arrow-up-task-priority.svg'"
          [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
        ></svg-icon>

        <a
          draggable="false"
          [href]="taskSidenavController.getPreviewTaskPath(task)"
          (click)="$event.preventDefault()"
          [title]="task?.taskId ? task.taskId + ' | ' + task?.description : task?.description"
        >
          {{ task?.description }}
        </a>
        <svg-icon
          [title]="'ProjectTasks.Task.hiddenFromClient' | translate"
          *ngIf="task?.hiddenForClient && !storage?.Employee?.isClient"
          class="ml-1"
          src="./assets/img/ic-eye-off.svg"
          [svgStyle]="{ 'width.px': 17, 'height.px': 17 }"
        >
        </svg-icon>
      </div>

      <div
        class="list-tasks-single-task__content__type pr-2"
        *ngIf="
          task?.type === TaskType.TYPE_TASK ||
          task?.type === TaskType.TYPE_NOTE ||
          task?.type === TaskType.TYPE_CHANGE ||
          task?.type === TaskType.TYPE_ORDER ||
          task?.type === TaskType.TYPE_CORRECTION
        "
      >
        {{ 'Tasks.TaskType.' + task?.type | translate }}
      </div>

      <div
        class="list-tasks-single-task__content__project"
        *ngIf="listTaskService?.isMyWorkTaskLists && task?.project && !parentTask"
      >
        {{ getProject(task).fullName }}
      </div>
    </div>

    <div class="list-tasks-single-task__info flex-wrap {{ 'list-tasks-single-task__info--' + task?.status }}">
      <!-- #region Task name -->
      <div
        class="list-tasks-single-task__content__project list-tasks-single-task__info__project"
        *ngIf="listTaskService?.isMyWorkTaskLists && task?.project && !parentTask"
      >
        {{ getProject(task).fullName }}
      </div>
      <!-- #endregion -->

      <!-- #region Task Children count -->
      <div
        *ngIf="task?.childrenCount"
        class="list-tasks-single-task__info__amount cursor-pointer"
        (click)="task.openedSubtasks = !task?.openedSubtasks"
      >
        <svg-icon
          [src]="'/assets/img/ic-subtask.svg'"
          [title]="('ProjectTasks.Task.subtasksAmount' | translate) + ' ' + task?.childrenCount"
          [svgStyle]="{ width: '16px', height: '16px' }"
        ></svg-icon>
        {{ task?.childrenCount }}
      </div>
      <!-- #endregion -->

      <!-- #region Task Linked attachments -->
      <div
        class="list-tasks-single-task__info__amount"
        *ngIf="
          (task?.attachments?.length || task?.linkedChangeAttachments?.length) &&
          !listTaskService?.isMyWorkTaskLists
        "
      >
        <svg-icon
          [src]="'/assets/img/ic-attach.svg'"
          [title]="('ProjectTasks.Task.attachmentsAmount' | translate) + ' ' + task?.totalAttachmentsCount"
          [svgStyle]="{ width: '16px', height: '16px' }"
        ></svg-icon>
        {{ task?.totalAttachmentsCount }}
      </div>
      <!-- #endregion -->

      <!-- #region Task comments length -->
      <div class="list-tasks-single-task__info__amount" *ngIf="task?.comments?.length">
        <svg-icon
          [src]="'/assets/img/ic-chatbox-outline.svg'"
          [title]="('ProjectTasks.Task.commentsAmount' | translate) + ' ' + task?.comments.length"
          [svgStyle]="{ width: '16px', height: '16px' }"
        ></svg-icon>
        {{ task?.comments.length }}
      </div>
      <!-- #endregion -->

      <!-- #region Task Deadline -->
      <div
        class="list-tasks-single-task__info__deadline"
        [ngClass]="{
          'list-tasks-single-task__info__deadline--after-deadline': task?.afterDeadline
        }"
        *ngIf="task?.termEnd"
      >
        <ng-container *ngIf="!task.termStartTheSameTermEnd">
          {{ task?.termStart | dateFormat: 'DD MMM' }} - {{ task?.termEnd | dateFormat: 'DD MMM' }}
        </ng-container>

        <ng-container *ngIf="task.termStartTheSameTermEnd">
          {{ task?.termEnd | dateFormat: 'DD MMM' }}
        </ng-container>
      </div>
      <!-- #endregion -->

      <!-- #region Task Status -->
      <div
        class="div list-tasks-single-task__info__status"
        *ngIf="!listTaskService?.isMyWorkTaskLists && task?.status === TaskStatus.CANCELLED"
      >
        <status-reason
          [label]="'Projects.TaskStatus.' + task?.status | translate"
          [color]="getTaskColor()"
          [colorForText]="true"
        ></status-reason>
      </div>
      <!-- #endregion -->
    </div>
  </div>

  <!-- #region SubTasks -->
  <div
    class="list-tasks-single-task__subtasks-container"
    *ngIf="task?.openedSubtasks"
    [ngClass]="{ 'list-tasks-single-task__subtasks-container--opened': task?.openedSubtasks }"
  >
    <ng-container *ngFor="let subtask of task?.children; trackBy: identify">
      <list-tasks-single-task
        *ngIf="!subtask.deleted"
        [taskId]="subtask.id"
        [dataFromHashmap]="true"
        [parentTask]="task"
        [parentTaskComponent]="this"
        #subtasksComponents
      ></list-tasks-single-task>
    </ng-container>
  </div>
  <!-- #endregion -->
</div>
