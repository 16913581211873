import { Component, Input, TemplateRef } from '@angular/core';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'content-action-title-v2',
  templateUrl: './content-action-title-v2.component.html'
})
export class ContentActionTitleV2Component {
  WindowHelper = WindowHelper;

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() noDivider: boolean = false;
  @Input() rightSideTemplate?: TemplateRef<any>;
  @Input() titleTemplate?: TemplateRef<any>;
  @Input() subTitleTemplate?: TemplateRef<any>;
}
