import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'datepicker-v2',
  templateUrl: './date-picker-v2.component.html',
  styleUrls: ['./date-picker-v2.component.scss']
})
export class DatePickerV2 implements OnInit, AfterViewInit, OnDestroy {
  @Input() config: any;
  @Input() title: string = null;
  @Input() disabled: boolean = false;
  @Input() form: FormGroup;
  @Input() hasError: boolean = false;
  @Input() clearable: boolean = true;
  @Input() controlName: string = 'start';
  @Input() emitControlEvent: boolean = false;

  @ViewChild('datepicker') datepicker: DaterangepickerComponent;
  @ViewChild(MatMenuTrigger) picker: MatMenuTrigger;

  dateControl: FormControl = new FormControl();

  locale: any = {
    format: Config.DATETIME_FORMAT_DOTS,
    firstDay: 1,
    daysOfWeek: this.t.instant('DATERANGEPICKER.daysOfWeek'),
    monthNames: this.t.instant('DATERANGEPICKER.monthNames')
  };

  private unsubscribe$ = new Subject<void>();

  constructor(private t: TranslateService) {}

  ngOnInit() {}

  openMenu() {
    this.picker.openMenu();
  }

  ngAfterViewInit() {
    const control = this.form.get(this.controlName);
    this.syncDateWithDatePicker(control.value);

    control.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((date: string) => this.syncDateWithDatePicker(date));
    this.datepicker.clickCancel = () => this.picker.closeMenu();
  }

  getFormattedDate(date: string, format: string = Config.DATETIME_FORMAT_DOTS) {
    return moment(date, Config.DATE_SERVER).format(format);
  }

  private syncDateWithDatePicker(date: string) {
    this.applyUIChanges(date);
    this.applyControlChanges(date);
  }

  private applyControlChanges(date: string) {
    this.dateControl.setValue(date ? this.getFormattedDate(date, Config.DATE_FORMAT_DOTS) : null);
  }

  private applyUIChanges(date: string) {
    if (date) {
      this.datepicker?.setStartDate(this.getFormattedDate(date));
      this.datepicker?.setEndDate(this.getFormattedDate(date));
    } else {
      this.datepicker?.clear();
    }

    this.datepicker?.updateCalendars();
    this.datepicker?.updateView();
  }

  resetValue() {
    this.applyUIChanges(null);
  }

  applyDates({ startDate }: any) {
    this.applyControlChanges(startDate);

    const formattedDate = startDate ? startDate.format(Config.DATE_SERVER) : null;

    if (this.emitControlEvent && formattedDate === this.form.get(this.controlName).value) return;

    this.form.get(this.controlName).setValue(formattedDate, { emitEvent: this.emitControlEvent });
    this.picker.closeMenu();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
