import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  ElementRef,
  Input,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { Country } from '@shared/models/country.model';
import { phoneCountries } from './phone-countries';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormGroup } from '@angular/forms';
import { StorageService } from '@core/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'country-picker-v2',
  templateUrl: './country-picker-v2.component.html',
  styleUrls: ['./country-picker-v2.component.scss']
})
export class CountryPickerV2Component implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  private _form: FormGroup;

  sub: Subscription = new Subscription();

  @ViewChild('countriesContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('item', { read: TemplateRef }) template: TemplateRef<any>;
  @ViewChild('countriesList') countriesList: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() phoneFlagName: string;

  @Input()
  set form(form: FormGroup) {
    this._form = form;
    this.checkFormPhoneFlagValue();
  }

  get form() {
    return this._form;
  }

  @Input() disabled: boolean;
  @Input() displayOnly?: boolean;
  @Input() emitter: EventEmitter<CountryPickerEvent>;

  countries: Country[] = phoneCountries;
  query: string = '';
  searchTimeout;
  chosenCountry: Country = this.getDefaultCountry();

  constructor(
    public ref: ChangeDetectorRef,
    private storageService: StorageService
  ) {}

  ngOnChanges(): void {
    if (this.displayOnly) this.chosenCountry = this.getDefaultCountry(this.phoneFlagName);
  }

  ngOnInit() {
    this.subFlagChange();
  }

  subFlagChange() {
    let old = null;
    const sub = this.form?.get(this.phoneFlagName)?.valueChanges.subscribe((value: string) => {
      const _new = value;
      if (old !== _new) {
        old = value;
        this.checkFormPhoneFlagValue();
        this.ref.detectChanges();
      }
    });

    this.sub.add(sub);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkFormPhoneFlagValue();
    }, 700);
  }

  public checkFormPhoneFlagValue() {
    if (this.form) {
      const val = this.form.get(this.phoneFlagName).value;
      if (val) {
        this.setInitCountry(val);
      } else {
        this.form.get(this.phoneFlagName).setValue(this.chosenCountry?.flagClass);
      }
    }
  }

  private buildCountryList() {
    this.container.clear();
    for (let n = 0; n < this.countries.length; n++) {
      const country = this.countries[n];
      if (this.query !== '' && !country.searched) {
        continue;
      }
      this.container.createEmbeddedView(this.template, {
        country: this.countries[n]
      });
    }
    this.ref.detectChanges();
  }

  setInitCountry(flagClass: string) {
    const country = this.countries[this.countries.findIndex((x) => x.flagClass === flagClass)];
    country ? this.choose(country, true) : this.choose(this.countries[0], true);
  }

  getDefaultCountry(initCountry?: string) {
    const userPhone = initCountry || this.storageService?.UserPerson?.phoneCountry || 'pl';
    const country = this.countries[this.countries.findIndex((x) => x.flagClass === userPhone)];
    return country;
  }

  search() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.countries.map((country: Country) => {
        const name = country.name.toLowerCase();
        const dialCode = country.dialCode.toLowerCase();
        const query = this.query.trim().toLowerCase().replace('+', '');
        if (name.indexOf(query) !== -1 || dialCode.indexOf(query) !== -1) {
          country.searched = true;
        } else {
          country.searched = false;
        }
      });
      this.buildCountryList();
    }, 200);
  }

  choose(country: Country, withoutEmit?: boolean) {
    let current = this.countries[this.countries.findIndex((x) => x.chosen)];
    if (current) {
      current.chosen = false;
    }

    country.chosen = true;
    this.chosenCountry = country;

    if (this.trigger) {
      this.trigger.closeMenu();
    }

    const phoneFlagControl = this.form.get(this.phoneFlagName);
    if (phoneFlagControl) {
      phoneFlagControl.setValue(this.chosenCountry.flagClass, { emitEvent: !withoutEmit });
      phoneFlagControl.markAsDirty();
      phoneFlagControl.markAsTouched();
    }

    if (this.emitter && !withoutEmit) {
      this.emitter.emit({ eventType: CountryPickerEventType.CHOSE });
    }

    this.ref.detectChanges();
  }

  openPicker() {
    setTimeout(() => {
      this.buildCountryList();
    });
    this.searchInput.nativeElement.focus();
  }

  closePicker() {
    this.query = '';
    this.buildCountryList();
    this.countriesList.nativeElement.scrollTop = 0;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}

export interface CountryPickerEvent {
  eventType: CountryPickerEventType;
}

export enum CountryPickerEventType {
  OPEN,
  CLOSE,
  CHOSE
}
