<div #manageBox class="d-inline">
  <ng-container>
    <div class="d-flex justify-content-between align-items-center">
      <div *ngIf="showTitle" class="new-select-label">
        {{ 'Commentaries.titleMany' | translate }}
      </div>
      <button
        *ngIf="!hideSort && commentaries?.length"
        class="btn-icon p-0 d-inline-flex pointer"
        [matMenuTriggerFor]="menu"
      >
        <img draggable="false" src="./assets/img/sort-icon.svg" alt="sort" />
      </button>
    </div>

    <ul class="commentary__manage__list">
      <li
        *ngFor="let commentary of commentaries; let i = index; trackBy: trackCommentaries"
        [@fadeInOnEnter]="commentary"
        [@fadeOutOnLeave]
      >
        <commentary-item-v2
          (commentaryEvent)="emitCommentaryEvent($event)"
          [commentary]="commentary"
          [attachmentsConfig]="attachmentsConfig"
          (commentaryEdited)="editCommentary($event, commentary)"
        ></commentary-item-v2>
      </li>
    </ul>
  </ng-container>

  <div #commentaryInp class="commentary__manage__attachments d-flex">
    <text-attachments-input
      #textAttach
      [fileName]="'files'"
      [textName]="'note'"
      [form]="form"
      [config]="config"
      [attachmentsConfig]="attachmentsConfig"
    >
      <app-button
        #content
        [loading]="loading"
        [disabled]="loading"
        (onClick)="addCommentary()"
        [config]="{
          type: ButtonTypes.NEW_SECONDARY_BLUE,
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          text: 'Commentaries.saveCommentary' | translate
        }"
      ></app-button
    ></text-attachments-input>
  </div>
</div>

<mat-menu #menu="matMenu" xPosition="before" class="menu-v2">
  <button (click)="sortFromLatest()" mat-menu-item>
    <span>{{ 'Commentaries.fromLatest' | translate }}</span>
  </button>
  <button (click)="sortFromOldest()" mat-menu-item>
    <span>{{ 'Commentaries.fromOldest' | translate }}</span>
  </button>
</mat-menu>
