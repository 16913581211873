import * as moment from 'moment';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Injectable, Injector } from '@angular/core';
import { CalendarLeftSectionController } from '../controllers/calendar-left-section.controller';
import { CallendarMainController } from '../controllers/calendar-main.controller';
import { CalendarSettingsController } from '../controllers/calendar-settings.controller';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { CalendarSubscriptionController } from '../controllers/calendar-subscription.controller';
import { AppInjector } from '@shared/services/app-injector.service';
import { SettingsService } from '@modules/settings/shared/services/settings.service';
import { BaseHttpService } from '@core/http/base-http.service';
import { Config } from '@shared/configs/config';

@Injectable({ providedIn: 'root' })
export class CalendarService {
  injector: Injector;
  settingsService: SettingsService;
  httpService: BaseHttpService;

  subCalendarCtrl: CalendarSubscriptionController = null;
  settingsCtrl: CalendarSettingsController = new CalendarSettingsController();
  leftSideCtrl: CalendarLeftSectionController = new CalendarLeftSectionController();
  mainCtrl: CallendarMainController = new CallendarMainController();

  constructor(
    public eventService: EventSidenavService,
    public taskService: TaskSidenavService
  ) {
    this.initializeInjectors();

    this.subCalendarCtrl = new CalendarSubscriptionController(
      this.leftSideCtrl,
      this.mainCtrl,
      eventService,
      taskService
    );
  }

  initializeInjectors() {
    this.injector = AppInjector.getInjector();
    this.settingsService = this.injector.get(SettingsService);
    this.httpService = this.injector.get(BaseHttpService);
  }

  initialize() {
    // 1. Prepeare initial view by reseting previous state
    this.resetViewState();

    // 2. Add single subscription to handle multiple emitters
    this.subCalendarCtrl.initSubscription();

    // 3. Set initial left / main view states
    this.initializeViewState();

    // 4. Add subscribtion to single sub
    this.subCalendarCtrl.subscribeEvents();
  }

  initializeViewState() {
    this.mainCtrl.initializeDefaultViewState();
    this.leftSideCtrl.initializeDefaultViewState();
    this.settingsCtrl.initializeDefaultViewState();
  }

  showCurrentDate() {
    this.mainCtrl.goToDate(moment(this.settingsCtrl.selectedDate).toDate());
    this.updateCalendarView();
  }

  updateCalendarView() {
    this.subCalendarCtrl.calendarEvents.subscribe();
  }

  resetViewState() {
    this.leftSideCtrl.resetViewState();
    this.subCalendarCtrl.resetSubscription();
  }

  // refactor later
  getGoogleCalendars() {
    return this.httpService.get(`${Config.API}/calendar/google`);
  }

  gainGoogleCalendarAccess() {
    return this.httpService.get(`${Config.API}/calendar/google/authorize`);
  }

  grantGoogleCalendarAccess(code: string) {
    return this.httpService.post(`${Config.API}/calendar/google/authorize?code=${code}`, {});
  }

  importCalendarFromGoogle(calendarId: string) {
    return this.httpService.post(`${Config.API}/calendar/google/primary`, { calendarId });
  }
  //
}
