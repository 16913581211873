export const VideoExtensions = [
  'WEBM',
  'MPG',
  'MP2',
  'MPEG',
  'MPE',
  'MPV',
  'OGG',
  'MP4',
  'M4P',
  'M4V',
  'AVI',
  'WMV',
  'MOV',
  'QT',
  'FLV',
  'SWF',
  'AVCHD',
  '3GP', // Android
];
