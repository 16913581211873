import { Attachment } from '@shared/interfaces/attachment.interface';
import { Employee } from '@shared/models/employee.model';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';

export class Entry {
  assignedTo: Array<Employee> = [];
  externalEmployees: Array<Contact> = [];
  attachments: Array<Attachment> = [];
  description: string;
  id: number;
  name?: string;
  type: EntryType;
  opened: boolean = false;
  imgAttachments: Array<Attachment> = [];
  fileAttachments: Array<Attachment> = [];
  created: string;
  deleted: string;

  constructor(entry?: Entry) {
    this.deserialize(entry);
  }

  deserialize(entry?: Entry) {
    entry ? Object.assign(this, entry) : null;

    entry?.attachments?.sort((a, b) => (!!a.deleted > !!b.deleted ? 1 : -1));

    this.imgAttachments = entry?.attachments?.length
      ? entry.attachments?.filter((i) => i.type.indexOf('image') > -1)
      : [];
    this.fileAttachments = entry?.attachments?.length
      ? entry.attachments?.filter((i) => i.type.indexOf('image') === -1)
      : [];
    entry?.assignedTo?.length ? (this.assignedTo = entry.assignedTo.map((i) => new Employee(i))) : null;
  }

  get isEmployee() {
    return this.type === EntryType.TYPE_WORKER_ENTRY;
  }
}
