import { filterToFormControl } from '@shared/modules/list/helpers/filter-name.helper';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { BaseComponent } from '../base.component';
import { ListParams, ListService } from '@shared/modules/list/services/list.service';

export class BaseListFiltersComponentComponent extends BaseComponent {
  constructor(public service: ListService) {
    super();
    this.listenQueryParams();
  }

  handleSelectedValues(items: any[], controlName: string) {
    this.service.setTempChips({
      ...this.service.tempChips,
      [controlName]: items,
    });
  }

  refreshChips() {
    const params = this.service.getParams();
    const chips = { ...this.service.tempChips };

    Object.keys(chips).forEach((key) => {
      if (
        !Object.keys(params)
          .map((k) => filterToFormControl(k.replace('filters', '')))
          .includes(key)
      ) {
        delete chips[key];
      }
    });

    this.service.setTempChips(chips);
  }

  listenQueryParams() {
    this.sub = this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      if (e.type === ListEventType.QUERY_PARAMS) {
        this.setFiltersToForm(e.data);
      }
    });
  }

  setFiltersToForm(params: ListParams) {
    Object.keys(params).forEach((key: string) => {
      const k = filterToFormControl(key.replace('filters', ''));
      const val = !isNaN(params[key]) ? Number(params[key]) : params[key]; // if it's ID of object then I need number type of value

      if (key.indexOf('[]') !== -1) {
        if (!Array.isArray(params[key])) {
          params[key] = [params[key]];
        }
        params[key] = params[key].map((id: string) => {
          return isNaN(Number(id)) ? id : Number(id);
        });
        this.service.filtersForm.get(k).setValue(params[key]);
      }

      if (k && this.service.filtersForm.get(k) && key.indexOf('[]') === -1) {
        this.service.filtersForm.get(k).setValue(val);
      }
    });
  }

  shouldShowClearFilters() {
    let show = false;
    Object.keys(this.service.filtersForm.value).forEach((key) => {
      if (this.service?.filtersForm?.value[key]) {
        show = true;
      }
    });
    return show;
  }

  clearFilters() {
    Object.keys(this.service?.filtersForm.value).forEach((key) => {
      this.service.filtersForm.get(key).setValue(null);
    });
  }

  onChangeFilter() {
    Object.keys(this.service.filtersForm.value).forEach((key: string) => {
      let value = this.service.filtersForm.value[key];
      Array.isArray(value) && !value.length ? (value = null) : null;
      if (key === '[deleted]') {
        value = value ? 1 : 0;
      }
      if (key === '[show_completed]') {
        value = value ? 1 : 0;
      }
      if (key === '[showUnassigned]') {
        value = value ? 1 : 0;
      }
      this.service.setFilter(key, value);
    });
  }
}
