<div
  class="contact-exists-info"
  [ngClass]="{ 'contact-exists-info--active': foundContacts?.contacts.length > 0 }"
>
  <div class="contact-exists-info-title">
    {{ 'Contacts.Manage.ContactExistsInfo.contactExistsTitle.' + foundContacts?.type | translate }}
  </div>
  <div class="contact-exists-info-description">
    {{ 'Contacts.Manage.ContactExistsInfo.contactExistsDescription.' + foundContacts?.type | translate }}
  </div>
  <ul class="contact-exists-info-list">
    <li *ngFor="let contact of foundContacts?.contacts" class="contact-exists-info-list-item">
      <span
        confirmationPopover
        (confirm)="preview(contact)"
        [hideCancelButton]="true"
        confirmButtonType="primary"
        [closeOnOutsideClick]="true"
        [customDescriptionTemplate]="popoverTemplate"
        [confirmText]="'Contacts.Manage.ContactExistsInfo.showContact' | translate"
        [popoverTitle]="'Contacts.Type.' + contact.type | translate"
        [popoverMessage]="contact.fullName"
        [appendToBody]="true"
      >
        <span class="contact-exists-info-list-item__name">{{ contact.fullName }}</span>
      </span>
      <span
        *ngIf="
          parentContactToAssign &&
          parentContactToAssign?.employee?.id !== loggedEmployee.id &&
          parentContactToAssign.id !== contact.id
        "
        class="contact-exists-info-list-item__assign"
        >({{ 'Contacts.Manage.ContactExistsInfo.assignTo' | translate }}
        <a draggable="false" (click)="assignAsSubcontact(contact)">{{ parentContactToAssign.fullName }}</a
        >)</span
      >
      <ng-template #popoverTemplate let-options="options">
        <contact-quick-information-popover
          [contact]="contact"
          [options]="options"
        ></contact-quick-information-popover>
      </ng-template>
    </li>
  </ul>
</div>
