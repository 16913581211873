import { PipeTransform, Pipe } from '@angular/core';
import { FileSignatureController } from '@shared/controllers/file-signature.controller';
import { privateFile } from '@shared/helpers/private-file.helper';
import { publicFile as _publicFile } from '@shared/helpers/public-file.helper';

@Pipe({
  name: 'authFile'
})
export class AuthFilePipe implements PipeTransform {
  constructor(private fs: FileSignatureController) {}

  transform(src: string, width?: number, height?: number, publicFile?: boolean, withoutPrefixUrl?): string {
    if (publicFile) {
      return _publicFile(src);
    }
    if (!src) {
      return;
    }
    let path = '';
    const signature = this.fs.getSignature();

    if (this.fs?.isSharedJWT?.indexOf('&projectToken=true') > -1) {
      path = `${privateFile(src)}`;
      return path;
    }

    if (!!this.fs.isSharedJWT && !signature) {
      path = `${privateFile(src)}?shareToken=${this.fs.isSharedJWT}`;
      return path;
    }

    path = `${withoutPrefixUrl ? src : privateFile(src)}?token=${signature}`;
    !height ? (height = width) : null;
    width ? (path = `${path}&width=${width}&height=${height}`) : null;
    return path;
  }

  getPrivateFile(
    src: string,
    width?: number,
    height?: number,
    publicFile?: boolean,
    withoutPrefixUrl?
  ): string {
    if (!src) {
      return;
    }
    let path = '';
    const signature = this.fs.getSignature();

    path = `${withoutPrefixUrl ? src : privateFile(src)}?token=${signature}`;
    !height ? (height = width) : null;
    width ? (path = `${path}&width=${width}&height=${height}`) : null;
    return path;
  }
}
