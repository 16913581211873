import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectPreviewEventType } from '@modules/projects/modules/project-preview/enums/projcet-preview-event-type.enum';
import { StorageService } from '@core/services/storage.service';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';
import { UserPerson } from '@shared/interfaces/user.interface';

@Component({
  selector: 'project-send-stage-notification',
  templateUrl: './project-send-stage-notification.component.html',
  styleUrls: ['./project-send-stage-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSendStageNotificationComponent implements OnInit {

  CONFIG = Config;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;
  form: FormGroup = new FormGroup({
    content: new FormControl('', [Validators.required])
  });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: '450px',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    defaultFontName: 'Arial',
    showToolbar: true,
    placeholder: this.t.instant('ProjectSummary.SendNotificationAboutStageChange.emailTextPlaceholder'),
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'fontName',
        'heading',
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat'
      ]
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<ProjectSendStageNotificationComponent>,
    private t: TranslateService,
    private projectPreviewService: ProjectPreviewService,
    private s: SnackBarService,
    private changes: ChangeDetectorRef,
    private store: StorageService,
    private projectStageApiService: ProjectStageAPIService,
  ) {
  }

  ngOnInit(): void {
    this.setDefaultMessage();
  }

  setDefaultMessage() {
    const userPerson: UserPerson = this.store.UserPerson;
    const responsiblePerson: UserPerson = this.projectPreviewService.project.basicDataBox.responsibleEmployee.userPerson;
    this.form.get('content').setValue(
      this.t.instant('Projects.ChangeStageNotification.' + this.projectPreviewService.project.stage, {
        mainContactName: this.projectPreviewService.project.MainContact.fullName,
        loggedUserName: `${userPerson.previewName}` + (userPerson.fullPhone ? `<br>Tel.: ${userPerson.fullPhone}` : '') + (userPerson.fullPhone ? `<br>Email: ${userPerson.email}` : ''),
        responsiblePerson: `${responsiblePerson.previewName}`,
        projectId: this.projectPreviewService.project.projectId,
        projectName: this.projectPreviewService.project.fullName
      })
    );
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid || this.loading || !this.projectPreviewService?.project?.MainContact?.email) {
      return;
    }
    this.loading = true;
    this.changes.detectChanges();
    this.projectStageApiService.sendStageNotification(this.projectPreviewService.project.id, this.form.get('content').value).subscribe({
      next: () => {
        this.s.success(this.t.instant('ProjectSummary.SendNotificationAboutStageChange.successSendNotification'));
        this.form.reset();
        this.dialogRef.close();
        this.projectPreviewService.project.pendingStageNotification = false;
        this.projectPreviewService.projectServiceEventEmitter.emit({type: ProjectPreviewEventType.UPDATE_PROJECT});
      },
      error: () => {
        this.s.error(this.t.instant('ProjectSummary.SendNotificationAboutStageChange.errorSendNotification'));
      }
    }).add(() => {
      this.loading = false;
      this.changes.detectChanges();
    });
  }
}
