import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DatePickerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() withoutYear: boolean = false;
  @Input() controlName: string;
  @Input() required: boolean;
  @Input() maxDate?: Date;
  @Input() inputConfig: GbxsoftInputConfig;

  @ViewChild('dp') dp: MatDatepicker<any>;
  @ViewChild('input') input: MatDatepickerInput<any>;

  isFocused: boolean = false;

  constructor(
    private adapter: DateAdapter<any>,
    public t: TranslateService
  ) {}

  ngOnInit(): void {
    this.adapter.setLocale(moment.locale());
  }

  maxDateFilter = (d: Date | null): boolean => {
    return this.maxDate ? d < this.maxDate : true;
  };

  get hasRequiredError() {
    const control = this.form.get(this.controlName);
    return control.touched && this.required && !control.value;
  }

  get placeholder() {
    if (this.isFocused && this.inputConfig.placeholder) {
      return this.inputConfig.placeholder;
    }
    return this.isFocused
      ? `${this.t.instant('Protocols.forExample')} ${moment().format(Config.DATE_FORMAT_DOTS)} ${
          this.required ? '*' : ''
        }`
      : '';
  }

  get hasValue(): boolean {
    const control = this.form?.get(this.controlName);
    return control?.value?.toString().length > 0;
  }
}
