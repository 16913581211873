import { Pipe, PipeTransform } from '@angular/core';
import { ContactType } from '../../modules/contacts/shared/enums/contact-type.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';

@Pipe({ name: 'contactName' })
export class ContactNamePipe implements PipeTransform {
  transform(contact: Contact): string {
    const { type, companyName, firstName, lastName } = contact;
    return type === ContactType.COMPANY ? companyName || '' : `${firstName || ''} ${lastName || ''}`.trim();
  }
}
