<section class="grey-v2">
  <ng-content></ng-content>

  <div class="page-container" [ngClass]="{ 'page-container--with-bottom-bar': bottomBar }">
    <div class="tabs-v2">
      <div
        #tabs
        [ngClass]="{ 'pt-0': !tabsTemplate || hideTopTabIconOnMobile, 'pt-md-3': hideTopTabIconOnMobile }"
      >
        <app-content-tabs-v2
          [selectTabsOnMobile]="selectTabsOnMobile"
          [tabsTemplate]="tabsTemplate"
          [tabsDescription]="tabsDescription"
          [tabsDescriptionTemplate]="tabsDescriptionTemplate"
          [tabLinks]="tabLinks"
          [hideTopTabIconOnMobile]="hideTopTabIconOnMobile"
          [customActivePaths]="customActivePaths"
        ></app-content-tabs-v2>
      </div>
    </div>
    <div class="content scroll-content">
      <div class="content-with-tabs content-with-tabs-content">
        <router-outlet *ngIf="!contentTemplate && !componentTemplate"></router-outlet>
        <ng-container *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate"></ng-container>
        <ng-template #dynamicContentContainer></ng-template>
      </div>
    </div>
  </div>

  <ng-content select="[footer]"></ng-content>
</section>
