import * as moment from 'moment';

export class CalendarSettingsController {
  settingsOpened: boolean;
  selectedDate: any;

  constructor() {
    this.initializeDefaultViewState();
  }

  initializeDefaultViewState() {
    this.settingsOpened = false;
    this.selectedDate = moment();
  }
}
