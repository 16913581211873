import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Task } from '@shared/models/task.model';
import { ListCustomCell } from '@shared/modules/list/interfaces/list-custom-cell.interface';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { TaskController } from '@shared/controllers/task.controller';
import { ListService } from '@shared/modules/list/services/list.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectChangesService } from '@modules/projects/modules/project-changes/shared/services/project-changes.service';
import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';

@Component({
  selector: 'changes-toggle-cell',
  templateUrl: './changes-toggle-cell.component.html',
  styleUrls: ['./changes-toggle-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangesToggleCellComponent implements OnInit, ListCustomCell {
  PermissionsGroups = PermissionsGroups;
  $row: Task;
  taskPermissionCtrl: TaskPermissionController = new TaskPermissionController();

  @ViewChild('template') template;
  @Input()
  set row($row: Task) {
    this.$row = $row;
    this.taskPermissionCtrl.initPermissions(this.$row);
    this.changes.detectChanges();
  }

  get row() {
    return this.$row;
  }

  constructor(
    private listService: ListService,
    private s: SnackBarService,
    private t: TranslateService,
    private changes: ChangeDetectorRef,
    private changesService: ProjectChangesService
  ) {}

  ngOnInit(): void {}

  preview() {
    const ctrl = new ChangesSidenavController();
    ctrl.previewChange(this.row?.taskId || String(this.row?.id));
  }

  edit() {
    const ctrl = new ChangesSidenavController();
    ctrl.editChange(this.row?.project?.id, this.row?.taskId || String(this.row?.id));
  }

  share() {
    const shareData = () => {
      const ctrl = new ChangesSidenavController();
      const project = this.row?.project ? new Project(this.row?.project) : null;
      ctrl.shareSingleChange(this.row, this.row.project?.id, project);
    };

    if (this.row.changeStatus === ProjectChangesStatus.CHANGE_STATUS_SETTLED) {
      const ctrl = new TaskController(this.row);
      ctrl.askForShare(this.row).then(() => {
        shareData();
      });
    } else {
      shareData();
    }

    // const sub = ctrl.shareSingleChange(this.row, this.row.project?.id, project).subscribe(() => {
    //   sub.unsubscribe();
    // });
  }

  createTask() {
    const ctrl = new ChangesSidenavController();
    ctrl.taskCreate(this.row?.id, this.row.project?.id);
  }

  objectCreatorId() {
    if (this.row) {
      return [this.row?.creator?.id, ...this.row?.assignedTo.map((e) => e.id)];
    }
  }

  remove() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        const _ctrl = new TaskController(this.row);
        _ctrl.removeTask(this.row).subscribe({
          next: () => {
            this.listService.getRows();
            this.changesService.updateEmitter.emit(true);
            this.s.success(this.t.instant('ProjectChanges.List.changeRemoved'));
          },
          error: () => {
            this.s.defaultError();
          }
        });
      })
      .subscribe(() => {});
  }
}
