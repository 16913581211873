import { CalendarOptions } from '@fullcalendar/angular';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { Languages, LanguageService } from '@core/services/language.service';
import localePL from '@fullcalendar/core/locales/pl';
import localeDE from '@fullcalendar/core/locales/de';
import localeEN from '@fullcalendar/core/locales/en-gb';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ProjectsTimelineStorageService } from '../../shared/services/projects-timeline-storage.service';

export const projectsTimelineCalendarConfig = (
  languageService: LanguageService,
  getFavouritesButtonComponent,
  getResourceLabelComponent,
  eventChange,
  eventClick,
  eventDidMount,
  projectsTimelineStorage: ProjectsTimelineStorageService,
  slotLabelClick,
): CalendarOptions => {
  const getLocale = () => {
    switch (languageService.getCurrentLanguage()) {
      case Languages.PL:
        return localePL;
      case Languages.DE:
        return localeDE;
      default:
        return localeEN;
    }
  };

  return {
    schedulerLicenseKey: Config.FULL_CALENDAR_LICENSE_KEY,
    initialView: projectsTimelineStorage.TimelineView,
    height: '100%',
    locale: getLocale(),
    headerToolbar: false,
    resourceOrder: '',
    resourceAreaWidth: WindowHelper.isMoreMobileWidth ? '241px' : '140px',
    resourceAreaHeaderContent: getFavouritesButtonComponent.bind(this),
    resourceLabelContent: getResourceLabelComponent.bind(this),
    resourcesInitiallyExpanded: true,
    eventChange: eventChange.bind(this),
    eventClick: eventClick.bind(this),
    eventDrop: eventDidMount.bind(this),
    eventDidMount: eventDidMount.bind(this),
    lazyFetching: true,
    nowIndicator: true,
    slotLabelDidMount: (e) => {
      e.el.addEventListener('click', () => {
        slotLabelClick(e);
      });

      if (
        (e.view.type === 'resourceTimelineYear' && e.level === 1) ||
        e.view.type === 'resourceTimelineWeeksSlot' ||
        (e.view.type === 'resourceTimelineMonth' && e.level === 1)
      ) {
        e.el.classList.add('cursor-pointer');
      }
    },
    views: {
      resourceTimelineWeek: {
        type: 'resourceTimelineWeek',
        slotLabelFormat: [{ month: 'long' }, { weekday: 'short', day: 'numeric', month: 'numeric' }],
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
        slotDuration: '24:00:00',
        titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
        displayEventTime: false,
      },
      resourceTimelineMonth: {
        type: 'resourceTimelineMonth',
        slotLabelFormat: [{ month: 'long' }, { day: '2-digit' }],
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
        titleFormat: {
          week: 'short',
          year: 'numeric',
          month: 'long',
        },
        displayEventTime: false,
      },
      resourceTimelineWeeksSlot: {
        type: 'resourceTimeline',
        slotLabelFormat: [{ month: 'long', year: 'numeric' }, { week: 'numeric' }],
        slotDuration: { weeks: 1 },
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
        // visibleRange: (date) => {
        //   console.log(date);
        //   return {
        //     start: moment().subtract(2, 'year').startOf('year').toDate(),
        //     end: moment().add(2, 'year').endOf('year').toDate(),
        //   };
        // },
        // dateIncrement: { year: 1 },
        // visibleRange: function (currentDate) {
        //   // Generate a new date for manipulating in the next step
        //   // // Adjust the start & end dates, respectivel
        //   // startDate.setDate(startDate.getDate() - 365); // One day in the past
        //   // endDate.setDate(endDate.getDate() + 365); // Two days into the future
        //   // console.log(
        //   //   moment(currentDate.valueOf()).subtract(1, 'year').startOf('year').toDate(),
        //   //   moment(currentDate.valueOf()).add(1, 'year').endOf('year').toDate(),
        //   // );
        //   // return {
        //   //   start: moment(currentDate.valueOf()).subtract(1, 'month').startOf('month').toDate(),
        //   //   end: moment(currentDate.valueOf()).add(5, 'month').endOf('month').toDate(),
        //   // };
        // },
        titleFormat: {
          week: 'short',
          year: 'numeric',
          month: 'long',
        },
        duration: {
          year: 2,
        },
        displayEventTime: false,
      },
      resourceTimelineYear: {
        type: 'resourceTimeline',
        slotLabelFormat: [{ month: 'short', year: 'numeric' }, { day: 'numeric' }],
        slotDuration: { days: 1 },
        slotMinWidth: 4,
        slotLabelClassNames: 'no-weekend-marked',
        slotLaneClassNames: 'no-weekend-marked',
        viewClassNames: 'year-without-days',
        visibleRange: {
          start: moment().subtract(1, 'year').startOf('year').toDate(),
          end: moment().endOf('year').toDate(),
        },
        titleFormat: {
          week: 'short',
          year: 'numeric',
          month: 'long',
        },
        duration: {
          year: 2,
        },
        displayEventTime: false,
      },
    },
  };
};
