import { countries } from '@shared/datas/countries';
import { phoneCountries } from '@shared/components/phone-input/components/country-picker/phone-countries';
import { Employee } from '@shared/models/employee.model';
import { Link } from '@shared/models/link.model';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { Commentary } from '@shared/modules/commentary/models/commentary.model';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';
import { ContactStatus } from '../enums/contact-status.enum';
import { BaseContactModel } from './base-contact.model';
import { ContactTag } from '../interfaces/contact-tag.interface';
import { ContactFunction } from '../interfaces/contact-function.interface';

export class Contact extends BaseContactModel {
  phone?: string;
  phoneCountry?: string;

  additionalPhone?: string;
  additionalPhoneCountry?: string;

  email?: string;
  deleted?: string;
  birth?: any;
  address?: string;
  postalCode?: any;
  town?: any;
  country?: any;
  function?: ContactFunction[];
  availability?: any;
  discount?: number;
  isNetPrices?: boolean;

  childContacts?: Contact[] = [];
  taxNumber?: string;
  taxNumberEU?: string;
  website?: string;
  links?: Link[] = [];
  treeStatus?: TreeStatusEnum;
  childCount?: number;
  comment?: string;
  acquisitionSource?: any;
  isContactPerson: boolean;
  contactPerson?: Contact;
  created: string;
  creator?: Employee;
  employee?: Employee;
  contact?: Contact;
  parentContacts?: Contact[] = [];
  mainContactProjects?: ContactAccessProject[] = [];
  contactProjects?: ContactAccessProject[] = [];
  contactProjectsFinished?: ContactAccessProject[] = [];
  contactProjectsInProgress?: ContactAccessProject[] = [];
  mainContact?: Contact;
  parentCount?: number;
  comments?: Commentary[];
  fullPhone?: string;
  communications?: { phone: string; phoneCountry: string }[];
  tags?: ContactTag[] = [];

  fullAddress?: string;
  isResponsible?: boolean;
  status?: ContactStatus;
  isDraft?: boolean;
  disabled?: boolean = false;
  employeeId?: number;
  accessToPreview?: boolean;
  mainContactId?: any;
  optimalContactTimeType?: string;
  optimalContactTimeAt?: string;
  recommendedBy: Contact;
  owner: Contact;

  addressType?: string;
  correspondenceTown?: string;
  correspondenceAddress?: string;
  correspondencePostalCode?: string;

  childContactPerson?: Contact;

  get contactAccountType() {
    if (this.employee) {
      return 'ROLES.' + this.employee.role;
    } else {
      return 'Contacts.Preview.Details.noAccount';
    }
  }

  constructor(contact?: any) {
    super(contact);
    this.deserialize(contact);
    this.setHasContactPerson();
    this.setFullPhone();
    this.setFullName();
    this.setFullAddress();
  }

  deserialize(contact?: Contact) {
    contact ? Object.assign(this, contact) : null;
    if (this.parentContacts && Array.isArray(this.parentContacts)) {
      this.parentContacts = this.parentContacts.map((c) => new Contact(c));
    }
    if (this.creator) {
      this.creator = new Employee(this.creator);
    }
    if (this.employee) {
      this.employee = new Employee(this.employee);
    }
    if (this.childContacts && Array.isArray(this.childContacts)) {
      this.childContacts = this.childContacts.map((c) => new Contact(c));
    }

    if (contact?.comments?.length) {
      this.comments = contact.comments.map((i) => new Commentary(i));
    }

    if (this.childContactPerson) {
      this.childContactPerson = new Contact(this.childContactPerson);
    }

    this.isDraft = this.status === ContactStatus.DRAFT;
  }

  setHasContactPerson() {
    if (!this.childContacts?.length) {
      this.contactPerson = null;
      return;
    }

    this.childContacts.map((contact: Contact) => {
      if (contact.isContactPerson) {
        this.contactPerson = new Contact(contact);
      }
    });
  }

  setFullPhone(): void {
    if ((!this.phoneCountry && !this.phone) || !this.phone) {
      this.fullPhone = '';
      return;
    }
    const country = phoneCountries[phoneCountries.findIndex((x) => x.flagClass === this.phoneCountry)];
    country ? (this.fullPhone = `+${country.dialCode}${this.phone}`) : (this.fullPhone = this.phone);
  }

  setFullAddress() {
    if (!!this.address) {
      const country = countries.filter((i) => i.id.toString() === this.country)[0]?.text;
      this.fullAddress = `${this.address}${!!this.postalCode ? ', ' + this.postalCode : ''} ${
        !!this.town ? ' ' + this.town : ''
      }${!!country ? ', ' + country : ''}`;
    }
  }
}
