<div class="settings settings-content">
  <content-title-v2 [title]="'Settings.Email.titleV2' | translate"> </content-title-v2>
  <div [formGroup]="form">
    <div class="checkbox-container">
      <div class="form-control-checkbox-v2 mr-4">
        <label class="m-0 d-flex align-items-center">
          <input
            [formControlName]="EmailSettingsForm.mailMethod"
            type="radio"
            [value]="UserMailMethod.default"
          />
          <span></span>
          <span [innerHTML]="'Settings.Email.systemTypeV2' | translate"></span>
        </label>
      </div>

      <div class="form-control-checkbox-v2">
        <label class="m-0 d-flex align-items-center">
          <input
            [formControlName]="EmailSettingsForm.mailMethod"
            type="radio"
            [value]="UserMailMethod.google"
          />
          <span></span>
          <span [innerHTML]="'Settings.Email.googleTypeV2' | translate"></span>
        </label>
      </div>
    </div>
    <div class="mt-3" *ngIf="form.get(EmailSettingsForm.mailMethod).value === UserMailMethod.google">
      <button
        (click)="getAccessGoogleAccount()"
        class="btn btn-empty_grey d-flex align-items-center p-1 px-2"
      >
        <img class="mr-2" src="./assets/img/ic-google.svg" alt="google" />
        {{ 'Settings.Email.googleAuth' | translate }}
      </button>
    </div>
    <hr class="spacer-v2" />

    <div>
      <content-title-v2 [title]="'Settings.Email.descriptionTitleV2' | translate"> </content-title-v2>
      <div class="relative pb-3">
        <quill-editor
          id="quill-editor"
          [disabled]="!!form.get(EmailSettingsForm.useSystemMailFooter).value"
          [readOnly]="!!form.get(EmailSettingsForm.useSystemMailFooter).value"
          [formControlName]="EmailSettingsForm.mailFooter"
          [modules]="modules"
          [formats]="formats"
          [placeholder]="'Settings.Email.descriptionPlaceholder' | translate"
          class="quill-v2 quill-custom-img-size"
          (onFocus)="onEditorFocus()"
        ></quill-editor>
      </div>
      <div class="checkbox-container">
        <new-toggle-checkbox-v2
          [formControlName]="EmailSettingsForm.useSystemMailFooter"
          [label]="'Settings.Email.useSystemDescritpionV2' | translate"
        ></new-toggle-checkbox-v2>
      </div>
      <!-- <div class="form-control-checkbox-v2">
        <label class="m-0 d-flex align-items-center">
          <input [formControlName]="EmailSettingsForm.useSystemMailFooter" type="checkbox" />
          <span></span>
          <span [innerHTML]="'Settings.Email.useSystemDescritpion' | translate"></span>
        </label>
      </div> -->
    </div>
  </div>
</div>
