import { MatDialog } from '@angular/material/dialog';
import { Injector } from '@angular/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { MailingService } from '../services/mailing.service';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { NavigateService } from '@shared/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { Permission } from '@core/permissions/decorators/permissions.decorator';

export class MailingController {
  injector: Injector;
  service: MailingService;
  dialog: MatDialog;
  n: NavigateService;

  private activatedRoute: ActivatedRoute;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.service = this.injector.get(MailingService);
    this.dialog = this.injector.get(MatDialog);
    this.n = this.injector.get(NavigateService);
    this.activatedRoute = this.injector.get(ActivatedRoute);
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EMAIL_TAB'
  })
  goToTemplates(email: string, projectId: number) {
    this.n.go('new-contact', {}, { queryParams: { projectId, email } });
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EMAIL_TAB'
  })
  goToProjectTemplates(email: string, projectId: number = null) {
    this.n.go('project-preview-email', { projectId }, { queryParams: { projectId, email } });
  }

  getDeepestChildRoute(route: ActivatedRoute): ActivatedRoute {
    let deepestChild = route;

    // Iterate over the child routes until there are no more children
    while (deepestChild.firstChild) {
      deepestChild = deepestChild.firstChild;
    }

    return deepestChild;
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EMAIL_TAB'
  })
  openMailingSelection(email: string, projectId: number = null) {
    const deepestChildRoute = this.getDeepestChildRoute(this.activatedRoute);

    if (deepestChildRoute) {
      const routeProjectId = deepestChildRoute?.parent?.parent?.snapshot?.params?.projectId;
      if (routeProjectId) {
        this.goToProjectTemplates('', routeProjectId);
        return;
      }
    }
    this.goToTemplates(null, null);

    // this.dialog.open(MailingSelectionModalComponent, {
    //   width: '450px',
    //   autoFocus: false,
    //   disableClose: true,
    //   data: {
    //     email,
    //     projectId,
    //   },
    // });
  }
}
