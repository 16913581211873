<div class="picker">
  <div
    class="picker-chosen d-flex align-items-center"
    mat-button
    [matMenuTriggerFor]="phonePicker"
    (menuClosed)="closePicker()"
    (menuOpened)="openPicker()"
    [ngClass]="{ disabled: disabled }"
  >
    <div class="picker-chosen-flag d-flex align-items-center">
      <div class="iti-flag" [ngClass]="chosenCountry?.flagClass"></div>
      <span class="picker-chosen-arr"
        ><svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5L3 3.5L6 0.5H0Z" fill="#7C7C7D" />
        </svg>
      </span>
    </div>
    <div class="picker-chosen-dialCode">+{{ chosenCountry?.dialCode }}</div>
  </div>

  <mat-menu #phonePicker="matMenu" [class]="'countryPicker'">
    <div class="picker-wrapper" (click)="$event.stopPropagation()">
      <div class="picker-wrapper-search">
        <input
          type="text"
          [placeholder]="'Global.searchCountry' | translate"
          [(ngModel)]="query"
          (input)="search()"
          #searchInput
        />
      </div>
      <div class="picker-wrapper-content">
        <ul #countriesList>
          <ng-container #countriesContainer></ng-container>
        </ul>
      </div>
    </div>
  </mat-menu>
</div>
<ng-template #item let-country="country">
  <li (click)="choose(country)" [ngClass]="{ chosen: country.chosen }">
    <div class="iti-flag" [ngClass]="country.flagClass"></div>
    <span
      ><b>+{{ country.dialCode }}</b> {{ country.name }}</span
    >
  </li>
</ng-template>
