import * as QuillNamespace from 'quill';
import { ImageHandler } from 'ngx-quill-upload';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import { CustomAttributes } from '@shared/helpers/quill-translation.helper';
import { CustomLinkSanitizer } from './quill-link.helper';

export class QuillHelper {
  static init() {
    const Quill: any = QuillNamespace;
    Quill.register(CustomAttributes, true); // helps world translation fix
    Quill.register(CustomLinkSanitizer, true);
    Quill.register('modules/imageHandler', ImageHandler);
    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
    // Quill.register('modules/clipboard', PlainClipboard, true); // This logic destroys native change detection on form and it cant be saved
  }
}
