<div [formGroup]="service.form">
  <div class="row">
    <div class="col-12 mb-2">
      <div class="new-select-label">{{ 'Projects.assignedPerson' | translate }}</div>
      <employee-search-v2
        #search
        [readonly]="!!service?.task?.isDeleted"
        [initialValue]="service.assignedUsers"
        [projectId]="service.formProjectId"
        [form]="service.form"
        [controlName]="service.TASK_SIDENAV_FORM.assignedTo"
      ></employee-search-v2>
    </div>
    <div class="col-12 mb-2">
      <div class="new-select-label">{{ 'Protocols.realizationTerm' | translate }}</div>
      <daterangepicker-v2
        #datepicker
        [title]="'Projects.selectDeadline' | translate"
        [interactive]="true"
        [isCalendarTime]="true"
        [disabled]="!!service?.task?.isDeleted"
        (rangeChanged)="service.rangeChanged($event)"
      ></daterangepicker-v2>
    </div>
  </div>

  <!-- <div [hidden]="!service.isTaskSelection">
    <ng-select
      #taskGroup
      ngSelectExtension
      [hidden]="!service?.form?.get(service.TASK_SIDENAV_FORM.projectId)?.value"
      [url]="taskGroupURL"
      [formControlName]="service.TASK_SIDENAV_FORM.groupId"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      class="new-select w-100"
      [notFoundText]="'Projects.notFoundText' | translate"
      [placeholder]="'Projects.taskGroup' | translate"
      [clearable]="false"
      [translateLabelPrefix]="'Projects.TaskGroups.'"
      [responseTransform]="taskGroupTransform.bind(this)"
      [sortByField]="'sortOrder'"
      [readonly]="!!service?.task?.isDeleted"
    ></ng-select>
  </div> -->

  <div class="w-100">
    <div class="new-select-label">{{ 'ProjectTasks.Filters.tags' | translate }}</div>
    <ng-select
      ngSelectExtension
      #tagsSelect="ngSelectExtensionDir"
      [url]="service.TagUrl"
      [bindValue]="'name'"
      [bindLabel]="'name'"
      [formControlName]="service.TASK_SIDENAV_FORM.tags"
      [closeOnSelect]="false"
      [addTag]="true"
      [multiple]="true"
      [clearable]="true"
      [tabIndex]="-1"
      [notFoundText]="'Projects.notFoundText' | translate"
      [addTagText]="'Projects.addTaskTag' | translate"
      [clearAllText]="'clearAll' | translate"
      class="new-select w-100"
      [placeholder]="'Protocols.assignTagsToPerson' | translate"
      [enableSort]="false"
      [readonly]="!!service?.task?.isDeleted"
    ></ng-select>
  </div>
</div>
