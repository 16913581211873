import { EventEmitter, Injectable, InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseHttpService } from '@core/http/base-http.service';
import { StorageService } from '@core/services/storage.service';
import { Config } from '@shared/configs/config';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { isMoment } from 'moment';
import { ListFilterComponent } from '../components/list-filter/list-filter.component';
import { DefaultListConfig } from '../configs/default-list.config';
import { ListUrlController } from '../controllers/list-url.controller';
import { formControlToFilter } from '../helpers/filter-name.helper';
import { CustomTableColumn } from '../interfaces/custom-table-column.interface';
import { ListConfig } from '../interfaces/list-config.interface';
import { ListEvent, ListEventType } from '../model/list-event.model';
import { ListResponse } from '../model/list-response.model';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

export const CHIPS_VIEW = new InjectionToken<boolean>('app.filters.chipsView');

declare var $: any;

@Injectable()
export class ListService extends BaseHttpService {
  isFiltersOpened = false;
  table: DatatableComponent;
  tempChips = {};
  chips = {};
  filters: ListFilterComponent;
  filtersForm: FormGroup;
  config: ListConfig;
  private params: ListParams = new ListParams();
  private _hideFilters: string[];
  eventEmitter: EventEmitter<ListEvent<any>> = new EventEmitter();
  loading: boolean = false;
  rows: any[];
  total: number = 0;
  data?: any = {};

  constructor(
    private storage: StorageService,
    private urlController: ListUrlController
  ) {
    super();
  }

  get PerPage() {
    const perpage = this.storage.get('list-perpage');
    return perpage ? perpage : DefaultListConfig.limit;
  }

  set PerPage(p) {
    this.storage.put('list-perpage', p);
  }

  get hideFilters() {
    return this._hideFilters;
  }

  set hideFilters(filters: string[]) {
    this._hideFilters = filters;
  }

  readUrlParams(reAssignParams = true) {
    if (reAssignParams) {
      this.params = Object.assign(this.params, this.urlController.readParamsFromUrl());
    }
    this.setDefaultSort();
    this.eventEmitter.emit({ type: ListEventType.QUERY_PARAMS, data: this.params });
    this.updateListData();
  }

  setTempChips(chips: any) {
    this.tempChips = chips;

    if (!this.isFiltersOpened) {
      this.chips = chips;
    }

    if (!Object.keys(chips).length) {
      // After clear filters red button click
      this.chips = chips;
    }
  }

  removeFilterByKey(key: string, refresh = true) {
    this.setFilter(key, null);
    this.tempChips[key] = [];

    if (!refresh) return;

    this.getRows();
  }

  removeFilterByKeyAndValue(key: string, value: any) {
    const newValues = this.chips[key].filter((v) => v.id !== value);
    this.setFilter(
      key,
      newValues.map(({ id }) => id)
    );
    this.tempChips[key] = newValues;

    this.getRows();
  }

  setSort(field: string, sortType: string, column: any, columns: any[]) {
    // #region search for additional sort columns to remove them from request
    const fields = columns?.reduce((accum: any[], column: any) => {
      return accum.concat(column?.additionalSortFields || []);
    }, []);
    fields.forEach((element) => delete this.params[element]);
    // #endregion

    if (isNotNullOrUndefined(sortType)) {
      this.params.sortField = field;
      this.params.sortDirection = sortType.toUpperCase();
    } else {
      delete this.params.sortField;
      delete this.params.sortDirection;
    }

    // #region set additional fields the same value of sortField
    if (column?.additionalSortFields) {
      if (sortType) {
        column.additionalSortFields?.forEach((element) => {
          this.setParams(element, sortType.toUpperCase());
        });
        delete this.params.sortField;
        delete this.params.sortDirection;
      } else {
        column.additionalSortFields?.forEach((element) => {
          delete this.params[element];
        });
      }
    }
    // #endregion

    this.getRows();
  }

  setDefaultSort() {
    if (!this.params.sortField && !this.params.sortDirection) {
      if (this.config?.defaultSort) {
        this.params.sortDirection = this.config.defaultSort.sortDirection;
        this.params.sortField = this.config.defaultSort.sortField;
      }
    }
  }

  setSearch(search: string) {
    this.params.search = search;
    this.setPage(1);
    this.getRows();
  }

  setPage(page: number) {
    this.params.page = page;
    this.updateListData();
  }

  get Page() {
    return this.params.page;
  }

  setParams(key: string, value: any) {
    this.params[key] = value;
  }

  setFilter(key: string, value: any) {
    let exists = false;
    this.params.fullFilters.map((filter: ListParamsFilter) => {
      if (filter.key === key) {
        filter.value = value;
        exists = true;
      }
    });
    if (!exists) {
      this.params.fullFilters.push({
        key,
        value
      });
    }
    this.updateFiltersForm(key, value);
  }

  updateFiltersForm(key, value) {
    this.filtersForm?.get(key) ? this.filtersForm.get(key).setValue(value) : null;
  }

  // update per page in cookies and config of table
  setPerPage(p: number) {
    this.PerPage = p.toString();
    this.config.limit = p;
    this.params.page = 1;
    this.config.offset = 0;
    this.getRows();
  }

  setDelegatedTasks(d: number) {
    this.params.delegatedTasks = d;
  }

  onFooterPage(data) {
    this.setPage(data.page);
    this.table.onFooterPage(data);
  }

  updateListData() {
    this.params.page && this.config.offset ? (this.config.offset = this.params.page - 1) : null;
    this.params.records && this.config.limit ? (this.config.limit = this.params.records) : null;
  }

  setInitSorting() {
    if (!this.params.sortDirection || !this.params.sortField) {
      return;
    }
    const prop = this.config.columns.filter((c: CustomTableColumn) => {
      return c.sortField === this.params.sortField;
    })[0]?.prop;
    setTimeout(() => {
      this.table.sorts = [{ prop, dir: this.params.sortDirection.toLowerCase() }];
    });
  }

  setFiltersString() {
    const filters = {};
    this.params.fullFilters
      ? this.params.fullFilters.map((filter: ListParamsFilter) => {
          const key = `filters${formControlToFilter(filter.key)}`;
          const value = isMoment(filter.value) ? filter.value.format(Config.DATE_SERVER) : filter.value;
          if (isNotNullOrUndefined(value)) {
            filters[key] = value;
          } else {
            this.params[key] || this.params[key] === 0 ? delete this.params[key] : null;
          }
        })
      : '';
    this.params = Object.assign(this.params, filters);
  }

  getRows() {
    this.loading = true;
    this.setFiltersString();
    this.params.records = this.config?.limit;
    this.urlController.setParamsToUrl(this.params);
    this.eventEmitter.emit({ type: ListEventType.START_GET_ROWS });
    const params = this.getParams();
    this.chips = this.tempChips;
    return this.get(this.getUrl(params), false, this.getParams(), true)
      .subscribe({
        next: (res: ListResponse<any>) => {
          this.rows = this.config?.responseKey ? res[this.config.responseKey] : res;
          this.setCheckedParam();
          this.total = res.total;
          if (this.table) {
            this.table.count = this.rows.length;
            this.table.recalculate();
            this.table.bodyComponent.updateOffsetY(0);
          }
        },
        error: (e) => {
          this.s.error(this.t.instant('Global.errorDuringGetList'));
        }
      })
      .add(() => {
        this.loading = false;
        this.eventEmitter.emit({ type: ListEventType.END_GET_ROWS });
      });
  }

  private setCheckedParam() {
    if (this.config?.checkboxes) {
      this.rows = this.rows.map((r) => {
        r.checked = false;
        return r;
      });
    }
  }

  private getUrl(params: any): string {
    if (this.config.urlTransform) {
      return this.config.urlTransform(this.config.url, params);
    }
    return this.config.url;
  }

  get _search() {
    return this.params.search ? this.params.search : '';
  }

  getParams() {
    const params = Object.assign({}, this.params);
    delete params.fullFilters;
    return params;
  }

  clearParams() {
    this.params = {};
    this.tempChips = {};
    this.chips = {};
  }

  clearService() {
    this.filtersForm ? this.filtersForm.reset() : null;
    this.params = { fullFilters: [], page: 1 };
    this.filters = null;
    this.rows = [];
    this.config = null;
    this.tempChips = {};
    this.chips = {};
  }
}

export class ListParams {
  page?: number;
  records?: number;
  sortField?: string;
  delegatedTasks?: number;
  sortDirection?: string;
  tour?: boolean;
  search?: string;
  fullFilters?: ListParamsFilter[] = [];

  constructor() {}
}

export interface ListParamsFilter {
  key: string;
  value: any;
}
