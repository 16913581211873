<ng-container
  *ngIf="subTitleTemplate && WindowHelper.isMobileWidth"
  [ngTemplateOutlet]="subTitleTemplate"
></ng-container>
<div class="row d-flex align-items-center">
  <div class="col-12 col-md-5">
    <div
      class="grey-2 content-with-tabs-content-subtitle_sm mb-2"
      [innerHTML]="subtitle"
      *ngIf="subtitle && WindowHelper.isMobileWidth"
    ></div>
    <h1
      class="content-with-tabs-content-title mb-1 h1 grey-1"
      [innerHTML]="title"
      [ngClass]="{ 'content-with-tabs-content-title--no-subtitle': !subtitle && !subTitleTemplate }"
      *ngIf="title"
    ></h1>
    <ng-container *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate"></ng-container>
  </div>
  <div class="col-12 col-md-7">
    <ng-container *ngIf="rightSideTemplate" [ngTemplateOutlet]="rightSideTemplate"></ng-container>
  </div>
</div>

<ng-container
  *ngIf="subTitleTemplate && WindowHelper.isMoreMobileWidth"
  [ngTemplateOutlet]="subTitleTemplate"
></ng-container>
<div
  class="grey-2 content-with-tabs-content-subtitle_sm"
  [innerHTML]="subtitle"
  *ngIf="subtitle && WindowHelper.isMoreMobileWidth"
></div>

<hr class="content-with-tabs-content-divider content-with-tabs-content-divider--action" *ngIf="!noDivider" />
