import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { HttpError } from '@shared/interfaces/error.interface';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { EmployeeSearchComponent } from '@shared/components/employee-search/employee-search.component';
import { Task } from '@shared/models/task.model';
import { SubtasksService } from '@shared/modules/subtasks/subtasks.service';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { ObjectTaskType } from '@shared/enums/task-type.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { ListTaskEventType, ListTaskService } from '@shared/modules/list/services/list-task.service';
import { map } from 'rxjs/operators';
import { filterAndMapErrorMessages } from '@shared/helpers/filter-and-map-error.helper';
import { WindowHelper } from '@shared/helpers/window.helper';
import { InputV2Component } from '@shared/modules/forms-v2/components/input-v2/input-v2.component';

@Component({
  selector: 'subtask-create',
  templateUrl: './subtask-create.component.html',
  styleUrls: ['./subtask-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubtaskCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  TASK_SIDENAV_FORM = TASK_SIDENAV_FORM;
  WindowHelper = WindowHelper;

  loading: boolean = false;

  @ViewChild('employeeSearch') employeeSearch: EmployeeSearchComponent;
  @ViewChild('datepicker') datepicker: DateRangePickerComponent;
  @ViewChild('taskTitle') taskTitle: InputV2Component;
  @Output() discard: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup;

  get initialFormValues() {
    return {
      [TASK_SIDENAV_FORM.description]: null,
      [TASK_SIDENAV_FORM.assignedTo]: [],
      [TASK_SIDENAV_FORM.termEnd]: null,
      [TASK_SIDENAV_FORM.termStart]: null,
      parentId: null,
      objectType: ObjectTaskType.PLAIN,
      [TASK_SIDENAV_FORM.type]: this.service.type,
      [TASK_SIDENAV_FORM.status]: TaskStatus.CREATED
    };
  }

  get errorMessages() {
    const messages = Config.validationMessages;
    const control = this.form.get(TASK_SIDENAV_FORM.description);

    return filterAndMapErrorMessages(messages, control.errors);
  }

  constructor(
    private fb: FormBuilder,
    private changes: ChangeDetectorRef,
    public taskService: TaskSidenavService,
    private service: SubtasksService,
    private listTaskService: ListTaskService,
    private t: TranslateService,
    private s: SnackBarService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    this.taskTitle.inputv2.nativeElement.focus();
  }

  createForm() {
    this.form = this.fb.group(this.initialFormValues);
    this.form.get(TASK_SIDENAV_FORM.description).setValidators([Validators.required]);
    this.form.get(TASK_SIDENAV_FORM.description).updateValueAndValidity();
  }

  resetDefault() {
    this.form.setValue(this.initialFormValues);
    this.form.get(TASK_SIDENAV_FORM.description).markAsUntouched();
    this.form.get(TASK_SIDENAV_FORM.description).updateValueAndValidity();
    this.datepicker?.resetValue();
    this.form.markAsPristine();
  }

  discardAdding() {
    this.resetDefault();
    this.discard.emit(true);
    this.changes.detectChanges();
  }

  setDataBeforeSave() {
    this.form.get('parentId').setValue(this.service.parentId);
    const value = Object.assign(this.form.value, {});
    value.dataChanges = 1;
    delete value.assignedToIds;
    return Object.assign(value, this.employeeSearch.formData);
  }

  saveSubtask() {
    this.form.get(TASK_SIDENAV_FORM.description).markAsTouched();
    this.form.get(TASK_SIDENAV_FORM.description).updateValueAndValidity();
    this.changes.detectChanges();
    if (this.loading || this.form.invalid) return;
    this.loading = true;
    const form = this.setDataBeforeSave();
    this.service
      .createTask(form)
      .pipe(map((task) => new Task(task)))
      .subscribe({ next: this.successAddSubtask.bind(this), error: this.errorAddSubtask.bind(this) })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });

    this.changes.detectChanges();
  }

  successAddSubtask(task: Task) {
    this.service.taskAdd.emit(task);
    this.s.success(this.t.instant('Tasks.successSubtask'));
    this.resetDefault();
    this.taskTitle.inputv2.nativeElement.focus();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.ADD_SUB_TASK, data: task });
  }

  errorAddSubtask(err: HttpError) {
    this.s.error(this.t.instant('Tasks.errorSubtask'));
  }

  rangeChanged($event) {
    const startControl = this.form.get(TASK_SIDENAV_FORM.termStart);
    const endControl = this.form.get(TASK_SIDENAV_FORM.termEnd);

    startControl.setValue(
      !!$event.start ? moment($event.start, Config.DATE_SERVER).format(Config.DATE_SERVER) : null
    );

    endControl.setValue(
      !!$event.end ? moment($event.end, Config.DATE_SERVER).format(Config.DATE_SERVER) : null
    );
    this.form.markAsDirty();
    this.changes.detectChanges();
  }

  ngOnDestroy() {}

  @HostListener('document:keyup', ['$event']) onKeyupHandler(e: KeyboardEvent) {
    e.keyCode === 13 ? this.saveSubtask() : null;
  }
}
