import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { CustomTableColumn } from './interfaces/custom-table-column.interface';
import { ListConfig } from './interfaces/list-config.interface';
import { ListService } from './services/list.service';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StorageService } from '@core/services/storage.service';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { NavigateService } from '@shared/services/navigate.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {
  SelectionType = SelectionType;

  activateSub: Subscription;
  serviceSub: Subscription = new Subscription();
  scrollSub: Subscription = new Subscription();
  initedScrollPosition: boolean = false;

  @ViewChild('table') table: DatatableComponent;
  @ViewChild('listContainer') listContainer: ElementRef;
  @ViewChild('toggleMenu') toggleMenu: TemplateRef<any>;
  @ViewChild('checkbox') checkbox: TemplateRef<any>;
  @ViewChild('checkboxAll') checkboxAll: TemplateRef<any>;
  @ViewChild('toggleTree') toggleTree: TemplateRef<any>;
  @ViewChild('filtersComponent') filtersComponent: ListFilterComponent;

  private _config: ListConfig;
  @Input()
  set config(config: ListConfig) {
    this._config = config;
    // this.changes?.detectChanges();
  }

  get config() {
    return this._config;
  }

  @Input() emptyTemplate: TemplateRef<any>;
  @Input() filters;
  @Input() searchName: string;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() isSearch: boolean = true;
  @Input() selected: any[] = [];
  @Input() filtersTourAnchor: string;
  @Input() listTourAnchor: string;
  @Input() treeActionTourAnchor: string;
  @Input() toggleMenuTourAnchor: string;
  @Input() showToggleMenu?: Function;
  @Input() hideFilters?: string[];
  @Input() headerHidden = false;

  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() unselect: EventEmitter<any> = new EventEmitter();

  constructor(
    public service: ListService,
    public t: TranslateService,
    public changes: ChangeDetectorRef,
    private store: StorageService,
    private n: NavigateService
  ) {}

  ngOnInit(): void {
    this.service.clearService();
    this.service.config = this.config;
    this.service.config.limit = this.service.PerPage as number;
    this.listenListServiceEvents();
    this.changes.detectChanges();
  }

  ngAfterViewInit() {
    this.service.table = this.table;
    this.service.filters = this.filtersComponent;
    this.service.readUrlParams();
    this.service.setInitSorting();
    this.service.getRows();
    this.setToggleMenuAndTreeMenuAndCheckboxes();
    this.setHiddenFilters();
    this.changes.detectChanges();
  }

  setHiddenFilters() {
    if (this.hideFilters) {
      this.service.hideFilters = this.hideFilters;
    }
  }

  listenListServiceEvents() {
    this.serviceSub = this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.END_GET_ROWS:
          this.initScrollPosition();
          break;
      }
    });
  }

  setToggleMenuAndTreeMenuAndCheckboxes() {
    for (let i = 0; i < this.service.config.columns.length; i++) {
      const c = this.service.config.columns[i];
      if (c.toggleMenu) {
        c.cellTemplate = this.toggleMenu;
        c.cellClass += ' toggle-cell';
      }
      if (c.isTreeColumn) {
        c.treeToggleTemplate = this.toggleTree;
        c.cellClass += ' tree-cell';
      }
      if (c.checkbox) {
        c.cellTemplate = this.checkbox;
        c.headerTemplate = this.checkboxAll;
        c.cellClass += ' checkbox';
      }
    }

    this.table.headerComponent.headerHeight = this.table.headerComponent.headerHeight; // refresh header templates
    this.changes.detectChanges();
  }

  onSort(e) {
    this.service.setSort(e.column.sortField, e.newValue, e.column, this.config.columns);
  }

  onTreeAction(e) {
    if (e.row.treeStatus === TreeStatusEnum.LOADING) {
      return;
    }
    this.config.treeAction.treeActionFunction ? this.config.treeAction.treeActionFunction(e) : null;
  }

  /**
   * check if click is on tree toggle cell - if yes block activate event
   * @param e
   */
  onActivate(e: { type: string; row: any; column: CustomTableColumn; event }) {
    e = this.checkTreeAction(e);
    this.activate.emit(e);
  }

  /**
   * Check if this activate event is connected with click on tree button (expand / collapsed single record)
   * @param e
   */
  checkTreeAction(e: { type: string; row: any; column: CustomTableColumn; event }) {
    if ((e.type === 'click' || e.type === 'touchstart') && e.column && e.column.isTreeColumn) {
      const path = e.event.path || (e.event.composedPath && e.event.composedPath()); // for firefox and safair which doesnt support event.path
      for (let i = 0; i <= path.length - 1; i++) {
        if (path[i]?.classList?.contains('datatable-tree-button')) {
          e.type = 'unselect';
        }
      }
    }
    return e;
  }

  treeDisabled(event) {
    return this.config.treeAction.treeDisabledFunction
      ? this.config.treeAction.treeDisabledFunction(event)
      : false;
  }

  singleSelectCheck(row: any) {
    this.unselect.emit();
    return this.selected.indexOf(row) === -1;
  }

  getRowClass(row) {
    const rowClass = {
      [this.service.config.rowClass]: true,
      'tree-row': row.treeStatus === TreeStatusEnum.EXPANDED && !this.treeDisabled({ row })
    };
    this.service.config.rowClassFunction ? (rowClass[this.service.config.rowClassFunction(row)] = true) : '';
    return rowClass;
  }

  initScrollPosition() {
    if (this.initedScrollPosition) {
      this.setScrollPosition();
      return;
    }
    this.initedScrollPosition = true;

    const current = this.n.currentUrl.split('?')[0] || this.n.currentUrl;
    const secondPreviousUrl = this.n.secondPreviousUrl.split('?')[0] || this.n.secondPreviousUrl;

    if (secondPreviousUrl.indexOf(current) === -1 && current.indexOf(secondPreviousUrl) === -1) {
      this.listenScroll();
      return;
    }

    const historyScroll = this.store.get(this.service.config.listName + '-scroll');
    if (!historyScroll) {
      this.listenScroll();
      return;
    }

    this.setScrollPositionToBasePanel(historyScroll);
  }

  setScrollPosition() {
    if (!this.service.config.listName) {
      return;
    }
    const historyScroll = this.store.get(this.service.config.listName + '-scroll');
    this.setScrollPositionToBasePanel(historyScroll);
  }

  setScrollPositionToBasePanel(historyScroll) {
    if (!historyScroll) {
      return;
    }
    let counts = 0;

    const intervalToLoadRows = setInterval(() => {
      const rowsLength = document.querySelectorAll('.datatable-row-wrapper').length; // check if all rows are already rendered in DOM
      if (counts > 40 || this.table.pageSize <= rowsLength) {
        clearInterval(intervalToLoadRows);
        this.listContainer.nativeElement.querySelector('.datatable-body').scrollTop = historyScroll;
        this.listenScroll();
      }
      counts++;
    }, 100);
  }

  listenScroll() {
    this.scrollSub = this.table.bodyComponent.scroll.pipe(debounceTime(120 /* ms */)).subscribe(() => {
      const scroll = this.table.bodyComponent.offsetY;
      this.store.put(this.service.config.listName + '-scroll', scroll);
    });
  }

  ngOnDestroy() {
    this.service.clearService();
    this.initedScrollPosition = false;
    this.service = null;
    this.config = null;
    this.activateSub ? this.activateSub.unsubscribe() : null;
    this.scrollSub.unsubscribe();
    this.serviceSub.unsubscribe();
  }
}
