import { Component, EventEmitter, OnInit } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { Project } from '@modules/projects/shared/models/project.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { ProjectLostStageReason } from '@shared/enums/project-lost-stage-reason.enum';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';

@Component({
  selector: 'project-lost-stage-modal',
  templateUrl: './project-lost-stage-modal.component.html',
})
export class ProjectLostStageModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  loading: boolean = false;
  project: Project;
  eventEmitter: EventEmitter<ProjectChangeStageEvent> = new EventEmitter<ProjectChangeStageEvent>();
  form: FormGroup = new FormGroup({
    reason: new FormControl(null),
    failureNote: new FormControl(''),
  });
  stageLostReason: { id: ProjectLostStageReason; text: string }[] = [];
  termStart?: string;
  termEnd?: string;

  constructor(
    public dialogRef: MatDialogRef<ProjectLostStageModalComponent>,
    private s: SnackBarService,
    private t: TranslateService,
    private projectStageApiService: ProjectStageAPIService,
  ) {}

  ngOnInit(): void {
    this.setLostStageReasons();
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });
  }

  setLostStageReasons() {
    Object.keys(ProjectLostStageReason).map((key) => {
      this.stageLostReason.push({
        id: ProjectLostStageReason[key],
        text: this.t.instant('Projects.FailureReason.' + ProjectLostStageReason[key]),
      });
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid || this.loading) {
      return;
    }
    this.loading = true;
    this.projectStageApiService
      .setStage(
        this.project.id,
        ProjectStage.STAGE_LOST,
        this.form.value,
        this.termStart || this.project?.basicDataBox?.termStart,
        this.termEnd || this.project?.basicDataBox?.termEnd,
      )
      .subscribe({
        next: (project: Project) => {
          this.s.success(this.t.instant('Projects.PreviewHeader.stageUpdated'));
          this.eventEmitter.emit({
            type: ProjectChangeStageEventType.CHANGED,
            project,
          });
          this.form.reset();
          this.dialogRef.close();
        },
        error: () => {
          this.s.error(this.t.instant('Projects.PreviewHeader.errorStageUpdated'));
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  cancel() {
    this.eventEmitter.emit({
      type: ProjectChangeStageEventType.CANCELED,
    });
    this.dialogRef.close();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    return messages;
  }
}

export interface ProjectChangeStageEvent {
  type: ProjectChangeStageEventType;
  project?: Project;
}

export enum ProjectChangeStageEventType {
  CHANGED,
  CANCELED,
}
