import { Component, ChangeDetectorRef, ContentChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { BaseAttachmentComponent } from '@shared/modules/attachments/components/base-attachment/base-attachment.component';
import { ButtonSize } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'text-attachments-input',
  templateUrl: './text-attachments-input.component.html',
  styleUrls: ['./text-attachments-input.component.scss']
})
export class TextAttachmentsInputComponent extends BaseAttachmentComponent {
  // Support v2 version by content passing
  @ContentChild('content') content: ElementRef;

  ButtonSize = ButtonSize;

  constructor(
    public translate: TranslateService,
    public changes: ChangeDetectorRef,
    public s: SnackBarService,
    public authFilePipe: AuthFilePipe,
  ) {
    super(translate, changes, s, authFilePipe);
  }
}
