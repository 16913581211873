<div class="contact-name" *ngIf="contact?.fullName">
  <svg-icon
    [src]="'/assets/img/ic-user.svg'"
    [svgStyle]="{ width: '16px', height: '16px' }"
    *ngIf="contact.type === ContactType.PERSONAL"
  ></svg-icon>
  <svg-icon
    [src]="'/assets/img/ic-company-tower.svg'"
    [svgStyle]="{ width: '16px', height: '16px' }"
    *ngIf="contact.type === ContactType.COMPANY"
  ></svg-icon>
  <div class="contact-name__title">
    {{ contact?.fullName }}
  </div>
</div>

<!-- <div
  class="contact-name"
  *ngIf="contact?.fullName"
  confirmationPopover
  #cp="confirmationPopover"
  (confirm)="preview()"
  (cancel)="contactInProject()"
  [cancelButtonType]="'cancel'"
  [confirmButtonType]="'primary'"
  [closeOnOutsideClick]="true"
  [hideCancelButton]="!previewPermission || contact.deleted || !isProjectProfile"
  [hideConfirmButton]="!previewPermission || contact.deleted"
  [appendToBody]="true"
  [closeOnScroll]="true"
  [customDescriptionTemplate]="popoverTemplate"
  [confirmText]="'Contacts.Manage.ContactExistsInfo.showContact' | translate"
  [cancelText]="'ProjectsList.showContactProfile' | translate"
  [popoverClass]="'popover--bigger-width no-select'"
  [placement]="placement"
  [popoverTitle]="'Contacts.Type.' + contact?.type | translate"
>
  <svg-icon
    [src]="'/assets/img/ic-user.svg'"
    [svgStyle]="{ width: '16px', height: '16px' }"
    *ngIf="contact.type === ContactType.PERSONAL"
  ></svg-icon>
  <svg-icon
    [src]="'/assets/img/ic-company-tower.svg'"
    [svgStyle]="{ width: '16px', height: '16px' }"
    *ngIf="contact.type === ContactType.COMPANY"
  ></svg-icon>
  <div class="contact-name__title">
    {{ contact?.fullName }}
  </div>
</div>

<ng-template #popoverTemplate let-options="options">
  <contact-quick-information-popover
    [contact]="contact"
    [options]="options"
    [class]="'no-select'"
  ></contact-quick-information-popover>
</ng-template> -->
