import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ChangeDetectorRef, Component } from '@angular/core';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckPermission } from '@core/permissions/check-permission';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { ECalendarAction } from '@modules/calendar/shared/enums/calendar-action.enum';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent {
  PermissionsGroups = PermissionsGroups;
  eventCtrl: EventSidenavController = new EventSidenavController();

  constructor(
    public service: EventSidenavService,
    public sanitizer: DomSanitizer,
    private changes: ChangeDetectorRef,
    private calendarService: CalendarService
  ) {}

  editOpen() {
    this.eventCtrl.openEdit(this.service.calendarEvent);
  }

  removeEvent() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        const ctrl = new EventSidenavController();
        ctrl.deleteEvent(this.service.calendarEvent?.id).subscribe({
          next: () =>
            this.calendarService.subCalendarCtrl.calendarEmmiter.emit({ action: ECalendarAction.UPDATE_VIEW })
        });
        this.changes.detectChanges();
        this.service.closeEventSidenav();
      })
      .subscribe();
  }

  isRemoveEnabled(event: CalendarEvent) {
    return new CheckPermission({
      group: PermissionsGroups.CALENDAR,
      action: 'EVENT_EDIT',
      objectCreatorId: [event?.participantCreator?.contact?.employeeId]
    }).check();
  }
}
