import { Config } from '@shared/configs/config';
import { NavigateService } from '@shared/services/navigate.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClientAcceptanceService } from '@modules/client-panel/shared/services/client-acceptance.service';
import { TaskChangeAcceptance } from '@modules/client-panel/shared/models/task-change-acceptance.interface';

@Injectable()
export class TaskChangeAcceptanceResolver implements Resolve<TaskChangeAcceptance> {
  constructor(
    private service: ClientAcceptanceService,
    private n: NavigateService,
    private s: SnackBarService,
    private t: TranslateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TaskChangeAcceptance> {
    return this.service.getTaskChangesAcceptance(route.params.token).pipe(
      map((i) => new TaskChangeAcceptance(i)),
      catchError((error) => {
        Config.DEBUG ? console.log(error) : null;
        this.s.error(this.t.instant('Projects.errorGettingAcceptanceChanges'));
        this.n.go('login');
        return of(error);
      })
    );
  }
}
