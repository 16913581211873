import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

export interface IUnsavedModalData {
  title: string;
  text: string;
  saveUnload: string;
  unload: string;
  cancel: string;
}

export enum IUnsavedStates {
  DISCARD,
  SAVE_UNLOAD,
  UNLOAD
}

@Component({
  selector: 'app-changes-unsaved-modal',
  templateUrl: './changes-unsaved-modal.component.html',
  styleUrls: ['./changes-unsaved-modal.component.scss']
})
export class ChangesUnsavedModalComponent {
  IUnsavedStates = IUnsavedStates;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUnsavedModalData,
    public dialogRef: MatDialogRef<ChangesUnsavedModalComponent>
  ) {
    !this.data.saveUnload ? (this.data.saveUnload = 'saveUnload') : null;
    !this.data.unload ? (this.data.unload = 'unload') : null;
    !this.data.cancel ? (this.data.cancel = 'cancel') : null;
  }

  cancel(action: IUnsavedStates): void {
    this.dialogRef.close(action);
  }
}
