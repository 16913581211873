import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { BaseListComponent } from '@shared/components/base-list/base-list.component';
import { ContactsListConfig } from '@modules/contacts/shared/configs/contacts-list.config';
import { ContactsFiltersComponent } from '@modules/contacts/pages/contacts-list/components/contacts-filters/contacts-filters.component';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ContactNameCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-name-cell/contact-name-cell.component';
import { ContactTypeCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-type-cell/contact-type-cell.component';
import { ContactPhoneCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-phone-cell/contact-phone-cell.component';
import { ContactEmailCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-email-cell/contact-email-cell.component';
import { ContactAvailabilityCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-availability-cell/contact-availability-cell.component';
import { ContactSubcontactsCountCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-subcontacts-count-cell/contact-subcontacts-count-cell.component';
import { ContactCreatorCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-creator-cell/contact-creator-cell.component';
import { ContactFunctionCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-function-cell/contact-function-cell.component';
import { ContactController } from '@modules/contacts/shared/controllers/contact.controller';
import { ListService } from '@shared/modules/list/services/list.service';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ContactService } from '@modules/contacts/shared/services/contact.service';
import { ListResponse } from '@shared/modules/list/model/list-response.model';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { ContactParentsCountCellComponent } from '@modules/contacts/pages/contacts-list/components/contact-parents-count-cell/contact-parents-count-cell.component';
import { CheckPermission } from '@core/permissions/check-permission';
import parsePhoneNumber from 'libphonenumber-js';
import { finalize } from 'rxjs/operators';
import { downloadExcel } from '@shared/helpers/download-file.helper';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ContactManagePanelService } from '@shared/modules/contact-sidenav/services/contact-manage-panel.service';

@Component({
  selector: 'contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListComponent extends BaseListComponent implements OnInit, OnDestroy {
  WindowHelper = WindowHelper;
  PermissionsGroups = PermissionsGroups;
  ContactsFiltersComponent = ContactsFiltersComponent;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @ViewChild('contactNameCell') public contactNameCell: ContactNameCellComponent;
  @ViewChild('contactTypeCcell') public contactTypeCcell: ContactTypeCellComponent;
  @ViewChild('contactPhoneCell') public contactPhoneCell: ContactPhoneCellComponent;
  @ViewChild('contactEmailCell') public contactEmailCell: ContactEmailCellComponent;
  @ViewChild('contactAvailabilityCell') public contactAvailabilityCell: ContactAvailabilityCellComponent;
  @ViewChild('contactParentCountCell')
  public contactParentCountCell: ContactParentsCountCellComponent;
  @ViewChild('contactSubcontactsCountCell')
  public contactSubcontactsCountCell: ContactSubcontactsCountCellComponent;
  @ViewChild('contactCreatorCell') public contactCreatorCell: ContactCreatorCellComponent;
  @ViewChild('contactFunctionCell') public contactFunctionCell: ContactFunctionCellComponent;

  constructor(
    public changes: ChangeDetectorRef,
    public listService: ListService,
    private contactService: ContactService,
    private contactPanelService: ContactManagePanelService
  ) {
    super(changes, listService);
    ContactsListConfig.treeAction.treeActionFunction = this.treeAction.bind(this);
    ContactsListConfig.treeAction.treeDisabledFunction = this.treeDisabled.bind(this);
    this.initListConfig(ContactsListConfig);
    this.listenChangeColumnsOnSearch();
    this.setOnSideNavCloseListener();
  }

  ngOnInit(): void {}

  setOnSideNavCloseListener(): void {
    this.sub.add(this.contactPanelService.dataChanged$.subscribe(() => this.listService.getRows()));
  }

  addContact() {
    const ctrl = new ContactController();
    ctrl.add();
  }

  listenChangeColumnsOnSearch() {
    this.sub.add(
      this.listService.eventEmitter.subscribe((e: ListEvent<any>) => {
        switch (e.type) {
          case ListEventType.END_GET_ROWS:
            this.setContactsRows();
            if (this.listService.getParams().search) {
              this.setParentColumn();
            } else {
              this.removeParentColumn();
            }
            break;
        }
      })
    );
  }

  private setContactsRows() {
    this.listService.rows = this.listService.rows.map((c: Contact) => {
      c = new Contact(c);

      const previewPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.CONTACTS,
        action: 'PREVIEW'
      });
      c.accessToPreview = previewPermissionCtrl.check();

      const phoneNumber = parsePhoneNumber(c.fullPhone);
      phoneNumber ? (c.fullPhone = phoneNumber.formatInternational()) : '';

      return c;
    });
  }

  private setParentColumn() {
    const isAlreadyAdded = this.config.columns.filter((c) => c.prop === 'parentContacts');
    if (!isAlreadyAdded.length) {
      this.config.columns.push({
        prop: 'parentContacts',
        name: 'Contacts.List.parentCount',
        componentRef: 'contactParentCountCell',
        sortField: 'parentCount',
        resizeable: false,
        hideOnMobile: true,
        width: 110,
        order: 6
      });
      this.configColumns();
    }
  }

  private removeParentColumn() {
    this.config.columns = this.config.columns.filter((c) => c.prop !== 'parentContacts');
    this.configColumns();
  }

  treeAction(event: { row: Contact }) {
    const row = event.row;
    if (row.treeStatus === TreeStatusEnum.COLLAPSED || !row.treeStatus) {
      this.openTree(row);
    } else {
      this.closeTree(row);
    }
  }

  openTree(row: Contact): void {
    row.treeStatus = TreeStatusEnum.LOADING;
    this.getSubconctacts(row.id, row);
  }

  closeTree(row: Contact): void {
    row.treeStatus = TreeStatusEnum.COLLAPSED;
    this.removeChildren();
  }

  removeChildren() {
    this.list.service.rows = this.list.service.rows.filter((record) => !record['mainContactId']);
  }

  private getSubconctacts(mainContactId: number, currentContact: Contact) {
    const params = {
      [`filters[parent][eq]`]: mainContactId
    };

    this.contactService.search(params).subscribe({
      next: (res: ListResponse<Contact>) => {
        this.onGetSubcontactsSuccess(res.records, currentContact);
      },
      error: () => {
        currentContact.treeStatus = TreeStatusEnum.COLLAPSED;
        this.s.error(this.t.instant('Contacts.List.errorGettingSubcontacts'));
      }
    });
  }

  activate(e: { type: string; row: Contact; column: CustomTableColumn; event }) {
    if ((e.type === 'click' || e.type === 'touchstart') && !isNotNullOrUndefined(e.row.deleted)) {
      let hasNoSelect: boolean = false;
      const path = e.event.path || (e.event.composedPath && e.event.composedPath());
      path.map((el) => {
        if (el.classList && el.classList.contains('no-select')) {
          hasNoSelect = true;
        }
      });
      if (!e.column?.toggleMenu && !hasNoSelect) {
        const ctrl = new ContactController();
        ctrl.preview(e.row.id);
      }
    }
  }

  onGetSubcontactsSuccess(records: Contact[], row: Contact): void {
    for (let i = 0; i < records.length; i++) {
      const record = records[i];
      if (record.parentCount > 1) {
        this.closeParentsWithChildren();
      }
      for (let j = 0; j < this.list.service.rows.length; j++) {
        const currentContact: Contact = this.list.service.rows[j];

        if (Number(record.id) === Number(currentContact.id)) {
          records.splice(i, 1);
          i--;
          continue;
        } else {
          record.mainContactId = row.id;
        }
      }
    }
    records = records.map((r) => new Contact(r));
    this.list.table.count += records.length;
    this.list.service.rows = [...this.list.service?.rows, ...records];
    row.treeStatus = TreeStatusEnum.EXPANDED;
    this.list.changes.detectChanges();
  }

  closeParentsWithChildren() {
    for (let j = 0; j < this.list.service.rows.length; j++) {
      const record: Contact = this.list.service.rows[j];
      if (record.childCount >= 1) {
        record.treeStatus = TreeStatusEnum.COLLAPSED;
      }
      if (record['mainContactId']) {
        this.list.service.rows.splice(j, 1);
        --j;
      }
    }
    this.list.service.rows = [...this.list.service.rows];
    this.list.changes.detectChanges();
  }

  treeDisabled(event: { row: Contact }) {
    if (event.row?.childCount > 0) {
      return false;
    }
    return true;
  }

  showToggleMenu(row: Contact) {
    return (
      !isNotNullOrUndefined(row.deleted) &&
      (this.employee.role === UserRoles.ROLE_EMPLOYEE_MANAGER ||
        this.employee.role === UserRoles.ROLE_EMPLOYEE_ADMIN)
    );
  }

  getDownloadableColumns() {
    let obj = {};
    const columns = [...this.listService.config.columns];
    columns
      .filter((i) => i.exportPath)
      .forEach((column) => {
        Object.keys(column.exportPath).forEach((key: string) => {
          obj[this.t.instant(key)] = column.exportPath[key];
        });
      });
    return obj;
  }

  downloadExcel() {
    const obj = this.getDownloadableColumns();
    this.listService.loading = true;
    this.changes.detectChanges();
    const data = Object.assign({ columns: obj });
    const params = Object.assign({}, this.listService.getParams());
    this.listService
      .postFileBlob(`${this.listService.config.urlExport}-xls`, data, false, {}, params)
      .pipe(
        finalize(() => {
          this.listService?.getRows();
          this.listService.loading = false;
          this.changes.detectChanges();
        })
      )
      .subscribe({
        next: (data) => {
          const name = this.t.instant(`${this.listService.config.listTitle}`);
          downloadExcel(`[XLS]-${name}`, data);
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    super.ngOnDestroy();
  }
}
