import { ProjectCreateGuard } from '@core/guards/projects/project-create.guard';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const ProjectCreateRoutes: CustomRoutes = [
  {
    path: ':projectid',
    canActivate: [ProjectCreateGuard],
    // canDeactivate: [CanDeactivateProjectCreation],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'contact'
      },
      {
        path: 'configurator',
        name: 'project-contact',
        data: {
          metaTitle: 'Projects.projectConfigurator',
          notRestorationPosition: true,
          customHeaderStyles: true,
          create: true
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/projects-configurator/projects-configurator.module`).then(
            (m) => m.ProjectsConfiguratorModule
          )
      }
    ]
  }
];
