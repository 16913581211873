<div class="new-modal">
  <div class="title__wrapper">
    <span class="title">{{ modalData.title | translate }}</span>
    <svg-icon
      class="clear-icon ml-2 align-self-start"
      src="/assets/img/close-icon.svg"
      (click)="onCancel()"
    ></svg-icon>
  </div>

  <div class="new-modal__content px-2">
    <p>{{ modalData.body | translate }}</p>
  </div>

  <div class="d-flex justify-content-end mt-4">
    <app-button
      (onClick)="onCancel()"
      [config]="{
        text: modalData.cancelButtonText | translate,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>

    <app-button
      [class]="'ml-2'"
      (onClick)="onSubmit()"
      [config]="{
        text: modalData.submitButtonText | translate,
        type: modalData.submitButtonType,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL,
        appendSrc: modalData.submitButtonIcon
      }"
    ></app-button>
  </div>
</div>
