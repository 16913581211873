import { phoneCountries } from '@shared/components/phone-input/components/country-picker/phone-countries';
import { Currency } from '@shared/models/currency.interface';

export class Company {
  address: string;
  businessProfile: string;
  contactEmail: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactPosition: string;
  currency: Currency;
  country: string;
  contactPhoneCountry: string;
  created: string;
  deleted: boolean;
  email: string;
  employeesCount: EmployeesCount;
  id: number;
  logo: string;
  modified: string;
  name: string;
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerPhone: string;
  ownerPhoneCountry: string;
  phone: string;
  phoneCountry: string;
  postalCode: string;
  taxNumber: string;
  euTaxNumber?: string;
  bankName?: string;
  iban?: string;
  bic?: string;
  town: string;
  website: string;
  iAmOwner: boolean;
  fullPhone?: string;
  projectCreatorToken: string;

  constructor(data?: any) {
    data ? Object.assign(this, data) : null;
    this.setFullPhone();
  }

  setFullPhone() {
    const code = phoneCountries.filter((i) => i.flagClass === this.phoneCountry)[0]?.dialCode;
    this.fullPhone = code ? `+${code}${this.phone}` : this.phone;
  }
}

export enum EmployeesCount {
  EMPLOYEES_COUNT_1 = '1-5',
  EMPLOYEES_COUNT_5 = '5-10',
  EMPLOYEES_COUNT_10 = '10-50',
  EMPLOYEES_COUNT_50 = '50-100',
  EMPLOYEES_COUNT_100 = '> 100'
}
