import { Component, OnInit } from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { CommentaryEventType } from '@shared/modules/commentary/enums/commentary-event-type.enum';
import { CommentaryType } from '@shared/modules/commentary/enums/commentary-type.enum';
import { switchMap, take } from 'rxjs/operators';
import { ContactServiceV2 } from '../../services/contact-v2.service';
import { ContactManagePanelService } from '../../services/contact-manage-panel.service';

@Component({
  templateUrl: './contact-commentaries.component.html',
  styleUrls: ['./contact-commentaries.component.scss']
})
export class ContactCommentariesComponent implements OnInit {
  readonly CommentaryType = CommentaryType;
  contact: Contact;

  constructor(
    private managePanelService: ContactManagePanelService,
    private contactService: ContactServiceV2
  ) {}

  ngOnInit(): void {
    this.managePanelService.contactData$
      .pipe(
        take(1),
        switchMap(({ id }) => this.contactService.getContact(id))
      )
      .subscribe((contact) => (this.contact = new Contact(contact)));
  }

  updateCommentaries(e: { type: CommentaryEventType; data: any }) {
    switch (e.type) {
      case CommentaryEventType.ADD:
      case CommentaryEventType.EDIT:
      case CommentaryEventType.REMOVE:
        this.contact.comments = e.data.list;
        break;
      default:
        break;
    }
  }
}
