import { tap } from 'rxjs/operators';
import { HttpError } from '@shared/interfaces/error.interface';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { SnackBarService } from '@core/services/snackbar.service';
import { ETaskAction } from '@shared/modules/task-sidenav/enums/task-action.enum';

export enum ECHANGE_ACCEPTANCE_FIELDS {
  comment = 'comment',
  accepted = 'accepted',
}

@Component({
  selector: 'task-sidenav-change-acceptance',
  templateUrl: './task-sidenav-change-acceptance.component.html',
  styleUrls: ['./task-sidenav-change-acceptance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSidenavChangeAcceptanceComponent implements OnInit {
  ECHANGE_ACCEPTANCE_FIELDS = ECHANGE_ACCEPTANCE_FIELDS;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  loading: boolean = false;
  form: FormGroup;

  get initialFormValues() {
    return {
      [ECHANGE_ACCEPTANCE_FIELDS.comment]: null,
      [ECHANGE_ACCEPTANCE_FIELDS.accepted]: false,
    };
  }

  constructor(
    public service: TaskSidenavService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private changes: ChangeDetectorRef,
    private t: TranslateService,
    private s: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group(this.initialFormValues);
    this.changes.detectChanges();
  }

  getStatus(accepted: boolean): ProjectChangesStatus {
    return accepted
      ? ProjectChangesStatus.CHANGE_STATUS_ACCEPTED
      : ProjectChangesStatus.CHANGE_STATUS_REJECTED;
  }

  submitTask(accepted: boolean) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.form.get(ECHANGE_ACCEPTANCE_FIELDS.accepted).setValue(accepted);
    const values = Object.assign({}, this.form.value);
    this.changes.detectChanges();
    this.taskService
      .acceptChange(this.service.task.id, values)
      .pipe(tap(() => this.updateTaskModel()))
      .subscribe({
        next: (res) => this.successAcceptingTask(res, values),
        error: (err) => this.errorAcceptingTask(err, values),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successAcceptingTask(task, values) {
    this.form.setValue(this.initialFormValues);
    const status = this.getStatus(values[ECHANGE_ACCEPTANCE_FIELDS.accepted]);
    this.service.taskActionEmitter.emit({ action: ETaskAction.ACTION_UPDATE, data: { task } });
    this.s.success(this.t.instant(`ProjectChanges.AcceptingSuccess.${status}`));
    this.loading = false;
    this.changes.detectChanges();
  }

  errorAcceptingTask(err: HttpError, values) {
    const status = this.getStatus(values[ECHANGE_ACCEPTANCE_FIELDS.accepted]);
    this.s.error(this.t.instant(`ProjectChanges.AcceptingError.${status}`));
    this.loading = false;
    this.changes.detectChanges();
  }

  updateTaskModel() {
    this.service
      .getSidenavTask({ taskId: this.service.task.id })
      .subscribe({
        next: () => {
          this.service.loadingTask = false;
        },
      })
      .add(() => this.changes.detectChanges());
  }
}
