import { Component, Input } from '@angular/core';

@Component({
  selector: 'content-title-v2',
  templateUrl: './content-title-v2.component.html'
})
export class ContentTitleV2Component {
  _title: string;
  _subtitle: string;

  @Input()
  set title(title: string) {
    this._title = title;
  }

  @Input()
  set subtitle(subtitle: string) {
    this._subtitle = subtitle;
  }
}
