<div class="task-checkbox">
  <p-checkbox
    *ngIf="task?.status !== TaskStatus.COMPLETED && task?.shouldShowCompleteSubtasksPopover"
    shape="round"
    color="info"
    confirmationPopover
    #cp="confirmationPopover"
    #prettyCheckboxComponent
    [disabled]="disabled"
    [placement]="placement"
    [appendToBody]="true"
    [closeOnScroll]="true"
    [confirmButtonType]="'primary'"
    [cancelText]="'ProjectTasks.Task.cancelCompletedSubtasks' | translate"
    [confirmText]="'ProjectTasks.Task.confirmCompletedSubtasks' | translate"
    (confirm)="setCompletedForSubtasks()"
    (cancel)="prettyCheckboxComponent.checked = true; onStatusChange({ value: true, checked: true })"
    [popoverMessage]="'ProjectTasks.Task.doYouWantToCompletedSubtasks' | translate"
    [popoverTitle]="'ProjectTasks.Task.doYouWantToCompletedSubtasksTitle' | translate"
    (click)="onCheckboxWithSubtasksClick($event)"
  >
    <i p-icon class="material-icons">done</i>
  </p-checkbox>
  <!-- (task?.status !== TaskStatus.COMPLETED && !task?.shouldShowCompleteSubtasksPopover) -->
  <p-checkbox
    *ngIf="
      (task?.status !== TaskStatus.COMPLETED && !task?.shouldShowCompleteSubtasksPopover) ||
      task?.status === TaskStatus.COMPLETED
    "
    shape="round"
    color="info"
    [disabled]="disabled"
    #prettyCheckboxComponent
    [checked]="task?.status === TaskStatus.COMPLETED"
    (change)="onStatusChange($event)"
  >
    <i p-icon class="material-icons">done</i>
  </p-checkbox>
</div>
