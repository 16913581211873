import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Task } from '@shared/models/task.model';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { TaskType } from '@shared/enums/task-type.enum';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService
} from '@shared/modules/list/services/list-task.service';
import { TaskController } from '@shared/controllers/task.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { ProjectPreviewService } from '@project-modules/project-preview/services/project-preview.service';
import { NgxPrettyCheckboxComponent } from 'ngx-pretty-checkbox';
import { ConfirmationPopoverDirective } from '@shared/libs/angular-confirmation-directive/confirmation-popover.directive';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { TaskCheckboxComponent } from '@shared/components/task-checkbox/task-checkbox.component';
import { StorageService } from '@core/services/storage.service';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TaskPermissionController } from '@shared/controllers/task-permission.controller';
import { publicFile } from '@shared/helpers/public-file.helper';
import { Employee } from '@shared/models/employee.model';

@Component({
  selector: 'list-tasks-single-task-v2',
  templateUrl: './list-tasks-single-task-v2.component.html',
  styleUrls: ['./list-tasks-single-task-v2.component.scss'],
  host: { class: 'list-tasks-single-task-wrapper' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListTasksSingleTaskV2Component implements OnInit, AfterViewInit, OnDestroy {
  TaskType = TaskType;
  TaskStatus = TaskStatus;
  PermissionsGroups = PermissionsGroups;
  moment = moment;
  Config = Config;
  static readonly DELAY_AFTER_TASK_COMPLETED = 2 * 1000; //(4s)

  taskSidenavController: TaskSidenavController = new TaskSidenavController();
  objectCreatorIds: number[] = [];
  initEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  parentId: number;
  isActive: boolean = false;
  sub: Subscription = new Subscription();
  taskPermission: TaskPermissionController = new TaskPermissionController();

  @Input() disabled: boolean = false;
  @Input() task: Task;
  @Input() taskId: string | number;

  @Input() parentTask: Task;
  @Input() parentTaskComponent: ListTasksSingleTaskV2Component;
  @Input() dataFromHashmap: boolean;
  @Output('onChangeParentTask') onChangeParentTask: EventEmitter<Task> = new EventEmitter<Task>();
  @ViewChild('prettyCheckboxComponent') prettyCheckboxComponent: NgxPrettyCheckboxComponent;
  @ViewChild('taskCheckboxComponent') taskCheckboxComponent: TaskCheckboxComponent;
  @ViewChild('host') host: ElementRef;
  @ViewChild('cp') cp: ConfirmationPopoverDirective;
  @ViewChildren('subtasksComponents') subtasksComponents: QueryList<ListTasksSingleTaskV2Component>;

  constructor(
    public changes: ChangeDetectorRef,
    public listTaskService: ListTaskService,
    public projectPreviewService: ProjectPreviewService,
    private ngZone: NgZone,
    public elRef: ElementRef,
    public renderer: Renderer2,
    public storage: StorageService,
    public taskSidenav: TaskSidenavService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setObjectCreatorIds();
    this.listenUpdateTask();
    this.setTask();
    this.listenChangeRoute();
    this.initPermissions();
  }

  ngAfterViewInit() {
    this.initEmitter.emit(true);
  }

  initPermissions() {
    this.taskPermission.initPermissions(this.task);
    this.changes.detectChanges();
  }

  listenChangeRoute() {
    this.checkModalDataAndActiveTask();
    this.sub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.checkModalDataAndActiveTask();
    });
  }

  checkModalDataAndActiveTask() {
    if (this.route.snapshot?.queryParams?.modalData) {
      const modalData = JSON.parse(this.route.snapshot.queryParams.modalData);
      if (modalData.taskId === this.task?.taskId || modalData.taskId == this.task?.id) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    } else {
      this.isActive = false;
    }
    this.changes.detectChanges();
  }

  manuallySetTask(task: Task) {
    this.taskId = task?.id;
    this.task = task;
  }

  setTask() {
    if (!this.dataFromHashmap) {
      return;
    }

    this.task = this.listTaskService.getTask(this.taskId);
    this.changes.detectChanges();
  }

  setObjectCreatorIds() {
    if (!this.task) {
      this.objectCreatorIds = [];
      return;
    }
    const ids = this.task.assignedTo.map((u: EmployeeSearchItem) => u?.id);
    this.objectCreatorIds = [this.task?.creator?.id].concat(ids);
  }

  identify(index, item: Task) {
    return item.id;
  }

  listenUpdateTask() {
    this.listTaskService.emitter.subscribe((e: ListTaskEvent) => {
      switch (e.type) {
        case ListTaskEventType.REFRESH_LIST_GROUPS:
          this.setTask();
          this.updateChangesInSubtasks();
          break;
      }
    });
  }

  getTaskColor() {
    switch (this.task?.status) {
      case TaskStatus.CANCELLED:
        return 'dark-grey';
      case TaskStatus.COMPLETED:
        return 'green';
      case TaskStatus.CREATED:
        return 'orange';
    }
  }

  getProject(task: Task) {
    return new Project(task.project);
  }

  previewProject(project: Project) {
    const ctrl = new ProjectController(project);
    ctrl.preview(project);
  }

  previewTask() {
    this.ngZone.run(() => {
      //Everyone in project can preview task
      this.taskSidenavController.previewTask(this.task?.taskId || this.task?.id?.toString());
    });
  }

  updateTask(task: Task) {
    this.task = new Task(task);
    const ctrl = new TaskController(this.task);
    ctrl.changeTaskStatus(this.task).subscribe(() => {
      this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_COUNTERS });
    });
    this.updateChildrenInParentTask();
    this.listTaskService.emitter.emit({ type: ListTaskEventType.UPDATE_TASK, data: this.task });
    this.changes.detectChanges();
  }

  updateChildrenInParentTask() {
    if (this.parentTaskComponent) {
      const task = this.parentTaskComponent.task;
      task.children = task.children.map((t: Task) => {
        if (t.id === this.task.id) {
          t = this.task;
        }
        return t;
      });
      this.parentTaskComponent.task = new Task(task);
      this.listTaskService.emitter.emit({
        type: ListTaskEventType.UPDATE_TASK,
        data: this.parentTaskComponent.task
      });
    } else if (this.parentTask) {
      this.parentTask.children = this.parentTask.children.map((t: Task) => {
        if (t.id === this.task.id) {
          t = this.task;
        }
        return t;
      });
      this.onChangeParentTask.emit(this.parentTask);
    }
  }

  checkedSubtasks(checked: boolean) {
    this.subtasksComponents.toArray().map((subtaskComponent: ListTasksSingleTaskV2Component) => {
      subtaskComponent.task.status = checked ? TaskStatus.COMPLETED : TaskStatus.CREATED;
      subtaskComponent.taskCheckboxComponent.prettyCheckboxComponent.checked = checked;
      subtaskComponent.checkedSubtasks(checked);
      subtaskComponent.changes.detectChanges();
    });
  }

  private updateChangesInSubtasks() {
    this.subtasksComponents.toArray().map((subtaskComponent: ListTasksSingleTaskV2Component) => {
      subtaskComponent.changes.detectChanges();
    });
  }

  toggleSubtasks() {
    this.task.openedSubtasks = !this.task.openedSubtasks;
    this.listTaskService.updateTask(this.task);
    this.changes.detectChanges();
  }

  getPhoto(employee: Employee) {
    return employee?.userPerson?.photo ? publicFile(employee.userPerson.photo) : null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
