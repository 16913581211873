import { tap, finalize, switchMap } from 'rxjs/operators';
import { ProjectBoxSecondInviteModalComponent } from './../components/project-box-second-invite-modal/project-box-second-invite-modal.component';
import { StickyFooterEventType, StickyFooterService } from '@shared/services/sticky-footer.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { Injector } from '@angular/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { NavigateService } from '@shared/services/navigate.service';
import { BasicDataBox } from '../models/project-basic-data-box.model';
import { ProjectAPIService } from '../services/project-api.service';
import { Permission } from '@core/permissions/decorators/permissions.decorator';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ContactAddToProjectComponent } from '@modules/contacts/shared/components/contact-add-to-project/contact-add-to-project.component';
import { Config } from '@shared/configs/config';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, throwError } from 'rxjs';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectAlertsModalComponent } from '@modules/projects/modules/project-summary/shared/components/project-alerts-modal/project-alerts-modal.component';
import { ProjectMainContactModalComponent } from '@modules/projects/shared/components/project-main-contact-modal/project-main-contact-modal.component';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import {
  ProjectChangeStageEvent,
  ProjectChangeStageEventType,
  ProjectLostStageModalComponent,
} from '@modules/projects/shared/components/project-lost-stage-modal/project-lost-stage-modal.component';
import { ProjectResponsibleCompanyModalComponent } from '@modules/projects/shared/components/project-responsible-company-modal/project-responsible-company-modal.component';
import { ProjectRemoveModalComponent } from '@modules/projects/shared/components/project-remove-modal/project-remove-modal.component';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';
import { StorageService } from '@core/services/storage.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { WindowHelper } from '@shared/helpers/window.helper';

export class ProjectController {
  loading: boolean = false;

  injector: Injector;
  n: NavigateService;
  s: SnackBarService;
  t: TranslateService;
  store: StorageService;
  dialog: MatDialog;
  projectAPI: ProjectAPIService;
  footerService: StickyFooterService;
  projectStageApiService: ProjectStageAPIService;
  project: Project;
  contact: Contact;

  constructor(project?: Project, contact?: Contact) {
    this.injector = AppInjector.getInjector();
    this.n = this.injector.get(NavigateService);
    this.s = this.injector.get(SnackBarService);
    this.t = this.injector.get(TranslateService);
    this.store = this.injector.get(StorageService);
    this.footerService = this.injector.get(StickyFooterService);
    this.dialog = this.injector.get(MatDialog);
    this.projectAPI = this.injector.get(ProjectAPIService);
    this.projectStageApiService = this.injector.get(ProjectStageAPIService);
    this.setProject(project);
    this.contact = new Contact(contact);
  }

  setProject(project) {
    this.project = new Project(project);
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'ADD',
  })
  add(initContactId?: number) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let queryParams = {};
    initContactId ? (queryParams['contact'] = initContactId) : null;
    return this.projectAPI
      .createProjectDraft()
      .subscribe({
        next: (project: Project) => {
          let isSamePage = !!(window.location.pathname.indexOf('panel/projects/create') > -1);
          this.n
            .go(
              'project-contact',
              {
                projectid: project.id,
              },
              { queryParams },
            )
            .then(() => {
              if (!!isSamePage) {
                window.location.reload();
              }
            });
        },
        error: () => {
          this.s.error(this.t.instant('Projects.errorCreatingDraft'));
        },
      })
      .add(() => (this.loading = false));
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  setProjectBasicDataBox(
    id: number,
    basicData: BasicDataBox,
    activate: boolean = true,
    isEditing: boolean = false,
    callback?: () => void,
  ) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.projectAPI
      .editProjectDataBox(id, basicData)
      .subscribe({
        next: (project: Project) => this.successEditProjectDataBox(project, activate, isEditing, callback),
      })
      .add(() => {
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.loading = false;
      });
  }

  successEditProjectDataBox(
    project: Project,
    activate: boolean,
    isEditing: boolean = false,
    callback?: () => void,
  ) {
    this.project = new Project(project);
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'EDIT',
      objectCreatorId: [project?.basicDataBox?.responsibleEmployee?.id],
    });
    callback ? callback() : null;
    if (ctrl.check()) {
      if (isEditing) {
        setTimeout(() => this.s.success(this.t.instant('Projects.projectBox1Updated')), callback ? 1000 : 0);
      } else {
        activate ? this.activateProject(project) : null;
      }
    } else {
      this.n.go('projects-list');
    }
  }

  initInviteModal(project: Project) {
    project = new Project(project);
    const responsible = project?.basicDataBox?.contacts?.filter((i) => !!i.isResponsible)[0] || null;
    const contact =
      project?.MainContact || responsible?.contact
        ? new Contact(project?.MainContact || responsible?.contact)
        : null;

    const ref = this.dialog.open(ProjectBoxSecondInviteModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: {
        project,
        contact,
      },
    });
    return ref;
  }

  openInvitationModal(project: Project) {
    const ref = this.initInviteModal(project);
    let sub: Subscription;
    ref.afterOpened().subscribe(() => {
      sub = ref.componentInstance.continueProcessEmitter.subscribe(() => {
        this.n.go('project-preview-summary', { projectId: project.id });
      });
    });
    ref.afterClosed().subscribe(() => sub?.unsubscribe());
  }

  activateProject(project: Project) {
    this.loading = true;
    this.projectAPI
      .activateProject(project.id)
      .pipe(tap(() => this.s.success(this.t.instant('Projects.projectCreated'))))
      .pipe(finalize(() => (this.loading = false)))
      .pipe(
        switchMap(() => {
          this.loading = true;
          return this.withoutSharing(project).pipe(finalize(() => (this.loading = false)));
        }),
      )
      .subscribe();
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) =>
      self?.project?.basicDataBox?.responsibleEmployee?.id || self?.contact?.creator?.id,
  })
  addContactToProject(
    contact: Contact,
  ): Observable<{ project: Project; accessContactId: number; hasAccess: boolean }> {
    const dialog = this.dialog.open(ContactAddToProjectComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
    });
    dialog.componentInstance.contact = contact;
    return dialog.componentInstance.onAddAsContactToProject;
  }

  //Anyone has access to project!!!
  preview(project: Project): Promise<boolean> {
    if (project.deleted) {
      return;
    }
    return this.n.goWithPromise('project-preview', { projectId: project.id });
  }

  previewTasks(project: Project): Promise<boolean> {
    if (project.deleted) {
      return;
    }
    return this.n.goWithPromise('project-preview-tasks', { projectId: project.id });
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'PREVIEW',
    objectCreatorIdMethod: (self) => [
      self?.project?.basicDataBox?.responsibleEmployee?.id,
      self?.project?.MainContact?.employeeId,
    ],
  })
  prewiewChanges(project: Project) {
    if (project.deleted) {
      return;
    }
    return this.n.goWithPromise('project-preview-changes', { projectId: project.id });
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'ALERTS',
  })
  openAlerts(project: Project): Observable<Project> {
    const dialog = this.dialog.open(ProjectAlertsModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
    });
    dialog.componentInstance.project = project;
    return dialog.componentInstance.eventEmitter;
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  setMainContact(project: Project) {
    const dialog = this.dialog.open(ProjectMainContactModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
    });
    dialog.componentInstance.project = project;
    return dialog.componentInstance.eventEmitter;
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  removeContactFromProject(contactId: number, projectId: number) {
    return this.projectAPI.removeContactFromProjectWithoutAccessContact(contactId, projectId);
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  setSpecification(project: Project, specification: File) {
    return new Observable((observer) =>
      this.projectAPI.setSpecification(project.id, specification).subscribe({
        next: (res: Project) => {
          this.s.success(this.t.instant('ProjectSummary.RightSection.successAddSpecification'));
          observer.next(res);
          observer.complete();
        },
        error: () => {
          this.s.error(this.t.instant('ProjectSummary.RightSection.errorAddSpecification'));
        },
      }),
    );
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  setResponsibleCompany(project: Project) {
    const dialog = this.dialog.open(ProjectResponsibleCompanyModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
      data: { project },
    });
    dialog.componentInstance.project = project;
    return dialog.componentInstance.eventEmitter;
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'PREVIEW_EXISTING_PROJECT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  edit(project: Project) {
    this.n.go('project-configurator', { projectId: project.id }).then(() => {
      if (!WindowHelper.isMobileWidth) return;
      setTimeout(() => {
        const basePanel = document.getElementsByClassName('base-panel')[0];
        basePanel.classList.add('base-panel--no-smooth');
        document.querySelector('.project-panel-preview__content').scrollIntoView();
        basePanel.classList.remove('base-panel--no-smooth');
      }, 100);
    });
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'PROJECT_BOX_SECOND_EDIT',
  })
  editSecondBox(project: Project) {
    this.n.go('question-directory-edit-second', { projectid: project.id });
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'REMOVE',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  removeAction(project: Project): Observable<any> {
    return new Observable((observer) =>
      this.projectAPI.removeProject(project.id).subscribe({
        next: (res) => {
          this.s.success(this.t.instant('Projects.RemoveModal.successRemoveProject'));
          this.n.go('projects-list');
          observer.next(res);
          observer.complete();
        },
        error: () => {
          this.s.error(this.t.instant('Projects.RemoveModal.errorRemoveProject'));
        },
      }),
    );
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'REMOVE',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  remove(project: Project): Observable<boolean> {
    const dialog = this.dialog.open(ProjectRemoveModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: false,
    });
    dialog.componentInstance.project = new Project(project);
    return dialog.componentInstance.eventEmitter;
  }

  @Permission({
    group: PermissionsGroups.PROJECTS,
    action: 'EDIT',
    objectCreatorIdMethod: (self) => self?.project?.basicDataBox?.responsibleEmployee?.id,
  })
  setStage(
    project: Project,
    newStage: ProjectStage,
    startDate: string,
    endDate: string,
  ): Observable<ProjectChangeStageEvent> {
    if (project.stage !== ProjectStage.STAGE_CREATOR && newStage === ProjectStage.STAGE_CREATOR) {
      this.s.error(this.t.instant('Projects.PreviewHeader.errorStageStart'));
      return throwError(this.t.instant('Projects.PreviewHeader.errorStageStart'));
    }

    if (project.stage === ProjectStage.STAGE_CREATOR && newStage !== ProjectStage.STAGE_CREATOR) {
      if (!project?.name) {
        this.s.error(this.t.instant('Projects.PreviewHeader.errorStageUpdatedName'));
        return throwError(this.t.instant('Projects.PreviewHeader.errorStageUpdatedName'));
      }
    }

    if (newStage === ProjectStage.STAGE_LOST && project.stage !== ProjectStage.STAGE_LOST) {
      return this.openLostStageDialog(project, startDate, endDate);
    }
    // else {
    // if (
    //   this.isWonOrBeforeRealizationStage(project, newStage) &&
    //   !project.basicDataBox.termStart &&
    //   !project.basicDataBox.termEnd &&
    //   !startDate &&
    //   !endDate
    // ) {
    //   this.openStartEndTermDialog(project, newStage);
    // }
    return this.changeState(project, startDate, endDate, newStage);
    // }
  }

  // private isWonOrBeforeRealizationStage(project: Project, newStage: ProjectStage) {
  //   return (
  //     (newStage === ProjectStage.STAGE_WON && project.stage !== ProjectStage.STAGE_WON) ||
  //     (newStage === ProjectStage.STAGE_BEFORE_REALIZATION &&
  //       project.stage !== ProjectStage.STAGE_BEFORE_REALIZATION)
  //   );
  // }

  private changeState(project, startDate, endDate, newStage): Observable<ProjectChangeStageEvent> {
    return new Observable((observer) =>
      this.projectStageApiService
        .setStage(project.id, newStage, this.getReason(project, newStage), startDate, endDate)
        .subscribe({
          next: (res: Project) => {
            // this.openInfoDialogAboutCreatingAccount(new Project(res));
            // this.openAddEvent(project, newStage);

            observer.next({
              type: ProjectChangeStageEventType.CHANGED,
              project: res,
            });
            observer.complete();
          },
          error: (err: HttpError) => {
            let msg = 'Projects.PreviewHeader.errorStageUpdated';
            switch (err.messageCode) {
              case IntilioCodes.PROJECT_STAGE_ERROR:
                msg = 'Projects.PreviewHeader.errorStageUpdatedName';
                break;
              default:
                break;
            }

            this.s.error(this.t.instant(msg));
          },
        }),
    );
  }

  // private openAddEvent(project: Project, newStage: ProjectStage) {
  //   if (
  //     (project.stage !== ProjectStage.STAGE_MEETING && newStage === ProjectStage.STAGE_MEETING) ||
  //     (project.stage !== ProjectStage.STAGE_COMPLAINT && newStage === ProjectStage.STAGE_COMPLAINT)
  //   ) {
  //     const ctrl = new EventSidenavController();
  //     const companyAndProjectData = `${this.store.Company.name}${
  //       project.clientName ? ' | ' + project.clientName : ''
  //     }`;

  //     ctrl.createEvent({
  //       projectId: project.id,
  //       title: this.t.instant('Projects.meetingWith', { companyName: companyAndProjectData }),
  //     });
  //   }
  // }

  // private openStartEndTermDialog(project: Project, newStage: ProjectStage) {
  //   const dialog = this.dialog.open(ProjectStarEndTermModalComponent, {
  //     width: '350px',
  //     autoFocus: false,
  //   });
  //   dialog.componentInstance.project = project;
  //   dialog.componentInstance.newStage = newStage;
  // }

  private openLostStageDialog(project, startDate, endDate) {
    const dialog = this.dialog.open(ProjectLostStageModalComponent, {
      width: '670px',
      autoFocus: false,
    });
    dialog.componentInstance.termStart = startDate;
    dialog.componentInstance.termEnd = endDate;
    dialog.componentInstance.project = project;
    return dialog.componentInstance.eventEmitter;
  }

  // private openInfoDialogAboutCreatingAccount(project: Project) {
  //   if (project.accountCreated) {
  //     const dialog = this.dialog.open(ProjectInfoCreateAccountModalComponent, {
  //       width: Config.DEFAULT_MODAL_WIDTH,
  //       autoFocus: false,
  //     });
  //     dialog.componentInstance.project = new Project(project);
  //   }
  // }

  private getReason(project: Project, newStage: ProjectStage) {
    if (project.stage === ProjectStage.STAGE_LOST && newStage === ProjectStage.STAGE_LOST) {
      return {
        failureNote: project.failureNote,
        reason: project.failureReason,
      };
    } else {
      return null;
    }
  }

  withoutSharing(project) {
    return this.projectAPI
      .projectWithoutShare(project.id)
      .pipe(tap(() => this.n.go('project-preview-summary', { projectId: project.id })));
  }
}
