import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { NotificationsStatus } from '../interfaces/notifications-status.interface';
import { NotificationChangeEventType, NotificationsApiService } from './notifications-api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends BaseHttpService {
  private _unreadCount: number = 0;
  private notificationsInterval = null;

  get unreadNotifications() {
    return this._unreadCount <= 99 ? this._unreadCount : '+99';
  }

  set unreadCount(count: number) {
    this._unreadCount = count;
  }

  constructor(private notificationsApiService: NotificationsApiService) {
    super();
    this.startNotificationsInterval();
    this.listenChangeCounter();
  }

  open() {
    this.sidenav.open(NotificationsComponent);
  }

  close() {
    this.sidenav.close();
  }

  private listenChangeCounter() {
    this.notificationsApiService.changeEmitter.subscribe((event: { type: NotificationChangeEventType }) => {
      switch (event.type) {
        case NotificationChangeEventType.DECREASE_COUNTER:
          this.unreadCount = this._unreadCount - 1 >= 0 ? this._unreadCount - 1 : this._unreadCount;
          break;
        case NotificationChangeEventType.INCREASE_COUNTER:
          this.unreadCount = this._unreadCount + 1;
          break;
        case NotificationChangeEventType.CLEAR_COUNTER:
          this.unreadCount = 0;
          break;
      }
    });
  }

  startNotificationsInterval() {
    if (!this.store?.Employee?.id) return;
    this.getNotificationsStatus();
    this.stopInterval();
    this.notificationsInterval = setInterval(this.getNotificationsStatus.bind(this), 20000); //20s
  }

  stopInterval() {
    this.notificationsInterval ? clearInterval(this.notificationsInterval) : null;
    this.notificationsInterval = null;
  }

  private getNotificationsStatus() {
    if (!this.store.jwt || !this.store.UserPerson) {
      return this.stopInterval();
    }

    this.notificationsApiService.getNotificationsStatus().subscribe({
      next: (res: NotificationsStatus) => {
        if (this._unreadCount !== res.unread) {
          this.notificationsApiService.changeEmitter.emit({
            type: NotificationChangeEventType.LOAD_NEW_NOTIFICATIONS
          });
        }

        this.unreadCount = res.unread;
      },
      error: (e) => {
        this.unreadCount = 0;
      }
    });
  }
}
