<div class="settings settings-content">
  <div class="team-content mx-n2">
    <ng-container *ngFor="let employee of employees">
      <app-team-card
        class="col-12 col-md-6 col-xl-3 px-2 mb-3"
        [role]="'ROLES.' + employee.role | translate"
        [active]="employee.active"
        [previewName]="employee.userPerson.previewName"
        [salary]="employee?.hourlyRate ? employee.hourlyRate + companyCurrency : '-'"
        [address]="employee.userPerson.email"
        (emitAction)="handleAction($event, employee)"
      ></app-team-card>
    </ng-container>
  </div>
</div>
