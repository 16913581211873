import { Injector, Pipe, PipeTransform } from '@angular/core';
import { Config } from '@shared/configs/config';
import { Currency } from '@shared/models/currency.interface';
import { AppInjector } from '@shared/services/app-injector.service';
import { StorageService } from '@core/services/storage.service';

@Pipe({ name: 'price', pure: true })
export class PricePipe implements PipeTransform {
  injector: Injector;
  storage: StorageService;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.storage = this.injector.get(StorageService);
  }

  transform(value: number, withoutSymbol: boolean = false, currency?: Currency): string | number {
    const val = value !== 0 ? (value * 10) / 1000 : value;
    if (withoutSymbol) {
      return numberWithSpaces(val);
    } else {
      !currency ? (currency = Config?.currency) : '';
      const num = val;
      const symbol = currency?.symbol ? currency?.symbol : '';
      return currency?.leftSide ? `${symbol} ${numberWithSpaces(num)}` : `${numberWithSpaces(num)} ${symbol}`;
    }
  }

  insertValueToInput(value: number, withoutSymbol: boolean = false, currency?: Currency) {
    return commaToDotHelper(this.transform(value, withoutSymbol, currency))?.replace(/\s/g, '');
  }
}

export const commaToDotHelper = (value) => {
  const v = value?.toString().replace(/\,/g, '.');
  return v?.length === 1 && v.charAt(0) === '.' ? 0 : v;
};

export function numberWithSpaces(x) {
  var parts = parseFloat(Number(commaToDotHelper(x)?.replace(/\s/g, '')).toString())
    .toFixed(2)
    .toString()
    .split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
}
