import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  template: '<div>Middleware token: <b>{{active.snapshot.params.token}}</b></div>'
})
export class ProjectCreatorMiddleware implements OnInit {
  constructor(public active: ActivatedRoute) {
    console.log(this.active);
  }

  ngOnInit(): void {}
}
