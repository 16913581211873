import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { NavigateService } from '@shared/services/navigate.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Config } from '@shared/configs/config';

@Injectable({ providedIn: 'root' })
export class ContactPreviewGuard implements CanActivate {
  constructor(private n: NavigateService) {}

  canActivate() {
    const ctrl = new CheckPermission({ group: PermissionsGroups.CONTACTS, action: 'PREVIEW' });
    if (ctrl.check()) {
      return true;
    } else {
      this.n.go(Config.MAIN_VIEW);
      return false;
    }
  }
}
