import { NgModule } from '@angular/core';
import { InputV2Component } from './components/input-v2/input-v2.component';
import { TextareaV2Component } from './components/textarea-v2/textarea-v2.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const FORMS_COMPONENTS = [InputV2Component, TextareaV2Component];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [FORMS_COMPONENTS],
  exports: [FORMS_COMPONENTS]
})
export class FormsV2Module {}
