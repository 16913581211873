<div class="commentary">
  <div class="commentary__icon">
    <avatar [width]="42" [imageUrl]="getPhoto(commentary.creator)"></avatar>
  </div>
  <div class="commentary__data">
    <div class="commentary__data__name elipsis">{{ commentary.creator?.userPerson?.previewName }}</div>
    <div class="commentary__data__description">
      {{ commentary.content }}
    </div>

    <ul *ngIf="commentary?.imgAttachments?.length" class="commentary__data__attachments">
      <li
        *ngFor="let attachment of commentary?.imgAttachments; let i = index; trackBy: trackAttachment"
        [ngClass]="{ 'commentary__data__attachments--hidden': i >= maxImagesToShow }"
      >
        <attachment-image
          [hidden]="i >= maxImagesToShow"
          [attachment]="attachment"
          [fancyboxUrl]="'comment-gallery-' + commentary.created + commentary.id"
        ></attachment-image>
      </li>
      <li *ngIf="commentary?.imgAttachments?.length > maxImagesToShow">
        <attachment-image-more [fancyboxUrl]="'comment-gallery-' + commentary.created + commentary.id">
        </attachment-image-more>
      </li>
    </ul>

    <div class="mb-2" *ngIf="commentary?.fileAttachments?.length">
      <li *ngFor="let file of commentary?.fileAttachments; trackBy: trackAttachment">
        <attachment-file [file]="file" [edit]="true"></attachment-file>
      </li>
    </div>

    <div class="commentary__data__actions">
      <span class="commentary__data__actions__date">{{ commentary.created | dateTimeFormat }}</span>
      <span class="commentary__data__actions__buttons">
        <span
          [permission]="{
            group: PermissionsGroups.COMMENTARIES,
            action: 'EDIT',
            objectCreatorId: [commentary?.creator?.id]
          }"
          class="commentary__data__actions__button"
          [title]="'Commentaries.edit' | translate"
          (click)="edit()"
        >
          {{ 'Commentaries.edit' | translate }}
        </span>
        <span
          [permission]="{
            group: PermissionsGroups.COMMENTARIES,
            action: 'REMOVE',
            objectCreatorId: [commentary?.creator?.id]
          }"
          (click)="remove()"
          [title]="'Commentaries.remove' | translate"
          class="commentary__data__actions__button commentary__data__actions__button__delete"
        >
          {{ 'Commentaries.remove' | translate }}
        </span>
      </span>
    </div>
  </div>
</div>
