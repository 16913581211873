import { BaseTaskModel } from './base-task.model';
import { Commentary } from './../modules/commentary/models/commentary.model';
import { PermissionDecorator } from '@core/permissions/interfaces/permission-decorator.interface';
import { TaskType } from '@shared/enums/task-type.enum';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { EmployeeSearchItem } from './employee-search-item.model';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { Employee } from './employee.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { TaskGroup } from '@shared/models/task-group.model';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';
import { ProjectChangesStatus } from '@modules/projects/shared/enums/project-changes-status.enum';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Config } from '@shared/configs/config';
import * as moment from 'moment';
import { sortListByGroup } from '@shared/helpers/list-sort.helper';
import { sortTasks } from '@shared/helpers/sort-tasks.helper';

export class Task extends BaseTaskModel {
  settlementStatus?: any;
  internalNote?: string;
  assignment?: any;
  discount?: any;
  realization?: any;
  showMoreImages?: boolean;
  filesCount?: number;
  imagesToShow?: number;
  selected?: boolean = false;
  assignedEmails?: string[] = [];
  imgAttachments?: Array<Attachment> = [];
  fileAttachments?: Array<Attachment> = [];
  downloadImgAttachments?: Array<string> = [];
  protocol?: Protocol;
  isHighPriority?: boolean;
  project?: Project;
  editPermission?: PermissionDecorator;
  deletePermission?: PermissionDecorator;
  openedSubtasks?: boolean;
  children?: Task[] = [];
  taskGroup?: TaskGroup;
  changeStatus?: ProjectChangesStatus;
  taskId?: string; // Applied after creation
  tags?: { id: number; name: string }[] = [];
  versions?: Task[] = [];
  treeStatus?: TreeStatusEnum;
  acceptanceComment?: string;
  acceptanceDate?: string;
  acceptedEmail?: string;
  acceptedContact?: Contact;
  expanded?: boolean;
  parent?: Task;
  linkedChange?: Task;
  linkedChangeAttachments?: Array<Attachment> = [];
  afterDeadline?: boolean;
  shouldShowCompleteSubtasksPopover?: boolean;
  parentId?: number;
  taskGroupOrder?: number;
  showInCalendar?: boolean = false;
  isDelegated?: boolean;
  isPriceEstimated?: boolean;
  isAdditionalTimeEstimated?: boolean;
  termStartTheSameTermEnd?: boolean = false;
  originalAndChangeAttachments?: Array<Attachment> = [];

  totalAttachmentsCount: number = 0;
  childrenCount: number = 0;

  get isChangeStatusRejected() {
    return this.changeStatus === ProjectChangesStatus.CHANGE_STATUS_REJECTED;
  }

  get isChangeStatusAccepted() {
    return this.changeStatus === ProjectChangesStatus.CHANGE_STATUS_ACCEPTED;
  }

  get isChangeStatusEdit() {
    return this.changeStatus === ProjectChangesStatus.CHANGE_STATUS_EDITED;
  }

  get isTaskShared() {
    return this.changeStatus === ProjectChangesStatus.CHANGE_STATUS_SHARED;
  }

  get isDeleted() {
    return !!this.deleted;
  }

  /**
   *Task Type change cannot be completed only accepted or rejected by client
   *
   * @readonly
   * @memberof Task
   */
  get isCompleted() {
    return !!(this.status === TaskStatus.COMPLETED) && this.type === TaskType.TYPE_NOTE;
  }

  get isCustomType() {
    return this?.type === TaskType.TYPE_CLIENT_TASK || this?.type === TaskType.TYPE_COMPANY_TASK;
  }

  constructor(obj?: Task) {
    super();
    this.deserialize(obj);
  }

  private deserialize(obj?: Task) {
    obj ? Object.assign(this, obj) : null;

    this.type = this.isCustomType ? TaskType.TYPE_TASK : obj?.type;
    this.price = this.price ? this.price : 0;
    this.additionalTime = this.additionalTime ? Number(this.additionalTime?.toString()) : 0;
    obj?.attachments?.length ? this.setAttachmentsData() : null;
    this.assignedTo = this.assignedTo.map((i) => new EmployeeSearchItem(i));
    this.assignedUsers = this.assignedTo.map((i) => i.userPerson);
    obj?.creator ? (this.creator = new Employee(obj.creator)) : null;
    obj?.comments ? (this.comments = obj?.comments?.map((i) => new Commentary(i))) : null;
    obj?.acceptedContact ? (obj.acceptedContact = new Contact(obj.acceptedContact)) : null;
    obj?.linkedChangeAttachments?.length ? this.setLinkedAttachments() : null;
    this.originalAndChangeAttachments = this.attachments.concat(this.linkedChangeAttachments);
    this.setAfterDeadline();
    this.setShouldShowCompleteSubtasksPopover();
    this.setTheSameTermStartTermEnd();
    this.children = sortListByGroup(this.children);
    this.sortAllChildren();
    this.setAdditionalViewTaskParams();
  }

  sortAllChildren() {
    const reccur = (items: Task[]) => {
      if (!items?.length) {
        return items;
      }

      items.sort(sortTasks);

      items.forEach((child: Task) => reccur(child.children));
    };

    reccur(this.children);
  }

  setAdditionalViewTaskParams() {
    this.setTotalAttachments();
    this.setChildrenCount();
  }

  setTotalAttachments() {
    this.totalAttachmentsCount =
      (this.attachments?.length || 0) + (this.linkedChangeAttachments?.length || 0);
  }

  setChildrenCount() {
    this.childrenCount = this.children?.filter((t) => !t?.deleted)?.length || 0;
  }

  setLinkedAttachments() {
    this?.linkedChangeAttachments?.forEach((i) => (i.isUploaded = true));
  }

  setAfterDeadline() {
    this.afterDeadline = moment().isAfter(moment(this.termEnd, Config.DATE_SERVER));
  }

  setShouldShowCompleteSubtasksPopover() {
    const allSubtasksAndChildren: Task[] = [];

    const checkChildrenComplete = (task: Task) => {
      task.children.map((t: Task) => {
        allSubtasksAndChildren.push(t);
        checkChildrenComplete(t);
      });
    };
    checkChildrenComplete(this);

    let isIncompleteTask: boolean = false;
    for (let i = 0; i < allSubtasksAndChildren.length; i++) {
      if (allSubtasksAndChildren[i].status !== TaskStatus.COMPLETED) {
        isIncompleteTask = true;
        break;
      }
    }
    this.shouldShowCompleteSubtasksPopover = isIncompleteTask;
  }

  setAttachmentsData() {
    this.setAttachmentsUploadStatus();
    this.fileAttachments = this.attachments?.filter((i) => i.type?.indexOf('image') === -1) || [];
    this.filesCount = this.fileAttachments.length;

    this.imagesToShow = !!this.filesCount ? 2 : 3;
    this.imgAttachments = this.attachments.filter((i) => i.type.indexOf('image') > -1);

    this.showMoreImages = !!(this.imgAttachments.length > this.imagesToShow);
  }

  setAllChildren() {
    this.children = this.children?.map((i) => new Task(i)) || [];
  }

  setTheSameTermStartTermEnd() {
    if (this.termEnd && this.termStart) {
      this.termStartTheSameTermEnd = this.termEnd.split(' ')[0] === this.termStart.split(' ')[0];
    }
  }

  setChildrenFirstLevel() {
    this.children =
      this.children?.map((i) => {
        const t = new Task(i);
        // this was hiding subtasks in subtask
        // t.children = [];
        return t;
      }) || [];
  }

  setAttachmentsUploadStatus() {
    this?.attachments?.forEach((i) => (i.isUploaded = true));
  }
}
