import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { Config } from '@shared/configs/config';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, takeUntil, take, switchMap } from 'rxjs/operators';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactModalV2Component } from '@shared/components/contact-modal-v2/contact-modal-v2.component';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ContactManagePanelService } from '../../services/contact-manage-panel.service';
import { ContactServiceV2 } from '../../services/contact-v2.service';

type ConnectionType = 'subContact' | 'recommender' | 'director';

@Component({
  templateUrl: './contact-connections.component.html',
  styleUrls: ['./contact-connections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactConnectionsComponent implements OnInit, OnDestroy {
  contactSearchUrl: string;
  loading: boolean = false;
  contacts: Contact[] = [];
  subContacts: Contact[] = [];
  recommender: Contact;
  director: Contact;
  contactsParams: { page: number; total: number } = { page: 1, total: 0 };
  contactType: ContactType;
  mainContactId: number;
  selectedTypeSearch: ConnectionType;

  connectionControl = new FormControl();
  recommenderControl = new FormControl();
  directorControl = new FormControl();

  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private contactsService: ContactServiceV2,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private t: TranslateService,
    private managePanelService: ContactManagePanelService
  ) {}

  get isCompany(): boolean {
    return this.contactType === ContactType.COMPANY;
  }

  ngOnInit(): void {
    this.initSearchSubject();
    this.setInitialData();
  }

  searchFn = () => this.contacts;

  setInitialData(): void {
    this.managePanelService.contactData$
      .pipe(
        take(1),
        switchMap(({ id }) => this.contactsService.getContact(id))
      )
      .subscribe((contact) => {
        this.recommender = contact.recommendedBy;
        this.subContacts = contact.childContacts;
        this.director = contact.owner;
        this.contactType = contact.type;
        this.mainContactId = contact.id;
        this.cdr.detectChanges();
      });
  }

  initSearchSubject(): void {
    this.searchSubject
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((term) => this.getContacts(term)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getContacts(query?: string): void {
    this.contactsParams.page = 1;
    this.loading = true;
    const excludedIds = this.selectedTypeSearch === 'subContact' ? this.subContacts.map(({ id }) => id) : [];
    this.contactsService
      .searchContacts({
        query,
        page: this.contactsParams.page,
        excludedIds
      })
      .subscribe(({ records, total }) => {
        this.contacts = records.map((c) => new Contact(c));
        this.contactsParams.total = total;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  fetchMore(): void {
    const nextPage = this.contactsParams.page + 1;

    if (this.contactsParams.total <= this.contacts.length) return;

    this.loading = true;

    this.contactsService.searchContacts({ page: nextPage }).subscribe(({ records }) => {
      this.contacts = [...this.contacts, ...records].map((c) => new Contact(c));
      this.loading = false;
      this.contactsParams.page = nextPage;
      this.cdr.detectChanges();
    });
  }

  onSelectOpen(type: ConnectionType): void {
    this.selectedTypeSearch = type;
    this.getContacts();
  }

  onSearch({ term }): void {
    this.searchSubject.next(term);
  }

  addContact(
    selectedContact: Contact,
    resetFn: () => void,
    pushFn: (contact: Contact) => void,
    addFn: (mainContactId: number, subContactId: number) => Observable<unknown>,
    type: ConnectionType
  ): void {
    if (selectedContact.id) {
      this.loading = true;
      addFn(this.mainContactId, selectedContact.id)
        .pipe(take(1))
        .subscribe(
          () => {
            resetFn();
            pushFn(selectedContact);
            this.loading = false;
            this.managePanelService.notifyDataChanged();
            this.snackBarService.success(this.t.instant(`Contacts.V2.Connections.successAddContact`));
          },
          () => {
            resetFn();
            this.snackBarService.error(this.t.instant('Contacts.V2.Connections.errorAddContact'));
          }
        );
    } else {
      this.openAddContactModal(selectedContact.fullName, type);
    }
  }

  addSubContactFn(selectedContact: Contact): void {
    this.addContact(
      selectedContact,
      () => this.connectionControl.reset(),
      (contact) => this.subContacts.push(contact),
      this.contactsService.addSubContact.bind(this.contactsService),
      'subContact'
    );
  }

  addRecommenderFn(selectedContact: Contact): void {
    this.addContact(
      selectedContact,
      () => this.recommenderControl.reset(),
      (contact) => (this.recommender = contact),
      this.contactsService.addRecommender.bind(this.contactsService),
      'recommender'
    );
  }

  addDirectorFn(selectedContact: Contact): void {
    this.addContact(
      selectedContact,
      () => this.directorControl.reset(),
      (contact) => (this.director = contact),
      this.contactsService.addDirector.bind(this.contactsService),
      'director'
    );
  }

  onRemove(contactId: number, connectionType: ConnectionType): void {
    this.dialog
      .open(ConfirmModalComponent, {
        width: Config.CONFIRM_MODAL_WIDTH,
        data: {
          title: 'RemoveData.title',
          body: 'RemoveData.description',
          submitButtonText: 'RemoveData.confirm',
          submitButtonType: ButtonTypes.NEW_RED,
          submitButtonIcon: './assets/img/ic-warning-v2.svg'
        }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((confirm: boolean) => {
        if (confirm) {
          let request: Observable<any>;
          switch (connectionType) {
            case 'subContact':
              request = this.contactsService.removeSubContact(this.mainContactId, contactId);
              break;
            case 'recommender':
              request = this.contactsService.removeRecommender(this.mainContactId);
              break;
            case 'director':
              request = this.contactsService.removeDirector(this.mainContactId);
              break;
          }

          request.pipe(take(1)).subscribe(
            () => {
              switch (connectionType) {
                case 'subContact':
                  this.subContacts = this.subContacts.filter((c) => c.id !== contactId);
                  break;
                case 'recommender':
                  this.recommender = null;
                  break;
                case 'director':
                  this.director = this.director = null;
                  break;
              }
              this.managePanelService.notifyDataChanged();
              this.snackBarService.success(this.t.instant('Contacts.V2.Connections.successContactRemoved'));
              this.cdr.detectChanges();
            },
            () => this.snackBarService.error(this.t.instant('Contacts.V2.Connections.errorContactRemoved'))
          );
        }
      });
  }

  onEdit(contact: Contact, type: ConnectionType): void {
    this.dialog
      .open(ContactModalV2Component, {
        width: '580px',
        autoFocus: false,
        disableClose: true,
        data: { contact }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((updatedContact) => {
        if (!updatedContact) return;

        switch (type) {
          case 'subContact':
            this.subContacts = this.subContacts.map((connection) =>
              connection.id === contact.id ? updatedContact : connection
            );
            break;
          case 'recommender':
            this.recommender = updatedContact;
            break;
          case 'director':
            this.director = updatedContact;
            break;
        }

        this.cdr.detectChanges();
      });
  }

  openAddContactModal(firstName: string, type: ConnectionType): void {
    const dialogRef = this.dialog.open(ContactModalV2Component, {
      width: '580px',
      autoFocus: false,
      disableClose: true,
      data: { firstName }
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        switchMap((newContact) => {
          if (!newContact) return of(null);

          this.loading = true;
          switch (type) {
            case 'subContact':
              return this.contactsService.addSubContact(this.mainContactId, newContact.id);
            case 'recommender':
              return this.contactsService.addRecommender(this.mainContactId, newContact.id);
            case 'director':
              return this.contactsService.addDirector(this.mainContactId, newContact.id);
          }
        })
      )
      .subscribe((updatedContact: Contact | null) => {
        if (updatedContact) {
          switch (type) {
            case 'subContact':
              this.subContacts = updatedContact.childContacts;
              break;
            case 'recommender':
              this.recommender = updatedContact.recommendedBy;
              break;
            case 'director':
              this.director = updatedContact.owner;
              break;
          }
        }
        this.loading = false;
        this.resetControls();
        this.managePanelService.notifyDataChanged();
        this.cdr.detectChanges();
      });
  }
  resetControls(): void {
    this.connectionControl.reset();
    this.recommenderControl.reset();
    this.directorControl.reset();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
