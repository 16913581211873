import { Injector } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { AppInjector } from '@shared/services/app-injector.service';
import {
  ESummaryBoxStyle,
  ESummaryColor,
  ESummaryValueType,
  ISummaryConfig,
  ISummaryResponse
} from '../interfaces/summary-config.interface';
import { PriceFormatPipe } from '@shared/pipes/price-format.pipe';

export class ProjectChangesSummaryHelperController {
  private injector: Injector;
  private pricePipe: PriceFormatPipe;
  private storage: StorageService;
  project: Project = null;
  summary: ISummaryResponse = this.defaulSummaryResponse;
  summaryConfig: ISummaryConfig = null;

  get defaulSummaryResponse() {
    return {
      increasingChangesSum: 0,
      decreasingChangesSum: 0,
      zeroChangesCount: 0,
      additionalTimeSum: 0,
      changesBalance: 0,
      discountsSum: 0
    };
  }

  private get _orderAmount() {
    return Number(this.project?.orderAmount) || 0;
  }

  private get _currentProjectCost(): number {
    return this._orderAmount + this.summary?.changesBalance - this.summary?.discountsSum;
  }

  private get _config(): ISummaryConfig {
    return {
      title: 'ProjectChanges.Summary.title',
      items: [
        {
          title: 'ProjectChanges.Summary.changes',
          tip: 'ProjectChanges.Summary.changesHelpIcon',
          items: [
            {
              title: 'ProjectChanges.Summary.increaseBudget',
              value: `${this._getMoneyAmount(this.summary?.increasingChangesSum)}`,
              color: this.getValueColor(this.summary?.increasingChangesSum)
            },
            {
              title: 'ProjectChanges.Summary.decreaseBudget',
              value: `${this._getMoneyAmount(this.summary?.decreasingChangesSum)}`,
              color: this.getValueColor(this.summary?.decreasingChangesSum)
            },
            {
              title: 'ProjectChanges.Summary.changesNoMoney',
              value: this.summary?.zeroChangesCount.toString()
            },
            {
              title: 'ProjectChanges.Summary.additionalDays',
              value: `${this.summary?.additionalTimeSum}`
            }
          ],
          total: {
            title: 'ProjectChanges.Summary.summaryBilans',
            value: `${this._getMoneyAmount(this.summary?.changesBalance)}`,
            color: this.summary?.changesBalance >= 0 ? ESummaryColor.GREEN : ESummaryColor.RED
          }
        },
        {
          title: 'ProjectChanges.Summary.currentNetto',
          style: ESummaryBoxStyle.BLUE,
          items: [
            {
              title: 'ProjectChanges.Summary.costTodo',
              value: `${this._getMoneyAmount(this._orderAmount)}`,
              apiConfig: {
                url: `/project/${this.project?.id}/order-amount`,
                fieldName: 'orderAmount',
                value: this._getMoneyAmount(this._orderAmount, true) as number,
                currency: this.storage?.Company?.currency.symbol,
                type: ESummaryValueType.MONEY
              }
            },
            {
              title: 'ProjectChanges.Summary.changes',
              value: `${this._getMoneyAmount(this.summary?.changesBalance)}`
            },
            {
              title: 'ProjectChanges.Summary.protocolsDiscount',
              value: `${this._getMoneyAmount(this.summary?.discountsSum)}`
            }
          ],
          total: {
            title: 'ProjectChanges.Summary.currentProjectCost',
            value: `${this._getMoneyAmount(this._currentProjectCost)}`
          }
        }
      ]
    };
  }

  constructor() {
    this.injector = AppInjector.getInjector();
    this.pricePipe = this.injector.get(PriceFormatPipe);
    this.storage = this.injector.get(StorageService);
  }

  private _getMoneyAmount(value: number, withoutSymbol: boolean = false) {
    return this.pricePipe?.transform(value, withoutSymbol, this.storage?.Company?.currency);
  }

  getValueColor(value) {
    if (value === 0) {
      return ESummaryColor.BLACK;
    }
    return value > 0 ? ESummaryColor.GREEN : ESummaryColor.RED;
  }

  updateSummaryConfig() {
    this.summaryConfig = Object.assign({}, this._config);
  }

  setSummary(summary: ISummaryResponse, project?: Project) {
    this.summary = Object.assign({}, summary);
    this.setProject(new Project(project));
    this.updateSummaryConfig();
  }

  setProject(project: Project) {
    project ? (this.project = project) : null;
  }
}
