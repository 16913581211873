import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'base-remove-modal',
  templateUrl: './base-remove-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseRemoveModalComponent extends BaseComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  constructor(
    public dialogRef: MatDialogRef<BaseRemoveModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      subtitle?: string;
      description?: string;
      cancelBtn?: string;
      confirmBtn?: string;
    },
  ) {
    super();
  }
}
