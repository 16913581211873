import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';

@Component({
  selector: 'phone-info',
  templateUrl: './phone-info.component.html',
  styleUrls: ['./phone-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInfoComponent {

  @Input() phone: string;
  @Input() class?: string;
  @Input() phoneCountry?: string;

  constructor() {
  }

  formatPhoneNumber() {
    const phoneNumber = parsePhoneNumber(this.phone);
    return phoneNumber ? phoneNumber.formatInternational() : this.phone;
  };

}
