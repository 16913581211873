import { Component, Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { GbxsoftBaseFormComponent } from '../gbxsoft-base.component';
import { GbxsoftTextareaConfig } from './gbxsoft-textarea.interface';
import { GbxsoftValueAccessor, GbxsoftValidatorAccessor } from '../gbxsoft-accessors';

@Component({
  selector: 'gbxsoft-textarea',
  templateUrl: './gbxsoft-textarea.component.html',
  styleUrls: ['./gbxsoft-textarea.component.scss'],
  providers: [
    GbxsoftValueAccessor(GbxsoftTextareaComponent),
    GbxsoftValidatorAccessor(GbxsoftTextareaComponent),
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GbxsoftTextareaComponent extends GbxsoftBaseFormComponent {
  @Input('config') config: GbxsoftTextareaConfig = this.defaultConfig;
  @Input() readonly: boolean = false;
  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }

  /**
   *Value change on type
   *
   * @param {*} value
   * @memberof GbxsoftTextareaComponent
   */
  input(value: any) {
    this.writeValue(value);
    //Check if FormControl is defined in parent
    this.fnOnChange ? this.fnOnChange(value) : null;
  }

  /**
   *Blur event on input
   *
   * @param {*} value
   * @memberof GbxsoftTextareaComponent
   */
  blur(value) {
    this.writeValue(value);
    //Check if FormControl is defined in parent
    this.fnOnTouched ? this.fnOnTouched(value) : null;
  }

  /**
   *Get Default config
   *
   * @readonly
   * @type {GbxsoftInputConfig}
   * @memberof GbxsoftTextareaComponent
   */
  get defaultConfig(): GbxsoftTextareaConfig {
    return {
      name: 'Textarea name',
      placeholder: '',
    };
  }

  get placeholder() {
    return this.config?.placeholder ? this.config?.placeholder : '';
  }

  /**
   *Style Input States
   *
   * @readonly
   * @memberof GbxsoftTextareaComponent
   */
  get inputCSS(): { [key: string]: boolean } {
    return {
      'has-value': this.value && this.value.length,
      'has-error': this.errorCtrl.isError && this.errorCtrl.errorMessage ? true : false,
    };
  }
}
