import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'contact-remove-account-modal',
  templateUrl: './contact-remove-account-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactRemoveAccountModalComponent {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  contact: Contact;
  eventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading: boolean = false;

  constructor(public dialogRef: MatDialogRef<ContactRemoveAccountModalComponent>) {}
}
