import { StorageService } from '@core/services/storage.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { SnackBarService } from '@core/services/snackbar.service';
import { IEntryTemplateConfig } from './../../interfaces/entry-template-config.interface';
import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  EntryListComponent,
  EntryManageType,
} from '@shared/modules/entry/components/entry-list/entry-list.component';
import { ProjectAPIService } from '../../services/project-api.service';
import { ProjectQuestionDirectoryBase } from '../project-question-directory-base/project-question-directory-base.component';
import { ProjectStoreService } from '../../services/project-store.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { ProjectSecondBoxEntries } from '../../consts/project-second-box-entries';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';
import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import { ProjectController } from '../../controllers/project.controller';

@Component({
  templateUrl: './project-question-directory-second.component.html',
  styleUrls: ['./project-question-directory-second.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectQuestionDirecorySecondComponent
  extends ProjectQuestionDirectoryBase
  implements OnInit, OnDestroy
{
  PermissionsGroups = PermissionsGroups;
  ProjectSecondBoxEntries = ProjectSecondBoxEntries;

  isLimitedAction: boolean = false;

  entryListsConfig: IEntryTemplateConfig[] = this.filterList(
    ProjectSecondBoxEntries,
    'dataBox2',
    EntryManageType.BUTTON,
  );

  attachmentsConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachments: 20,
    maxAttachmentFileSize: 50,
  };

  @ViewChildren('entryList') entryList: QueryList<EntryListComponent>;

  constructor(
    private store: StorageService,
    private footerService: StickyFooterService,
    private t: TranslateService,
    private s: SnackBarService,
    private n: NavigateService,
    public projectAPI: ProjectAPIService,
    public changes: ChangeDetectorRef,
    public projectStore: ProjectStoreService,
    private appTourService: AppTourService,
  ) {
    super(projectStore, changes);
  }

  ngOnInit() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'EDIT_SECOND_BOX_FROM_LIST',
    });
    this.isLimitedAction = ctrl.check();

    this.additionalComment.setValue(
      this.projectStore.projectCtrl.project.dataBox2?.additionalComment || null,
    );
    this.subscribeSaving();
    this.changes.detectChanges();

    if (!this.store.SecondBoxTourButton) {
      this.appTourService.initTour(AppTourTypes.QUESTION_DIRECTORY_SECOND, 0, true, null, () => {
        const first = this.entryList.toArray()[0];
        first.openEntryModal();
        this.store.SecondBoxTourButton = true;
        this.changes.detectChanges();
      });
      this.changes.detectChanges();
    }
  }

  ngAfterViewInit() {}

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.SUBMITTED:
          this.submitDirectoryModel();
          break;
        default:
          break;
      }
    });
  }

  discard() {
    this.n.go('projects-list');
  }

  submitDirectoryModel() {
    this.projectStore.loading = true;
    this.changes.detectChanges();
    this.footerService.emitter.emit({ type: StickyFooterEventType.START_SAVING });
    this.projectAPI
      .updateBox2(this.projectStore.projectCtrl.project.id, this.directoryModel)
      .subscribe({
        next: () => {
          this.s.success(this.t.instant('Projects.projectBox2Updated'));
        },
      })
      .add(() => {
        this.projectStore.loading = false;
        this.footerService.emitter.emit({ type: StickyFooterEventType.END_SAVING });
        this.changes.detectChanges();
      });
  }

  openInvitationModal() {
    const project = this.projectStore.projectCtrl.project;
    const ctrl = new ProjectController(project);
    ctrl.initInviteModal(project);
    // const contact = this.projectStore.contacts.filter((i) => !!i.isResponsible)[0] || null;
    // this.dialog.open(ProjectBoxSecondInviteModalComponent, {
    //   width: Config.DEFAULT_MODAL_WIDTH,
    //   autoFocus: false,
    //   data: {
    //     project: this.projectStore.projectCtrl.project,
    //     contact: this.projectStore.projectCtrl?.project?.MainContact || contact,
    //   },
    // });
  }

  ngOnDestroy() {
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
