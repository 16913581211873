import { MatDialog } from '@angular/material/dialog';
import { Injector } from '@angular/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { SidenavConfig, SidenavService } from '@shared/services/sidenav/sidenav.service';
import { NavigateService } from '@shared/services/navigate.service';
import { TaskSidenavService } from '../services/task-sidenav.service';

export class BaseTaskSidenavController {
  injector: Injector;
  sidenav: SidenavService;
  dialog: MatDialog;
  n: NavigateService;
  taskSidenavService: TaskSidenavService;

  getSidenavConfig(extra: any): SidenavConfig {
    this.taskSidenavService.TaskConfigType = extra.type;
    return {
      maxWidth: '611px',
      class: 'no-padding',
      hideBackdrop: false,
      hardReload: true,
      closeOnBackdrop: true,
      extra,
    };
  }

  constructor() {
    this.injector = AppInjector.getInjector();
    this.sidenav = this.injector.get(SidenavService);
    this.dialog = this.injector.get(MatDialog);
    this.n = this.injector.get(NavigateService);
    this.taskSidenavService = this.injector.get(TaskSidenavService);
  }
}
