import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'userRole', pure: true })
export class UserRolePipe implements PipeTransform {
  roles: any = {};
  constructor(private t: TranslateService) {
    this.roles = t.instant('ROLES');
  }

  transform(value: string): string {
    return !!this.roles[value] ? this.roles[value] : this.roles[this.t.instant('Global.ROLE_NOT_FOUND')];
  }
}
