import { INotificationManager } from '@modules/notification/shared/interfaces/notification-manager.interface';
import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { INotificationAvatarConfig } from '@modules/notification/shared/interfaces/notification-avatar-config.interface';
import { Notification } from '@modules/notification/shared/models/notification.model';
import { AppInjector } from '@shared/services/app-injector.service';
import { NotificationBaseComponent } from '@modules/notification/shared/components/notification/types/notification-base/notification-base.component';
import { Task } from '@shared/models/task.model';
import { Employee } from '@shared/models/employee.model';
import { publicFile } from '@shared/helpers/public-file.helper';
import { NotificationType } from '@modules/notification/shared/models/notification-type';
import { HttpError } from '@shared/interfaces/error.interface';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { TaskType } from '@shared/enums/task-type.enum';
import { Languages, LanguageService } from '@core/services/language.service';
import { ProtocolStuffsSideComponent } from '@modules/protocols/shared/components/protocol-stuffs-side/protocol-stuffs-side.component';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ChangesSidenavController } from '@shared/modules/task-sidenav/controllers/changes-sidenav.controller';
import { NavigateService } from '@shared/services/navigate.service';

export class NotificationTaskManager implements INotificationManager {
  resolver: ComponentFactoryResolver;
  t: TranslateService;
  sidenav: SidenavService;
  avatarConfig: INotificationAvatarConfig = {
    src: '/assets/img/ic-document-text.svg',
    type: 'person',
    class: 'orange-500',
    hideAvatar: false
  };
  pService: ProtocolApiService;
  dialog: MatDialog;
  s: SnackBarService;
  n: NavigateService;
  langService: LanguageService;
  taskSidenavController: TaskSidenavController = new TaskSidenavController();
  changeSidenavController: ChangesSidenavController = new ChangesSidenavController();

  constructor(private notification: Notification<any>) {
    this.resolver = AppInjector.getInjector().get(ComponentFactoryResolver);
    this.t = AppInjector.getInjector().get(TranslateService);
    this.sidenav = AppInjector.getInjector().get(SidenavService);
    this.pService = AppInjector.getInjector().get(ProtocolApiService);
    this.dialog = AppInjector.getInjector().get(MatDialog);
    this.s = AppInjector.getInjector().get(SnackBarService);
    this.n = AppInjector.getInjector().get(NavigateService);
    this.langService = AppInjector.getInjector().get(LanguageService);
  }

  getFactory(): ComponentFactory<any> {
    return this.resolver.resolveComponentFactory(NotificationBaseComponent);
  }

  createComponent(content: ViewContainerRef): Function | null {
    let factory = this.getFactory();
    let component = content.createComponent<NotificationBaseComponent>(factory);
    this.avatarConfig.src = publicFile(this.notification?.data?.editor?.userPerson?.photo, 200);

    switch (this.notification.type) {
      // (N.2.2.1) // (N.2.2.2) // (N.2.2.3) // (N.2.2.4) // (N.2.2.5) // (N.2.2.6) // (N.2.2.7) // (N.2.2.8) // (N.2.2.9)
      case NotificationType.TASK.ASSIGNED_EMPLOYEE:
      case NotificationType.TASK.EDITED_EMPLOYEE:
      case NotificationType.TASK.EDITED_CREATOR:
      case NotificationType.TASK.REMOVED_CREATOR:
      case NotificationType.TASK.COMPLETED_CREATOR:
      case NotificationType.TASK.COMPLETED_EMPLOYEE:
      case NotificationType.TASK.REMOVED_EMPLOYEE:
      case NotificationType.TASK.EXPIRED_CREATOR:
      case NotificationType.TASK.EXPIRED_EMPLOYEE:
      case NotificationType.TASK.TASK_COMMENT_CREATED:
        let notificationTextData = this.getNotificationTextData();

        if (this.notification.type === NotificationType.TASK.ASSIGNED_EMPLOYEE) {
          const task = this.notification.data.task;
          notificationTextData['pl_add_1'] =
            task.type !== TaskType.TYPE_TASK &&
            task.type !== TaskType.TYPE_ORDER &&
            this.langService.getCurrentLanguage() === Languages.PL
              ? 'a'
              : 'e';
        }

        import('@modules/protocols/protocols.module').then((m) => m.ProtocolsModule);

        component.instance.text = this.t.instant(
          `Notifications.Texts.Task.${this.notification.type}`,
          this.getNotificationTextData()
        );

        if (
          this.notification.type === NotificationType.TASK.EXPIRED_CREATOR ||
          this.notification.type === NotificationType.TASK.EXPIRED_EMPLOYEE
        ) {
          this.avatarConfig.src = '/assets/img/ic-alert-circle-orange.svg';
          this.avatarConfig.class = 'orange-500';
          this.avatarConfig.type = 'svg';
        }

        if (
          this.notification.type === NotificationType.TASK.REMOVED_CREATOR ||
          this.notification.type === NotificationType.TASK.REMOVED_EMPLOYEE
        ) {
          return null;
        }

        if (this.notification.type === NotificationType.TASK.TASK_COMMENT_CREATED) {
          this.avatarConfig.src = '/assets/img/ic-chatbox-outline.svg';
          this.avatarConfig.class = 'green-500';
          this.avatarConfig.type = 'svg';
        }
        // const ctrl = new CheckPermission({
        //   group: PermissionsGroups.TASKS,
        //   action: 'PREVIEW',
        // });
        return () => {
          this.showTaskPreview(this.notification.data.task);
        };

      // (N.2.2.10) // (N.2.2.11) // (N.2.2.12)
      case NotificationType.TASK.ASSIGNED_GROUPED:
      case NotificationType.TASK.EDITED_GROUPED_EMPLOYEE:
      case NotificationType.TASK.EDITED_GROUPED_CREATOR:
        component.instance.text = this.notification.data.protocol
          ? this.getNotificationDataWithProtocol()
          : this.getNotifiactionDataWithProject();

        this.avatarConfig.src = '/assets/img/ic-check-square-noti.svg';
        this.avatarConfig.type = 'svg';
        this.avatarConfig.class = 'blue-300';
        if (
          this.notification.type === NotificationType.TASK.EDITED_GROUPED_EMPLOYEE ||
          this.notification.type === NotificationType.TASK.EDITED_GROUPED_CREATOR
        ) {
          this.avatarConfig.src = '/assets/img/ic-info.svg';
        }
        return () => {
          if (this.notification.data.protocol) {
            this.sidenav.open(
              ProtocolStuffsSideComponent,
              {},
              { protocolId: this.notification.data.protocol.id }
            );
          } else {
            const ctrl = new ProjectController(this.notification.data.project);
            ctrl.preview(this.notification.data.project);
          }
        };
    }
  }

  getNotificationDataWithProtocol() {
    const project = new Project(this.notification.data.protocol.project);
    return this.t.instant(`Notifications.Texts.Task.${this.notification.type}_protocol`, {
      task_count: this.notification.data.tasksCount,
      protocol_type: this.t.instant(`Protocols.Types.${this.notification.data.protocol.type}`),
      protocol_number: this.notification.data.protocol.idNumber
        ? this.notification.data.protocol.idNumber
        : this.t.instant('Protocols.newName'),
      project_fullName: project?.fullName ? project.fullName : '',
      de_add_1: 'n',
      from: this.notification.data.protocol ? this.t.instant('Notifications.Texts.Task.from') : '',
      from_2: this.notification.data.protocol ? this.t.instant('Notifications.Texts.Task.from_2') : ''
    });
  }

  getNotifiactionDataWithProject() {
    const project = new Project(this.notification.data.project);
    return this.t.instant(`Notifications.Texts.Task.${this.notification.type}_project`, {
      task_count: this.notification.data.tasksCount,
      project_fullName: project?.fullName ? project.fullName : '',
      de_add_1: 'n',
      from: project?.fullName ? this.t.instant('Notifications.Texts.Task.from') : '',
      from_2: project?.fullName ? this.t.instant('Notifications.Texts.Task.from_2') : ''
    });
  }

  getNotificationTextData(obj: any = {}) {
    const task: Task = new Task(this.notification.data.task);
    const editor: Employee = new Employee(this.notification.data.editor);
    const project = new Project(task.protocol ? task.protocol?.project : task.project);

    return Object.assign(
      {
        editor: editor?.userPerson?.previewName,
        task_type: this.t.instant(`Tasks.TaskType.${task.type}`),
        task_name: task?.description ? task.description : '', // Description - Task Title
        task_description: task?.description ? task.description : '', //Content - Task Description
        protocol_type: task?.protocol ? this.t.instant(`Protocols.Types.${task?.protocol?.type}`) : '',
        protocol_number: task.protocol
          ? task?.protocol?.idNumber
            ? task?.protocol?.idNumber
            : this.t.instant('Protocols.newName')
          : '',
        project_fullName: project?.fullName ? project.fullName : '',
        pl_add_1:
          task.type !== TaskType.TYPE_TASK &&
          task.type !== TaskType.TYPE_COMPANY_TASK &&
          task.type !== TaskType.TYPE_ORDER &&
          this.langService.getCurrentLanguage() === Languages.PL
            ? 'a'
            : 'o',
        pl_add_2:
          task.type !== TaskType.TYPE_TASK &&
          task.type !== TaskType.TYPE_COMPANY_TASK &&
          task.type !== TaskType.TYPE_ORDER &&
          this.langService.getCurrentLanguage() === Languages.PL
            ? 'a'
            : 'e',
        pl_add_3:
          task.type !== TaskType.TYPE_TASK &&
          task.type !== TaskType.TYPE_COMPANY_TASK &&
          task.type !== TaskType.TYPE_ORDER &&
          this.langService.getCurrentLanguage() === Languages.PL
            ? 'a'
            : 'o',
        de_add_1: '',
        from: project?.fullName || task?.protocol ? this.t.instant('Notifications.Texts.Task.from') : '',
        from_2: project?.fullName || task?.protocol ? this.t.instant('Notifications.Texts.Task.from_2') : ''
      },
      obj
    );
  }

  private showTaskPreview(_task: Task) {
    this.pService.getTask(_task.id).subscribe({
      next: (task: Task) => {
        if (task.project) {
          this.n.goWithPromise('project-preview-tasks', { projectId: task.project.id }).then(() => {
            this.openTaskPreview(task);
          });
        } else {
          this.n.goWithPromise('my-work').then(() => {
            this.openTaskPreview(task);
          });
        }
      },
      error: (e: HttpError) => {
        switch (e.messageCode) {
          case IntilioCodes.ITEM_NOT_EXISTS:
            this.s.error(this.t.instant('Protocols.errorTaskNotExists'));
            break;
          default:
            this.s.error(this.t.instant('Protocols.errorGetTaskData'));
            break;
        }
      }
    });
  }

  private openTaskPreview(task: Task) {
    if (task.type === TaskType.TYPE_CHANGE) {
      this.changeSidenavController.previewChange(task?.taskId || task?.id?.toString());
    } else {
      this.taskSidenavController.previewTask(task?.taskId || task?.id?.toString());
    }
  }
}
