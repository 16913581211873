<div
  [formGroup]="formGroup"
  class="d-flex align-items-center justify-content-between attachment-inline attachment-inline--{{
    !!control?.value ? 'selected' : ''
  }}"
>
  <div class="d-flex align-items-center">
    <!-- #region Checkbox -->
    <checkbox *ngIf="!disabled" [form]="formGroup" [controlName]="attachment?.id.toString()"></checkbox>

    <!-- #endregion -->

    <!-- #region File -->
    <div
      *ngIf="!isImage"
      class="d-flex align-items-center pointer attachment-inline__ico__holder"
      docPreview
      [file]="!!attachment && attachment?.isUploaded ? attachment : null"
      (onLoading)="downloading = $event"
      [title]="attachment.name"
    >
      <span class="attachment-inline__title" [title]="attachment?.name">{{ attachment?.name }}</span>
    </div>
    <!-- #endregion -->

    <!-- #region Image -->
    <a
      draggable="false"
      *ngIf="isImage"
      class="d-flex align-items-center pointer attachment-inline__ico__holder"
      [href]="attachment?.file | authFile: null : null : !privateFile"
      [attr.data-fancybox]="fancyboxUrl"
      (click)="openImage($event)"
    >
      <span class="attachment-inline__title" [title]="attachment?.name">{{ attachment?.name }}</span>
    </a>
    <!-- #endregion -->
  </div>

  <div class="d-flex align-items-center">
    <app-button
      [type]="'button'"
      *ngIf="!!attachment?.isUploaded"
      [loading]="downloading"
      (click)="downloadAttachment()"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        text: 'Protocols.download' | translate,
        prependSrc: './assets/img/download-blue-icon.svg'
      }"
    ></app-button>
  </div>
</div>
