import { StorageService } from '@core/services/storage.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { EntryAction } from '@modules/protocols/shared/enums/entry-action.enum';
import { IEntryEmitter } from '../entry-list/entry-list.component';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
import { TextAttachmentsInputComponent } from '@shared/components/text-attachments-input/text-attachments-input.component';
import { BaseEntryModalComponent } from '../base-entry-modal.component';
import { IEntryModalData } from '../../interfaces/entry-modal-config.interface';
import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';

export const ENTRY_MODAL_FORM = {
  files: 'files',
  note: 'note'
};

@Component({
  selector: 'entry-modal',
  templateUrl: './entry-modal.component.html',
  styleUrls: ['./entry-modal.component.scss']
})
export class EntryModalComponent implements OnInit, OnDestroy, AfterViewInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  submited: boolean = false;

  loading: boolean = false;
  employees: Array<any> = [];

  attachments: Array<TextAttachment> = [];
  attachmentsToDelete: Array<number> = [];
  form: FormGroup;

  componentInstance: BaseEntryModalComponent;
  componentRef: ComponentRef<any>;

  @Output() onEntry: EventEmitter<IEntryEmitter> = new EventEmitter();

  @ViewChild('textAttach') textAttach: TextAttachmentsInputComponent;
  @ViewChild('componentContainer', { read: ViewContainerRef }) entry: ViewContainerRef;

  get NoteField() {
    return this.form?.get(ENTRY_MODAL_FORM.note);
  }

  get FilesField() {
    return this.form?.get(ENTRY_MODAL_FORM.files);
  }

  get isNoteInvalid() {
    return !this.NoteField?.value?.length;
  }

  get isFilesInvalid() {
    return !this.FilesField?.value?.length;
  }

  get formData() {
    const data = !this.data.entry
      ? this.form.value[ENTRY_MODAL_FORM.files]
      : this.form.value[ENTRY_MODAL_FORM.files]?.filter((i) => !i.created);

    let _data = {
      type: this.data?.entry?.type,
      [this.descriptionField]: this.form.get(ENTRY_MODAL_FORM.note).value || '',
      [this.attachmentsField]: data ? data : [],
      attachmentsToDelete: this.attachmentsToDelete
    };

    if (!!this.componentInstance?.formData) {
      _data = Object.assign(_data, this.componentInstance?.formData);
    }
    return _data;
  }

  get config(): GbxsoftInputConfig {
    return (
      this.data?.config || {
        name: ' ',
        type: GbxsoftInputTypes.TEXT,
        placeholder: this.t.instant('Protocols.describeEmployee'),
        showPlaceholderOnFocus: true
      }
    );
  }

  get attachmentsConfig(): TextAttachmentsConfig {
    return (
      this.data?.attachmentsConfig || {
        photoAttachments: true,
        fileAttachments: true,
        showText: true,
        maxAttachmentSize: 50,
        maxAttachments: 10
      }
    );
  }

  get modalTitle() {
    return this.data?.entry?.id
      ? this.data.entryModalConfig?.editTitle
      : this.data.entryModalConfig?.addTitle;
  }

  get descriptionField() {
    return this.data?.entryModalConfig?.descriptionField
      ? this.data.entryModalConfig.descriptionField
      : 'description';
  }

  get attachmentsField() {
    return this.data?.entryModalConfig?.attachmentsField
      ? this.data.entryModalConfig.attachmentsField
      : 'attachments';
  }

  constructor(
    private store: StorageService,
    private appTourService: AppTourService,
    private fb: FormBuilder,
    public changes: ChangeDetectorRef,
    private t: TranslateService,
    private resolver: ComponentFactoryResolver,
    public pService: ProtocolApiService,
    public dialogRef: MatDialogRef<EntryModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IEntryModalData
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.setTour();
  }

  ngAfterViewInit() {
    this.ceateComponent(this.data?.componentInstance);
    this.componentInstance?.setFormFields(this.data);
    this.form.get(ENTRY_MODAL_FORM.note).setValue(this.data.entry[this.descriptionField]);
    this.attachments = this.data.entry[this.attachmentsField]?.map((attachment: Attachment) =>
      this.textAttach.getAttachment(attachment as any, null, attachment)
    );

    this.changes.detectChanges();

    setTimeout(() => {
      this.textAttach.setFilesToFormControl();
      this.changes.detectChanges();
    }, 0);
  }

  setTour() {
    if (!this.store.SecondBoxTourModal && this.data.attachmentTour && this.data.attachmentTourType) {
      this.appTourService.initTour(
        this.data.attachmentTourType,
        0,
        true,
        () => {
          this.store.SecondBoxTourModal = true;
          this.changes.detectChanges();
        },
        () => {
          this.dialogRef?.close();
          this.changes.detectChanges();
        }
      );
      this.changes.detectChanges();
    }
  }

  createForm() {
    this.form = this.fb.group({
      note: [null],
      files: [null]
    });
  }

  ceateComponent(component: any) {
    if (!component) return;
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(component);
    this.componentRef = this.entry.createComponent(factory);
    this.componentInstance = this.componentRef.instance;
    this.componentRef.changeDetectorRef.detectChanges();
  }

  destroyComponent() {
    this.componentRef?.destroy();
  }

  removeFileEvent(attachment: TextAttachment) {
    attachment.id ? this.attachmentsToDelete.push(parseInt(attachment.id)) : null;
    this.changes.detectChanges();
  }

  errorFileRemove() {}

  submitEntryAdd() {
    Object.keys(this.form.controls).forEach((key: string) => {
      const control = this.form.get(key);
      control.markAsTouched();
      control.updateValueAndValidity({ emitEvent: false });
    });
    this.submited = true;
    this.componentInstance?.submit();
    this.changes.detectChanges();
    !!this.data.entry?.id ? this.editEntry() : this.addEntry();
  }

  addEntry() {
    this.setAction(EntryAction.ADD);
  }

  editEntry() {
    this.setAction(EntryAction.EDIT);
  }

  setAction(action: EntryAction) {
    if (!!this.loading || this.form.invalid) return;
    const isAnyFileExists = !!this.form.value[ENTRY_MODAL_FORM.files]?.length;
    const isDescriptionFilled = !!this.formData[this.descriptionField]?.length;

    const validFormData = isAnyFileExists || isDescriptionFilled;
    const validComponent = this.componentInstance ? this.componentInstance?.form?.valid : true;
    const componentExist = !!this.componentInstance;

    if (componentExist) {
      validComponent ? this.updateEntry(action) : null;
    } else {
      !!this.data.acceptEmptyState || validFormData ? this.updateEntry(action) : null;
    }
  }

  updateEntry(action: EntryAction) {
    this.onEntry.emit({ type: action, data: this.formData });
    this.submited = false;
    this.changes.detectChanges();
  }

  resetForm() {
    this.attachments = [];
    this.closeAssignee();
    this.textAttach?.removeAllAttachments();
  }

  closeAssignee() {
    this.form?.reset();
    this.dialogRef?.close();
  }

  ngOnDestroy() {
    this.destroyComponent();
  }
}
