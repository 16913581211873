export const filterAndMapErrorMessages = (
  messages: { [key: string]: string },
  errors: { [key: string]: any },
): { [key: string]: string } => {
  return Object.keys(messages)
    .filter((key) => Object.keys(errors || {}).includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: messages[key] });
    }, {});
};
