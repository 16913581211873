<div class="settings settings-content">
  <div class="settings-content-section-forms">
    <system-language [form]="form"></system-language>
    <br />
    <system-privacy></system-privacy>
    <br />
    <ng-container *ngIf="employee?.isEmployeeOwner">
      <system-project-creator></system-project-creator>
      <br class="spacer-v2" />
      <br class="spacer-v2" />
      <system-contact-person [form]="form"></system-contact-person>
    </ng-container>
    <br class="spacer-v2" />
    <system-delete-account></system-delete-account>
    <br class="spacer-v2" />
  </div>
</div>
