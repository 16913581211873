import { CheckPermission } from '@core/permissions/check-permission';
import { NavigateService } from '@shared/services/navigate.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Injectable({ providedIn: 'root' })
export class ProjectThirdBoxEditGuard implements CanActivate {
  constructor(private n: NavigateService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'PROJECT_BOX_THIRD_EDIT'
    });
    if (ctrl.check()) {
      return true;
    }
    this.n.go('projects-list');
    return false;
  }
}
