import { FormControl } from '@angular/forms';
import { GbxsoftBaseFormComponent } from '../gbxsoft-base.component';

export class GbxsoftFomControlErrorController {
  control: FormControl;

  constructor(private base: GbxsoftBaseFormComponent) {}

  updateControl(control: FormControl) {
    this.control = control;
    this.base.changes.detectChanges();
  }

  get errorMessage() {
    const errors = this.control.errors;
    if (!errors) return;
    let message = '';
    Object.keys(errors).forEach((error) => (message = this.getValidatorMessage(error)));
    return message;
  }

  /**
   *Check if user provide it`s validation messages
   *if no -> use default messages
   *
   * @param {string} name
   * @returns
   * @memberof GbxsoftFomControlErrorController
   */
  getValidatorMessage(name: string) {
    switch (name) {
      case GbxsoftErrorTypes.pattern:
        const pattern = this.control.errors.pattern.requiredPattern;
        return this.base.errMessages && this.base.errMessages[pattern]
          ? this.base.errMessages[pattern]
          : 'No pattern validator';
      default:
        return this.base.errMessages && this.base.errMessages[name]
          ? this.base.errMessages[name]
          : this.getDefualtMessages(name);
    }
  }

  /**
   *Predefined messages for validation
   *
   * @param {GbxsoftErrorTypes} errorType
   * @returns
   * @memberof GbxsoftFomControlErrorController
   */
  getDefualtMessages(errorType: string) {
    const err = this.control.errors[errorType];

    switch (errorType) {
      case GbxsoftErrorTypes.red:
        return '&nbsp;';
      case GbxsoftErrorTypes.required:
        return 'This field is required.';
      case GbxsoftErrorTypes.min:
        return `Please enter a value greater than or equal to ${err.min}.`;
      case GbxsoftErrorTypes.max:
        return `Please enter a value less than or equal to ${err.max}.`;
      case GbxsoftErrorTypes.email:
        return 'Please enter a valid email address.';
      case GbxsoftErrorTypes.minLength:
        return `Please enter at least ${err.requiredLength} characters.`;
      case GbxsoftErrorTypes.maxLength:
        return `Please enter no more than ${err.requiredLength} characters.`;
      default:
        return 'No validator message';
    }
  }

  get isError() {
    return this.control && this.control.touched;
  }
}

/**
 *Default Angular errors
 *
 * @export
 * @class GbxsoftErrorTypes
 */
export class GbxsoftErrorTypes {
  static readonly red = 'red';
  static readonly required = 'required';
  static readonly min = 'min';
  static readonly max = 'max';
  static readonly requiredTrue = 'requiredTrue';
  static readonly email = 'email';
  static readonly minLength = 'minlength';
  static readonly maxLength = 'maxlength';
  static readonly pattern = 'pattern';
}

/**
 *Custom control error message
 *
 * @export
 * @interface GbxsoftControlError
 */
export interface GbxsoftControlError {
  [error: string]: string;
}
