import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-action-button-link-like',
  templateUrl: './action-button-link-like.component.html',
  styleUrls: ['./action-button-link-like.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonLinkLikeComponent {
  @Input() text: string = null;
  @Input() icon: string = null;
  @Input() type: 'blue' | 'red' = 'blue';
  @Input() disabled: boolean = false;

  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter();
}
