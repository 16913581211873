import { NgModule } from '@angular/core';
import { COMMENTARY_COMPONENTS, COMMENTARY_EXPORT, COMMENTARY_PROVIDERS, COMMENTARY_IMPORTS } from './index';

@NgModule({
  declarations: [COMMENTARY_COMPONENTS],
  imports: [COMMENTARY_IMPORTS],
  exports: [COMMENTARY_EXPORT],
  providers: [COMMENTARY_PROVIDERS]
})
export class CommentaryModule {}
