import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { LanguageService } from '@core/services/language.service';
import { RegisterController } from '@modules/authorization/shared/controllers/register.controller';
import { IGoogleUser, OAuthService } from '@core/services/oauth.service';
import { JWT } from '@shared/interfaces/jwt.interface';
import { HttpError } from '@shared/interfaces/error.interface';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Component({
  templateUrl: './register.component.html'
})
export class RegisterComponent extends BaseComponent implements OnInit, AfterViewInit {
  ctrl: RegisterController;
  @ViewChild('googleButton') googleButton: any;

  constructor(
    private langService: LanguageService,
    private oAuth: OAuthService,
    private authService: AuthorizationService,
    private changes: ChangeDetectorRef,
    private zone: NgZone
  ) {
    super();
    this.ctrl = new RegisterController();
  }

  ngOnInit() {
    this.ctrl.createForm();
    this.ctrl.setValidatorsSimple();
    this.initLanguage();
  }

  initLanguage() {
    const lang = this.langService.getInitLanguage();
    this.langService.useLanguage(lang).subscribe();
  }

  ngAfterViewInit(): void {
    this.oAuth.initializeGoogleOAuth(this.signInGoogle.bind(this));
    this.oAuth.renderGoogleButton(this.googleButton.nativeElement);
    this.changes.detectChanges();
  }

  signInGoogle(user: IGoogleUser) {
    this.authService.signInGoogle(user.credential).subscribe({
      next: (jwt: JWT) => {
        this.successSignIn(jwt);
      },
      error: (err: HttpError) => {
        this.zone.runOutsideAngular(() => {
          switch (err.messageCode) {
            case IntilioCodes.GOOGLE_AUTH_ALREADY_EXIST:
              this.s.warn(this.t.instant('Auth.Errors.googleAccountExitsts'));
              break;
            default:
              this.s.error(err.message);
              break;
          }
        });
      }
    });
  }

  successSignIn(jwt: JWT) {
    this.store.setSignedIn(jwt);
    setTimeout(() => this.initLanguageAfterAuth(jwt), 10);
  }

  initLanguageAfterAuth(jwt: JWT) {
    this.langService.useLanguage(jwt.userPerson.language).subscribe({
      next: () => {
        this.zone.run(() => {
          this.s.success(this.t.instant('Auth.Messages.successSignIn'));
          this.n.navigate('company-selection');
          this.changes.detectChanges();
        });
      }
    });
  }
}
