import { Router } from '@angular/router';
import { debounce } from '@shared/decorators/debounce.decorator';
import { WindowHelper } from '@shared/helpers/window.helper';
import {
  Component,
  TemplateRef,
  Input,
  HostListener,
  ChangeDetectorRef,
  ViewChild,
  ChangeDetectionStrategy
} from '@angular/core';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-content-tabs-v2',
  templateUrl: './content-tabs-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTabsV2Component {
  @Input() tabsTemplate: TemplateRef<any>;
  @Input() tabsDescriptionTemplate: TemplateRef<any>;
  @Input() tabsDescription: string;
  @Input() tabLinks: TabLink[];
  @Input() hideTopTabIconOnMobile: boolean = false;
  @Input() customActivePaths?: boolean;

  @Input() selectTabsOnMobile: boolean = false;

  @ViewChild('selectTab') selectTab: NgSelectComponent;

  @HostListener('window:resize')
  @debounce(30)
  resize() {
    this.changes?.detectChanges();
  }

  get menuTabsClass() {
    return {
      'mt-0': !this.tabsTemplate || this.hideTopTabIconOnMobile,
      'mt-md-3': this.hideTopTabIconOnMobile
    };
  }

  get isMobile() {
    return WindowHelper.isMoreLessMD;
  }

  constructor(
    private changes: ChangeDetectorRef,
    private router: Router
  ) {}

  onTabClick(clickedTab) {
    this.router.navigate([clickedTab.link]);
  }

  isActivePath(patterns = []): boolean {
    return patterns?.some((pattern: RegExp) => pattern.test(this.router.url));
  }

  getTabLinkAttrs(tabLink: TabLink) {
    return {
      routerLink: tabLink.navigateFn ? [] : [tabLink.link],
      routerLinkActive: tabLink.navigateFn ? [] : ['active'],
      queryParamsHandling: tabLink.navigateFn ? 'preserve' : null,
      clickHandler: () => {
        if (tabLink.navigateFn) {
          tabLink.navigateFn();
          this.tabLinks.forEach((tab) => (tab.active = false));
          tabLink.active = true;
        }
      }
    };
  }
}
