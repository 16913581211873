<div
  *ngIf="!content"
  class="text-attachments-control"
  [ngClass]="{
    'text-attachments-control__double-attachments': isDoubleAttachments,
    hasFocus: input?.isFocused
  }"
  drag-drop
  (filesDropped)="onFilesChange($event)"
  [attachments]="attachments"
  [attachmentsConfig]="attachmentsConfig"
  #textAttachmentControl
>
  <div class="text-attachments-control-drag-drop align-items-center justify-content-center">
    {{ 'Global.dragDropFileText' | translate }}
  </div>

  <div class="text-attachments-control__text-line">
    <form [tourAnchor]="inputTour" [formGroup]="form">
      <gbxsoft-input
        *ngIf="attachmentsConfig.showText"
        formControlName="{{ textName }}"
        [config]="config"
        [disabled]="disabled"
        #input
      ></gbxsoft-input>
    </form>

    <app-button
      [tourAnchor]="buttonTour"
      *ngIf="
        attachments.length < attachmentsConfig.maxAttachments ||
        (!attachmentsConfig.maxAttachments && !onlyTextInteraction)
      "
      (onClick)="addFile()"
      [disabled]="fileLoading || disabled"
      [loading]="fileLoading"
      [config]="{
        text: buttonText,
        prependSrc: '/assets/img/' + imageSrc,
        type: ButtonTypes.SECONDARY_BLUE
      }"
    ></app-button>

    <input
      type="file"
      #fileInput
      multiple
      [accept]="acceptFiles()"
      (click)="markAsTouched()"
      (change)="onFilesChange()"
    />
  </div>
</div>
<img draggable="false" src="/assets/img/ic-file-plus-white.svg" class="d-none" />

<!-- V2 below -->

<div
  *ngIf="content"
  class="new-input-wrapper w-100"
  drag-drop
  (filesDropped)="onFilesChange($event)"
  [attachments]="attachments"
  [attachmentsConfig]="attachmentsConfig"
>
  <div>
    <form class="form" [tourAnchor]="inputTour" [formGroup]="form">
      <textarea
        #textarea
        class="custom-input"
        *ngIf="attachmentsConfig.showText"
        [formControlName]="textName"
        [placeholder]="config.placeholder"
        [disabled]="disabled"
        #input
      ></textarea>
      <button
        class="attachment-button"
        *ngIf="
          attachments.length < attachmentsConfig.maxAttachments ||
          (!attachmentsConfig.maxAttachments && !onlyTextInteraction)
        "
        (click)="addFile()"
        [disabled]="fileLoading || disabled"
      >
        <svg-icon [src]="'/assets/img/paper-clip-icon.svg'"></svg-icon>
      </button>
    </form>
    <div *ngIf="attachments.length" class="attachments mb-3">
      <div
        class="text-attachments-control__attachments-line_files d-flex"
        [ngClass]="{
          'text-attachments-control__attachments-line_files__notext': !attachmentsConfig.showText
        }"
      >
        <ng-container *ngFor="let item of attachments">
          <div
            [title]="item?.name"
            *ngIf="!item.file.deleted"
            class="d-flex w-100 justify-content-between align-items-center my-2"
          >
            <div class="new-attachment-name">
              {{ item.name }}
            </div>
            <app-action-button-link-like
              type="red"
              class="remove"
              *ngIf="!disabled && !onlyTextInteraction"
              (onClick)="removeItem(item)"
              [text]="'Attachments.remove' | translate"
              [icon]="'./assets/img/trash-icon-red.svg'"
            ></app-action-button-link-like>
          </div>
        </ng-container>
      </div>
    </div>
    <input
      type="file"
      #fileInput
      multiple
      [accept]="acceptFiles()"
      (click)="markAsTouched()"
      (change)="onFilesChange()"
    />
  </div>
  <div class="commentary-buttons w-100 d-flex flex-row flex-wrap justify-content-between">
    <div></div>
    <ng-content></ng-content>
  </div>
</div>
