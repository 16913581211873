import { ProtocolTaskListComponent } from './../../modules/protocol-task-list/protocol-task-list.component';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';
import { SidenavEvent, SidenavEventType, SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ProtocolController } from '../../controllers/protocol.controller';
import { ProtocolsStatuses } from '../../enums/protocols-statuses.enum';
import { Protocol } from '../../models/protocol';
import { ProtocolApiService } from '../../services/protocol-api.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { ProtolActionEmitter } from '@modules/protocols/shared/interfaces/protocol-action-emitter.interface';
import { ProtocolAction } from '@modules/protocols/shared/enums/protocol-action.enum';
import { Task } from '@shared/models/task.model';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';

@Component({
  selector: 'protocol-stuffs-side',
  templateUrl: './protocol-stuffs-side.component.html',
  styleUrls: ['./protocol-stuffs-side.component.scss']
})
export class ProtocolStuffsSideComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  ProtocolsStatuses = ProtocolsStatuses;

  @ViewChild('taskList') taskList: ProtocolTaskListComponent;

  static componentName: string = 'ProtocolStuffsSideComponent';

  protocolController: ProtocolController;

  constructor(
    @Inject(SIDENAV_DATA) public data: SIDENAV_DATA,
    public sidenav: SidenavService,
    public pService: ProtocolApiService,
    public taskService: TaskService,
    private basePanelService: BasePanelService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProtocol();
    this.listenChangeAssign();
    this.setOnCloseListener();
  }

  ngAfterViewInit() {
    this.basePanelService.restoreScrollPosition();
  }

  setOnCloseListener() {
    this.sidenav.emitter.subscribe((e: SidenavEvent) => {
      if (e.type === SidenavEventType.CLOSE && document.querySelector('.datatable-body-row.active')) {
        document.querySelector('.datatable-body-row.active').classList.remove('active');
      }
    });
  }

  getProtocol() {
    this.pService.getProtocolDraft(this.data.data.protocolId).subscribe({
      next: (protocol: Protocol) => this.protocolSuccess(protocol),
      error: (err: HttpError) => this.protocolErrors.bind(this)
    });
  }

  listenChangeAssign() {
    this.pService.manager.protocolAction.subscribe((e: ProtolActionEmitter) => {
      switch (e.type) {
        case ProtocolAction.GROUP_ASSIGNMENT:
          if (e.data.assignedIDs.indexOf(this.employee.id) === -1) {
            this.pService.manager.protocolAction.emit({ type: ProtocolAction.RELOAD_LIST });
          }
          break;
        case ProtocolAction.EDIT_TASK:
          const isMyId = (e.data as Task).assignedTo.filter(
            (e: EmployeeSearchItem) => e.id === this.employee.id
          );
          if (!isMyId) {
            this.pService.manager.protocolAction.emit({ type: ProtocolAction.RELOAD_LIST });
          }
          break;
      }
    });
  }

  protocolSuccess(protocol: Protocol) {
    this.pService.manager.protocol = new Protocol(protocol);
    this.protocolController = new ProtocolController(this.pService, protocol);
    this.getSearchedEmployees();
    this.taskList.changes.detectChanges();
  }

  protocolErrors(err: HttpError) {}

  getSearchedEmployees() {
    this.pService
      .getEmployees('')
      .subscribe()
      .add(() => {
        this.taskList.changes.detectChanges();
      });
  }

  edit() {
    this.sidenav.close();
    this.protocolController.edit();
  }

  ngOnDestroy() {
    this.pService.manager.clearService();
  }
}
