<div
  class="avatar overflow-hidden align-items-center d-flex justify-content-center"
  [ngStyle]="{
    width: avatarWidth + 'px',
    height: avatarHeight + 'px',
    borderColor: color ? color : 'transparent',
    borderWidth: color ? '2px' : '0px'
  }"
  [ngClass]="{ 'rounded-circle': rounded }"
>
  <img
    draggable="false"
    [src]="imageUrl"
    *ngIf="imageUrl; else placeholder"
    [width]="avatarWidth"
    [height]="avatarHeight"
    draggable="false"
  />
  <ng-template #placeholder>
    <img
      draggable="false"
      src="/assets/img/avatar-placeholder.svg"
      alt="Avatar Placeholder"
      [width]="avatarWidth"
      [height]="avatarHeight"
      draggable="false"
    />
  </ng-template>
</div>
