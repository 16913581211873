import { AddressType } from './../enums/address-type.enum';
import { PropertyType } from './../enums/property-type.enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventEmitter, Injectable } from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ProjectController } from '../controllers/project.controller';
import { PROJECT_DATA_FORM_BOX } from '../components/project-question-directory-first/consts/project-data-form-box';
import { countries } from '@shared/datas/countries';
import { BasicDataBox } from '../models/project-basic-data-box.model';
import { NavigateService } from '@shared/services/navigate.service';
import { ContactStatus } from '@modules/contacts/shared/enums/contact-status.enum';
import * as moment from 'moment';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';

export interface IMainContactState {
  addingContact: boolean;
  isAccepted: boolean;
  formValues: any;
  contact: Contact;
  firstChange: boolean;
  contactSearchbox?: any;
}

export interface IMainDirectoryFirststate {
  formValues: any;
}

@Injectable({providedIn: 'root'})
export class ProjectStoreService {
  isContactCreationOpened: boolean = false;
  projectCreated: boolean = false;
  loading: boolean = false;
  contacts: ContactAccessProject[] = [];
  clientContacts: ContactAccessProject[] = [];
  responsibleContacts: ContactAccessProject[] = [];
  projectCtrl: ProjectController = new ProjectController();

  mainContactState: IMainContactState = this.defaultMainContantState;
  mainDirectoryFirstState: IMainDirectoryFirststate = this.defaultMainDirectoryFirstState;
  mainDirectoryFirstForm: FormGroup;

  resetComponentState: EventEmitter<boolean> = new EventEmitter();

  get defaultMainContantState(): IMainContactState {
    return {
      firstChange: true,
      addingContact: false,
      isAccepted: false,
      contact: null,
      formValues: {},
      contactSearchbox: null,
    };
  }

  get defaultMainDirectoryFirstState(): IMainDirectoryFirststate {
    return {
      formValues: null,
    };
  }

  get basicDataBox(): BasicDataBox {
    return this.mainDirectoryFirstState?.formValues || this.projectCtrl?.project?.basicDataBox;
  }

  constructor(
    private fb: FormBuilder,
    private n: NavigateService
  ) {
    this.createMainDirectoryFirstForm();
  }

  createMainDirectoryFirstForm() {
    const group = this.setDefaultFormValues();

    this.mainDirectoryFirstForm = this.fb.group(group);
    this.setValidators();
  }

  setValidators() {
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).setValidators([Validators.required]);
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).markAsUntouched();
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).updateValueAndValidity();
  }

  setMainContact() {
    if (this.projectCtrl?.project?.MainContact?.id) {
      this.mainDirectoryFirstForm
        .get(PROJECT_DATA_FORM_BOX.mainContactId)
        .setValue(this.projectCtrl.project.MainContact.id);
    }
  }

  setProjectContacts() {
    this.contacts =
      this.projectCtrl.project?.basicDataBox?.contacts.map((i: ContactAccessProject) => {
        const c = new ContactAccessProject(i);
        c.isResponsible = i.isResponsible;
        return c;
      }) || [];
    this.setContactIds();
  }

  filterContacts() {
    this.clientContacts = this.contacts.filter((i) => !i.isResponsible);
    this.responsibleContacts = this.contacts.filter((i) => !!i.isResponsible);

    this.clientContacts.sort((a, b) => moment(b.created).unix() - moment(a.created).unix());
  }

  setDefaultFormValues() {
    let group = {};
    Object.keys(PROJECT_DATA_FORM_BOX).forEach((key: string) => {
      if (key.indexOf('has') > -1) {
        group[PROJECT_DATA_FORM_BOX[key]] = false;
      } else if (key.indexOf('Ids') > -1) {
        group[PROJECT_DATA_FORM_BOX[key]] = [];
      } else {
        group[PROJECT_DATA_FORM_BOX[key]] = null;
      }
    });
    return group;
  }

  setInitialFormValues() {
    const dataBox = this.basicDataBox;

    this.setValidators();
    if (dataBox) {
      Object.keys(dataBox).forEach((k: string) => {
        const control = this.mainDirectoryFirstForm.get(k);
        !!control ? control.setValue(dataBox[k]) : null;
      });
      this.setAddress();
      this.setCountryData();
      this.setAddressAsMainContact();
      this.setProjectContacts();
      !dataBox.propertyType ? this.setDefaultProjectType() : null;
      this.setMainContact();
      this.setProjectName();
      this.setProjectResponsibleEmployee();
    }
  }

  setAddress() {
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.address).setValue(this.projectCtrl.project.address);
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.postalCode).setValue(this.projectCtrl.project.postalCode);
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.country).setValue(this.projectCtrl.project.country);
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.town).setValue(this.projectCtrl.project.town);
  }

  setAddressAsMainContact() {
    const contact = this.projectCtrl.project.MainContact;
    const sameAsContact =
      this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.addressType).value === AddressType.FROM_CLIENT;

    if (contact && sameAsContact) {
      this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.address).setValue(contact.address || null);
      this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.postalCode).setValue(contact.postalCode || null);
      this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.town).setValue(contact.town || null);
      this.setCountryById(contact?.country || null);
    }
  }

  clearAddressField() {
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.address).setValue(null);
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.postalCode).setValue(null);
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.town).setValue(null);
    this.setCountryById(null);
  }

  setCountryData() {
    const countryCtrl = this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.country);

    if (!!countryCtrl?.value) {
      this.setCountryById(countryCtrl?.value?.id || countryCtrl?.value);
      return;
    }
    this.setCountryById(this.projectCtrl?.project.country);
  }

  setDefaultProjectType() {
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.propertyType).setValue(PropertyType.OTHER);
  }

  setCountryById(id: string) {
    const selectedCountry = countries.filter((i) => i.id == id)[0];
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.country).setValue(selectedCountry?.id);
  }

  setProjectName() {
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).setValue(this.projectCtrl?.project?.name);
  }

  setProjectResponsibleEmployee() {
    this.mainDirectoryFirstForm
      .get(PROJECT_DATA_FORM_BOX.responsibleEmployeeId)
      .setValue(this.basicDataBox?.responsibleEmployee?.id);
  }

  setDefault() {
    this.mainContactState = this.defaultMainContantState;
    this.setEditableContact();
    this.mainDirectoryFirstState = this.defaultMainDirectoryFirstState;
    this.mainDirectoryFirstForm.setValue(this.setDefaultFormValues());
    this.contacts = [];
    this.filterContacts();
  }

  setEditableContact() {
    const contact = this.projectCtrl.project?.MainContact;

    if (contact?.status === ContactStatus.DRAFT) {
      this.mainContactState.contact = contact;
      this.mainContactState.addingContact = true;
    }
  }

  submitProject(
    activate: boolean = true,
    ignoreValidation: boolean = false,
    isEditing: boolean = false,
    callback?: () => void,
  ) {
    this.projectCreated = false;
    if (this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).invalid && !ignoreValidation) {
      this.markProejctNameErrors(isEditing);
    } else {
      const id = this.projectCtrl?.project?.id;
      const values = this.mainDirectoryFirstForm.value;
      values.contactsIds = values.contactsIds?.filter((id) => id !== values?.mainContactId) || [];
      this.projectCtrl.setProjectBasicDataBox(id, values, activate, isEditing, callback);
    }
  }

  markProejctNameErrors(isEditing: boolean) {
    if (isEditing) {
      this.goToErrorField();
    } else {
      this.n
        .go('question-directory', {
          id: 1,
          projectid: this.projectCtrl?.project?.id,
        })
        .then(() => this.goToErrorField());
    }
  }

  goToErrorField() {
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).markAsTouched();
    this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.name).updateValueAndValidity();
    // this.s.error(this.t.instant('Projects.projectNameRequired'));
    setTimeout(() => this.scrollToError(), 0);
  }

  scrollToError() {
    const el = document.getElementsByClassName('form-control has-error')[0];
    if (el && el.parentElement) {
      el.parentElement.scrollIntoView();
      el.querySelector('input').focus();
    }
  }

  // #region Contact Manage
  setContactIds() {
    this.filterContacts();
    const contactIDS = this.mainDirectoryFirstForm.get(PROJECT_DATA_FORM_BOX.contactsIds);
    contactIDS.setValue(this.contacts.map((i) => i.contact.id));
  }

  addNewContact(contact: Contact) {
    this.contacts.push({contact: new Contact(contact)} as any);
    this.setContactIds();
  }

  findContactIndex(contact: Contact) {
    return this.contacts.findIndex((i) => i?.contact?.id === contact?.id);
  }

  onRemove(contact: Contact, main?: boolean) {
    if (main) {
      this.projectCtrl.project.basicDataBox.mainContact = null;
      this.mainDirectoryFirstState.formValues = {};
      this.mainContactState.contact = null;
    } else {
      this.contacts.splice(this.findContactIndex(contact), 1);
    }
    this.setContactIds();
  }

  onUpdate(contact: Contact, main?: boolean) {
    if (main) {
      this.projectCtrl.project.basicDataBox.mainContact.mainContact = new Contact(contact);
      this.mainDirectoryFirstState.formValues = new Contact(contact);
      this.mainContactState.contact = new Contact(contact);
    } else {
      this.contacts[this.findContactIndex(contact)].contact = contact;
    }
    this.setContactIds();
  }

  // #endregion
}
