import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigateService } from '@shared/services/navigate.service';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { Protocol } from '../../models/protocol';
import { StorageService } from '@core/services/storage.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolApiService } from '../../services/protocol-api.service';
import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';

@Component({
  templateUrl: './protocol-selection.component.html',
  styleUrls: ['./protocol-selection.component.scss']
})
export class ProtocolSelectionComponent {
  ProtocolTypes = ProtocolType;
  loading: boolean = false;
  projectId?: number;

  constructor(
    public dialogRef: MatDialogRef<ProtocolSelectionComponent>,
    private n: NavigateService,
    private pService: ProtocolApiService,
    private store: StorageService,
    private s: SnackBarService,
    private t: TranslateService,
    public appTourService: AppTourService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  navigate(name: string) {
    this.dialogRef.close();
    this.n.navigate(name);
  }

  createNewDraft(type: ProtocolType) {
    if (!this.data?.ignoreTutorial) {
      if (
        !this.store.UserPerson.shownTutorials ||
        !this.store.UserPerson.shownTutorials[AppTourTypes.PROTOCOL_MANAGE]
      ) {
        this.editTour();
        return;
      }
    }

    if (this.store.Employee.isClient) {
      this.s.error(this.t.instant('Protocols.noPermissionCreate'));
      return;
    }
    if (this.loading) {
      return;
    }
    this.pService.manager.clearService();
    this.loading = true;
    this.pService.manager.protocolType = type;
    this.pService
      .createProtocolDraft(this.pService.manager.ProtocolDraft)
      .subscribe({
        next: (protocol: Protocol) => this.successCreatingDraft(type, protocol),
        error: () => {}
      })
      .add(() => (this.loading = false));
  }

  editTour() {
    this.appTourService.initTour(AppTourTypes.PROTOCOL_MANAGE);
    this.dialogRef.close();
  }

  successCreatingDraft(type: ProtocolType, protocol: Protocol) {
    this.dialogRef.close();
    if (!!this.data?.successCallback) {
      this.data.successCallback(protocol);
      return;
    }

    switch (type) {
      case ProtocolType.TYPE_ACCEPTANCE:
        this.n.go(
          'acceptance-protocol-new',
          { id: protocol.id },
          { queryParams: { projectId: this.projectId } }
        );
        break;
      case ProtocolType.TYPE_PROJECT:
        this.n.go(
          'project-protocol-new',
          { id: protocol.id },
          { queryParams: { projectId: this.projectId } }
        );
        break;
      case ProtocolType.TYPE_MEETING:
        this.n.go(
          'meeting-protocol-new',
          { id: protocol.id },
          { queryParams: { projectId: this.projectId } }
        );
        break;
      default:
        break;
    }
  }
}
