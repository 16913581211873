import { LanguageService } from '@core/services/language.service';
import {  Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { BaseComponent } from '@shared/components/base.component';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './auth-panel.component.html',
  styleUrls: ['./auth-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthPanelComponent extends BaseComponent implements OnInit {
  isFullWidth: boolean = false;

  get isRegister() {
    return !!this.active.snapshot?.firstChild?.data?.register;
  }

  constructor(private active: ActivatedRoute, private langService: LanguageService) {
    super();
  }

  ngOnInit() {
    this.initLanguage();
    this.updateFullWidth();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.updateFullWidth());
  }

  initLanguage() {
    const lang = this.langService.getInitLanguage();
    this.langService.useLanguage(lang).subscribe();
  }

  updateFullWidth() {
    this.isFullWidth = !!this.deepestChild?.params['confirmation-token'];
  }

  get deepestChild(): ActivatedRouteSnapshot {
    const getChild = (child: ActivatedRouteSnapshot) => {
      if (!child.firstChild) {
        return child;
      }
      return getChild(child.firstChild);
    };
    return getChild(this.active.snapshot.firstChild);
  }
}
