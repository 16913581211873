import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { GbxsoftSelectConfig } from '@form/src/lib/gbxsoft-select/gbxsoft-select.interface';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { Regex } from '@shared/configs/regex';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectTeamService } from '@modules/projects/shared/services/project-team.service';
import { IAddTeamMember } from '@modules/projects/shared/interfaces/add-team-member.interface';
import { StorageService } from '@core/services/storage.service';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { GbxsoftSelectComponent } from '@form/src/lib/gbxsoft-select/gbxsoft-select.component';
import { ProjectTeamManageSuccessModalComponent } from '@project-modules/project-team/pages/project-team-list/components/project-team-manage-modal/components/project-team-manage-success-modal/project-team-manage-success-modal.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';

@Component({
  selector: 'project-team-manage-modal',
  templateUrl: './project-team-manage-modal.component.html',
  styleUrls: ['./project-team-manage-modal.component.scss']
})
export class ProjectTeamManageModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;
  Config = Config;

  isNecessaryToChoseRole: boolean = false;

  form: FormGroup = new FormGroup({
    role: new FormControl('', [Validators.required]),
    user: new FormControl('', [Validators.required]),
    position: new FormControl('', [Validators.maxLength(30)]),
    hourlyRate: new FormControl('', [Validators.min(0), Validators.max(99999)])
  });

  loading: boolean = false;
  projectId: number;
  currencySymbol: string;
  positionSelectConfig: GbxsoftSelectConfig = {
    id: 'id',
    label: 'text',
    notFoundText: this.t.instant('Global.notFoundResults'),
    debounceTime: 0,
    valueName: 'id',
    placeholder: this.t.instant('ProjectTeam.Manage.role'),
    labelName: this.t.instant('ProjectTeam.Manage.role'),
  };

  positionSelectOptions = [];
  @Output('change') change: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('contactSearchSelect') contactSearchSelect: NgSelectExtensionDirective;
  @ViewChild('roleSelect') roleSelect: GbxsoftSelectComponent;

  get isMinHeight() {
    return this.contactSearchSelect?.select?.isOpen || this.roleSelect?.isOpened;
  }

  constructor(
    private t: TranslateService,
    public dialogRef: MatDialogRef<ProjectTeamManageModalComponent>,
    private dialog: MatDialog,
    private s: SnackBarService,
    private projectTeamService: ProjectTeamService,
    private store: StorageService,
    private changes: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.setCurrencySymbol();
    this.setPositions();
  }

  setPositions() {
    Object.keys(UserRoles).forEach((key) => {
      this.positionSelectOptions.push({
        id: UserRoles[key],
        text: this.t.instant('ROLES.' + key),
      });
    });
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return {name: $event, id: $event, newItem: true, label: $event};
  }

  submit() {
    const employeesAndEmails = this.getEmployeesAndEmailsToInvite(true);
    this.validChosenUsersRoles();
    this.form.markAllAsTouched();
    if (this.loading || !employeesAndEmails || !this.form.valid) {
      return;
    }
    const data: IAddTeamMember = Object.assign(this.form.value, employeesAndEmails);
    if (!data.emails.length && !data.employeeIds.length) {
      return this.dialogRef.close();
    }
    this.loading = true;
    this.projectTeamService.addTeamMember(data, this.projectId).subscribe({
      next: () => {
        this.change.emit();
        this.s.success(this.t.instant('ProjectTeam.Manage.successAddingTeamMember'));
        this.dialogRef.close();

        if (data.emails.length) {
          this.dialog.open(ProjectTeamManageSuccessModalComponent, {
            width: Config.DEFAULT_MODAL_WIDTH,
            autoFocus: false,
            data: {emails: data.emails}
          });
        }
      },
      error: () => {
        this.s.error(this.t.instant('ProjectTeam.Manage.errorAddingTeamMember'));
      }
    }).add(() => {
      this.loading = false;
      this.changes.detectChanges();
    });
  }

  validChosenUsersRoles() {
    if (this.isNecessaryToChoseRole) {
      this.form.get('role').setValidators([Validators.required]);
      this.form.get('role').markAsTouched();
    } else {
      this.form.get('role').clearValidators();
      this.form.get('role').setValue('');
      this.form.get('role').markAsTouched();
    }
  }

  onUserSelectChange() {
    const employeesAndEmails = this.getEmployeesAndEmailsToInvite();
    if (this.form.get('user').value.length && (!employeesAndEmails || !employeesAndEmails.employeeIds.length)) {
      this.isNecessaryToChoseRole = true;
    } else {
      this.isNecessaryToChoseRole = false;
    }
  }

  getEmployeesAndEmailsToInvite(withErrorSnackbar?: boolean): { emails, employeeIds } {
    let employeeIds = [];
    const emails = [];

    const users = this.form.get('user').value || [];

    let isError = false;

    users.map((user) => {

      if (user.type) { //check if its contact object

        if (user.employee) {
          employeeIds.push(user.employee.id);
        } else if (user.email) {
          emails.push(user.email);
        } else {
          withErrorSnackbar ? this.s.error(this.t.instant('ProjectTeam.Manage.errorAddingContact', {contactName: user.name})) : '';
          isError = true;
        }
      }

      if (user.label) { // add clear email
        emails.push(user.label);
      }
    });

    return isError ? null : {
      emails,
      employeeIds
    };
  }

  setCurrencySymbol() {
    this.currencySymbol = this.store.Employee?.company?.currency?.symbol;
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    if (control?.errors?.min?.min || control?.errors?.min?.min === 0) {
      messages[GbxsoftErrorTypes.min] = this.t.instant('FormErrors.min', {
        number: control.errors?.min?.min.toString(),
      });
    }

    if (control?.errors?.max?.max) {
      messages[GbxsoftErrorTypes.max] = this.t.instant('FormErrors.max', {
        number: control.errors?.max.max,
      });
    }

    return messages;
  }
}
