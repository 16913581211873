import { Subscription } from 'rxjs';
import { CommentaryViewType } from '@shared/modules/commentary/components/commentary-manage/commentary-manage.component';
import { FormControl } from '@angular/forms';
import { ChangeDetectorRef, Injector, ViewChild, Directive } from '@angular/core';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { CommentaryManageComponent } from '@shared/modules/commentary/components/commentary-manage/commentary-manage.component';
import { CommentaryType } from '@shared/modules/commentary/enums/commentary-type.enum';
import { Commentary } from '@shared/modules/commentary/models/commentary.model';
import {
  EntryManageType,
  IEntryEmitter,
} from '@shared/modules/entry/components/entry-list/entry-list.component';
import { EntryObjectType } from '@shared/modules/entry/enums/entry-object-type.enum';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { IEntryTemplateConfig } from '../../interfaces/entry-template-config.interface';
import { ProjectDataBox } from '../../models/project-data-box.model';
import { EntryAction } from '@modules/protocols/shared/enums/entry-action.enum';
import { AppInjector } from '@shared/services/app-injector.service';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { ProjectStoreService } from '../../services/project-store.service';

@Directive()
export abstract class ProjectQuestionDirectoryBase {
  CommentaryViewType = CommentaryViewType;
  EntryManageType = EntryManageType;
  EntryObjectType = EntryObjectType;
  CommentaryType = CommentaryType;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  additionalComment: FormControl = new FormControl('');

  entryListsConfig: IEntryTemplateConfig[] = [];

  injector: Injector;
  projectPreviewService: ProjectPreviewService;

  subSaving: Subscription;

  showRemovedEntries: FormControl = new FormControl(false);

  @ViewChild('comments') comments: CommentaryManageComponent;

  get directoryModel() {
    return {
      additionalComment: this.additionalComment.value,
    };
  }

  get isBasicBox2Filled() {
    const p = this.projectStore.projectCtrl.project;
    return !!p.dataBox2?.entries?.length || !!p.dataBox2?.comments?.length || !!p.basicDataBox?.tasks?.length;
  }

  get isBasicBox3Filled() {
    const p = this.projectStore.projectCtrl.project;
    return !!p.dataBox3?.entries?.length || !!p.dataBox3?.comments?.length || !!p.basicDataBox?.tasks?.length;
  }

  get project() {
    return this.projectStore?.projectCtrl?.project;
  }

  constructor(public projectStore: ProjectStoreService, public changes: ChangeDetectorRef) {
    this.injector = AppInjector.getInjector();
    this.projectPreviewService = this.injector.get(ProjectPreviewService);
  }

  isTitleVisible(index: number) {
    const show = !!this.showRemovedEntries.value;

    if (show) {
      return !!this.entryListsConfig[index].list.filter((i) => !!i.deleted).length;
    }

    return !!this.entryListsConfig[index].list.filter((i) => !i.deleted).length;
  }

  getListByType(type: EntryType, boxName: string) {
    const list = this.projectStore.projectCtrl?.project[boxName]?.entriesTypeList[type] || [];
    return list;
  }

  filterList(list: IEntryTemplateConfig[], boxName: string, type: EntryManageType) {
    return list.map((i) => {
      let j = Object.assign({}, i);
      j.list = this.getListByType(j.entryType, boxName);
      j.type = type;
      return j;
    });
  }

  updateListEntry(list: IEntryTemplateConfig[], $event: IEntryEmitter, type: EntryType, boxName: string) {
    const index = this.entryListsConfig.findIndex((l) => l.entryType === type);
    if (!this.projectStore.projectCtrl.project[boxName]) {
      this.projectStore.projectCtrl.project[boxName] = new ProjectDataBox();
    }

    if (index > -1) {
      this.entryListsConfig[index].list = $event.data.list;
      this.projectStore.projectCtrl.project[boxName].entriesTypeList[type] = $event.data.list;
    }

    this.updateEntryEventList($event, boxName);
    this.entryListsConfig = this.filterList(list, boxName, this.entryListsConfig[0]?.type);
    this.changes.detectChanges();
  }

  updateEntryEventList($event: IEntryEmitter, boxName) {
    if (!this.projectStore.projectCtrl.project[boxName]) {
      this.projectStore.projectCtrl.project[boxName] = new ProjectDataBox();
    }

    switch ($event.type) {
      case EntryAction.EDIT:
      case EntryAction.RESTORE:
        {
          let index = this.projectStore.projectCtrl.project[boxName].entries.findIndex(
            (i) => i.id === $event.data.item.id,
          );
          index > -1
            ? (this.projectStore.projectCtrl.project[boxName].entries[index] = $event.data.item)
            : null;
        }
        break;
      case EntryAction.ADD:
        this.projectStore.projectCtrl.project[boxName].entries.push($event.data.item);
        break;
    }
  }

  updateCommentaries(c: Commentary, boxName: string) {
    const _data = this.projectStore.projectCtrl.project[boxName] || {};
    const basic: any = Object.assign(_data, {
      comments: this.comments.commentaries,
    });

    this.projectStore.projectCtrl.project[boxName] = new ProjectDataBox(basic);
    this.changes.detectChanges();
  }

  abstract submitDirectoryModel(): void;
}
