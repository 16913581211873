import { Component, OnInit } from '@angular/core';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';

@Component({
  selector: 'event-data',
  templateUrl: './event-data.component.html',
  styleUrls: ['./event-data.component.scss']
})
export class EventDataComponent implements OnInit {
  constructor(public service: EventSidenavService) {}

  ngOnInit(): void {}

  openGoogleMaps(address: string) {
    if (!address) return;
    window.open(`https://www.google.com/maps/search/${address}`, '_blank');
  }

  sendMail(email: string) {
    if (!email) return;
    window.location.href = `mailto:${email}`;
  }

  call(phone: string) {
    if (!phone) return;
    window.location.href = `tel:${phone}`;
  }
}
