import { downloadFile } from '@shared/helpers/download-file.helper';
import { Config } from '@shared/configs/config';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { Attachment } from '@shared/interfaces/attachment.interface';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { BaseAttachmentComponent } from '@shared/modules/attachments/components/base-attachment/base-attachment.component';
import { SnackBarService } from '@core/services/snackbar.service';
import { AttachmentsManageService } from '../../services/attachments-manage.service';
import { TextAttachment } from '@shared/components/text-attachments-input/models/text-attachment.model';
import { interval } from 'rxjs';
import * as moment from 'moment';
import { RemoveModalController } from '@shared/controllers/remove-modal.controller';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Component({
  selector: 'attachment-manager-list-v2',
  templateUrl: './attachment-manager-list-v2.component.html',
  styleUrls: ['./attachment-manager-list-v2.component.scss'],
  providers: [AttachmentsManageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentManagerListV2Component extends BaseAttachmentComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  counter: number = 0;
  entered: boolean = false;

  date: number = moment().unix();

  form: FormGroup;
  changingState: boolean = false;

  _attachments: Attachment[] = [];
  _showLimit: boolean = true;
  showAllFiles: boolean = false;
  fileLoading: boolean = false;
  fileDeleting: boolean = false;
  fileZipLoading: boolean = false;
  selectedFilesCount: number = 0;
  groupName: string = 'attachments';
  filesUploadCounter: number = 0;
  @Output('singleFileUploaded') singleFileUploaded: EventEmitter<any> = new EventEmitter();
  @Output('filesUploaded') filesUploaded: EventEmitter<boolean> = new EventEmitter();
  @Output('onAttachmentsChange') onAttachmentsChange: EventEmitter<Attachment[]> = new EventEmitter<
    Attachment[]
  >();

  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() disabled: boolean;
  @Input() isPreview: boolean;
  @Input() attachmentsConfig: TextAttachmentsConfig;

  @Input() apiConfig: any = null;
  @Input() uploadConfig: { id: number; objectType: string } = null;

  @Input() limit: number = 8;
  @Input()
  set showLimit(_showLimit: boolean) {
    this._showLimit = _showLimit;
    this.showAllFiles = !_showLimit;
  }
  get showLimit() {
    return this._showLimit;
  }

  @Input()
  set fileAttachments(_attachments: any[]) {
    _attachments.forEach((i: Attachment) => this.createFormControl(i));
    this._attachments = _attachments.map((i) => new Attachment(i));
    this.sortAttachmentsByDate();
    this.changes.detectChanges();
  }
  get fileAttachments() {
    return this._attachments;
  }

  get isDoubleAttachments() {
    return this.attachmentsConfig?.photoAttachments && this.attachmentsConfig?.fileAttachments;
  }

  get selectedFiles() {
    const ids = [];
    Object.keys((this.form.get(this.groupName) as FormGroup).controls).forEach((key: string) => {
      if (!!this.form.get(this.groupName).get(key).value) {
        ids.push(parseInt(key));
      }
    });
    return ids;
  }

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    public changes: ChangeDetectorRef,
    public s: SnackBarService,
    public authFilePipe: AuthFilePipe,
    public service: AttachmentsManageService,
  ) {
    super(translate, changes, s, authFilePipe);
    this.createForm();
  }

  ngOnInit() {
    this.subscribeOptionAllChange();
    this.subscribeOptionChange();
    this.subscribeFileUpload();
  }

  createForm() {
    this.form = this.fb.group({ [this.groupName]: this.fb.group({}), all: false });
  }

  dragEnter(e) {
    if (this.disabled) return;
    e.preventDefault(); // needed for IE
    this.counter++;
    this.entered = true;
    this.changes.detectChanges();
  }

  dragLeave() {
    if (this.disabled) return;
    this.counter--;
    if (this.counter === 0) {
      this.entered = false;
    }
    this.changes.detectChanges();
  }

  dragDropped() {
    if (this.disabled) return;
    this.counter = 0;
    this.entered = false;
    this.changes.detectChanges();
  }

  subscribeOptionAllChange() {
    this.form.get('all').valueChanges.subscribe((checked: boolean) => {
      if (this.changingState) {
        return;
      }
      const formGroup = this.form.get(this.groupName) as FormGroup;
      const controls = formGroup.controls;

      Object.keys(controls).forEach((key: string) => formGroup.get(key).setValue(checked));
      this.changes.detectChanges();
    });
  }

  subscribeOptionChange() {
    this.form.get(this.groupName).valueChanges.subscribe((values) => {
      this.changingState = true;
      const newValues = [...Object.values(values)];
      this.selectedFilesCount = Object.values(values).filter((i) => !!i).length;

      if (!!newValues.length) {
        if (newValues.indexOf(false) > -1) {
          !!this.form.get('all').value ? this.form.get('all').setValue(false) : null;
        } else {
          !this.form.get('all').value ? this.form.get('all').setValue(true) : null;
        }
      }
      this.changingState = false;
      this.changes.detectChanges();
    });
  }

  subscribeFileUpload() {
    this.uploadFile.subscribe((obj) => {
      //1. Upload file to server
      this.uploadFileToServer(obj.file, obj.callback);
      //2. Show mocked attachment
      this.fileAttachments = [obj.file].concat([...this.fileAttachments]);
      this.changes.detectChanges();
    });
  }

  uploadFileToServer(file: TextAttachment, callback?: Function) {
    this.uploadConfig
      ? this.uploadSingleAttachment(file, callback)
      : this.uploadFilesViaPacket(file, callback);
  }

  uploadSingleAttachment(file: TextAttachment, callback?: Function) {
    this.service.attachmentService
      .uploadAttachment(
        {
          objectId: this.uploadConfig.id,
          objectType: this.uploadConfig.objectType,
          files: [file.file],
        },
        this.apiConfig,
      )
      .subscribe({
        next: (list) => {
          this.successAttachments(file, list, callback);
        },
        error: (error) => this.handleUploadError(error, file)
      })
      .add(this.onUpdatePacket.bind(this));
  }

  uploadFilesViaPacket(file: TextAttachment, callback?: Function) {
    !this.fileAttachments.length
      ? this.createAttachmentPacket(file, callback)
      : this.updateAttachmentPacket(file, callback);
  }

  createAttachmentPacket(file: TextAttachment, callback?: Function) {
    this.service
      .createAttachmentPacket([file.file], this.apiConfig)
      .subscribe({
        next: (v) => this.successAttachments(file, v.attachments, callback),
        error: (error) => this.handleUploadError(error, file)
      })
      .add(this.onUpdatePacket.bind(this));
  }

  createAttachments(list) {
    list.forEach((attachment) => {
      const findIndex = this._attachments.findIndex((i) => i.id === attachment.id);
      if (findIndex === -1) {
        attachment = new Attachment(attachment);
        attachment.isUploaded = true;
        this._attachments.unshift(attachment);
        this.createFormControl(attachment);
        this.sortAttachmentsByDate();
        this.changes.detectChanges();
      }
    });
  }

  updateAttachmentPacket(file: TextAttachment, callback?: Function) {
    const source$ = interval(400)
      .pipe()
      .subscribe(() => {
        if (!!this.service?.attachmentPacket?.id) {
          this.updateAttachmentPacketUploaded(file);
          source$.unsubscribe();
        }
      });
  }

  sortAttachmentsByDate() {
    if (!this._attachments.length) return;
    this._attachments.sort(
      (a, b) => moment(b.created, Config.DATE_SERVER).unix() - moment(a.created, Config.DATE_SERVER).unix(),
    );
  }

  sortAttachmentsByName() {
    if (!this._attachments.length) return;
    this._attachments.sort((a, b) => a.name.localeCompare(b.name));
  }

  updateAttachmentPacketUploaded(file: TextAttachment, callback?: Function) {
    this.service
      .updateAttachmentPacket(this.service?.attachmentPacket?.id, [file.file], this.apiConfig)
      .subscribe({
        next: (v) => this.successAttachments(file, v.attachments, callback),
        error: (error) => this.handleUploadError(error, file)
      })
      .add(this.onUpdatePacket.bind(this));
  }

  successAttachments(file, list, callback?: Function) {
    callback ? callback(list.length ? list[list.length - 1] : null) : null;
    this.removeUploadingAttachment(file);
    this.createAttachments(list);
    this.onAttachmentsChange.emit(this.fileAttachments);
  }

  successLoadFilesEnd() {
    this.onAttachmentsChange.emit(this.fileAttachments);
  }

  onUpdatePacket() {
    // this.filesUploadCounter++;
    // if (this.filesUploadCounter >= this.validFiles) {
    //   this.filesUploadCounter = 0;
    //   this.filesUploaded.emit(true);
    // }
  }

  removeUploadingAttachment(file: TextAttachment) {
    this.removeUploadingAttachmentById(parseInt(file?.id?.toString()));
  }

  removeUploadingAttachmentById(id: number, updateService: boolean = false) {
    const index = this.fileAttachments.findIndex((i) => i.id.toString() === id.toString());
    this.fileAttachments.splice(index, 1);

    if (updateService) {
      const serviceIndex = this.service.attachmentPacket.attachments.findIndex(
        (i) => i.id.toString() === id.toString(),
      );
      this.service.attachmentPacket.attachments.splice(serviceIndex, 1);
    }

    this.changes.detectChanges();
    (this.form.get(this.groupName) as FormGroup).removeControl(id?.toString());
    this.changes.detectChanges();
  }

  createFormControl(atttachment: Attachment) {
    (this.form.get(this.groupName) as FormGroup).addControl(
      atttachment?.id.toString(),
      new FormControl(false),
    );
  }

  trackAttachments(index: number, attachment: Attachment) {
    return attachment.id;
  }

  downloadSelectedFiles() {
    if (this.fileZipLoading) return;
    this.fileZipLoading = true;
    this.changes.detectChanges();

    const data: string[] = this.fileAttachments
      .filter((i) => {
        return !!this.selectedFiles.filter((j) => j === i.id)[0];
      })
      .map((i) => i.file as any);

    this.service.attachmentService
      .getAttachmentZip(data)
      .subscribe({
        next: (zip) => this.successGettingZip(zip),
      })
      .add(() => {
        this.fileZipLoading = false;
        this.changes.detectChanges();
      });
  }

  successGettingZip(zip) {
    downloadFile(zip, '[intilio] file-attachments-' + moment().unix());
  }

  deleteSelectedFiles() {
    const ctrl = new RemoveModalController();
    ctrl
      .remove(() => {
        this.deleteAttachmentGroup();
      })
      .subscribe(() => {});
  }

  deleteAttachmentGroup() {
    if (this.fileDeleting || !this.selectedFiles?.length) return;
    this.fileDeleting = true;
    this.changes.detectChanges();
    const ids = this.selectedFiles;
    this.service.attachmentService
      .removeAttachmentGroup(ids)
      .subscribe({
        next: () => this.successDeletingAttachments(ids),
      })
      .add(() => {
        this.fileDeleting = false;
        this.changes.detectChanges();
      });
  }

  successDeletingAttachments(ids: number[]) {
    ids.forEach((id: number) => this.removeUploadingAttachmentById(id, true));
    this.onAttachmentsChange.emit(this.fileAttachments);
  }

  private handleUploadError(error: any, file: TextAttachment) {
    this.removeUploadingAttachment(file);
    if (error.messageCode === IntilioCodes.FILE_IS_BIG) {
      this.s.error(this.translate.instant('Settings.User.Errors.errorFileTooBig').replace('{{size}}', 10));
    } else {
      this.s.error(this.translate.instant('Attachments.errorUploading'));
    }
  }
}
