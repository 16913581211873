<content-with-tabs-v2
  [tabLinks]="tabLinks"
  [bottomBar]="showFooter$ | async"
  [customActivePaths]="true"
  [componentTemplate]="selectedComponent"
>
  <sidenav-title
    [titleTemplate]="titleTemplate"
    [isEditEnabled]="(isPreview$ | async) && contactData"
    [isRemoveEnabled]="(isPreview$ | async) && contactData"
    [closeFunc]="closeSideNav.bind(this)"
    [customClass]="'strict-height'"
    (removeClick)="removeContact()"
    (editClick)="editContact()"
  ></sidenav-title>

  <div *ngIf="showFooter$ | async" class="sticky-footer" footer>
    <ng-container *ngFor="let button of footerButtons">
      <app-button
        (onClick)="button.action()"
        [loading]="loading && button.loading"
        [disabled]="loading"
        [config]="{
          size: ButtonSize.LARGE,
          type: button.type,
          text: button.text | translate
        }"
      ></app-button>
    </ng-container>
  </div>
</content-with-tabs-v2>

<ng-template #titleTemplate>
  <div class="mr-2">
    <h2 *ngIf="contactData$ | async as contact; else defaultTitle" class="contact-title">
      <span class="contact-title__name" [matTooltip]="contact | contactName" matTooltipPosition="below">
        {{ contact | contactName }}
      </span>
      <span class="font-weight-light">{{ 'Contacts.V2.' + contact.type | translate }}</span>
    </h2>
    <ng-template #defaultTitle>
      <h2>{{ 'Contacts.V2.newContactTitle' | translate }}</h2>
    </ng-template>
  </div>
</ng-template>
