import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { catchError, map } from 'rxjs/operators';
import { NavigateService } from '@shared/services/navigate.service';
import { ProjectAPIService } from '@modules/projects/shared/services/project-api.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Project } from '@modules/projects/shared/models/project.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class ProjectEditPanelResolver implements Resolve<Project> {
  constructor(
    private service: ProjectAPIService,
    private projectStore: ProjectStoreService,
    private n: NavigateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Project> {
    return this.service.findProjectByID(route.params.projectid).pipe(
      map((i) => {
        this.projectStore.projectCtrl.setProject(i);
        this.projectStore.setDefault();
        this.projectStore.setInitialFormValues();
        return i;
      }),
      catchError((error) => {
        this.n.go('projects-list');
        return of(error);
      })
    );
  }
}
