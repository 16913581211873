import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ListService } from '@shared/modules/list/services/list.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { StorageService } from '@core/services/storage.service';
import { ListNotShowColumnConfig } from '@shared/modules/list/interfaces/list-not-show-column-config.interface';
import { ListEventType } from '@shared/modules/list/model/list-event.model';
import { TranslateService } from '@ngx-translate/core';
import { downloadExcel } from '@shared/helpers/download-file.helper';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @Input() title: string = 'List';
  @Input() headerTemplate: TemplateRef<any>;

  constructor(
    public service: ListService,
    private storage: StorageService,
    private changes: ChangeDetectorRef,
    private t: TranslateService
  ) {}

  ngOnInit(): void {
    this.initNotShowColumnFromStorage();
  }

  get end() {
    const end = (this.service?.table?.offset + 1) * this.service?.table?.pageSize;
    return end > this.service?.table?.rowCount ? this.service?.table?.rowCount : end;
  }

  onColumnShowChange(column: CustomTableColumn) {
    if (column.canNotShow === false) {
      return;
    }
    column.notShow = !column.notShow;
    this.changes.detectChanges();
    this.updateNotShowColumnsInStorage(column);
  }

  columnsSort(a: CustomTableColumn, b: CustomTableColumn) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  getDownloadableColumns() {
    let obj = {};
    const columns = [...this.service.config.columns];
    columns
      .filter((i) => i.exportPath)
      .forEach((column) => {
        Object.keys(column.exportPath).forEach((key: string) => {
          obj[this.t.instant(key)] = column.exportPath[key];
        });
      });
    return obj;
  }

  downloadExcel() {
    const obj = this.getDownloadableColumns();
    this.service.loading = true;
    this.changes.detectChanges();
    const data = Object.assign({ columns: obj });
    const params = Object.assign({}, this.service.getParams());
    this.service
      .postFileBlob(`${this.service.config.urlExport}-xls`, data, false, {}, params)
      .pipe(
        finalize(() => {
          this.service?.getRows();
          this.service.loading = false;
          this.changes.detectChanges();
        })
      )
      .subscribe({
        next: (data) => {
          const name = this.t.instant(`${this.service.config.listTitle}`);
          downloadExcel(`[XLS]-${name}`, data);
        }
      });
  }

  updateNotShowColumnsInStorage(column: CustomTableColumn) {
    const notShowColumnConfig: ListNotShowColumnConfig = this.storage.NotShowColumns || {};

    if (
      notShowColumnConfig[this.service.config.listName] &&
      notShowColumnConfig[this.service.config.listName].userId === this.storage.UserPerson.id
    ) {
      const index = notShowColumnConfig[this.service.config.listName].columns.indexOf(column.prop as string);
      if (column.notShow) {
        // add column prop to storage
        if (index === -1) {
          notShowColumnConfig[this.service.config.listName].columns.push(column.prop as string);
        }
      } else {
        if (index !== -1) {
          // remove column prop from storage
          notShowColumnConfig[this.service.config.listName].columns.splice(index, 1);
        }
      }
    } else {
      notShowColumnConfig[this.service.config.listName] = {
        userId: this.storage.UserPerson.id,
        columns: [column.prop as string]
      };
    }
    this.storage.NotShowColumns = notShowColumnConfig;
    this.service.eventEmitter.emit({ type: ListEventType.CONFIG_COLUMNS });
  }

  initNotShowColumnFromStorage() {
    const notShowColumnConfig: ListNotShowColumnConfig = this.storage.NotShowColumns || {};
    const columns = [];
    const hideColumns = [];
    const concated = this.service.config.columns.concat(this.service.config.hideColumns);
    if (
      notShowColumnConfig[this.service.config.listName] &&
      notShowColumnConfig[this.service.config.listName].userId === this.storage.UserPerson.id
    ) {
      const notShownColumns = notShowColumnConfig[this.service.config.listName].columns;
      for (let i = 0; i < concated.length; i++) {
        const column = concated[i];
        if (notShownColumns.indexOf(column.prop as string) !== -1) {
          hideColumns.push(column);
          column.notShow = true;
        } else {
          columns.push(column);
          column.notShow = false;
        }
      }
    } else if (
      !notShowColumnConfig[this.service.config.listName] ||
      notShowColumnConfig[this.service.config.listName].userId !== this.storage.UserPerson.id
    ) {
      //set all columns notShow=false if user didnt set any settings before
      for (let i = 0; i < concated.length; i++) {
        columns.push(concated[i]);
        concated[i].notShow = false;
      }
    }

    this.service.config.columns = [...columns.sort(this.columnsSort)];
    this.service.config.hideColumns = [...hideColumns];
    this.changes.detectChanges();
  }

  identify(index, item: CustomTableColumn) {
    return item.prop;
  }
}
