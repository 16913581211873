import { Injectable } from '@angular/core';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactManagePanelService {
  private _contactData = new BehaviorSubject<Contact | null>(null);
  private _isPreview = new BehaviorSubject<boolean>(true);
  private _isFormDirty = new BehaviorSubject<boolean>(false);
  private _dataChanged = new Subject<void>();

  defaultRestored = new Subject<void>();

  contactData$ = this._contactData.asObservable();
  isPreview$ = this._isPreview.asObservable();
  isFormDirty$ = this._isFormDirty.asObservable();
  dataChanged$ = this._dataChanged.asObservable();

  notifyDataChanged() {
    this._dataChanged.next();
  }

  setContactData(data: Contact) {
    this._contactData.next(data);
  }

  setIsPreview(isPreview: boolean) {
    this._isPreview.next(isPreview);
  }

  setFormDirty(isDirty: boolean) {
    this._isFormDirty.next(isDirty);
  }

  reset(): void {
    this._contactData.next(null);
    this._isPreview.next(true);
    this._isFormDirty.next(false);
  }
}
