<list-chips *ngIf="chipsView"></list-chips>
<div class="new-filters-modal" [hidden]="chipsView">
  <div class="title__wrapper">
    <span class="title">{{ 'List.Filters.filters' | translate }}</span>
    <svg-icon class="clear-icon" [src]="'/assets/img/close-icon.svg'" (click)="cancel()"></svg-icon>
  </div>
  <div class="rows__wrapper">
    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectChanges.List.creator' | translate"
        [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
        [form]="service?.filtersForm"
        [controlName]="'[a-creator][eq]'"
        (change)="onChangeFilter()"
        [multiple]="true"
        [searchGetParams]="'search'"
        [requiredIdUrl]="Config.API + '/employee/search'"
        [valueTransform]="creatorName.bind(this)"
        (selectedValues)="handleSelectedValues($event, '[a-creator][eq]')"
      ></list-single-filter-select-v2>

      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectChanges.List.status' | translate"
        [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
        [form]="service?.filtersForm"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [controlName]="'[a-changeStatus][eq]'"
        (change)="onChangeFilter()"
        [localItems]="statuses"
        (selectedValues)="handleSelectedValues($event, '[a-changeStatus][eq]')"
      ></list-single-filter-select-v2>
    </div>

    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectChanges.List.source' | translate"
        [iconUrl]="'/assets/img/ic-check-square.svg'"
        [form]="service?.filtersForm"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [controlName]="'[a-source][eq]'"
        (change)="onChangeFilter()"
        [localItems]="sources"
        (selectedValues)="handleSelectedValues($event, '[a-source][eq]')"
      ></list-single-filter-select-v2>

      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectChanges.List.type' | translate"
        [iconUrl]="'/assets/img/ic-check-square.svg'"
        [form]="service?.filtersForm"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [control]="typeControl"
        [multiple]="true"
        (change)="onTypeChange()"
        [localItems]="types"
      ></list-single-filter-select-v2>
    </div>

    <div class="new-filters-modal__row">
      <list-single-filter-select-v2
        class="w-100"
        [name]="'ProjectChanges.List.settlementStatus' | translate"
        [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
        [form]="service?.filtersForm"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [controlName]="'[a-settlementStatus][eq]'"
        (change)="onChangeFilter()"
        [localItems]="settlements"
        (selectedValues)="handleSelectedValues($event, '[a-settlementStatus][eq]')"
      ></list-single-filter-select-v2>

      <list-single-filter-range-date-v2
        #dateRanePickerFilter
        class="w-100"
        [name]="'ProjectChanges.List.dateRange' | translate"
        [iconUrl]="'/assets/img/ic-calendar.svg'"
        [form]="service?.filtersForm"
        [fromControlName]="'[a-termStart][gte]'"
        [toControlName]="'[a-termEnd][lte]'"
        (change)="onChangeFilter()"
      >
      </list-single-filter-range-date-v2>
    </div>
  </div>
  <div class="buttons">
    <list-single-clear-filters-v2
      [form]="service?.filtersForm"
      (change)="onChangeFilter()"
      [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
      [clearFilters]="clearFilters.bind(this)"
    >
    </list-single-clear-filters-v2>
    <div class="buttons__inside">
      <app-button
        [type]="'button'"
        (onClick)="cancel()"
        [config]="{
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
          text: 'DATERANGEPICKER.cancelBtn' | translate
        }"
      ></app-button>

      <app-button
        [type]="'button'"
        [disabled]="loading"
        [loading]="loading"
        (onClick)="submit()"
        [config]="{
          size: ButtonSize.LARGE,
          smSize: ButtonSize.SMALL,
          type: ButtonTypes.NEW_PRIMARY_BLUE,
          text: 'DATERANGEPICKER.acceptBtn' | translate,
          prependSrc: './assets/img/tick-icon.svg'
        }"
      ></app-button>
    </div>
  </div>
</div>
