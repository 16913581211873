import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { Project } from '@modules/projects/shared/models/project.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Config } from '@shared/configs/config';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { ContactAccessProject } from '@shared/models/contact-access-project.model';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { take, filter, switchMap } from 'rxjs/operators';
import { ContactManagePanelService } from '../../services/contact-manage-panel.service';
import { ContactServiceV2 } from '../../services/contact-v2.service';
import { ContactAddToProjectComponentV2 } from '@modules/contacts/shared/components/contact-add-to-project-v2/contact-add-to-project-v2.component';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';

@Component({
  templateUrl: './contact-projects.component.html',
  styleUrls: ['./contact-projects.component.scss']
})
export class ContactProjectsComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  activeProjects: ContactAccessProject[] = [];
  inactiveProjects: ContactAccessProject[] = [];
  contact: Contact;
  newProjectButtonLoading = false;

  constructor(
    private managePanelService: ContactManagePanelService,
    private dialog: MatDialog,
    private contactsService: ContactServiceV2,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private sideNav: SidenavService
  ) {}

  ngOnInit(): void {
    this.setInitialData();
  }

  setInitialData(): void {
    this.managePanelService.contactData$
      .pipe(
        take(1),
        switchMap(({ id }) => this.contactsService.getContact(id))
      )
      .subscribe((contact) => {
        this.activeProjects = contact.contactProjectsInProgress;
        this.inactiveProjects = contact.contactProjectsFinished;
        this.contact = contact;
      });
  }

  addNewProject(): void {
    this.newProjectButtonLoading = true;
    const ctrl = new ProjectController();
    ctrl.add(this.contact.id).add(() => {
      this.newProjectButtonLoading = false;
      setTimeout(() => this.sideNav.close(), 500);
    });
  }

  addExistingProject(): void {
    const currentProjectIds = this.activeProjects
      .map(({ project, mainProject }) => project?.id || mainProject?.id || null)
      .filter((id) => id !== null);

    this.dialog
      .open(ContactAddToProjectComponentV2, {
        width: Config.DEFAULT_MODAL_WIDTH,
        autoFocus: false,
        data: { contact: this.contact, currentProjectIds }
      })
      .afterClosed()
      .pipe(filter((confirmed) => !!confirmed))
      .subscribe(({ basicDataBox, ...project }) => {
        if (this.checkIfProjectInactive(project)) {
          this.inactiveProjects.push({
            ...basicDataBox.contacts.find(({ contact: c }) => c.id === this.contact.id),
            project
          });
        } else {
          this.activeProjects.push({
            ...basicDataBox.contacts.find(({ contact: c }) => c.id === this.contact.id),
            project
          });
        }
      });
  }
  checkIfProjectInactive(project: Project): boolean {
    return project.stage === ProjectStage.STAGE_FINISHED || project.stage === ProjectStage.STAGE_LOST;
  }

  onRemove({ id }: ContactAccessProject): void {
    this.dialog
      .open(ConfirmModalComponent, {
        width: Config.CONFIRM_MODAL_WIDTH,
        data: {
          title: 'Contacts.V2.Projects.removeProjectModalTitle',
          body: 'Contacts.V2.Projects.removeProjectModalBody',
          submitButtonText: 'Contacts.V2.Projects.removeProjectModalSubmit',
          submitButtonType: ButtonTypes.NEW_RED,
          submitButtonIcon: './assets/img/ic-warning-v2.svg'
        }
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.contactsService
            .removeContactFromProject(id)
            .pipe(take(1))
            .subscribe(
              () => {
                this.activeProjects = this.activeProjects.filter((p: ContactAccessProject) => p.id !== id);
                this.snackBarService.success(
                  this.translateService.instant('Contacts.V2.Projects.contactRemovedFromProject')
                );
              },
              () =>
                this.snackBarService.error(
                  this.translateService.instant('Contacts.V2.Projects.errorContactRemovedFromProject')
                )
            );
        }
      });
  }
}
