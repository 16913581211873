<div class="subtask-create" [formGroup]="form">
  <div class="mb-2">
    <div class="new-select-label">{{ 'Tasks.newTask' | translate }}</div>
    <input-v2
      #taskTitle
      [errMessages]="errorMessages"
      [class]="'required'"
      [formControlName]="TASK_SIDENAV_FORM.description"
      [placeholder]="'ProjectContacts.List.name' | translate"
    ></input-v2>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="new-select-label">{{ 'Projects.assignedPerson' | translate }}</div>
      <employee-search-v2
        #employeeSearch
        [form]="form"
        [projectId]="taskService.formProjectId"
        [controlName]="TASK_SIDENAV_FORM.assignedTo"
      ></employee-search-v2>
    </div>
    <div class="col-12 mb-2">
      <div class="new-select-label">{{ 'Protocols.realizationTerm' | translate }}</div>
      <daterangepicker-v2
        #datepicker
        (rangeChanged)="rangeChanged($event)"
        [interactive]="true"
        [isCalendarTime]="true"
        [title]="'Projects.selectDeadline' | translate"
      ></daterangepicker-v2>
    </div>
  </div>
  <div class="mt-2 d-flex flex-column flex-sm-row justify-content-between w-100 align-items-center relative">
    <app-action-button-link-like
      class="mb-3 mb-sm-0"
      [hidden]="form.dirty"
      [text]="'cancel' | translate"
      (onClick)="discardAdding()"
    ></app-action-button-link-like>
    <app-action-button-link-like
      class="mb-3 mb-sm-0"
      [hidden]="!form.dirty"
      confirmationPopover
      (confirm)="discardAdding()"
      [cancelText]="'Tasks.discard' | translate"
      [confirmText]="'Tasks.accept' | translate"
      [placement]="WindowHelper.isMobileWidth ? 'top' : 'right'"
      [popoverTitle]="'Tasks.acceptDiscardSubtask' | translate"
      [popoverMessage]="'Tasks.acceptDiscardSubtaskText' | translate"
      [text]="'cancel' | translate"
    ></app-action-button-link-like>

    <app-button
      (onClick)="saveSubtask()"
      [class]="'px-3'"
      [disabled]="loading"
      [loading]="loading"
      [config]="{
        type: ButtonTypes.NEW_SECONDARY_BLUE,
        size: ButtonSize.SMALL,
        text: 'Tasks.saveSubtask' | translate
      }"
    ></app-button>
  </div>
</div>
