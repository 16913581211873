<div class="new-modal">
  <div class="title__wrapper">
    <span class="title">{{ data?.title | translate }}</span>
    <svg-icon
      class="clear-icon"
      src="/assets/img/close-icon.svg"
      (click)="cancel(IUnsavedStates.DISCARD)"
    ></svg-icon>
  </div>

  <div class="new-modal__content">
    <p>{{ data?.text | translate }}</p>
  </div>

  <div class="d-flex justify-content-end mt-4">
    <app-button
      class="mr-2"
      (onClick)="cancel(IUnsavedStates.DISCARD)"
      [config]="{
        text: data?.cancel | translate,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>

    <app-button
      class="mr-2"
      (onClick)="cancel(IUnsavedStates.UNLOAD)"
      [config]="{
        text: data?.unload | translate,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>

    <app-button
      (onClick)="cancel(IUnsavedStates.SAVE_UNLOAD)"
      [config]="{
        text: data?.saveUnload | translate,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>
  </div>
</div>
