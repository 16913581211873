import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ListEventType } from '@shared/modules/list/model/list-event.model';
import { ListService } from '@shared/modules/list/services/list.service';
// import Event = JQuery.Event;

@Component({
  selector: 'list-checkbox',
  templateUrl: './list-checkbox.component.html',
  styleUrls: ['./list-checkbox.component.scss']
})
export class ListCheckboxComponent implements OnInit, OnDestroy {
  @Input() row: any;
  @Output() onCheckboxChange: EventEmitter<ListCheckboxEvent> = new EventEmitter<ListCheckboxEvent>();
  @ViewChild('input') input: ElementRef;

  sub: Subscription = new Subscription();

  constructor(private listService: ListService) {}

  ngOnInit() {
    this.clearAllCheckboxes();
  }

  clearAllCheckboxes() {
    const sub = this.listService.eventEmitter
      .pipe(filter((i) => i.type === ListEventType.CLEAR_CHECKBOX_SELECTION))
      .subscribe(() => {
        this.input.nativeElement.checked = false;
      });
    this.sub.add(sub);
  }

  onChange(e) {
    e.target.checked
      ? this.listService.eventEmitter.emit({
          type: ListEventType.SINGLE_CHECKBOX_SELECT,
          data: { row: this.row }
        })
      : this.listService.eventEmitter.emit({
          type: ListEventType.SINGLE_CHECKBOX_UNSELECT,
          data: { row: this.row }
        });
  }

  checkAll(e) {
    if (e.target.checked) {
      this.listService.rows = this.listService.rows.map((row: any) => {
        row['checked'] = true;
        return row;
      });
      this.listService.eventEmitter.emit({ type: ListEventType.ALL_CHECKBOXES_SELECT });
    } else {
      this.listService.rows = this.listService.rows.map((row: any) => {
        row['checked'] = false;
        return row;
      });
      this.listService.eventEmitter.emit({ type: ListEventType.ALL_CHECKBOXES_UNSELECT });
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}

export interface ListCheckboxEvent {
  value: boolean;
  row: any;
}
