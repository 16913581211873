import { CopyDirective } from '../shared/directives/copy.directive';
import { ChangeValuePipe } from '@shared/pipes/change-value.pipe';
import { PricePipe } from '@shared/pipes/price.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GbxsoftFormModule } from '@form/src/public-api';
import { NgSelectModule } from '@ng-select/ng-select';
import { PermissionsDecoratorService } from './permissions/services/permissions-decorator.service';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { ModalTitleComponent } from '@shared/components/modal-title/modal-title.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DragDropDirective } from '@shared/components/text-attachments-input/directives/drag-drop.directive';
import { CustomConfirmationPopoverDirective } from '@shared/libs/angular-confirmation-directive/custom-confirmation-popover.directive';
import { MatMenuModule } from '@angular/material/menu';
import { TourMatMenuModule } from '@shared/libs/ngx-tour/ngx-tour-md-menu/src/lib/md-menu.module';
import { DocPreviewDirective } from '@shared/directives/doc-preview.directive';
import { DocPreviewerComponent } from '@shared/components/doc-previewer/doc-previewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DateTimeFormatPipe } from '@shared/pipes/dateTimeFormat.pipe';
import { DateFormatPipe } from '@shared/pipes/dateFormat.pipe';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactPreviewGuard } from '@core/guards/contacts/contact-preview.guard';
import { ContactManageActivateGuard } from '@core/guards/contacts/contact-manage-activate.guard';
import { UIModule } from '@shared/modules/ui/ui.module';
import { PermissionDirectiveModule } from '@shared/modules/permission-directive/permission-directive.module';
import { NgSelectExtendedModule } from '@shared/modules/ng-select-extended/ng-select-extended.module';
import { TextAttachmentsInputComponent } from '@shared/components/text-attachments-input/text-attachments-input.component';
import { RangeFormatPipe } from '@shared/pipes/rangeFormat.pipe';
import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { CustomPdfViewerComponent } from '@shared/components/custom-pdf-viewer/custom-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PriceFormatPipe } from '@shared/pipes/price-format.pipe';
import { RangeFormatV2Pipe } from '@shared/pipes/range-format-v2.pipe';

const CORE_DIRECTIVES = [
  NgSelectExtensionDirective,
  DragDropDirective,
  CustomConfirmationPopoverDirective,
  DocPreviewDirective,
  CopyDirective
];

const CORE_PIPES = [
  AuthFilePipe,
  DateTimeFormatPipe,
  DateFormatPipe,
  RangeFormatPipe,
  RangeFormatV2Pipe,
  PricePipe,
  PriceFormatPipe,
  ChangeValuePipe
];
const CORE_COMPONENTS = [
  TextAttachmentsInputComponent,
  ModalTitleComponent,
  DocPreviewerComponent,
  DateRangePickerComponent,
  CustomPdfViewerComponent
];

const CORE_MODULES = [
  CommonModule,
  NgSelectExtendedModule,
  PermissionDirectiveModule,
  MatTooltipModule,
  TourMatMenuModule,
  TranslateModule,
  UIModule,
  AngularSvgIconModule,
  FormsModule,
  ReactiveFormsModule,
  GbxsoftFormModule,
  MatMenuModule,
  MatDialogModule,
  PdfViewerModule,
  NgxDaterangepickerMd.forRoot()
];

@NgModule({
  declarations: [CORE_DIRECTIVES, CORE_PIPES, CORE_COMPONENTS],
  exports: [NgSelectModule, CORE_MODULES, CORE_COMPONENTS, CORE_PIPES, CORE_DIRECTIVES],
  imports: [CORE_MODULES, NgxExtendedPdfViewerModule],
  providers: [PermissionsDecoratorService, ContactPreviewGuard, ContactManageActivateGuard, CORE_PIPES],
  bootstrap: []
})
export class CoreModule {
  /**
   * Creates an instance of CoreModule. PermissionsDecoratorService necessary to initialize for permissions decorator service
   * @author Robert Juszczyk
   * @param {DecoratorService} service
   * @memberof CoreModule
   */
  constructor(permissionsDecoratorService: PermissionsDecoratorService) {}
}
