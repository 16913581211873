export const CONTACT_FORM = {
  type: 'type',
  name: 'name',
  firstName: 'firstName',
  email: 'email',
  functions: 'functions',
  availability: 'availability',
  discount: 'discount',
  lastName: 'lastName',
  birth: 'birth',
  address: 'address',
  postalCode: 'postalCode',
  town: 'town',
  country: 'country',
  phone: 'phone',
  phoneCountry: 'phoneCountry',
  comment: 'comment',
  taxNumber: 'taxNumber',
  taxNumberEU: 'taxNumberEU',
  companyName: 'companyName',
  website: 'website',
  acquisitionSource: 'acquisitionSource',
  links: 'links',
  isNetPrices: 'isNetPrices',
  id: 'id',
  parentContactId: 'parentContactId',
  isContactPerson: 'isContactPerson',
  parentContact: 'parentContact',
  isResponsible: 'isResponsible',
  isMainContact: 'isMainContact',
  contactType: 'contactType',
  status: 'status',
  parentsIds: 'parentsIds',
  parentContacts: 'parentContacts',
  communications: 'communications',
  tagIds: 'tagIds'
};

export const ContactDetails = {
  type: 'type',
  name: 'name',
  companyName: 'companyName',
  lastName: 'lastName',
  firstName: 'firstName',
  birth: 'birth',
  functions: 'functions',
  tagIds: 'tagIds',
  phone: 'phone',
  phoneCountry: 'phoneCountry',
  email: 'email',
  communications: 'communications',
  address: 'address',
  postalCode: 'postalCode',
  town: 'town',
  country: 'country',
  links: 'links',
  linkName: 'name',
  linkUrl: 'link',
  taxNumber: 'taxNumber',
  taxNumberEU: 'taxNumberEU',
  discount: 'discount',
  isNetPrices: 'isNetPrices',
  availability: 'availability'
} as const;
